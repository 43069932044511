const zhCN = {
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostCPUUtilization':
    '主机CPU使用率',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.PodCPUUsage':
    'Pod-CPU使用率',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostMemory':
    '主机内存',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.PodMemoryUsage':
    'Pod内存使用率',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostDiskRead':
    '主机磁盘读取',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostDiskWrite':
    '主机磁盘写入',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostNetRecv':
    '主机网络接收',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostNetSent':
    '主机网络发送',

  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareElasticSearchPage.ThreadPool':
    '线程池',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareElasticSearchPage.Transport':
    '传输',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareElasticSearchPage.JVM':
    'Java虚拟机',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareElasticSearchPage.OS':
    '操作系统',

  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareKafkaPage.Offset':
    '偏移量',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareKafkaPage.Lag':
    '延迟',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareKafkaPage.Partition':
    '分区',

  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.SlowQueryCount':
    '慢查询计数',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.CommandCount':
    '命令计数',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.InnodbRows':
    'Innodb行集',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.Connections':
    '连接数',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.Threads':
    '线程数',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.Storages':
    '储存设备数',

  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.Keys':
    '键名集',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.Memory':
    '内存',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.KeyspaceHitsAndMisses':
    '命中次数/未命中次数',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.Evicted':
    '驱逐/过期',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.Clients':
    '客户端数',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.InstantaneousOPS':
    '瞬时并发量',

  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMongoDBPage.latency':
    '延迟',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMongoDBPage.repl_lag':
    '同步延迟',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMongoDBPage.assert':
    '断言',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMongoDBPage.queuedRequest':
    '队列请求',

  'app.easeMiddleware.base.detail.InstanceDetailPage.restart':
    '修改配置更改可能会导致服务重新启动。',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Two-FactorAuthenticating':
    '双因素认证',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Hosts': '主机集',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Port': '端口',
  'app.easeMiddleware.base.detail.InstanceDetailPage.RootUser': '管理员用户',
  'app.easeMiddleware.base.detail.InstanceDetailPage.RootPassword':
    '管理员密码',
  'app.easeMiddleware.base.detail.InstanceDetailPage.DatabaseUser': '普通用户',
  'app.easeMiddleware.base.detail.InstanceDetailPage.DatabasePassword':
    '普通密码',
  'app.easeMiddleware.base.detail.InstanceDetailPage.DataSize': '磁盘用量',
  'app.easeMiddleware.base.detail.InstanceDetailPage.RunningStatus': '运行状态',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Overview': '总览',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Tags': '标签',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Backups': '备份',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Monitor': '监控',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Logs': '日志',
  'app.easeMiddleware.base.detail.InstanceDetailPage.configuration': '配置',
  'app.easeMiddleware.base.detail.InstanceDetailPage.WebConsole': 'Web控制台',

  'app.easeMiddleware.base.detail.components.Overview.PrimaryInfo': '主要信息',
  'app.easeMiddleware.base.detail.components.Overview.HostsInfo': '主机信息',
  'app.easeMiddleware.base.detail.components.Overview.AccountInfo': '账户信息',
  'app.easeMiddleware.base.detail.components.Overview.NodeInfo': '节点信息',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.Restore': '恢复',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.BackupID': '备份ID',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.HostID': '主机ID',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.HostIP': '主机IP',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.NodeID': '节点ID',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.FilePath': '文件路径',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.BackupStatus':
    '备份状态',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.Hours': '小时数',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.Minutes': '分钟数',

  'app.easeMiddleware.base.detail.MiddlewareInstanceTagPage.UnbindConfirmation':
    '您确定要解绑这个标签吗?',

  'app.easeMiddleware.base.detail.TwoFactorAuthModal.Two-FactorAuthenticationCode':
    '出于安全考虑，请先输入双因素认证码:',

  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.DeleteConfirmation':
    '确认删除',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.RestoreConfirmation':
    '确认恢复',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.logo': '图标',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.SearchBy':
    '搜索过滤',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.InstanceName':
    '服务名称',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.Tags': '标签',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.TagKey':
    '标签键',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.TagValue':
    '标签值',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.HostName':
    '服务器名称',

  'app.easeMiddleware.base.host.HostMgmtPage.host_name': '服务器名称',
  'app.easeMiddleware.base.host.HostMgmtPage.ssh_port': 'SSH端口',
  'app.easeMiddleware.base.host.HostMgmtPage.ip_addr': 'IPv4',
  'app.easeMiddleware.base.host.HostMgmtPage.cpu': 'CPU',
  'app.easeMiddleware.base.host.HostMgmtPage.memory': '内存 (GB)',
  'app.easeMiddleware.base.host.HostMgmtPage.storage': '存储 (GB)',
  'app.easeMiddleware.base.host.HostMgmtPage.middleware': '中间件',
  'app.easeMiddleware.base.host.HostMgmtPage.tag': '标签',

  'app.easeMiddleware.base.host.HostTagPage.tag_key': '标签名',
  'app.easeMiddleware.base.host.HostTagPage.tag_value': '标签值',
  'app.easeMiddleware.base.host.HostTagPage.unbind': '解绑',
  'app.easeMiddleware.base.host.HostTagPage.CreatingTagsinBatches':
    '批量创建标签',
  'app.easeMiddleware.base.host.HostTagPage.AddTag': '添加标签',

  'app.easeMiddleware.base.host.HostEditPage.UserName': '用户名',
  'app.easeMiddleware.base.host.HostEditPage.Password': '密码',
  'app.easeMiddleware.base.host.HostEditPage.ClickToChooseSSHKey':
    '点击选择SSH密钥',
  'app.easeMiddleware.base.host.HostEditPage.ClickToReChooseSSHKey':
    '点击重新选择SSH密钥',
  'app.easeMiddleware.base.host.HostEditPage.SSHKey': 'SSH 密钥',
  'app.easeMiddleware.base.host.HostEditPage.HostName': '主机名称',
  'app.easeMiddleware.base.host.HostEditPage.HostIPAddress': 'IP地址',
  'app.easeMiddleware.base.host.HostEditPage.InternalIp': '内网IP',
  'app.easeMiddleware.base.host.HostEditPage.ExternalIp': '外部IPv4',
  'app.easeMiddleware.base.host.HostEditPage.SSHPort': 'SSH端口',
  'app.easeMiddleware.base.host.HostEditPage.AuthType': '认证类型',
  'app.easeMiddleware.base.host.HostEditPage.SystemVersion': '系统版本',
  'app.easeMiddleware.base.host.HostEditPage.CPU': 'CPU',
  'app.easeMiddleware.base.host.HostEditPage.Memory': '内存',
  'app.easeMiddleware.base.host.HostEditPage.Storage': '存储',
  'app.easeMiddleware.base.host.HostEditPage.Provider': '供应商',
  'app.easeMiddleware.base.host.HostEditPage.Tags': '标签',
  'app.easeMiddleware.base.host.HostEditPage.Please_select_the_host_key_file':
    '请选择主机密钥文件',
  'app.easeMiddleware.base.service.ServiceMgmtPage.InstanceName': '实例名称',
  'app.easeMiddleware.base.service.clone.from': '克隆来源',
  'app.easeMiddleware.base.service.ServiceMgmtPage.Name': '名称',
  'app.easeMiddleware.base.service.ServiceMgmtPage.Status': '状态',
  'app.easeMiddleware.base.service.ServiceMgmtPage.ServiceSpec': '服务规格',
  'app.easeMiddleware.base.service.ServiceMgmtPage.ServiceVersion': '服务版本',
  'app.easeMiddleware.base.service.ServiceMgmtPage.configTemplate': '配置模板',
  'app.easeMiddleware.base.service.ServiceMgmtPage.CreateService': '创建服务',
  'app.easeMiddleware.base.service.ServiceMgmtPage.ServiceName': '服务名称',

  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.middleware_version':
    '中间件版本',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.enabled': '状态',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.remarks': '备注',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.CreateServiceVersion':
    '创建服务版本',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.EditServiceVersion':
    '编辑服务版本',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.createAt': '创建时间',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.updateAt': '更新时间',
  'app.easeMiddleware.base.service.ServiceMgmtPage.majorVersion': '主版本号',
  'app.easeMiddleware.base.service.ServiceMgmtPage.minorVersion': '次版本号',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.VersionName':
    '版本名称',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.package': '包',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.packageName':
    '包名称',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.packageOS':
    '操作系统',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.packageArch': '架构',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.checkSum': '校验和',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.addPackage': '添加包',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.editPackage':
    '编辑包',

  'app.easeMiddleware.base.service.ServiceVersionCreateModal.ServiceVersionDescription':
    '服务版本描述',

  'app.easeMiddleware.base.spec.ServiceSpecMgmtPage.node_num': '节点数量',
  'app.easeMiddleware.base.spec.ServiceSpecMgmtPage.MiddlewareSpecName': '规格',

  'app.easeMiddleware.base.spec.ServiceSpecEditPage.MasterNodes':
    '主节点数量(默认=节点数量)',
  'app.easeMiddleware.base.spec.ServiceSpecEditPage.EsPrivateMaster':
    'Es私有主节点数量',
  'app.easeMiddleware.base.spec.ServiceSpecEditPage.RedisModel': 'Redis模型',

  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.CreateTag': '创建标签',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.CreateSuccess':
    '创建成功',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.EditTag': '编辑标签',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.EditSuccess': '编辑成功',

  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.tag_key': '标签名',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.tag_value': '标签值',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.count': '统计',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.Resources': '资源',

  'app.easeMiddleware.base.tags.EaseMiddlewareTagResourcePage.resource_id':
    '资源ID',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagResourcePage.resource_type':
    '资源类型',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagResourcePage.resource_name':
    '资源名称',

  'app.easeMiddleware.base.tags.EaseMiddlewareResourcePage.instance_id':
    '实例ID',
  'app.easeMiddleware.base.tags.EaseMiddlewareResourcePage.middleware_name':
    '中间件名称',
  'app.easeMiddleware.base.tags.EaseMiddlewareResourcePage.AddTags': '添加标签',
  'app.easeMiddleware.base.tags.EaseMiddlewareResourcePage.host_id': '主机ID',

  'app.easeMiddleware.base.detail.middlewareLog.MiddlewareLogPage.LogType':
    '日志类型',
  'app.easeMiddleware.base.detail.middlewareLog.MiddlewareLogPage.TraceId':
    '跟踪ID',

  'app.easeMiddleware.deploy.roles.DeployRolePage.role_name': '角色名称',
  'app.easeMiddleware.deploy.roles.DeployRolePage.service_id': '服务ID',
  'app.easeMiddleware.deploy.roles.DeployRolePage.deploy_mode': '操作',
  'app.easeMiddleware.deploy.roles.DeployRolePage.deploy_role': '部署角色',
  'app.easeMiddleware.deploy.roles.DeployRolePage.version': '版本',
  'app.easeMiddleware.deploy.roles.DeployRolePage.script_path': '脚本路径',
  'app.easeMiddleware.deploy.roles.DeployRolePage.subdirectory': '子目录',

  'app.easeMiddleware.deploy.roles.DeployRoleEditPage.MiddlewareType':
    '中间件类型',
  'app.easeMiddleware.deploy.roles.DeployRoleEditPage.Memo': '备注',

  'app.easeMiddleware.deploy.deploy.DeployPage.NamePrefix': '名称前缀',

  'app.easeMiddleware.base.wizard.CreateInstanceWizardPage.Before': '返回',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardPage.NodeType':
    '节点类型',

  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.SelectMiddleware':
    '选择中间件',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.SelectServiceSpec':
    '选择服务规格',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.SelectHost':
    '选择主机',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.EnableMonitor':
    '启用监控',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.MonitorPath':
    '监控路径',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.EnableLogs':
    '启用日志',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.LogPath': '日志路径',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.CreateService':
    '创建服务',
  'app.easeMiddleware.tags.team': '团队',
  'app.easeMiddleware.tags.project': '工程',
  'app.easeMiddleware.tags.env': '环境',
  'app.easeMiddleware.tags.name': '名称',

  'app.easeMiddleware.base.detail.baseConfig': '配置',
  'app.easeMiddleware.tags.tagEdit': '标签编辑',
  'app.easeMiddleware.tags.addMore': '添加更多',
  'app.easeMiddleware.tags.batchCreate': '批量添加标签',
  'app.easeMiddleware.host.hostEdit': '编辑主机信息',

  'app.easeMiddleware.backup.backupId': '备份 ID',
  'app.easeMiddleware.backup.time': '备份时间',
  'app.easeMiddleware.backup.restore': '恢复',
  'app.easeMiddleware.backup.restore.snapshot.desc': '备份将恢复到',
  'app.easeMiddleware.backup.restore.pointInTime.desc': '请选择恢复到的时间点',
  'app.easeMiddleware.backup.restore.pointInTime.later': '及以后',
  'app.easeMiddleware.backup.restore.latestTime': '最新时间',
  'app.easeMiddleware.backup.restore.specifiedTime': '指定时间',
  'app.easeMiddleware.backup.restore.unavailableTime': '当前日期时间不可用',
  'app.easeMiddleware.backup': '备份',

  'app.easeMiddleware.jumpServer': '跳板机',
  'app.easeMiddleware.jumpServerName': '跳板机名称',
  'app.easeMiddleware.jumpServer.testConnection': '测试连接',
  'app.easeMiddleware.jumpServer.testAndAddToList': '测试并添加到列表',
  'app.easeMiddleware.jumpServer.connectSuccess': '连接成功',
  'app.easeMiddleware.jumpServer.connectFailed': '连接失败',
  'app.easeMiddleware.jumpServer.edit': '跳板机编辑',
  'app.easeMiddleware.jumpServer.HostName': '跳板机名称',
  'app.easeMiddleware.jumpServer.HostIPAddress': 'IP地址',
  'app.easeMiddleware.jumpServer.SSHPort': 'SSH端口',
  'app.easeMiddleware.jumpServer.AuthType': '认证类型',

  'app.easeMiddleware.instance.info.detail': '详情',
  'app.easeMiddleware.instance.info.delete': '删除',
  'app.easeMiddleware.allFilters': '筛选条件',
  'app.easeMiddleware.fold': '收起',
  'app.easeMiddleware.Unfold': '展开',
  'app.easeMiddleware.moreTags': '更多标签',
  'app.easeMiddleware.tags.filter': '添加标签筛选',

  'app.easeMiddleware.base.host.add': '添加主机',
  'app.easeMiddleware.base.server.list': '主机列表',
  'app.easeMiddleware.base.jumpHost.add': '添加跳板机',
  'app.easeMiddleware.base.jumpHost.add1': '添加',
  'app.easeMiddleware.host.ServerName': '服务器名称',
  'app.easeMiddleware.noResult': '搜索结果不存在',

  'app.easeMiddleware.base.service': '服务',
  'app.easeMiddleware.base.status': '状态',
  'app.easeMiddleware.base.spec': '规格',
  'app.easeMiddleware.base.currentVersion': '当前版本',

  'app.easeMiddleware.base.detail.baseConfig.noConfig': '暂无相关配置',

  'app.easeMiddleware.app.name': '应用名称',
  'app.easeMiddleware.app.create': '创建应用',
  'app.easeMiddleware.app.previewAndEdit': '预览/编辑',
  'app.easeMiddleware.app.preview': '预览',
  'app.easeMiddleware.app.selectSource': '选择来源',
  'app.easeMiddleware.app.version': '版本',
  'app.easeMiddleware.app.spec': '选择服务规格',
  'app.easeMiddleware.app.host': '选择主机',
  'app.easeMiddleware.app.host.edit': '重新选择主机',
  'app.easeMiddleware.app.dockerComposeFile': 'Docker Compose 文件',
  'app.easeMiddleware.app.configFiles': '配置文件',
  'app.easeMiddleware.app.tags': '标签',
  'app.easeMiddleware.app.appName': '名称',
  'app.easeMiddleware.app.overview': '概览',
  'app.easeMiddleware.app.fileReady': '文件已准备好',

  'app.easeMiddleware.metric.throughputRate': '吞吐率',
  'app.easeMiddleware.metric.throughputErrorRate': '吞吐异常率',
  'app.easeMiddleware.metric.responseTime': '响应时间',

  'app.easeMiddleware.app.serviceName': '服务名称',
  'app.easeMiddleware.app.containerName': '容器名称',

  'app.easeMiddleware.keyPair.name': '密钥对名称',
  'app.easeMiddleware.keyPair.create': '创建密钥对',
  'app.easeMiddleware.keyPair.create1': '创建',
  'app.easeMiddleware.keyPair.keyPair': '密钥对',
  'app.easeMiddleware.keyPair.secretKeyType': '密钥类型',
  'app.easeMiddleware.keyPair.privatePair': '私钥',
  'app.easeMiddleware.events': '事件',
  'app.easeMiddleware.Applications': '应用',
  'app.easeMiddleware.Tracing': '链路追踪',
  'app.easeMiddleware.base.uploadFile': '上传文件',
  'app.easeMiddleware.base.file': '文件',
  'app.easeMiddleware.base.upload': '上传',
  'app.easeMiddleware.base.apply': '应用',

  'app.easeMiddleware.AlertRule': '告警规则',
  'app.easeMiddleware.AlertRules': '告警规则',

  'app.easeMiddleware.DashBoard': '仪表板',
  'app.easeMiddleware.Manifest': '清单',
  'app.easeMiddleware.Events': '事件',

  'app.easeMiddleware.KubernetesInformation': 'Kubernetes信息',
  'app.easeMiddleware.StackStructure': '部署应用结构',
  'app.easeMiddleware.ChooseOneStackCluster': '选择一个部署应用集群',
  'app.easeMiddleware.ServiceList': '服务列表',
  'app.easeMiddleware.NodesList': '节点列表',

  'app.easeMiddleware.StackList': '部署应用列表',
  'app.easeMiddleware.SearchStack': '搜索部署应用',

  'app.easeMiddleware.Stack': '部署应用',
  'app.easeMiddleware.StackInformation': '部署应用信息',
  'app.easeMiddleware.StackName': '应用名称',
  'app.easeMiddleware.ClusterName': '集群名称',
  'app.easeMiddleware.KubernatesNamespace': 'Kubernetes命名空间',
  'app.easeMiddleware.EnabledEaseMesh': '激活EaseMesh',

  'app.easeMiddleware.ManifestVersion': '远程版本',
  'app.easeMiddleware.ByMegaEaseTeam': '由MegaEase团队执行',
  'app.easeMiddleware.NotDeployYet': '尚未部署',

  'app.easeMiddleware.History': '历史',
  'app.easeMiddleware.AllEventsHappendToThisService':
    '所有发生在这项服务上的事件',
  'app.easeMiddleware.From': '来源',
  'app.easeMiddleware.ReadMore': '读取更多',
  'app.easeMiddleware.HideDetails': '隐藏详情',

  'app.easeMiddleware.base.action': '操作',
  'app.easeMiddleware.base.containerInfo': '容器信息',
  'app.easeMiddleware.base.containerInfo.host': '主机',
  'app.easeMiddleware.base.containerInfo.name': '名称',
  'app.easeMiddleware.base.containerInfo.status': '状态',
  'app.easeMiddleware.base.containerInfo.startTime': '开始时间',
  'app.easeMiddleware.base.containerInfo.finishTime': '完成时间',

  'app.easeMiddleware.base.copy': '复制',

  'app.easeMiddleware.base.directory': '目录',
  'app.easeMiddleware.base.version': '版本',
  'app.easeMiddleware.base.configuration': '配置',
  'app.easeMiddleware.base.overview': '概览',
  'app.easeMiddleware.base.clusterDeployMode': '集群部署模式',

  'app.easeMiddleware.base.start': '启动',
  'app.easeMiddleware.base.stop': '停止',
  'app.easeMiddleware.base.restart': '重启',
  'app.easeMiddleware.base.state': '状态',
  'app.easeMiddleware.base.remarks': '描述',
  'app.easeMiddleware.base.operation': '操作',
  'app.easeMiddleware.base.BackupMode': '备份模式',
  'app.easeMiddleware.base.backup': '备份',
  'app.easeMiddleware.base.installMonitor': '安装监控',
  'app.easeMiddleware.base.edit': '编辑',
  'app.easeMiddleware.base.instance.clone': '克隆',

  'app.easeMiddleware.mesh.ServcieForm.BasicInformation': '基本信息',
  'app.easeMiddleware.mesh.ServcieForm.Name': '名称',
  'app.easeMiddleware.mesh.ServcieForm.Description': '描述',
  'app.easeMiddleware.mesh.ServcieForm.LoadBalance': '负载均衡',
  'app.easeMiddleware.mesh.ServcieForm.Policy': '策略',
  'app.easeMiddleware.mesh.ServcieForm.HeaderHashKey': '请求头哈希关键字',

  'app.easeMiddleware.base.deployMode': '部署模式',
  'app.easeMiddleware.base.fileName': '文件名称',

  /*
   *progress steps code
   */
  // middleware system
  SUBMIT_DEPLOYMENT_TASK: '启动',
  EXECUTE_DEPLOYMENT_TASK: '执行任务',
  UNSUPPORTED_OPERATION: '未支持的操作',
  NOTHING_TO_DO: '无操作',
  UNSUPPORTED_LEGACY_DATA: '不支持的遗留数据',
  RESTORE_SUBMIT_DOWNLOAD_TASK: '启动下载任务',
  RESTORE_EXECUTE_DOWNLOAD_TASK: '执行下载任务',
  RESTORE_SUBMIT_RESTORE_TASK: '启动恢复任务',
  RESTORE_EXECUTE_RESTORE_TASK: '执行恢复任务',
  DELETE_SUBMIT_UNMONITOR_TASK: '启动监控移除任务',
  DELETE_EXECUTE_UNMONITOR_TASK: '执行监控移除任务',
  DELETE_SUBMIT_UNINSTALL_TASK: '启动卸载任务',
  DELETE_EXECUTE_UNINSTALL_TASK: '执行卸载任务',
  HOST_CONNECTIVITY_CHECK: '主机连通性检查',
  'host-sysctl.yml': '更新sysctl',
  STATE_SYNCHRONISATION: '结束',
  // deployment system
  'precheck.yml': '准备 - 必要条件',
  'os-users.yml': '准备 - 账号',
  'directory.yml': '准备 - 目录',
  'installation.yml': '部署 - 服务',
  'configuration.yml': '部署 - 配置',
  'file.yml': '部署 - 运维脚本',
  'systemd.yml': '启动 - 服务',
  'initialization.yml': '启动 - 集群',
  'postcheck.yml': '健康检查',
  'uninstallation.yml': '卸载服务',
  'update_configuration.yml': '更新配置',
  'add-monitor-config.yml': '安装监控配置',
  'remove-monitor-config.yml': '卸载监控',
  'telegraf.yml': '安装监控程序',
  'middleware-restart.yml': '重启服务',
  'middleware-start.yml': '启动服务',
  'middleware-status.yml': '查询服务状态',
  'middleware-stop.yml': '停止服务',
  'restore-mysql.yml': '恢复MySQL数据库的数据',

  //backup
  BACKUP_SUBMIT_BACKUP_TASK: '提交备份任务',
  BACKUP_EXECUTE_BACKUP_TASK: '执行备份任务',
  BACKUP_SUBMIT_UPLOAD_TASK: '提交文件上传任务',
  BACKUP_EXECUTE_UPLOAD_TASK: '执行文件上传任务',
  'restore-redis.yml': '恢复Redis的数据',
  'backup-redis.yml': '备份Redis的数据',

  'cronjob.yml': '部署 - 定时器',
  'backup.yml': '备份数据',
  //easemesh
  'empty.yml': '执行任务',
  'easemesh.yml': '安装 EaseMesh',

  IN_THE_WAITING_QUEUE: '处于执行队列',
  FAIL_BEFORE_EXECUTION: '执行前失败',

  'restore.yml': '恢复数据',

  //clone
  CLONE_SUBMIT_DOWNLOAD_TASK: '启动数据文件下载任务',
  CLONE_EXECUTE_DOWNLOAD_TASK: '数据文件下载中',
  CLONE_SUBMIT_CLONE_TASK: '启动克隆实例',
  CLONE_EXECUTE_CLONE_TASK: '运行克隆实例',

  'reset_no_prompt.yml': '卸载服务',
  'cluster.yml': '集群安装',

  'add-node.yml': '添加节点',
  'remove-node.yml': '移除节点',

  //web console
  'web-console-delete.yml': '停用网页控制台',
  'web-console-deploy.yml': '启用网页控制台',
  EXECUTE_TASK: '执行任务',

  'continuous_deploy.yml': '持续部署',
  CONTINUOUS_DEPLOY_SUBMIT_CONTINUOUS_DEPLOY_TASK: '启动持续部署任务',
  CONTINUOUS_DEPLOY_EXECUTE_CONTINUOUS_DEPLOY_TASK: '执行持续部署任务',
  EXECUTE_PRECHECK_TASK: '执行前置检查任务',

  //mesh
  'app.easeMiddleware.mesh.version': 'EaseMesh版本',
  'app.easeMiddleware.mesh.controlPlaneReplicas': '控制面副本数',
  'app.easeMiddleware.mesh.addons': '附件',
  'app.easeMiddleware.mesh.persistentVolumeSize': '持久化存储大小',
  'app.easeMiddleware.mesh.persistentVolumePath': '持久化存储路径',
  'app.easeMiddleware.mesh.installCoreDNS': '安装CoreDNS',
  'app.easeMiddleware.mesh.CoreDNSVersion': 'CoreDNS版本',
  'app.easeMiddleware.mesh.CoreDNSReplicas': 'CoreDNS副本数',
  'app.easeMiddleware.mesh.kubernetesCluster': 'Kubernetes 集群',
  'app.easeMiddleware.mesh.Occupied': '(Occupied)',
  'app.easeMiddleware.mesh.coreDNSTips':
    '请注意 CoreDNS-EaseMesh 安装后将无法 revert',

  'app.easeMiddleware.detail.config.dockerComposePortMapping':
    'Docker Compose 端口映射',
  'app.easeMiddleware.detail.config.serviceName': '服务名称',
  'app.easeMiddleware.detail.config.portMapping': '端口映射',
  'app.easeMiddleware.detail.webTerminal': 'Web 终端',

  'app.easeMiddleware.detail.addNodes': '扩容',
  'app.easeMiddleware.detail.removeNodes': '缩容',
  'app.easeMiddleware.detail.addNodes.prev': '上一步',
  'app.easeMiddleware.detail.addNodes.next': '下一步',
  'app.easeMiddleware.detail.config.history.version': '历史版本',
  'app.easeMiddleware.detail.config.history.version.date': '日期',
  'app.easeMiddleware.detail.config.history.version.digest': '摘要',
  'app.easeMiddleware.detail.config.history.version.rollback': '回滚',
  'app.easeMiddleware.detail.config.history.version.rollback.config':
    '回滚配置',
  'app.easeMiddleware.detail.config.history.version.rollback.config.confirm':
    '确认回滚配置？',
  'app.easeMiddleware.detail.config.history.version.detail': '配置详情',
  'app.easeMiddleware.detail.webConsole.tips': '扩缩容后请重装控制台。',
  'app.easeMiddleware.detail.webConsole.uninstall': '卸载控制台',
  'app.easeMiddleware.detail.webConsole.browse': '浏览',
  'app.easeMiddleware.detail.webConsole.reinstall': '重装控制台',
  'app.easeMiddleware.detail.webConsole.uninstalling': '正在卸载',
  'app.easeMiddleware.detail.webConsole.installing': '正在安装',
  'app.easeMiddleware.detail.webConsole.install': '安装控制台',
  'app.easeMiddleware.detail.webConsole.delete': '删除控制台',

  'app.easeMiddleware.delete.confirm.host': '确认删除主机 {name}？',
  'app.easeMiddleware.typeToProcess': '输入 {name} 进行操作',

  'app.easeMiddleware.delete.confirm.keyPair': '确认删除密钥对 {name}？',

  'app.easeMiddleware.delete.confirm.middleware': '确认删除中间件 {name}？',
  'app.easeMiddleware.delete.confirm.middleware1':
    '确认之后，将会执行以下操作：',
  'app.easeMiddleware.delete.confirm.middleware2': '1. 服务将被删除。',
  'app.easeMiddleware.delete.confirm.middleware3':
    '2. 服务的数据将被删除，无法恢复。',
  'app.easeMiddleware.delete.confirm.middleware4':
    '3. 服务的配置将被删除，无法恢复。',

  'app.easeMiddleware.delete.confirm.middleware.stack':
    '警告：存在与当前 stack 相关的 canaries',

  'app.easeMiddleware.detail.removeNodes.tips.kafka':
    '请将 broker 上托管的所有分区的副本移动到其他 broker 上，然后再移除 broker。',
  'app.easeMiddleware.detail.removeNodes.tips.redis.master':
    '请将数据从要删除的主节点重新存储到所有其他主节点。',

  'app.easeMiddleware.detail.removeNodes.confirm.kafka':
    '是否已移动所有 replica 副本？',
  'app.easeMiddleware.detail.removeNodes.confirm.redis':
    '是否所有插槽都已重新分片？',
  'app.easeMiddleware.detail.adjustNodes.confirm': '您确定要提交吗？',

  'app.easeMiddleware.nodeList': '节点列表',
  'app.easeMiddleware.hostList': '主机列表',
  'app.easeMiddleware.nodeList.keyboard': '快捷键',
  'app.easeMiddleware.nodeList.keyboard.tips1':
    '多选：按住 Command 键，然后点按节点。',
  'app.easeMiddleware.nodeList.keyboard.tips2':
    '选择多个相邻节点：按住 Shift 键，点按第一个节点和最后一个节点',
  'app.easeMiddleware.hostList.tips': '请先选择左侧的节点',

  //clone
  'app.easeMiddleware.clone.backup': '备份',
  CloudBackup: '云备份',
  'app.easeMiddleware.clone.name': '实例名称',
  'app.easeMiddleware.clone.selectHost': '选择主机',
  'app.easeMiddleware.clone.selectHost.tip': '请选择主机',
  'app.easeMiddleware.clone.directory': '目录',
  'app.easeMiddleware.clone.overview': '概览',
  'app.easeMiddleware.clone.from': '源实例',
  'app.easeMiddleware.clone.middleware': '中间件',
  'app.easeMiddleware.clone.deployMode': '部署模式',
  'app.easeMiddleware.clone.hostGroup': '主机分组',

  //scale
  ADDNODE_SUBMIT_ADDNODE_TASK: '启动扩容任务',
  ADDNODE_EXECUTE_ADDNODE_TASK: '执行扩容任务',
  REMOVENODE_SUBMIT_REMOVENODE_TASK: '启动缩容任务',
  REMOVENODE_EXECUTE_REMOVENODE_TASK: '执行缩容任务',
  REMOVENODE_SUBMIT_UNMONITOR_TASK: '开始卸载监控',
  REMOVENODE_EXECUTE_UPDATE_TASK: '执行更新任务',

  //web-console
  'web-console.100101.description':
    'phpMyAdmin是一个用PHP编写的免费软件工具，旨在通过Web来管理 MYSQL。phpMyAdmin支持 MySQL 和 MariaDB 上的多种操作。',
  'web-console.100201.description':
    'Kibana 是一个免费且开放的用户界面，能够让您对 Elasticsearch 数据进行可视化，并让您在 Elastic Stack 中进行导航。您可以进行各种操作，从跟踪查询负载，到理解请求如何流经您的整个应用，都能轻松完成。',
  'web-console.100401.description':
    '用node.js编写的Redis web管理工具。用于显示和编辑多个不同Redis服务器内的数据。',
  'web-console.100501.description':
    'ZooNavigator是一个基于web的ZooKeeper UI和编辑器/浏览器，具有许多实用的功能。',
  'web-console.100601.description':
    '用于管理Apache Kafka®集群的多功能、快速、轻量级web UI。专为开发人员构建。',
  'web-console.100701.description':
    '用Node.js、Express和Bootstrap3编写的基于Web的MongoDB管理界面。',
  'web-console.100801.description':
    'Dashboard是一个基于Web的Kubernetes用户界面。您可以使用Dashboard将容器化应用部署到Kubernetes集群，排查您的容器化应用，以及管理集群资源。',
  'web-console.101401.description':
    'Expression browser用于Prometheus server上的/graph，允许您输入任何表达式并以表格或图表形式查看其结果。',
  'web-console.101701.description':
    'Portainer Community Edition是一个面向集装箱化应用程序的轻量级服务交付平台，可用于管理Docker、Sarm、Kubernetes和ACI环境。它的设计就是部署和使用一样简单。',
  'web-console.101901.description':
    'MinIO控制台是一个丰富的图形用户界面，提供与mc命令行工具类似的功能。您可以使用MinIO控制台执行身份和访问管理、指标和日志监控或服务器配置等管理任务。',
  'web-console.102101.description':
    "pgAdmin是针对PostgreSQL拥有丰富功能的开源管理和开发工具。安装成功后，管理员username是'pgAdminUser@megaease.com',密码同pgAdminUser用户的密码.",

  'app.alert.service.rules.AddRule': '添加规则',
  'app.alert.service.rules.AddAlertRule': '添加告警规则',
  'app.alert.service.rules.editAlertRule': '编辑告警规则',

  'app.middleware.dashboard.category': '分类',
  'app.middleware.dashboard.service': '服务',
  'app.middleware.dashboard.metrics': '指标',
  'app.middleware.dashboard.metrics.tips': '请选择指标(最多选择5个)',

  'app.middleware.dashboard.createPanel.tips': '新建面板以查看指标',
  'app.middleware.dashboard.createDashboard.tips':
    '新建仪表盘以放置面板查看指标',
  'app.middleware.dashboard.createDashboard': '新建仪表盘',
  'app.middleware.dashboard.createPanel': '新建面板',
  'app.middleware.dashboard.panel': '面板',

  'app.middleware.jumpServer': '跳板机',
  'app.middleware.imageRegistry': '镜像地址',
  'app.middleware.k8sFile.tips': '请选择您的admin.conf',
  'app.middleware.upload': '上传',
  'app.middleware.reupload': '重新上传',
  'app.middleware.create': '创建',
  'app.middleware.import': '导入',
  'app.middleware.import.tips':
    'admin.conf 中 server 的 IP 地址必须是公网 IP 或者 MegaCloud 可访问的私网 IP。',
  'app.middleware.import.tips1':
    'admin.conf 中 server 的 IP 地址必须是127.0.0.1。',
  'app.middleware.import.tips2':
    'kube_control_plane_address ip 不能是127.0.0.1。 eg: 172.1.1.118:6443。',
  'app.middleware.import.tips3': '请输入正确的IP地址和端口号',

  'app.middleware.detail.backup.autoBackup': '自动备份',
  BackupToCloud: '备份到云端',

  'app.middleware.host.result': '结果',
  'app.middleware.host.goToHostList': '前往主机列表',
  'app.middleware.host.Processing': '处理中',
  'app.middleware.host.tips': '支持批量添加主机，多个IP之间用英文逗号分隔。',
  'app.middleware.delete.forceDelete': '强制删除',

  'app.middleware.event.redo': '重试',
  'app.middleware.event.redo.tips': '确定要重新执行此操作吗？',

  'app.easeMiddleware.base.application.components.MonitorConfigPanel.AgentDesc':
    '选择代理来挂载到你的docker中',
  'app.easeMiddleware.base.application.components.MonitorConfigPanel.AgentType':
    '代理类型',

  'app.easeMiddleware.event.success': '成功',
  'app.easeMiddleware.event.failure': '失败',
  'app.easeMiddleware.event.recovery': '恢复',
  'app.easeMiddleware.event.doing': '处理中',
  'app.easeMiddleware.event.terminated': '终止',
  'app.easeMiddleware.event.stopped': '停止',
  'app.easeMiddleware.event.pending': '等待',

  'app.easeMiddleware.host.edit': '编辑主机信息',
  'app.easeMiddleware.host.osVersion': '系统版本',
  'app.easeMiddleware.host.osArch': '系统架构',
  'app.easeMiddleware.host.osVersionArch': '系统版本/架构',

  'app.easeMiddleware.base.application.components.Topology.NodeMenu.ConfigMap':
    '配置映射集',
  'app.easeMiddleware.base.application.AppDetailPage.ApplicationDetailForApps.Manifest.StackInfo.helpid.enable':
    '打开此开关将会激活当前stack下的mesh功能，并影响Manifest/Manifest Version/Edit(打开拓扑图)/拓扑图中的任意组件上Edit/Edit Component/第2页Command & Resource/Annotation处的与Mesh相关的默认Annotations。',
  'app.easeMiddleware.base.application.AppDetailPage.ApplicationDetailForApps.Manifest.Warning':
    '在拓扑图中的节点上点击鼠标右键可以进行更多的操作。',

  'app.middleware.event.cancel': '取消任务',
  'app.middleware.event.cancel.tips': '确定要取消此任务吗？',
  'app.middleware.event.stop': '停止',
  'app.middleware.event.stop.tips': '确定要停止此任务吗？',

  'app.middleware.terminal.commands': '常用命令',
  'app.middleware.terminal.commands.title': '常用命令',

  'app.settings.cicd.confirm.content':
    'MegaEase Cloud将为您创建一个镜像仓库，请确认。',
  'app.settings.cicd.confirm.nevigatorToRegistry':
    '您还未开启镜像仓库。请点击下方按钮，前往镜像仓库页面，开启您的镜像仓库。',

  'app.settings.cicd.registry.title': '镜像仓库',
  'app.settings.cicd.registry.content':
    'MegaEase Cloud将为您创建一个私有镜像仓库，您可以在此处或者【settings】-> 【Registry】查看您的私有镜像仓库地址和凭证。',
  'app.settings.cicd.tips':
    'MegaEase 将提供一个私有镜像仓库地址来替换您原本的地址。更新后的镜像可以使用新地址访问：',
  'app.settings.cicd.imageList': '镜像列表',

  'app.settings.cicd.title.tips':
    '当推送相同的 name:tag 的镜像时，部署将自动触发。',
  'app.settings.cicd.title.tips1': '解释信息',

  'app.settings.cicd.howToUse': '如何使用',

  'app.settings.cicd.confirm.file': '请先上传 docker compose 文件',

  'app.settings.cicd.open.success': '开启CI/CD成功',
  'app.settings.cicd.close.success': '关闭CI/CD成功',
  'app.settings.cicd.close.confirm': '你确定要停止CI/CD吗？',

  'app.settings.cicd.blankPage.title': '镜像仓库',
  'app.settings.cicd.blankPage.desc':
    '您还没有启用镜像仓库, 请点击下方按钮，启用您的镜像仓库。',
  'app.settings.cicd.blankPage.create': '启用镜像仓库',

  'app.settings.cicd.registry.info': '镜像仓库信息',
  'app.middleware.k8s.install.tips':
    '要安装Kubernetes，需要从以下地址下载镜像和文件。请确保您的服务器能够正常访问这些站点。',
  'app.middleware.k8s.install.tips.imageAddress': '镜像地址',
  'app.middleware.k8s.install.tips.fileAddress': '文件下载地址',

  'app.middleware.minio.storeVolumes': '存储卷',
  'app.middleware.minio.sequentialHostnames': '顺序主机名',

  'app.middleware.cicd.trigger.tips':
    "只有在推送具有相应标签的映像时，才会触发持续部署（Continuous Deployment，CD）过程。建议使用稳定的标签，如 'latest'。",
  'app.middleware.backup.storage': '存储',
  'app.middleware.backup.storage.local': '本地',
  'app.middleware.backup.storage.online': '云端',

  'app.middleware.app.title': '应用',
  'app.middleware.app.description':
    '使用Docker Compose或Kubernetes部署应用程序。',
  'app.middleware.app.button': '创建应用',

  'app.middleware.service.title': '服务',
  'app.middleware.service.description': '在您的主机上部署软件服务。',
  'app.middleware.service.button': '创建服务',

  'app.middleware.resource.title': '资源',
  'app.middleware.resource.description': '添加主机以部署服务或应用程序。',
  'app.middleware.resource.button': '添加资源',

  'app.middleware.keyPair.title': '密钥对',
  'app.middleware.keyPair.description': '您需要创建/添加密钥对来访问主机。',
  'app.middleware.keyPair.button': '创建/添加密钥对',

  'app.middleware.jumpServer.title': '跳板机',
  'app.middleware.jumpServer.description':
    '您需要创建一个跳板服务器来访问主机。',
  'app.middleware.jumpServer.button': '添加跳板机',

  Type: '类型',
  PublicKey: '公钥',
  Refresh: '刷新',
  refresh: '刷新',

  'app.easeMiddleware.detail.config.history.version.title': '查看',
  nodeInfo: '节点信息',
  'app.easeMiddleware.detail.removeNodes.tips.minio':
    '为了避免数据丢失，请先将数据迁移至其他存储桶或节点中，再进行缩容操作。',
  'app.easeMiddleware.detail.removeNodes.tips.minio.link': '帮助文档',

  'app.easeMiddleware.detail.config.add': '添加配置',

  'app.easeMiddleware.detail.config.tips1': '在保存配置项之前，请输入配置',
  'app.easeMiddleware.detail.config.tips2': '您确定要放弃此配置项吗?',
  'app.easeMiddleware.detail.config.tips3': '请在提交前保存配置项目',
  'app.easeMiddleware.detail.config.tips4':
    'Please enter config before saving config item',
  'app.easeMiddleware.detail.config.tips5': '你确定要放弃所有更改吗？',
  'app.easeMiddleware.detail.config.tips6': '配置项已经存在, 请重新输入',

  'Offline deployment': '离线部署',
  SelectOfflineRepo: '选择主机',
  installDocker: '安装Docker',

  'app.easeMiddleware.k8s.host.tips':
    'Kubernetes部署需要访问Registry.k8s.io、quay.io 和其他地址才能下载文件。如果您的服务器无法访问上述地址，请启用离线部署。',

  'app.easeMiddleware.k8s.host.tips2':
    '请确保服务器未安装 Docker，否则部署 Kubernetes 过程中会将其卸载。',
  'app.easeMiddleware.k8s.host.tips3':
    '我们会在离线节点部署 nginx 和 docker registry，作为 Kubernetes 部署时的文件下载服务器和镜像仓库。服务会监听 5000 、8090 端口，请确保该端口未被占用。',

  'app.easeMiddleware.noHost.tips': '没有符合要求的主机。',

  'app.easeMiddleware.k8s.offlinehost.selected': '已选择',

  'app.easeMiddleware.nginx.config.fileName': '文件名',
  'app.easeMiddleware.nginx.config.fileContent': '文件内容',

  //cdn
  ENABLE_CDN_SETUP_CLOUDFLARE: '配置 Cloudflare',
  ENABLE_CDN_SUBMIT_CREATING_TUNNEL_TASK: '提交隧道创建任务',
  ENABLE_CDN_EXECUTE_CREATING_TUNNEL_TASK: '执行隧道创建任务',
  'cloudflared.yml': '安装并启动 cloudflared',
  DISABLE_CDN_SUBMIT_REMOVING_TUNNEL_TASK: '提交隧道销毁任务',
  DISABLE_CDN_EXECUTE_REMOVING_TUNNEL_TASK: '执行隧道销毁任务',
  'remove-cloudflared.yml': '停止并卸载 cloudflared',
  DISABLE_CDN_CLEANUP_CLOUDFLARE: '清理 Cloudflare',

  oneClickCDN: 'Cloudflare CDN',
  'app.cdn.tips': '本服务基于Cloudflare的免费CDN方案。注意事项如下：',
  'app.cdn.tips1': '遵循默认缓存行为',
  'app.cdn.tips2': '缓存级别为“标准”',
  'app.cdn.tips3': '最小缓存时间为2小时',
  'app.cdn.tips4': '（参考）',
  'app.cdn.tips0': '需要设置bucket的访问策略为公开',

  'app.cdn.status.enabling': '正在启用',
  'app.cdn.status.enabled': '已启用',
  'app.cdn.status.disabling': '正在停用',
  'app.cdn.status.failed': '失败',
  'app.cdn.status.disabled': '已停用',

  'app.k8s.host.tips': 'Kubernetes必须安装在具有相同架构的机器上。',
  'app.easegress.host.tips':
    'Easegress 无法安装在已经安装了 Kubernetes 的机器上。',
  'app.nodeCount': '节点数量',
  'app.minio.deoploy.optional': '（选填）',
  'app.minio.deoploy.tips':
    '部署之前，请仔细阅读官方手册。了解Node 和Drive相关的概念，及其数量要求。',
  'app.minio.deoploy.tips.more': ' 了解更多',

  'app.config.table.name.tips': '请输入有效的文件名，例如“test.conf”。',

  NodeStatus: '节点状态',
  NodeName: '节点名称',
  BucketName: '桶名称',
  BucketMetrics: '桶指标',
  ObjectsCount: '对象计数',
  UsageTotalBytes: '使用量总字节数',
  GlobalMetrics: '全局指标',
  ServersCount: '服务器计数',
  DisksCount: '磁盘计数',
  Capacity: '容量',
  DisksAvailable: '可用的磁盘',
  DataReceived: '收到的数据',
  DataSent: '发送的数据',
  NodeMemoryUsed: '节点使用的内存',
  NodeCPUTime: '节点CPU时间',
  HostName: '主机名称',
  HostIP: '主机IP',
  Port: '端口',
  Roles: '角色',
  Directories: '目录',

  Memory: '内存',
  Network: '网络',
  DiskIO: '磁盘IO',
  Storage: '存储',

  ChooseFilesToMountInYourDocker: '选择文件加载到你的Docker中',
  PleaseChooseYourFile: '请选择你的文件',
  LocalPath: '本地路径',
  BothContainerPathAndFileAreRequired: '容器路径和文件都是必须的',
  ServiceName: '服务名称',
  HostPort: '主机端口',
  ContainerPort: '容器端口',

  FormatError: '格式错误',
  AccountInfo: '账号信息',

  KubernetesCluster: 'Kubernetes集群',
  Namespace: '命名空间',
  Manifest: '清单',
  SaveAsManifestSuccess: '成功保存为清单',

  DockerFile: 'Docker文件',
  StackFile: 'Stack文件',
  SourceRequired: '来源是必须的',
  PleaseChooseYourDockerComposeFile: '请选择你的docker-compose文件',
  Components: '组件',
  Configuration: '配置',
  PleaseChooseAWayToCreateAStack: '请选择一个方式来创建Stack',
  ReuploadYAMLFile: '重新上传YAML文件',
  UploadYAMLFile: '上传YAML文件',
  EditFromYAMLTemplate: '从YAML模板编辑',
  CreateFromYAMLTemplate: '从YAML模板创建',
  EditStepByStep: '一步一步地编辑',
  CreateStepByStep: '一步一步地创建',
  EditYAMLText: '编辑YAML文本',
  TheAboveThreeItemsWillGenerateTheFollowingAppName:
    '上面三项将会生成以下应用名称',
  AppName: '应用名称',
  CurrentNumberOfCharacters: '当前字符数',
  ImportKubernetes: '导入Kubernetes',

  Username: '用户名',
  Password: '密码',
  CPUUsageMillicores: 'CPU使用量（毫核）',
  ServerConnection: '服务器连接',
  Responses: '响应数',
  Requests: '请求数',
  ServerBytes: '服务器字节数',
  UpstreamBytes: '上游字节数',
  ValueContent: '值内容',
  SlowQuery: '慢查询',
  InnoDBData: 'InnoDB数据',
  AbortedAndLock: '中止并锁定',
  LockingRate: '锁定率',
  NetworkTraffic: '网络流量',
  'app.easeMiddleware.base.CopySuccess': '复制成功',
  'app.easeMiddleware.forceDelete': '强制删除',
  'app.easeMiddleware.forceDelete.tips.title': '注意',
  'app.easeMiddleware.forceDelete.tips':
    '强制删除只会将服务从平台中删除，会在您服务器上遗留数据，需要自行处理！ 强制删除仅在普通删除失败情况下使用。',
  'app.easeMiddleware.forceDelete.host.tips':
    '强制删除只会将资源从平台中删除，会在您服务器上遗留数据，需要自行处理！ 强制删除仅在普通删除失败情况下使用。',

  HitRate: '命中率',
  MemFragmentationRatio: '内存碎片率',
  CommandsProcessed: '已处理的命令',
  Connections: '连接数',
  ConnectedSlaves: '已连接的从节点',

  TopicPartitionOffset: '主题分区偏移',
  ConsumerGroupLag: '消费者组滞后性',
  ConsumerGroupPartitionCount: '消费者组分区计数',
  MessagesInM1Rate: '每分钟消息速率',
  BytesInM1Rate: '每分钟输入字节速率',
  BytesOutM1Rate: '每分钟输出字节速率',
  ProduceRequestsPerSec: '每秒产生的请求',
  ProduceMessageConversionsPerSec: '每秒产生的信息转化率',
  TotalProduceRequestsPerSec: '每秒产生的请求总数',
  UnderReplicatedPartitions: '在副本之下的分区',
  OfflineReplicaCount: '离线副本计数',
  LeaderCount: '领导者计数',
  PartitionCount: '分区计数',
  LeaderElectionRateAndTime: '领导人选举率和时间',
  UncleanLeaderElectionsPerSec: '每秒不合格领导人的选举数',
  ActiveControllerCount: '活跃的控制器数量',
  GlobalTopicCount: '全局主题计数',
  GlobalPartitionCount: '全局分区计数',
  JavaCpuUseParSecond: 'Java Cpu每秒的使用情况',
  JavaMemoryPool: 'Java内存池',
  JavaThreadPool: 'Java线程池',
  JavaGCCount: 'Java垃圾回收计数',
  JavaGCTime: 'Java垃圾回收时间',
  JVM: 'Java虚拟机',
  Brokers: '代理',
  ReplicaManager: '副本管理器',
  PerTopic: '每主题',
  AllTopics: '所有主题',
  ConsumerAndLag: '消费者和滞后性',

  SearchQuery: '搜索查询',
  SearchCount: '搜索计数',
  SearchTime: '搜索时间',
  GCCountRate: '垃圾回收计数率',
  GCTimeRate: '垃圾回收时间率',
  Translog: '事务日志',
  TranslogOperation: '事务日志操作',
  TranslogOperationSizeInBytes: '在字节数上事务日志操作大小',
  Documents: '文档',
  DocumentsCount: '文档计数',
  DocumentsIndexedRate: '文档索引率',
  DocumentsDeletedRate: '文档删除率',
  DocumentsMergedRate: '文档合并率',
  DocumentsMergedBytes: '文档合并字节数',

  HttpServer: 'Http服务器',
  HttpServerStatus: 'Http服务器状态',
  BackendRequest: '后端请求',
  BackendResponse: '后端响应',
  BackendRequestDuration: '后端请求持续时间',
  Proxy: '代理',
  ProxyErrorConnections: '代理错误连接数',
  ProxyTotalConnections: '代理总连接数',
  GolangRuntime: 'Go语言运行时',

  Connection: '连接',
  CommandThroughputRate: '命令吞吐率',
  Cursor: '游标',
  Document: '文档',
  DatabaseNet: '数据库网',
  ResidentMegabytes: '占用兆字节',
  PageFaults: '页面故障',
  DBDataSize: 'DB数据大小',

  SamplesAppended: '样本追加',
  MemoryProfile: '内存档案',
  WALCorruptions: '日志文件损坏',
  ActiveAppenders: '活跃的追加器',
  BlocksLoaded: '块加载',
  HeadChunks: '头部块',
  HeadBlockGCActivity: '头部块垃圾收集活动',
  CompactionActivity: '压缩活动',
  ReloadCount: '重载计数',
  QueryDurations: '查询持续时间',
  RuleGroupEvalDuration: '规则组评估持续时间',
  FailuresAndErrors: '失败和错误',
  SeriesHead: '系列头部',
  TargetScrapePoolSync: '目标采集池同步',

  ZNodeCount: 'ZNode 数量',
  ApproximateDataSize: '估计数据大小',
  FileDescriptor: '文件描述符',
  Packets: '包',
  Sessions: '会话',
  WatchCount: '监控计数器',
  BytesReceived: '接收字节数',
  TimeAndLatency: '时间和延迟',
  Latency: '延迟',
  SnapshotTime: '快照时间',
  FsyncTime: '文件系统同步时间',
  JVMPauseTime: 'Java虚拟机暂停时间',

  UsedFileDescriptors: '已使用的文件描述符',
  Containers: '容器',
  Images: '镜像',

  OPERATING_SYSTEM: '操作系统',
  JUMP_SERVER: '跳板机',
  STATUS: '状态',
  SPEC: '规格',
  HOST_INFO: '主机信息',

  SingleNode: '单节点',
  Standalone: '单节点',
  PrimaryReplication: '副本集',
  ShardCluster: '分片集群',
  Distributed: '分布式',
  SelectHost: '选择主机',
  PleaseSelectHost: '请选择主机',
  PrimaryNodes: '主节点',
  PrimaryNodeNumber: '主节点数量',
  PrimaryNodeCount: '主节点数量',
  ReplicaNodes: '副本节点',
  ReplicaNodesNumber: '副本节点数量',
  BufferPoolSize: '缓冲池大小',
  SelectAll: '选择所有',
  NodeConfiguration: '节点配置',
  Cluster: '集群',
  ShardNumber: '分片数量',
  ReplicaNumber: '副本数量',
  SelectReplicaHost: '选择副本主机',
  MaxMemory: '最大内存',
  Broker: '代理',
  BrokerNumber: '代理数量',
  ZookeeperNumber: 'Zookeeper数量',
  KafkaMaxHeapSize: 'Kafka最大堆内存大小',
  NodesCount: '节点数量',
  ShardCount: '分片数量',
  NodesPerShard: '每个分片的节点数量',
  CustomizeConfigServer: '自定义配置服务器',
  ConfigServerCount: '配置服务器数量',
  CustomizeMongos: '自定义Mongos',
  MongosCount: 'Mongos数量',
  EtcdNodesCount: 'Etcd节点数量',
  ControlPlaneCount: '控制面的数量',
  KubeNodeCount: 'Kube节点数量',
  PleaseMakeSureTheOfflineRepoIsAvailableBefore: '请确保离线 repo 是可用的',
  KubeNetworkPlugin: 'Kube网络插件',
  KubernetesFile: 'Kubernetes文件',
  SecondaryNodes: '二级节点',
  Summary: '摘要',
  'app.easeMiddleware.gpu.timeSelect.title': '选择时长（小时）：',
  'app.easeMiddleware.gpu.timeSelect.increase': '增加 1 小时',
  'app.easeMiddleware.gpu.timeSelect.decrease': '减少 1 小时',
  'app.easeMiddleware.gpu.timeSelect.reset': '重置',
  'app.easeMiddleware.gpu.timeSelect.total': '总计',
  'app.easeMiddleware.gpu.timeSelect.total.hours': '小时',
  'app.easeMiddleware.gpu.timeSelect.total.hour': '小时',

  VendorLocation: '数据中心位置',
  GPUSpecs: '选择GPU规格',
  OutOfStock: '已售罄',
  OutOfStockDesc: '当前数据中心的GPU已售罄,请尝试切换数据中心或稍后再试。',
  OopsSomethingWrong: '糟糕, 出错了!',
  Hour: '小时',
  BuyNow: '立即购买',
  Payment: '付款',
  Hours: '小时',
  PaymentMethod: '付款方式',
  Pay: '支付',
  Alipay: '支付宝',
  WechatPay: '微信支付',
  UseWeChatToScan: '请使用微信扫码支付',
  OrderNumber: '订单编号',
  OrderStatus: '订单状态',
  ToBePaid: '未付款',
  AlreadyPaid: '已付款',
  PaymentFailure: '付款失败',
  Timeout: '已超时',
  Kind: '类型',
  Fee: '费用',
  CreateAt: '创建时间',
  UpdateAt: '更新时间',
  PaymentSuccessful: '支付成功！',
  ThankYouForPurchasingGPUService: '恭喜！非常感谢您购买我们的GPU服务。',
  StartUsingNow: '立即开始使用！',
  PaymentFailed: '支付失败！',
  SomethingWentWrongTryAgain: '糟糕！出错了。请重试。',
  TryAgain: '重试',
  PaymentTimeout: '支付超时！',

  RemainingTime: '剩余时长',
  Renew: '续费',
  Combine: '合并',
  SelectImage: '选择镜像',
  ImageName: '镜像名称',
  PerformingStartOperation: '执行启动操作',
  PerformingStopOperation: '执行停止操作',
  TIME: '时长',
  'gpu.time': '时长',
  VENDOR: '供应商',
  Vendor: '供应商',
  InstanceID: '实例ID',
  StartType: '启动类型',
  StopType: '停止类型',
  StartTime: '启动时间',
  StopTime: '停止时间',
  NoComputingResousesFound: '未找到计算资源',
  ComputingResouses: '计算资源',

  CombineTips: '所选GPU的剩余使用时间将被合并到目标GPU中，并在提交后删除。',
  NoAvailableGPU: '无可用的GPU',
  TotalRemainTime: '总剩余时长',
  'app.easeMiddleware.usageLog': '使用日志',
  'app.gpu.image': '镜像',
  'app.gpu.remainingTime': '剩余时长',
  'app.gpu.totalTime': '总时长',
  'app.gpu.usedTime': '已用时长',
  'app.gpu.spec.error': '请选择 GPU 规格',
  jupyter_pytorch_description:
    'Jupyter Notebook for pytorch 是一个基于 Jupyter Notebook 的深度学习开发环境，预装了 PyTorch 框架和依赖，支持交互式编程和数据可视化。',
  jupyter_tensorflow_description:
    'Jupyter Notebook for tensorflow 是一个基于 Jupyter Notebook 的深度学习开发环境，预装了 TensorFlow 框架和依赖，支持交互式编程和数据可视化。',
  jupyter_description:
    'Jupyter Notebook 是一个基于 Web 的交互式计算环境，支持运行 40 多种编程语言，包括 Python、R、Julia 等。Jupyter Notebook 可以被用于数据清理和转换、数值模拟、统计建模、机器学习等多种场景。',
  stable_diffusion_description:
    'Stable Diffusion 是一个根据描述快速生成高分辨率数字画作的神奇工具。',
  'app.gpu.webconsole.open': '打开',
  'app.gpu.webconsole.unavailable': 'Web控制台当前不可用。',

  StatusDetail: '状态详情',

  GoroutinesCount: 'Go协程数量',
  ThreadsCount: '线程数量',
  TotalAlloc: '总分配内存',
  TotalHeapAlloc: '总堆内存分配',
  TotalHeapObjects: '总堆对象数',
  TotalLookupsCount: '总查找次数',
  MCacheInuse: 'MCache使用中内存量',
  MSpanInuse: 'MSpan使用中内存量',
  StackInuse: '栈使用中内存量',
  GCDuration: '垃圾回收持续时间',

  ProxyName: '代理名称',
  HttpServerName: 'Http服务器名称',

  Size: '大小',
  ConfirmRestoreOfService: '确认恢复服务',
  RestoringDataTo: '将数据恢复到',
  RestoringDataWarning: '警告：所有当前数据将被删除，并由备份数据取代。',
  RestoreType: '输入',
  ToProcessed: '用来处理',
  'app.gpu.changeLog.type': '类型',
  'app.gpu.changeLog.duration': '时长变化',
  'app.gpu.changeLog.remarks': '备注',
  'app.gpu.Log': '日志',
  'app.gpu.usageLog': '使用日志',
  'app.gpu.operationLog': '操作日志',
  'app.webconsle.delete.tips': '您确定要删除此Web控制台吗？',

  'app.paymentAmount': '付款金额',
  'app.payee': '收款方：',
  'app.orderNumber': '订单编号：',
  'app.createTime': '创建时间：',
  'app.usageLog': '使用日志',
  'app.operationLog': '操作记录',

  'app.payment.description': '描述',
  'app.payment.unitPrice': '单价',
  'app.payment.quantity': '数量',
  'app.payment.price': '价格',
  'app.payment.total': '总计',
  'app.payment.tips': '请在 {timeout} 内完成支付, 超时后将取消订单',
  'app.payment.or': '或',
  'app.gpu.all': '全部',

  Inc: '公司',
  RegistrationAddress: '注册地址',
  RegistrationAddressContent1: '北京市海淀区海淀大街31号2层212',
  RegistrationAddressContent2: '100081',
  Amount: '金额',
  PayType: '支付类型',
  PayTime: '付款时间',
  ReceiptNumber: '交易流水号',
  UnitPrice: '单价',
  TotalPrice: '总价',
  Quantity: '数量',
  BillTo: '用户信息',
  ShipTo: '收货地址',
  DateOfIssue: '下单时间',
  DateDue: '超时时间',
  DueTo: '应付于',
  Paid: '支付于',
  PayOnline: '在线支付',
  PayNow: '立即支付',
  Invoice: '账单',
  InvoiceNumber: '账单编号',
  Subtotal: '商品总价',
  TotalExcludingTax: '不含税价格',
  Tax: '税',
  Total: '总计',
  AmountDue: '待支付金额',
  AmountPaid: '已支付金额',
  DownloadPDFFile: '下载PDF文件',
  ViewInvoice: '查看账单',
  OrderTime: '下单时间',
  PaidOn: '支付于',
  Plan: '付费计划',
  PlanQuota: '附加配额',
  GPUResource: 'GPU资源',
  TechSupport: '技术支持',
  MonthlyCost: '月度费用',
  PerHour: '每小时',
  MegaEaseInc: '北京重载智子科技有限公司',

  'app.payeeName': '北京重载智子',
  'app.beijing': '北京',
  'app.stop': '停止实例',
  'app.stop.tips': '在停止实例之后，非挂载存储内的数据会被销毁，请先备份数据。',
  'app.stop.confirm': '确认停止实例',
  'app.payment.alreadyPaid.tips': '如果支付后未自动跳转，请点击 {here}',
  'app.payment.alreadyPaid.here': '这里',

  OrderDetailRefreshTips: '订单状态出错？点击{refresh}试试',
  PaymentRefreshTips: '没有找到付款信息，请稍后再试或联系管理员',
  vRAMUtilizationRate: '显存使用率',
  vRAMUsage: '显存使用情况',
  powerUsage: '功率',

  BillingSummary: '计费摘要',
  YourNextPayment: '你的下一次付款',
  PaymentInformation: '付款信息',
  YourRecurringChargesAndDiscounts: '您的经常性费用和折扣',
  CurrentPlan: '当前计划',
  CompareAllPlans: '比较所有计划',
  Upgrade: '升级套餐',
  Downgrade: '降级套餐',
  Free: '免费版',
  Standard: '标准版',
  Advance: '高级版',
  UpgradeToAdvance: '升级到高级版',
  DowngradeToFree: '降级到免费版',
  TechnicalSupport: '技术支持',
  TechnicalSupportTips: '在你需要的时候获得MegaEase云团队的技术支持',
  AddOns: '附加信息',
  APMIntegration: 'APM集成',
  APMIntegrationTips: '你有资格免费使用APM集成系统',
  EaseMeshIntegration: 'EaseMesh集成',
  EaseMeshIntegrationTips: '将EaseMesh与你的K8S应用程序整合起来',
  EaseMeshIntegrationTips2: '您有资格免费使用EaseMesh集成系统',
  CloudflareCDNCustomizedDomain: 'Cloudflare CDN定制的域名',
  CloudflareCDNCustomizedDomainTips: '您有资格免费定制您的Cloudflare CDN域名',
  CloudflareCDNCustomizedDomainTips2: '定制您的Cloudflare CDN域名',
  MonitoringDataRetainingDays: '监测数据保留天数',
  MonitoringDataRetainingDaysTips: '你的监测数据将被保留3天',
  MonitoringDataRetainingDaysTips2: '你的监测数据将被保留24天',
  AddMore: '添加更多',
  Usage: '用量',
  DockerApp: 'Docker应用',
  K8SApp: 'K8S应用',
  DockerRegistrySpace: 'Docker注册空间',
  CloudflareCDNDomain: 'Cloudflare CDN域名',
  Seats: '座席',
  ViewPaymentHistory: '查看付款历史',
  Charge: '充值',
  PriceOfCurrentPlanMonth: '当前计划的价格（月）',
  PriceOfDestinationPlanMonth: '目的地计划的价格（月）',
  ChangesWillBeReflectedOnNextMonth: '变化将反映在下个月',
  YourDowngradeToFreePlanWillBeEffectiveOn:
    '您的降级为免费计划将在以下日期生效',
  TechnicalSupportForCurrentPlan: '对当前计划的技术支持',
  TechnicalSupportForExtraQuotas: '为额外配额提供技术支持',
  AllExtraQuotaFees: '所有额外配额费用的10%',
  EnableTechnicalSupport: '启用技术支持',
  DisableTechnicalSupport: '禁用技术支持',
  TechnicalSupportForCurrentPlanMonth: '对当前计划的技术支持（月）',
  TechnicalSupportForExtraQuotasMonth: '额外配额的技术支持(月)',
  DisableTechnicalSupportEffectiveOn: '禁用技术支持，于',
  EnableAPMIntegration: '启用APM集成',
  DisableAPMIntegration: '禁用APM集成',
  IntegrateAPMWithYourApplications: '将APM与你的应用程序整合',
  PriceMonth: '价格（月）',
  AddDays: '添加天数',
  RemoveDays: '删除天数',
  PlanIncluded: '计划包括',
  Purchased: '已购买的',
  TotalWillBe: '总数将是',
  EnableEaseMeshIntegration: '启用EaseMesh集成',
  DisableEaseMeshIntegration: '禁用EaseMesh集成',
  EnableCDNCustomizedDomain: '启用CDN自定义域名',
  DisableCDNCustomizedDomain: '禁用CDN自定义域名',
  AddMonitoringDataRetainingDays: '增加监测数据保留日',
  RemoveMonitoringDataRetainingDays: '删除监测数据保留日',
  AddHostSeats: '添加主机席位',
  RemoveHostSeats: '删除主机席位',
  AddServiceSeats: '添加服务席位',
  RemoveServiceSeats: '删除服务席位',
  AddDockerRegistrySpace: '添加Docker注册表空间',
  RemoveDockerRegistrySpace: '删除Docker注册表空间',
  AddCloudBackupSpace: '添加云备份空间',
  RemoveCloudBackupSpace: '删除云备份空间',
  AddDockerAppSeats: '添加Docker应用席位',
  RemoveDockerAppSeats: '删除Docker应用席位',
  AddK8SAppSeats: '添加K8S应用席位',
  RemoveK8SAppSeats: '删除K8S应用席位',
  AddCloudflareCDNDomainSeats: '添加Cloudflare CDN域名席位',
  RemoveCloudflareCDNDomainSeats: '删除Cloudflare CDN域名席位',
  BillingInformation: '计费信息',
  PayWith: '支付方式',
  CardNumber: '卡号',
  FirstName: '姓',
  LastName: '名',
  Address: '地址',
  City: '城市',
  PostalZipcode: '邮编',
  CountryRegion: '国家/地区',
  StateProvince: '州/省',
  LearnMore: '了解更多',
  PerformingDeleteOperation: '执行删除操作',
  DeleteJumpServerSuccess: '删除跳板机成功',

  'plan.free': '免费版',
  'plan.standard': '标准版',
  'plan.advanced': '高级版',
  'plan.advance': '高级版',

  'plan.host': '主机',
  'plan.service': '服务',
  'plan.docker_app': 'Docker应用',
  'plan.k8s_app': 'K8S应用',
  'plan.monitor_retaining_days': '监测数据保留天数',
  'plan.docker_registry_space': 'Docker注册空间',
  'plan.cloud_backup_space': '云备份空间',
  'plan.apm': '应用监控',
  'plan.easemesh': 'EaseMesh',
  'plan.cdn_domain': 'CDN域名',
  'plan.cdn_customized_domain': 'CDN自定义域名',
  'plan.support_base': '技术支持',

  'plan.balance': '余额',
  'plan.charge': '充值',
  'plan.history': '历史',

  'plan.description': '描述',
  'plan.price': '价格',
  'plan.dueBy': '到期时间',

  'plan.balance.history': '历史',

  CreateStorage: '创建存储',
  SearchForNameTags: '根据名称、标签等搜索',
  RemainingSize: '剩余大小',
  VolumeName: '卷名称',
  PurchaseDurationHour: '购买时长(小时)',
  MountPath: '挂载路径',
  EditStorage: '编辑存储',
  Expand: '扩容',
  ExpandErrorTips: '扩容后的容量不能小于或等于先前的值',
  ExpandStorage: '扩容存储',
  RenewStorage: '续费存储',
  DeleteStorage: '删除存储',
  StorageUsage: '存储使用量',
  UsedStorageSpace: '使用的存储空间',
  ExpiredAt: '过期时间',
  MountedBy: '挂载于',
  OpenWebConsole: '打开Web控制台',
  DataSize: '数据大小',
  DataSizeTips: '数据大小（0.07美元/GB/月，1TB或以上为0.05美元/GB/月）',
  DeleteStorageTips:
    '你确定要删除云存储磁盘吗？删除后，存储在磁盘中的所有文件和数据将被永久删除，无法恢复。',
  TypeToProcess: '输入{data}来处理',
  SelectDataCenter: '选择数据中心',
  StartGPU: '启动GPU',
  SelectStorage: '选择存储',
  Active: '激活的',
  Inactive: '未激活的',
  GPUStorage: 'GPU存储',
  Preparing: '准备中',
  Ready: '已准备',
  Failed: '失败',
  Deleted: '删除',
  Expired: '已过期',
  Attention: '注意',
  FileBrowserTips:
    'FileBrowser提供了一个指定目录内的文件管理界面，它可以用来上传，删除，预览，重命名和编辑你的文件。它允许创建多个用户，每个用户可以拥有自己的目录。它可以作为一个独立的应用程序使用。',
  MountPathValidatorMessage:
    '必须以/开头，并且只包含a-z、A-Z、0-9和/，不能以 /home 开头，不以/结尾，也不包含//，并且限制在63个字符以内。',
  'plan.balance.charge': '余额充值',
  'plan.balance.charge.amount': '充值金额',
  'plan.balance.other': '其他',
  'plan.balance.enter.amount': '请输入充值金额',
  'plan.balance.enter.amount.error': '充值金额必须大于0',
  'plan.balance.pay': '支付',

  'plan.usage': '用量',
  enableTechSupport: '启用技术支持',
  disableTechSupport: '禁用技术支持',

  TechnicalsupportCurrentplan: '当前计划的技术支持（月）',
  TechnicalsupportExtraquota: '额外配额的技术支持（月）',
  TechnicalsupportTips: '所有额外配额费用的10%',

  'plan.chargeToday': '今天收费（按 {days} 天折算）',

  'plan.price.month': '价格（月）',
  'plan.onNextMonth': '将在下个月生效',

  'plan.add': '添加',
  'plan.remove': '删除',

  //usage
  'plan.usage.price.month': '价格（月）',
  'plan.usage.plan.included': '计划包括',
  'plan.usage.purchased': '已购买的',
  'plan.usage.total.will.be': '总计',
  'plan.usage.add': '添加',

  UpgradeToStandard: '升级到标准版',
  DowngradeToStandard: '降级到标准版',

  'plan.addSeats': '添加席位',
  'plan.removeSeats': '删除席位',

  'plan.techSupport': '技术支持',
  'plan.addons': '附加服务',
  BindSuccess: '绑定成功',
  UnbindSuccess: '解绑成功',
  OperationCompleted: '操作完成',
  AddCredits: '充值',

  'plan.techSupport.base': '基础技术支持',
  'plan.techSupport.extra': '额外技术支持',

  //addons
  'plan.apm.intergration': 'APM 集成',
  'plan.apm.intergration.desc': '将 APM 集成到你的应用程序中',
  'plan.easemesh.intergration': 'EaseMesh 集成',
  'plan.easemesh.intergration.desc': '将 EaseMesh 集成到你的 K8S 应用程序中',
  'plan.cdn.intergration.customized': '自定义CDN 域名',
  'plan.cdn.intergration.customized.desc': '自定义您的Cloudflare CDN域名。',
  'plan.cdn.intergration': 'Cloudflare CDN 域名',
  'plan.cdn.intergration.desc': '将 CDN 集成到你的应用程序中',
  'plan.addons.tips': '关闭 {name}，{date}生效。',

  'payment.dueBy': '（扣费日期为 {date}）',

  changePlanOn: '您的降级到{planName}的计划将于 {date} 生效',
  'tips.deferredPlan': '计划取消成功',
  cancelSuccess: '取消成功',
  'techSupport.content': '在需要时从MegaEase Cloud团队获得技术支持。',

  'plan.unit.seats': '席位',
  'plan.unit.days': '天',
  'plan.unit.days2': '天',
  'plan.unit.day': '天',

  'plan.storage': '存储',

  'plan.monitor': '您的监控数据将保留 {days} 天。',
  'plan.changePlanSuccess': '计划更改成功',
  ThankYouForPurchasingService: '感谢您购买我们的服务',

  orderNo: '订单号',
  description: '描述',
  amount: '金额',
  date: '日期',

  'operation.success': '操作成功',

  'techSupport.deferred.tips': '{operation}技术支持，将于 {date} 生效。',
  'plan.usage.tips': '移除 {count}, 将于 {date} 生效。',

  'packs.add': '增加数据存储空间',
  'packs.remove': '减少数据存储空间',
  'days.add': '添加天数',
  'days.remove': '移除天数',
  maxDays: '（最多14天）',

  Balance: '余额',

  'plan.apm.intergration.included': '您有资格免费使用APM集成。',
  'plan.easemesh.intergration.included': '您有资格免费使用EaseMesh集成。',
  'plan.cdn.intergration.included': '您有资格免费定制您的Cloudflare CDN域名。',

  'plan.month': '月',
  proratedFor: '（按 {days} 天折算）',

  'plan.gb': 'GB',
  noUnit: ' ',

  'host.ip.cn': '您的IP地址在中国大陆，我们建议您使用 {link}  平台。',
  'host.ip.com': '您的IP地址不在中国大陆，我们建议您使用 {link} 平台。',
  'host.ip.bogon': '该IP地址不可用。',
  'host.ip.continue': '继续添加',
  'host.ip.title': '通知',
  QuotaControllTips:
    '对不起, {module}已达到用量限制({used}/{quota})。请{Purchase}或{Upgrade}',
  Supported: '支持',
  supported: '支持',
  AppQuotaControllTips:
    '对不起, {module}已达到{UsageLimit}。请{Purchase}或{Upgrade}',
  APMQuotaControllTips:
    '对不起, {module}不{supported}。请{Purchase}或{Upgrade}',
  UsageLimit: '用量限制',
  RegistryInfoQuotaControllTips:
    '需要更多容量? [购买](/megacloud/app/main/billing/plansAndUsage/usage/200107)或[升级](/megacloud/app/main/billing/plansAndUsage/comparePlans)用于更大的存储。',
  NeedMoreCapacity: '需要更多容量? {Purchase}或{Upgrade}用于更大的存储。',
  EnableNewFeature: '您可以通过{Purchase}或者{Upgrade}来启用自定义域名功能。',
  'quota.confirm.upgradePlan': '升级计划',
  'quota.confirm.purchaseMore': '购买更多',
  'quota.confirm.tips.useLimit': '{module}{useLimit}',
  'quota.confirm.tips.support': '对不起, {module}不{supported}。',
  'quota.confirm.tips.daysLimit':
    '要保留和查看早期数据，{Purchase}或{Upgrade}。',
  FrozenTips: '该{Module}已被冻结，请{GetMoreQuota}后点击{Unfreeze}。',
  GetMoreQuota: '获取更多配额',
  Unfreeze: '解冻',
  UnfreezeSuccess: '解冻成功',
  UnfreezeFailed: '解冻失败',
  DataRetention: '数据保留：',
  DaysLimitPrefix: '只有',
  DaysLimitSuffix: '天。',
  only: '只有',
  days: '天',
  'quota.confirm.title': '超出配额',
  Used: '已使用',
  Quota: '配额',
  Sorry: '对不起，',
  reached: '已到达。',

  'addons.enable': '启用',
  'addons.disable': '禁用',

  NoGPUStorageFound: '未找到GPU存储',

  'GPU.refund': '退款',
  refundApplication: '退款申请',
  'refundApplication.success': '退款申请成功',

  'refund.content.refundable':
    '未使用 {unused} 分钟，可退费 {refundable} 分钟。将生成 {order_num} 笔退款， 总退费金额 {amount} 元。',
  'refund.content.unrefundable':
    '未使用 {unused} 分钟，可退费 {refundable} 分钟。由于可退费时间不满60分钟不支持退费。',
  'refund.tips.refundable':
    '当前仅支持以小时为单位退款，退款时长不满一个小时不能退款。申请成功后，当前GPU可用时长会被扣除{refundable}分钟，剩余{remaining}分钟。退款将在5-7个工作日按原支付方式退回。',
  'refund.tips.unrefundable':
    '当前仅支持以小时为单位退款，退款时长不满一个小时不能退款。申请成功后，退款将在5-7个工作日按原支付方式退回。',

  'storage.refund.content.refundable':
    '当前过期时间{expire_at}，未使用 {unused} 分钟，可退费 {refundable} 分钟，退费后剩余 {remaining} 分钟，过期时间{expire_at_after_refund}。将生成 {order_num} 笔退款，总退费金额 {amount} 元。',
  'storage.refund.content.unrefundable':
    '未使用 {unused} 分钟，可退费 {refundable} 分钟。由于可退费时间不满60分钟不支持退费。',
  'storage.refund.tips.refundable':
    '当前仅支持以小时为单位退款，退款时长不满一个小时不能退款。申请成功后，当前GPU存储可用时长会被扣除 {refundable} 分钟，过期时间为{expire_at_after_refund}。退款将在5-7个工作日按原支付方式退回。',
  'storage.refund.tips.unrefundable':
    '当前仅支持以小时为单位退款，退款时长不满一个小时不能退款。申请成功后，退款将在5-7个工作日按原支付方式退回。',

  refundNum: '退款单号',
  tenantName: '租户名称',
  quotaName: '配额名称',
  quantity: '数量',
  refundAmount: '退款金额',
  status: '状态',
  refundTime: '退款时间',

  RefundInit: '初始化',
  RefundSuccess: '成功',
  RefundFailed: '失败',

  GPUInfomation: 'GPU信息',
  RefundOrderAssociatedOrderList: '关联订单',
  'remarks.refund': '退款备注',
  saveRemarks: '保存备注',
  'remarks.tips': '请填写退款的备注信息',
  RefundProcessing: '退款处理中',

  buy: '购买',
  usage: '用量',
  merge: '合并',
  refund: '退款',

  'refresh.success': '刷新成功',
  templateDetail: '镜像模板详情',
  templateDesc: '描述',
  templatePorts: '端口',
  templateEnvs: '环境变量',
  portPublic: '公共',
  portProbe: '探测',

  SelectTemplate: '选择模板',

  'noENV.tips': '暂无环境变量',
  'env.key': '键',
  'env.value': '值',
  'port.name': '名称',
  'port.port': '端口',

  InsufficientInventory: '当前GPU 无可用库存, 请稍后再试。',
  AdequateInventory: '当前GPU 库存数量充足，选择模板并启动服务。',
  TightInventory: '当前GPU 库存数量紧张， 选择模板并启动服务。',

  Discount: '{rate2} 折',
  publicTips: '如果勾选，会为该端口开放节点端口供外部访问。',
  probeTips: '如果勾选，我们会使用该端口作为探针，在容器启动时检测是否就绪。',

  DeleteCloudBackup: '删除云端备份',
  ConfirmDeleteCloudBackup: '确认删除云端备份吗？',

  AutoBackupSwitchOff: '自动备份开关已关闭',
  AutoBackupSwitchOn: '自动备份开关已打开',
  BackupToCloudSwitchOff: '备份到云端开关已关闭',
  BackupToCloudSwitchOn: '备份到云端开关已打开',
  UnknownError: '未知错误',
  RestoreRequestSent: '恢复请求已发送',

  EnableCDNForCurrentInstance: '为当前实例启用CDN功能：',
  RandomDomain: '随机域名',
  CustomizedDomain: '自定义域名',
  CustomSubdomainMessage:
    '自定义域名的格式要求为：1.小写字母、数字或中划线，2.中划线不能在开头或者结尾，3.总长度为4-17',
  DomainFormatError: '域名格式错误',
  DomainAlreadyOccupied: '域名已被占用',

  'refund.gpu': 'GPU',
  'refund.storage': 'GPU 存储',
  'refund.type': '类型',
  power: '倍数',

  templateTitle: '模板标题',
  templateImageName: '镜像名称',
  portName: '名称',
  port: '端口',
  envName: '名称',
  envValue: '值',
  registryCredential: '镜像仓库凭据',
  registryCredentialManage: '凭据管理',
  command: '启动命令',
  createTemplate: '创建模板',
  addCredentials: '添加凭据',
  DeleteCredentialSuccessfully: '删除凭据成功',
  AddCredentialsSuccessfully: '添加凭据成功',
  credentialName: '凭据名称',
  credentialUsername: '用户名',
  credentialPassword: '密码',
  createTemplateSuccessfully: '创建模板成功',
  deleteTemplateSuccessfully: '删除模板成功',
  updateTemplateSuccessfully: '更新模板成功',
  officialTemplate: '官方模板',
  customTemplate: '自定义模板',
  templateType: '模板类型',
  deleteTemplate: '删除模板',
  deleteTemplateTips: '确认删除模板吗？',

  insufficientBalance: '当前租户余额不足，请充值后重试。',
  change: '切换套餐',
  'port.type': '类型',

  isRequired: '是必填的',
  isUnique: '必须是唯一的',
  isInvalid: '无效的',
  baseUrl: '访问路径',

  Pending: '待处理',
  WithdrawalSuccess: '提现成功',
  WithdrawalFailed: '提现失败',

  'app.gpu.tips':
    '根据国家法律规定，严格禁止利用生成算法生成违禁图片或任何违背法律法规的内容。此外，严禁进行任何形式的挖矿活动。一旦发现违规行为，将立即采取封号措施。请务必遵守此规定，以维护网络环境的健康和安全。感谢您的合作！',
  Sync: '同步',
  StorageSync: '存储同步',
  baidunetdisk: '百度网盘',
  aliyundrive: '阿里云盘',
  copyTo: '上传到网盘',
  copyFrom: '从网盘下载',
  copyToLocal: '复制到本地',
  copyFromLocal: '复制自本地',
  goBack: '返回',
  localPath: 'MegaCloud云端存储路径',
  remotePath: '网盘路径',
  loginSuccess: '登录成功',
  operation: '操作',
  state: '状态',
  cloudPath: '网盘路径',
  startTime: '开始时间',
  endTime: '结束时间',
  taskType: '任务类型',
  diskType: '磁盘类型',

  taskInit: '初始化',
  taskSyncing: '同步中',
  taskSuccess: '成功',
  taskFailed: '失败',
  taskCanceled: '取消',

  SyncStart: '开始同步',
  SyncAt: '同步于{time}',
  SyncFileList: '正在同步文件',
  SyncDone: '同步完成',
  SyncDoneAt: '同步完成于{time}',
  SyncFailed: '同步失败',
  SyncStop: '停止同步',

  OutOfService:
    '为了提供更稳定的 GPU 服务，我们计划在2023-07-24日至2023-07-26日进行硬件资源升级。届时您的 GPU 的购买和使用功能将无法使用，预计在2023-07-27日0时恢复。非常抱歉给您的使用带来不便，感谢您的理解和支持。',
  ChangeAccount: '切换账号',

  unauthorized: '未授权',
  authorizedFailed: '授权失败, 请稍后重试。',
  folderId: '文件夹ID',
  aliyunTips: '远程路径不能是 / 根目录',
  baiduTips: `上传路径是 '/app/MegaEaseCloud' 应用目录下`,
  execute: '执行',
  baiduUploadTips: '文件将会被上传到网盘中的 { dir } 目录',

  stopTaskSuccess: '停止任务成功',
  stopTaskFailed: '停止任务失败',
  authSuccess: '授权成功。',
  Upload: '上传',
  Download: '下载',
  getAuth: '正在获取授权......',
  closePage: '当前页面会在 {countdown} 秒后自动关闭。',

  'monitor.connections': '链接',
  'monitor.transactions': '事务',
  'monitor.queryRows': '查询行数',
  'monitor.modifyRows': '修改行数',
  'monitor.templateFileInfo': '临时文件',
  'monitor.deadlocks': '死锁',

  'storage.size': '大小：',
  uploadTo: '上传到网盘',
  downloadFrom: '从网盘下载',

  startGPU: '新建GPU实例',
  'app.billingLog': '计费日志',

  'app.billingTime': '计费时间',
  'app.billingAmount': '金额',
  'app.billingTarget': '计费标的',
  'plan.balance.charge.orderNo': '订单号',
  'plan.balance.charge.cost': '费用',
  'plan.balance.charge.order.status': '订单状态',
  day_billing: '本日计费',
  week_billing: '本周计费',
  month_billing: '本月计费',
  'balance.refund': '退款',
  balanceHours: '按照目前计费情况，预计剩余运行时长为 {hours} 小时。',
  ApplicationForRefund: '申请退款',
  'gpu.cost': '花费',

  'refund.content':
    '可申请退款金额 {amount}，不可申请退款金额 {non_refundable_amount}，将生成{order_num}笔退款。',
  'refund.tips1': '余额中的金额才可以申请退费。已消费的金额不支持退费。',
  'refund.tips2':
    '不可申请退费金额指距今超过90天的订单产生的金额。此部分金额如需退费，请线下联系我们的客服人员。',
  'refund.tips3': '提交申请后，申请退费金额会从余额中扣除。',
  'refund.tips4': '退款将在5-7个工作日按原支付方式退回。',
  'app.gpu.mountPath': '挂载路径',
  viewOrder: '查看订单',
  'balance.cost': '花费',
  'balance.topUp': '充值',

  'storage.start': '启动中',
  'storage.stop': '停止中',
  'storage.delete': '删除中',
  'storage.expand': '扩容中',
  'storage.suspend': '挂起中',
  'storage.reinstate': '恢复中',

  'storage.stopped': '已停止',
  'storage.running': '运行中',
  'storage.suspended': '已挂起',
  'storage.provision': '准备中',
  'storage.ready': '计费中',
  'storage.deleted': '已删除',
  PerformingDelOperation: '执行删除操作',
  'refund.balance': '余额',
  'expand.success': '扩容成功',
  'gpu.deductions': '扣费',
  'gpu.refund': '退费',
  'gpu.log.cost': '费用',
  'gpu.log.summary': '摘要',
  'gpu.log.type': '类型',
  'gpu.log.spec': '规格',
  'gpu.log.deductionTime': '扣费时间',
  'gpu.log.billingRange': '计费区间',
  'gpu.log.amount': '金额',
  'gpu.log.time': '时间',
  'gpu.log.operation': '操作',
  'gpu.storage.mountPath.tips':
    '挂载点有可能覆盖模板的中的目录， 请注意挂载点的位置。',
  'refund.on': '退还于',
  Minutes: '分钟',
  'balance.Amount': '金额',
  'balance.migrate': '数据迁移',
  start_normal: '用户启动',
  stop_normal: '用户停止',
  delete_normal: '用户删除',
  suspend: '存储挂起',
  reinstate: '存储挂起恢复',
  expand: '存储扩容',
  stop_unpaid: '余额不足停止',
  delete_unpaid: '余额不足删除',
  stop_underfloor: '资源层停止',
  start_underfloor: '资源层启动',
  'spec.name': '名称',
  'spec.spec': '规格',
  'spec.template': '模板',
  'spec.capacity': '容量',
  'spec.mountPath': '挂载路径',
  InsufficientBalance: '余额不足',
  InsufficientBalanceTips: '当前余额不足，请充值后重试。',

  CreateCustomizableApp: '创建可持久化应用',
  newStorage: '新建存储',
  otherStorage: '已有存储',
  CreateCustomizableAppTips: '您的所有数据变动都会被保存到持久化存储中。',
  customizableApp: '可持久化应用',
  storageForCustomizableApp: '仅能被可持久化应用挂载',
  'gpu.createStorage.tips': '存储创建中',
  'gpu.start.tips': '实例启动中',
  'gpu.start.success': '实例启动成功',
  'gpu.progress.tips': '预计需要2-5分钟，请您稍作等待。',
  'app.stop.tips.persist':
    '挂载存储不能停止， 即便GPU停止后，挂载存储仍然会继续计费。 如果你不再使用GPU和挂载存储，你可以到存储菜单下将其删除， 一但删除，挂载存储上保留的所有内容将会消失无法恢复。',
  'gpu.template.chatglm.tips': '该模版对显存要求较高，请尽量选择双卡的规格。',
  'gpu.tempStorage': '临时存储',
  aliyunTips2: '必须位于资源盘，且不能是 / 根目录。',
  RunningLog: '运行日志',
  operationRecords: '操作记录',
  autoScroll: '自动滚动',
  newLogsAvailable: '有新日志',
  'gpu.log.latest': '最近',
  'gpu.log.lines': '行',
  advancedSettings: '高级设置',
  changePlanTipsTitle: '切换套餐',
  changePlanTipsContent:
    '切换套餐生效将会立即扣费（不包含GPU租赁业务），请确认您的需求后再进行切换。',
  viewDetail: '查看详情',
  'maxPortCount.tips': '（最多2个）',
  'maxEnvCount.tips': '（最多10个）',
  'portLimit.tips': '端口范围为1024-65535',
  'gpu.env.tips':
    '环境变量值必须是明确的字符串值。请勿使用类似 $PATH 这种引用其他变量的不明确的值。例如，可以设置 PATH 环境变量为 /usr/bin:/bin，但不能设置为 /usr/bin:$PATH。',
  'gpu.agreements1': '我已阅读并同意',
  'gpu.agreements2': '《用户租赁GPU和存储服务使用协议》',
  'gpu.agreements.title': '用户须知',
  'gpu.shutdownTimer': '定时关机',
  'gpu.shutdownTimer.set': '设置定时关机',
  'gpu.select.date.time': '选择日期和时间',
  'gpu.shutdownTimer.time': '定时关机时间',
  'gpu.time.modify': '修改',
  'gpu.time.cancel': '取消',
  'select.time.error': '所选时间必须晚于当前时间',
  'cicd.env.sync': '同步',
  'cicd.env.sync.success': '同步成功',
  'cicd.discord.placeholder': '请输入Discord Webhook地址',
  'gpu.EstimatedCost': '预计花费',
  AccountBalance: '账户余额',
  'gpu.time.cancel.success': '取消定时关机成功',
  'gpu.shutdownTimer.set.success': '设置定时关机成功',
  gpuBillingPrompt:
    'GPU及存储服务启动之后，会持续扣费，如果您不再使用GPU请及时停止，如果您不再使用存储，请及时删除。',
  billingInProgress: '计费中',
  auto_stop: '定时关机',
  'start.console': '启动Web控制台',
  RecordsOfConsumption: '消费记录',
  BalanceReminder: '余额即将不足',
  'gpu.start.continue': '继续',
  'gpu.start.balanceNotification.description':
    '按照目前计费情况，系统预计您的剩余 GPU 运行时长不足 1 小时，当余额不足时会使 GPU 意外终止运行，导致数据丢失，请合理规划使用或充值。',
  'gpu.storage.share': '分享',
  passwordSharing: '口令分享',
  passwordSharingTip: '其他用户可通过你分享的口令对你当前存储进行复制',
  copyPassword: '复制口令',
  copyStorage: '复制存储',
  enterCodeTips: '请输入口令',
  enterCode: '输入口令',
  codeExpire_at: '口令有效期至 {expire_at}',
  'gpu.reminder': '提醒',
  'gpu.reminder.title': '长期运行提醒',
  'gpu.reminder.content':
    '系统会对连续运行超过24小时的实例发出提醒，如果不需要可以关闭。',
  'gpu.reminder.success': '提醒修改成功',
  'gpu.reminder.storage.title': '空间不足提醒',
  'gpu.reminder.storage.content':
    '系统会对用量超过99%的存储发出提醒，如果不需要可以关闭。',
  'gpu.storage.tips.error':
    '您选择的存储{name}容量为{total}，当前已全部用完，无法进行GPU启动操作，请您及时{expand}。',
  'gpu.storage.tips.warning':
    '您选择的存储{name}容量为{total}，剩余可用空间不足500MB，可能导致GPU启动失败，请您及时{expand}。',
  'gpu.storage.tips.expand': '进行扩容',
  NotUsable: '不可用',
  'gpu.persist.storage.tips': '当前选择的模版需要至少{min}的存储空间',
  'gpu.persist.storage.tips2':
    '您选择的存储{name}容量为{total}，您选择的模版需要至少{min}的容量。该存储不满足需求，请选择其他存储。',
  'disk.total': '存储总容量',
  'disk.expand': '扩容容量',
  capacity_used: '已用容量',
  aliYunDrivePath: '阿里云盘路径',
  baiduNetdiskPath: '百度网盘路径',
  envNameRequired: '环境变量名称不能为空',
  envNamePattern: `启动环境变量名不合法，必须由字母、数字、'_'、'-'或'.'组成，并且不能以数字开头。`,
  'gpu.selectDataCenter': '选择数据中心：',
  'gpu.selectType': '选择GPU型号：',
  'gpu.outOfStock': '当前规格库存不足，请选择其他规格。',
  'gpu.notUsable': '当前规格不可用，请选择其他规格。',
  'gpu.open': '打开',
  'gpu.status.storageReady': '存储就绪',
  'gpu.initializing': '初始化中',
  'gpu.event.createStorage': '创建存储',
  reinstateStorage: '恢复存储',
  start_with_new_storage: '用户带新存储启动',
  'gpu.log.errorCode': '结果',
  'gpu.log.errorCode.0': '成功',
  'gpu.log.errorCode.1': '失败',
  'gpu.storage.create.limit': '请注意，存储创建和删除操作每天只能进行5次。',
  'gpu.storage.delete.limit': '请注意，存储删除每天只能进行5次。',
};

export default zhCN;
