// @flow
import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
import _ from 'lodash';
import store from 'store';
import settings from './settings';
import toolbox from './toolbox';
import { parseResponseError } from '@/app/_common/utils/utils.js';

const getAcceptLanguage = () => {
  const i18n = store.get('i18n');
  if (i18n === 'en-US') {
    return 'en';
  } else {
    return 'zh';
  }
};
export const axiosExtra = (() => {
  const data = {
    accessToken: null,
    serviceToken: null,
  };
  return {
    setAccessToken: (token) => {
      data.accessToken = token;
      if (_.isEmpty(token)) {
        store.remove('accessToken');
      } else {
        store.set('accessToken', token);
      }
    },
    getAccessToken: () => {
      let token = data.accessToken;
      if (!_.isEmpty(token)) {
        return token;
      }
      token = store.get('accessToken');
      if (!_.isEmpty(token)) {
        data.accessToken = token;
      }
      return token;
    },

    setServiceToken: (token) => {
      data.serviceToken = token;
      if (_.isEmpty(token)) {
        store.remove('serviceToken');
      } else {
        store.set('serviceToken', token);
      }
    },
    getServiceToken: () => {
      let token = data.serviceToken;
      if (!_.isEmpty(token)) {
        return token;
      }
      token = store.get('serviceToken');
      if (!_.isEmpty(token)) {
        data.serviceToken = token;
      }
      return token;
    },

    clearAll: () => {
      data.operateResourceHeader = null;
      data.accessToken = null;
      store.remove('accessToken');

      data.serviceToken = null;
      store.remove('serviceToken');
    },
  };
})();
const axios = Axios.create({
  validateStatus: function (status) {
    return status >= 200 && status < 400; // default
  },
});
/**
 * http request interceptor
 */
axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const newConfig = { ...config };
    newConfig.baseURL = settings.API_ADDRESS;
    // newConfig.headers.Accept = 'application/json, text/plain, */*';
    const token = axiosExtra.getAccessToken();
    if (!_.isEmpty(token)) {
      newConfig.headers.Authorization = `${token}`;
    }
    if (newConfig.method === 'get') {
      const system = store.get('system');
      newConfig.data = true;
      if (!_.isEmpty(system)) {
        newConfig.url += `?system=${system}`;
      }
    }
    // newConfig.headers['Content-Type'] = 'application/json';
    newConfig.headers['Accept-Language'] = getAcceptLanguage();
    return newConfig;
  },
  (err) => Promise.reject(err)
);

const enhanceResponseError = (err: AxiosError) => {
  const { response } = err;
  if (!response && err.message === 'Network Error') {
    toolbox.alertError('Network Error');
    return;
  }
  const { status, data } = response;
  // console.log(response, 'response');

  switch (status) {
    // check if unauthorized error returned
    case 400: {
      toolbox.alertError('Bad request, please check your input!');
      break;
    }
    case 401: {
      axiosExtra.setAccessToken(null);
      window.location = settings.CONTEXT_PATH + settings.LOGIN_PATH;
      return false;
    }
    // check if permission denied
    case 403: {
      toolbox.alertError('Permission denied!');
      break;
    }
    case 404: {
      toolbox.alertError('Resource Not Found!');
      break;
    }
    case 500: {
      toolbox.alertError('Service exception, please try again later.');
      break;
    }
    default:
      toolbox.alertError(`Unexpected exception, status code: ${status}.`);
      break;
  }
};
/**
 * http response
 */
axios.interceptors.response.use(
  (response) => {
    const { status } = response;
    switch (status) {
      case 200: {
        const tokenHeaderName = 'authorization';
        const token = response.headers[tokenHeaderName];
        if (token) {
          axiosExtra.setServiceToken(token);
        }
        break;
      }
      default: {
        // empty
      }
    }
    return response;
  },
  (err: AxiosError) => {
    /** check for autoError config
     * By passing a config property {autoError: false} in axios request
     * we can turn off global error handling for a specific endpoint call and you can use catch(error) to handle the error.
     */
    const { response } = err;
    if (!response && err.message === 'Network Error') {
      toolbox.alertError('Network Error');
      return;
    }
    const { status } = response;
    // console.log(response, 'response');
    if (status === 401) {
      axiosExtra.setAccessToken(null);
      window.location = settings.CONTEXT_PATH + settings.LOGIN_PATH;
      return false;
    }

    if (
      err.config &&
      Object.prototype.hasOwnProperty.call(err.config, 'autoError') &&
      err.config.autoError === false
    ) {
      return Promise.reject(err);
    }

    enhanceResponseError(err);
  }
);

export default axios;
