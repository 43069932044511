import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import './TooltipPicker.less';
import { withStyles } from '@material-ui/styles';

export const TooltipBlack = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#000',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  arrow: {
    color: '#000',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
    },
  },
}))(Tooltip);

class TooltipPicker extends React.Component {
  render() {
    const { value, placement = 'top', style } = this.props;
    return (
      <TooltipBlack title={value ?? ''} placement={placement} arrow interactive>
        <div className="tooltipPicker-div" style={style}>
          {this.props.children ?? value}
        </div>
      </TooltipBlack>
    );
  }
}

export default TooltipPicker;
