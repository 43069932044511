import React from 'react';
import ReactDOM from 'react-dom';
import App from '@/app/base/App';
import { initConfig } from './_common/utils/settings';
import { configure } from 'mobx';
import './index.less';

configure({
  enforceActions: 'always',
});
initConfig()
  .catch((err) => {
    /*extra config not exist, will use default */
    console.log(
      'Loading extra configuration failure, we will use the default.'
    );
  })
  .finally(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
  });
