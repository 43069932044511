const enUS = {
  'global.edit': 'Edit',
  'global.apply': 'Apply',
  'global.create': 'Create',
  'global.update': 'Update',
  'global.save': 'Save',
  'global.submit': 'Submit',
  'global.return': 'Return',
  'global.delete': 'Delete',
  'global.query': 'Query',
  'global.advancedSearch': 'Advanced Search',
  'global.download': 'Download',
  'global.open': 'Open',
  'global.confirm': 'Confirm',
  'global.confirm.tip': 'Confirm',
  'global.search': 'Search',
  'global.cancel': 'Cancel',
  'global.reset': 'Reset',
  'global.detail': 'Detail',
  'global.summary': 'Summary',
  'global.settings': 'Settings',
  'global.close': 'Close',
  'global.image': 'Picture',
  'global.all': 'All',
  'global.loading': 'Loading',
  'global.upload.image': 'Upload image',
  'global.modify': 'Modify',
  'global.file.select': 'Select file',
  'global.file.select.empty': 'No files selected',
  'global.add': 'Add',
  'global.key': 'Key',
  'global.value': 'Value',
  'global.action': 'Actions',
  'global.status': 'Status',
  'global.process': 'Process',
  'global.duplicate': 'Duplicate',
  'global.copy': 'Copy',
  'global.tips': 'Tips',
  'global.import': 'Import',
  'global.export': 'Export',
  'global.preview': 'preview',
  'global.no-change': 'Data no changes',
  'global.yes': 'Yes',
  'global.no': 'No',
  'global.no-data': 'No eligible data',

  'global.field.required': 'The {name} is necessary',
  'global.field.format.error': 'The format of {name} errors',
  'global.field.number': 'The {name} must be number',
  'global.field.number.integer': 'The {name} must be integer',
  'global.field.number.positive': 'The {name} must be positive number',
  'global.field.number.natural': 'The {name} must be number',
  'global.field.number.min': 'The minimum value of {name} is {min}',
  'global.field.number.max': 'The maximum value of {name} is {max}',
  'global.field.string.min': 'The length of {name} must be at least {length}',
  'global.field.string.max': 'The length of {name} must be up to {length}',
  'global.field.telphone': 'Please enter the phone number',

  'global.table.data.empty': 'No eligible data',
  'global.table.data.loading': 'Data is loading',

  'global.op.success': 'Successful Operation',
  'global.op.fail': 'Operation failed',

  'global.confirm.delete.text': 'Confirm to delete this data?',

  // https://en.wikipedia.org/wiki/ISO_4217[ISO 4217 - Wikipedia]
  'global.currency.cny.name': 'RMB',
  'global.currency.cny.unit': 'Yuan',

  'global.confirm.warn': 'Warning',

  'global.info.empty': 'No Information!',

  'global.enable': 'Enable',
  'global.disable': 'Disable',

  'global.config': 'Config',
  'global.back': 'Back',
  'global.next': 'Next',
  'global.more': 'More',
  'global.clone': 'Clone',
  'global.contactUs': 'Contact Us',
  'global.setting': 'Setting',

  'global.defaultAccount': 'Default Account',
  'global.defaultPassword': 'Default Password',
  'global.manual': 'User Manual',
  'global.learnMore': 'Learn More',
};
export default enUS;
