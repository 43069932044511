import workspace from './en_md/workspace.md';
import taskqueue from './en_md/taskqueue.md';
import reportlist from './en_md/reportlist.md';
import env from './en_md/env.md';
import variable from './en_md/variable.md';
import testcase_path from './en_md/testcase_path.md';
import testcase_header from './en_md/testcase_header.md';
import testcase_bodycontent from './en_md/testcase_bodycontent.md';
import testcase_checker from './en_md/testcase_checker.md';
import testcase_extractor from './en_md/testcase_extractor.md';
import workflow_steps from './en_md/workflow_steps.md';
import testcase_postaction from './en_md/testcase_postaction.md';
const enUS = {
  'app.easeload._common.components.WorkspaceWrapper.needCreateFirst':
    'There is currently no workspace available, please create one first.',

  'app.easeload.data.not.save':
    'Data has not been save, Are you sure you want to quit?',

  'app.easeload._common.components.LeftSideTree.collection': 'Collection',
  'app.easeload._common.components.LeftSideTree.collection.create.title':
    'Create a collection for storing use cases',
  'app.easeload._common.components.LeftSideTree.CollectionCannotBeNull':
    'Collection Cannot Be Null',
  'app.easeload._common.components.LeftSideTree.CollectionCannotBeRepeated':
    'Collection Cannot Be Repeated',

  'app.easeload._common.components.LeftSideTree.case': 'Case',
  'app.easeload._common.components.LeftSideTree.case.create.title':
    'Create a use case for this collection',
  'app.easeload._common.components.LeftSideTree.CaseCannotBeNull':
    'Case Cannot Be Null',
  'app.easeload._common.components.LeftSideTree.CaseCannotBeRepeated':
    'Case Cannot Be Repeated',

  'app.easeload._common.components.LeftSideTree.workflow': 'Workflow',
  'app.easeload._common.components.LeftSideTree.workflow.create.title':
    'Create workflow',
  'app.easeload._common.components.LeftSideTree.WorkflowCannotBeNull':
    'Workflow Cannot Be Null',
  'app.easeload._common.components.LeftSideTree.WorkflowCannotBeRepeated':
    'Workflow Cannot Be Repeated',

  'app.easeload._common.components.LeftSideTree.trafficmodel': 'TrafficModel',
  'app.easeload._common.components.LeftSideTree.trafficmodel.create.title':
    'Create traffic model',
  'app.easeload._common.components.LeftSideTree.TrafficModelCannotBeNull':
    'TrafficModel Cannot Be Null',
  'app.easeload._common.components.LeftSideTree.TrafficModelCannotBeRepeated':
    'TrafficModel Cannot Be Repeated',

  'app.easeload._common.components.LeftSideTreeCase.VariablesManagement':
    'Variables Management',
  'app.easeload._common.components.LeftSideTreeCase.VariablesManagement.title':
    'Variable management corresponds to specific variables in an environment that can be added and deleted directly in the variable management boundary, ' +
    'and can be automatically added in the scenario where the drop-down list can be created without a single item',

  'app.easeload._common.components.WorkspacePicker.CurrentWorkspace':
    'Current Workspace',

  'app.easeload._common.components.MenuBar.reset.title':
    'Rerequest the data of the current page and give up the Settings for the modification',
  'app.easeload._common.components.MenuBar.save.title':
    'Save all Settings for the current page',

  'app.easeload.toppanel.Workspace.title': 'Workspace',
  'app.easeload.toppanel.Workspace.help': workspace,

  'app.easeload.overview.OverviewPage.TaskQueue.title': 'Active Tasks',
  'app.easeload.overview.OverviewPage.TaskQueue.help': taskqueue,

  'app.easeload.overview.OverviewPage.ReportList.title': 'Report List',
  'app.easeload.overview.OverviewPage.ReportList.help': reportlist,

  'app.easeload.toppanel.Env.title': 'Env',
  'app.easeload.toppanel.Env.help': env,

  'app.easeload.toppanel.Variable.title': 'Variable',
  'app.easeload.toppanel.Variable.help': variable,

  'app.easeload.case.CaseMain.path.title': 'Path',
  'app.easeload.case.CaseMain.path.help': testcase_path,

  'app.easeload.case.CaseMain.header.title': 'Header',
  'app.easeload.case.CaseMain.header.help': testcase_header,

  'app.easeload.case.CaseMain.bodycontent.title': 'BodyContent',
  'app.easeload.case.CaseMain.bodycontent.help': testcase_bodycontent,

  'app.easeload.case.CaseMain.checker.title': 'Checker',
  'app.easeload.case.CaseMain.checker.help': testcase_checker,

  'app.easeload.case.CaseMain.extractor.title': 'Extractor',
  'app.easeload.case.CaseMain.extractor.help': testcase_extractor,

  'app.easeload.case.CaseMain.postaction.title': 'PostAction',
  'app.easeload.case.CaseMain.postaction.help': testcase_postaction,

  'app.easeload.workflow.Main.Steps.title': 'Steps',
  'app.easeload.workflow.Main.Steps.help': workflow_steps,

  'app.easeload.myworkspaces.MyWorkspacesPage.WorkspaceList': 'Workspace List',
  'app.easeload.myworkspaces.MyWorkspacesPage.WorkspaceList.WorkspaceSlogan':
    'Workspace Slogan',
  'app.easeload.myworkspaces.MyWorkspacesPage.WorkspaceList.TitleCannotBeNull':
    'Title can not be null.',
  'app.easeload.myworkspaces.MyWorkspacesPage.WorkspaceList.TitleCannotBeRepeated':
    'Title can not be repeated.',
  'app.easeload.myworkspaces.MyWorkspacesPage.WorkspaceList.Rename': 'Rename',

  'app.easeload.myworkspaces.MyWorkspacesPage.TaskQueue': 'Active Tasks',
  'app.easeload.myworkspaces.MyWorkspacesPage.Workspace': 'Workspace',
  'app.easeload.myworkspaces.MyWorkspacesPage.Slogan': 'Slogan',
  'app.easeload.myworkspaces.MyWorkspacesPage.Type': 'Type',
  'app.easeload.myworkspaces.MyWorkspacesPage.Status': 'Status',
  'app.easeload.myworkspaces.MyWorkspacesPage.StartTime': 'Start Time',
  'app.easeload.myworkspaces.MyWorkspacesPage.EndTime': 'End Time',
  'app.easeload.myworkspaces.MyWorkspacesPage.Elapse': 'Elapse',

  'app.easeload.myworkspaces.MyWorkspacesPage.ReportList': 'Report List',

  'app.easeload.overview.OverviewPage.RenameCurrentWorkspace': 'Rename',
  'app.easeload.overview.OverviewPage.RenameCurrentWorkspace.title':
    'You can rename the current workspace in the left drop-down list',
  'app.easeload.overview.OverviewPage.ExportCurrentWorkspace': 'Export',
  'app.easeload.overview.OverviewPage.ExportCurrentWorkspace.title':
    'You can export the current workspace in the left drop-down list to be a local file',

  'app.easeload.overview.OverviewPage.operation.video':
    'Learn workspace in 15 seconds',

  'app.easeload.overview.OverviewPage.ImportWorkspace': 'Import',
  'app.easeload.overview.OverviewPage.ImportWorkspace.title':
    'You can import the local workspace file into the workspace list, ' +
    'and the workspace will be shown in the drop-down list on the left side of the table',
  'app.easeload.overview.OverviewPage.CreateWorkspace': 'Create',
  'app.easeload.overview.OverviewPage.CreateWorkspace.title':
    'You can manually create the workspace, and the work space will be shown in the drop-down list on the left',

  'app.easeload.overview.OverviewPage.Slogan': 'Slogan',
  'app.easeload.overview.OverviewPage.Title': 'Title',
  'app.easeload.overview.OverviewPage.CreateMan': 'Create Man',
  'app.easeload.overview.OverviewPage.CreateTime': 'Create Time',
  'app.easeload.overview.OverviewPage.LastModifiTime': 'Last Modifi Time',
  'app.easeload.overview.OverviewPage.WorkspaceStatistics':
    'Workspace Statistics',
  'app.easeload.overview.OverviewPage.Cases': 'Cases',
  'app.easeload.overview.OverviewPage.CollectionCount': 'Collection',
  'app.easeload.overview.OverviewPage.CaseCount': 'Case',

  'app.easeload.overview.OverviewPage.Variables': 'Variables',
  'app.easeload.overview.OverviewPage.EnvCount': 'Env',
  'app.easeload.overview.OverviewPage.VariableCount': 'Variable',

  'app.easeload.overview.OverviewPage.Workflows': 'Workflows',
  'app.easeload.overview.OverviewPage.WorkflowCount': 'Workflow',

  'app.easeload.overview.OverviewPage.Tasks': 'Tasks',
  'app.easeload.overview.OverviewPage.RunnerCount': 'Runner',
  'app.easeload.overview.OverviewPage.CreatedCount': 'Created',
  'app.easeload.overview.OverviewPage.FinishCount': 'Finish',

  'app.easeload.overview.OverviewPage.Reports': 'Reports',
  'app.easeload.overview.OverviewPage.ReportCount': 'Report',

  'app.easeload.case.CasePage.operation.video': 'Learn test case in 15 seconds',

  'app.easeload.case.CasePage.CreateFirst': "Let's create your first test case",

  'app.easeload.case.CaseMain.Method': 'Method',
  'app.easeload.case.CaseMain.Endpoint': 'Endpoint',
  'app.easeload.case.CaseMain.Path': 'Path',
  'app.easeload.case.CaseMain.Send': 'Send',
  'app.easeload.case.CaseMain.Send.title':
    'To test the current set of good use cases, ' +
    'the TAB of the lower TAB can see the return of different types',

  'app.easeload.case.CaseMain.Request': 'Request',
  'app.easeload.case.CaseMain.Request.Headers': 'Request Headers',
  'app.easeload.case.CaseMain.Body': 'Body',
  'app.easeload.case.CaseMain.Checker': 'Checker',
  'app.easeload.case.CaseMain.Extractor': 'Extractor',
  'app.easeload.case.CaseMain.Type': 'Type',
  'app.easeload.case.CaseMain.Content': 'Content',

  'app.easeload.case.CaseMain.Response': 'Response',
  'app.easeload.case.CaseMain.Response.Headers': 'Response Headers',
  'app.easeload.case.CaseMain.TestResult': 'Test Result',
  'app.easeload.case.CaseMain.Checked': 'Checked',
  'app.easeload.case.CaseMain.Extracted': 'Extracted',

  'app.easeload.case.CaseMain.TableRequestHeaders.Name': 'Name',
  'app.easeload.case.CaseMain.TableRequestHeaders.Values': 'Values',
  'app.easeload.case.CaseMain.TableResponseHeaders.Name': 'Name',
  'app.easeload.case.CaseMain.TableResponseHeaders.Values': 'Values',

  'app.easeload.case.CaseMain.TableRequestChecker.Type': 'Type',
  'app.easeload.case.CaseMain.TableRequestChecker.Key': 'Key',
  'app.easeload.case.CaseMain.TableRequestChecker.Predictor': 'Predictor',
  'app.easeload.case.CaseMain.TableRequestChecker.Value': 'Value',
  'app.easeload.case.CaseMain.TableResponseChecked.Type': 'Type',
  'app.easeload.case.CaseMain.TableResponseChecked.Key': 'Key',
  'app.easeload.case.CaseMain.TableResponseChecked.Predictor': 'Predictor',
  'app.easeload.case.CaseMain.TableResponseChecked.Value': 'Value',

  'app.easeload.case.CaseMain.TableRequestExtractor.Type': 'Type',
  'app.easeload.case.CaseMain.TableRequestExtractor.Variable': 'Variable',
  'app.easeload.case.CaseMain.TableRequestExtractor.Path': 'Path',
  'app.easeload.case.CaseMain.TableResponseExtracted.Type': 'Type',
  'app.easeload.case.CaseMain.TableResponseExtracted.Variable': 'Variable',
  'app.easeload.case.CaseMain.TableResponseExtracted.Path': 'Path',
  'app.easeload.case.CaseMain.TableResponseExtracted.Value': 'Value',

  'app.easeload.case.CaseMain.PostAction': 'Post Action',
  'app.easeload.case.CaseMain.TableRequestPostAction.Action': 'Action',
  'app.easeload.case.CaseMain.TableRequestPostAction.Target': 'Target',

  'app.easeload.case.Variables.Env': 'Envs',
  'app.easeload.case.Variables.EnvManagement': 'Env Management',
  'app.easeload.case.Variables.EnvManagement.title':
    'Environment management corresponds to the development environment, test environment, and production environment in common projects. ' +
    'Multiple variables are managed in each environment. Each environment can be copied into a new environment, ' +
    'and the number and name of variables in different environments must be the same',
  'app.easeload.case.Variables.AddVariable': 'Add Variable',
  'app.easeload.case.Variables.Type': 'Type',
  'app.easeload.case.Variables.Name': 'Name',
  'app.easeload.case.Variables.Start': 'Start',
  'app.easeload.case.Variables.End': 'End',
  'app.easeload.case.Variables.Step': 'Step',
  'app.easeload.case.Variables.Value': 'Value',

  'app.easeload.case.EnvManagement.EnvName': 'Env Name',
  'app.easeload.case.EnvManagement.VariableCount': 'Variable Count',

  'app.easeload.workflow.Workflow.operation.video':
    'Learn workflow in 15 seconds',

  'app.easeload.workflow.Workflow.CreateFirst':
    "Let's create your first workflow",

  'app.easeload.workflow.Main.Steps': 'Steps',
  'app.easeload.workflow.Main.Type': 'Type',
  'app.easeload.workflow.Main.Title': 'Title',
  'app.easeload.workflow.Main.Case': 'Case',
  'app.easeload.workflow.Main.Step': 'Step',
  'app.easeload.workflow.Main.Step.create.title': 'Add workflow step',
  'app.easeload.workflow.Main.Step.icon.TESTREF.title': 'Test case reference',
  'app.easeload.workflow.Main.Step.icon.WAIT.title': 'Wait',
  'app.easeload.workflow.Main.Step.icon.PARALLEL.title': 'Parallel',
  'app.easeload.workflow.Main.Step.icon.LOOP.title': 'Loop',
  'app.easeload.workflow.Main.Step.icon.TRIGGER.title': 'Trigger',
  'app.easeload.workflow.Main.Step.case.detail.title':
    'Details of test cases associated with workflows',
  'app.easeload.workflow.Main.Wait': 'Wait(TimeUnit)',
  'app.easeload.workflow.Main.Jitter': 'Jitter(TimeUnit)',
  'app.easeload.workflow.Main.Times': 'Times',
  'app.easeload.workflow.Main.Workflow': 'Workflow',

  'app.easeload.workflow.Main.CaseOrStep': 'Case or Step',

  'app.easeload.trafficmodel.TrafficModelPage.operation.video':
    'Learn traffic model in 15 seconds',

  'app.easeload.trafficmodel.TrafficModelPage.CreateFirst':
    "Let's create your first traffic model",

  'app.easeload.trafficmodel.MetricType': 'Metric Type',
  'app.easeload.trafficmodel.ComboBox': 'Combo Box',
  'app.easeload.trafficmodel.MetricName': 'Metric Name',
  'app.easeload.trafficmodel.Value': 'Value',
  'app.easeload.trafficmodel.BreakControl': 'Break Control',

  'app.easeload.trafficmodel.TrafficModel': 'Traffic Model',
  'app.easeload.trafficmodel.Env': 'Env',

  'app.easeload.trafficmodel.RampUp': 'Ramp Up(5s)',
  'app.easeload.trafficmodel.LOAD.RampUp': 'Ramp Up(1m)',
  'app.easeload.trafficmodel.SOAK.RampUp': 'Ramp Up(2m)',
  'app.easeload.trafficmodel.STRESS.RampUp': 'Ramp Up(2m)',
  'app.easeload.trafficmodel.CUSTOM.RampUp': 'Ramp Up(10s)',

  'app.easeload.trafficmodel.StageTime': 'Stage Time(2m)',
  'app.easeload.trafficmodel.CUSTOM.StageTime': 'Stage Time(5m)',
  'app.easeload.trafficmodel.Attendees': 'Attendees(100)',

  'app.easeload.trafficmodel.CreateTask': 'Create Task',
  'app.easeload.trafficmodel.Run': 'Run',
  'app.easeload.trafficmodel.Run.title':
    'Can run current traffic model Settings, and jump to the server page to show the task operation',
  'app.easeload.trafficmodel.PerformanceAnalysis':
    'Whether performance analysis?',

  'app.easeload.trafficmodel.Workflow': 'Workflow',
  'app.easeload.trafficmodel.MaxParallel': 'Max Parallel(1000)',
  'app.easeload.trafficmodel.HttpMaxConnections': 'Http Max Connections(1000)',
  'app.easeload.trafficmodel.HttpTimeout': 'Http Timeout(5s)',

  'app.easeload.trafficmodel.MaxDuration': 'Max Duration(10m)',
  'app.easeload.trafficmodel.SOAK.MaxDuration': 'Max Duration(2h)',
  'app.easeload.trafficmodel.STRESS.MaxDuration': 'Max Duration(2h)',
  'app.easeload.trafficmodel.SPIKE.MaxDuration': 'Max Duration(1h)',
  'app.easeload.trafficmodel.CUSTOM.MaxDuration': 'Max Duration(1h)',

  'app.easeload.trafficmodel.MaxAttendees': 'Max Attendees(100)',
  'app.easeload.trafficmodel.STRESS.MaxAttendees': 'Max Attendees(10000)',

  'app.easeload.trafficmodel.BaseAttendees': 'Base Attendees(10)',
  'app.easeload.trafficmodel.StageRampUp': 'Stage Ramp Up(10s)',
  'app.easeload.trafficmodel.NormalAttendees': 'Normal Attendees(10)',
  'app.easeload.trafficmodel.NormalRampUp': 'Normal Ramp Up(10s)',
  'app.easeload.trafficmodel.PeakTime': 'Peak Time(1m)',
  'app.easeload.trafficmodel.PeakIntervalLow': 'Pea Interval Low(5m)',
  'app.easeload.trafficmodel.PeakIntervalHigh': 'Peak Interval High(7m)',

  'app.easeload.task.TasksPage.operation.video': 'Learn task in 15 seconds',

  'app.easeload.task.Summary': 'Summary',
  'app.easeload.task.Stage': 'Stage',
  'app.easeload.task.Execution': 'Execution',
  'app.easeload.task.Task': 'Task',
  'app.easeload.task.Title': 'Title',
  'app.easeload.task.Status': 'Status',
  'app.easeload.task.TrafficModels': 'Traffic Models',
  'app.easeload.task.Workflows': 'Workflows',
  'app.easeload.task.Nodes': 'Nodes',
  'app.easeload.task.Env': 'Env',
  'app.easeload.task.TaskType': 'Task Type',
  'app.easeload.task.CreateTime': 'Create Time',
  'app.easeload.task.Start': 'Start',
  'app.easeload.task.End': 'End',
  'app.easeload.task.Duration': 'Duration',
  'app.easeload.task.RequestTotal': 'Request Total',
  'app.easeload.task.ErrPercent': 'Err Percent(%)',
  'app.easeload.task.MaxActiveAttendees': 'Max Active Attendees',
  'app.easeload.task.AttendeeTotal': 'Attendee Total',
  'app.easeload.task.ActiveAttendees': 'Active Attendees',
  'app.easeload.task.ActiveConnections': 'Active Connections',
  'app.easeload.task.IdleConnections': 'Idle Connections',

  'app.easeload.task.Interface': 'Interface',
  'app.easeload.task.Count': 'Count',
  'app.easeload.task.Errs': 'Errs',
  'app.easeload.task.Qps': 'Qps(m1)',
  'app.easeload.task.P50': 'P50(ms)',
  'app.easeload.task.P95': 'P95(ms)',
  'app.easeload.task.P99': 'P99(ms)',
  'app.easeload.task.P999': 'P999(ms)',

  'app.easeload.task.Connections': 'Connections',
  'app.easeload.task.ActiveAttendee': 'Active Attendee',
  'app.easeload.task.Estimate': 'Estimate',
  'app.easeload.task.Elapse': 'Elapse',
  'app.easeload.task.cancel.title': 'Cancel the current task',

  'app.easeload.bottleneckanalysis.BottleneckAnalysis.operation.video':
    'Learn performance analysis in 15 seconds',

  'app.easeload.bottleneckanalysis.Service': 'Service',
  'app.easeload.bottleneckanalysis.Api': 'Api',
  'app.easeload.bottleneckanalysis.TimePoint': 'Time Point',

  'app.easeload.bottleneckanalysis.ExceptionalPoints': 'Exceptional Points',
  'app.easeload.bottleneckanalysis.TreeGraphs': 'Tree Graphs',

  'app.easeload.bottleneckanalysis.Weight': 'Weight',
  'app.easeload.bottleneckanalysis.Metric': 'Metric',
  'app.easeload.bottleneckanalysis.Diagnosis': 'Diagnosis',
  'app.easeload.bottleneckanalysis.Link': 'Link',
  'app.easeload.bottleneckanalysis.Trace': 'Trace',
  'app.easeload.bottleneckanalysis.M1ErrorRate': 'M1 Error Rate',
};

export default enUS;
