const enUS = {
  'stack.template.stack.name': 'Stack Name',
  'stack.template.create.stack': 'Create Stack',
  'stack.template.update.stack': 'Update Manifest',
  'stack.template.delete.stack': 'Delete Manifest',
  'stack.template.provision.stack': 'Provision a Stack',
  'stack.template.delete.confirm': 'Confirm to delete this Stack template?',
  'stack.template.update.error': 'Edit Stack Template Error',
  'stack.template.create.error': 'Create Stack Template Error',
  'stack.template.component.name.duplicate':
    'is dependent on other components and cannot be deleted',
  'stack.template.data.not.save':
    'Data has not been save, Are you sure you want to quit?',
  'stack.template.component': 'Component',
  'stack.template.stateful.component': 'Stateful Component',
  'stack.template.stateless.component': 'Stateless Component',
  'stack.template.enable.component': 'Enable Component',
  'stack.template.boot.command':
    'boot up command of the component(Please split by";")',
  'stack.template.liveness.command':
    'Liveness probe command of the component(Please split by";")',
  'stack.template.readiness.command':
    'Readiness probe command of the component(Please split by";")',
  'stack.template.coloring.node': 'Coloring node of the component',
  'stack.template.component.create': 'Add Component',
  'stack.template.component.update': 'Edit Component',
  'stack.template.component.common': 'Common Properties',
  'stack.template.component.command.resource': 'Command & Resource',
  'stack.template.component.access.configuration': 'Access & Configuration',
  'stack.template.export.spec': 'Export Spec',
  'stack.template.import.spec': 'Import Spec',
  'stack.template.component.add.env.variable': 'Add Environment Variable',
  'stack.template.component.add.env.variable.btn': 'Add env property',
  'stack.template.component.add.env.variable.desc':
    'To add environment variable, please enter environment variable label and environment variable value.',
  'stack.template.component.add.name.port': 'Add name port',
  'stack.template.component.edit.name.port': 'Edit name port',
  'stack.template.component.add.name.port.desc':
    'To add name port, please enter port, node port or name port.',
  'stack.template.component.management.name.configuration':
    'Configuration Management',
  'stack.template.component.add.name.configuration': 'Add Configuration',
  'stack.template.component.edit.name.configuration': 'Edit Configuration',
  'stack.template.component.add.name.configuration.btn': 'Add Configuration',
  'stack.template.component.add.name.configuration.desc':
    'To add configuration, please enter configured value and configured key.',

  'stack.template.component.add.annotation.key': 'Annotation Key',
  'stack.template.component.add.annotation.value': 'Annotation Value',
  'stack.template.component.add.annotation.variable': 'Add annotation variable',
  'stack.template.component.add.name.annotation.btn': 'Add annotation',
  'stack.template.component.add.annotation.variable.desc':
    'To add annotation variable, please enter annotation variable key and value.',

  'stack.edit.name.port.is.required': 'name port or configuration is required',

  'stack.instance.delete.confirm': 'Confirm to delete this Stack instance?',
  'stack.instance.new.version': 'Select New Versions',
  'stack.instance.detail': 'Stack Instance BalanceDetail',
  'stack.instance.differ.version': 'Differ Version',
  'stack.instance.new.version.btn': 'New Version',

  'stack.dashboard.pod.card.restart.count': 'Restart Count',
  'stack.dashboard.pod.card.running.status': 'Ready',
  'stack.dashboard.pod.card.pod.state': 'State',
  'stack.dashboard.service.list': 'Service List',
  'stack.dashboard.service.image': 'Image',
  'stack.dashboard.service.image.version': 'Image Version',
  'stack.dashboard.service.instance.count': 'Instance Count',

  'stack.rollback.history.version.title': 'Rollback History Version',
  'stack.rollback.history.version.confirm':
    'Confirm to rollback this history version?',
  'stack.rollback.alert.title': 'CAUTION',
  'stack.rollback.alert.content':
    'AFTER ROLLBACK SOME CONFIGURATION WILL BE LOSE',
  'stack.rollback.alert.rollback.version': 'Rollback Version',
  'stack.rollback.alert.version': 'Version',
  'stack.rollback.alert.cluster.name': 'Cluster Name',
  'stack.rollback.alert.namespace': 'Namespace',
  'stack.rollback.alert.comments': 'Comments',
  'stack.rollback.alert.your.version': 'Your version',

  'stack.manifest.base.cancel': 'Cancel',
  'stack.manifest.base.yes': 'Yes',
  'stack.wizard.manifest.deploy.title': 'Deploy',
  'stack.wizard.manifest.title.deploy.status': 'Deployment Status',
  'stack.wizard.manifest.title.deployment.history': 'Deployment History',
  'stack.wizard.manifest.toolbar.edit': 'Edit',
  'stack.wizard.manifest.toolbar.SaveAsManifest': 'Save As Manifest',
  'stack.wizard.manifest.toolbar.pull': 'Pull',
  'stack.wizard.manifest.toolbar.diff': 'Diff',
  'stack.wizard.manifest.toolbar.view': 'View',
  'stack.wizard.manifest.toolbar.deploy': 'Deploy',
  'stack.wizard.manifest.toolbar.Rollback': 'Rollback',
  'stack.wizard.manifest.toolbar.delete.manifest': 'Delete Manifest',
  'stack.wizard.manifest.card.info.hash.tag': 'Hash Tag is: ',
  'stack.wizard.manifest.card.info.description': 'Description is: ',
  'stack.wizard.manifest.card.info.not.deploy': 'Not Deploy yet ',
  'stack.wizard.manifest.card.info.not.description': 'No Description ',
  'stack.wizard.manifest.card.info.release.in':
    'By MegaEase Team * Released in ',
  'stack.wizard.manifest.card.info.by.team':
    'By MegaEase Team * Not Deploy yet ',
  'stack.wizard.manifest.card.info.edit.in': '  *  Edited in ',
  'stack.wizard.manifest.card.info.k8s.zone': 'Kubernates ',
  'stack.wizard.manifest.card.info.local': 'Local',
  'stack.wizard.manifest.card.info.no.description': 'No Description',
  'stack.wizard.manifest.card.info.remote': 'Remote',
  'stack.wizard.manifest.deploy.modal.stack.name': 'Stack Name',
  'stack.wizard.manifest.deploy.modal.namespace': 'Namespace',
  'stack.wizard.manifest.deploy.modal.cluster.name': 'Cluster Name',
  'stack.wizard.manifest.deploy.modal.comments': 'Comments',
  'stack.wizard.manifest.stack.list.title': 'Stack List',
  'stack.wizard.manifest.stack.new.stack': 'New Stack',
  'stack.wizard.manifest.home.title': 'Stack',
  'stack.wizard.manifest.home.spec.yaml': 'Stack Spec YAML',
  'stack.wizard.manifest.home.k8s.cluster': 'k8s Cluster',
  'stack.wizard.history.version.deploy.history': 'Stack Deployment History',
  'stack.wizard.history.version.topology': 'Stack Component',
  'stack.wizard.history.version.deploy.at': 'Deploy at',
  'stack.wizard.history.version.hash.tag': 'Hash tag: ',
  'stack.wizard.history.version.save.as.manifest.btn': 'Save As Manifest',
  'stack.wizard.history.version.event.info.btn': 'Event',
  'stack.wizard.history.version.rollback.btn': 'Rollback',
  'stack.wizard.history.version.save.as.manifest.modal.tip':
    'Save as manifest will overwrite the manifest. Are you sure you want to save it? ',
  'stack.wizard.history.version.save.as.manifest.instance.id': 'Instance ID',
  'stack.wizard.history.version.save.as.manifest.comments': 'Comments',
  'stack.wizard.history.version.rollback.modal.cluster.name': 'Cluster Name',
  'stack.wizard.history.version.rollback.modal.namespace': 'Namespace',
  'stack.wizard.history.version.rollback.modal.comments': 'Comments',
  'stack.wizard.history.version.topology.view': 'Image',
  'stack.wizard.history.version.topology.image.version': 'Image Version',
  'stack.wizard.history.version.topology.image.type': 'Image Type',
  'stack.wizard.history.version.topology.instance.count': 'Instance Count',
  'stack.wizard.event.tip.no.event': 'No Events ...',
  'stack.wizard.event.manifest.btn': 'Manifest File',
  'stack.wizard.event.deployment.btn': 'Deployment File',
  'stack.wizard.event.choose.instance.id': 'Choose One Stack Instance ID',
  'stack.wizard.event.list': 'Stack Event List',

  'stack.wizard.event.step.begin.update': 'Begin to Update Component',
  'stack.wizard.event.step.consistency.check': 'Stack Consistency Check',
  'stack.wizard.event.step.component.health.check': 'Component Health Check',
  'stack.wizard.event.step.finish': 'Finish',

  'stack.wizard.diff.version.title': 'Diff Version From Manifest To',
  'stack.wizard.diff.version.manifest.version': 'Manifest Version',
  'stack.wizard.diff.version.deploy.version': 'Remote Deploy Version',
  'stack.wizard.deploy.confirm.info': 'Confirm to deploy this Stack?',
  'stack.wizard.deploy.confirm.title': 'Deploy Stack',
  'stack.wizard.deploy.deploy': 'Deploy',
  'stack.wizard.deploy.version': 'Version',
  'stack.wizard.deploy.cluster.name': 'Cluster Name: ',
  'stack.wizard.deploy.namespace': 'Namespace: ',
  'stack.wizard.deploy.comments': 'Comments: ',
  'stack.wizard.deploy.your.version': 'Version To Be Released',
  'stack.wizard.current.running.version': 'Currently Running Version ',

  'stack.wizard.edit.base.modal.stack.description': 'Stack Description',

  'stack.wizard.edit.base.modal.stack.easemesh.EditMeshConfig':
    'Edit Mesh Config',
  'stack.wizard.edit.base.modal.stack.easemesh.enable':
    'Whether to enable EaseMesh',
  'stack.wizard.edit.base.modal.stack.easemesh.service_labels':
    'Service Labels',
  'stack.wizard.edit.base.modal.stack.easemesh.application_port':
    'Application Port',
  'stack.wizard.edit.base.modal.stack.easemesh.app_container_name':
    'App Container Name',
  'stack.wizard.edit.base.modal.stack.easemesh.alive_probe_url':
    'Alive Probe Url',
  'stack.wizard.edit.base.modal.stack.easemesh.init_container_image':
    'Init Container Image',
  'stack.wizard.edit.base.modal.stack.easemesh.sidecar_image': 'Sidecar Image',

  'stack.wizard.edit.topology.toolbox.title': 'Toolbox',
  'stack.wizard.edit.yaml.confirm.title': 'Close Edit Component YAML',
  'stack.wizard.edit.yaml.confirm.content':
    'Edited data has not been saved, confirm to exit?',

  'stack.wizard.edit.component.name': 'Component Name',
  'stack.wizard.edit.component.description': 'Description of Component',
  'stack.wizard.edit.component.image': 'Image of the Component',
  'stack.wizard.edit.component.image.version':
    'Image version of the of Component',
  'stack.wizard.edit.component.replicas': 'Replicas of the of Component',
  'stack.wizard.edit.component.select.depended': 'Select depended components',
  'stack.wizard.edit.component.image.pull.polices': 'Image pull polices',
  'stack.wizard.edit.component.EnableComponent': 'Enable Component',
  'stack.wizard.edit.component.DraftComponent': 'Draft Component',
  'stack.wizard.edit.component.cpu.limit': 'CPU limit',
  'stack.wizard.edit.component.memory.require': 'Memory require',
  'stack.wizard.edit.component.cpu.require': 'CPU require',
  'stack.wizard.edit.component.memory.limit': 'Memory limit',
  'stack.wizard.edit.component.pvc.mounts': 'PVC or PVC template mounts',

  'stack.dashboard.cluster.node.count': 'Node Count',
  'stack.dashboard.cluster.pod.count': 'Pod Count',
  'stack.dashboard.cluster.waiting.pod.count': 'Pod Waiting',
  'stack.dashboard.cluster.running.pod.count': 'Pod Running',
  'stack.dashboard.cluster.terminate.pod.count': 'Pod Terminated',

  'stack.wizard.manifest.search.stack': 'Search Stack',
  'stack.manifest.base.commit': 'Commit',
  'stack.manifest.base.ok': 'OK',
  'stack.manifest.base.close': 'Close',
  'stack.manifest.base.import.spec': 'Import Spec',
  'stack.manifest.base.no.comments': 'No Comments',
  'stack.manifest.base.topology': 'Topology',
  'stack.manifest.base.title.cluster.info': 'Cluster Info',
  'stack.wizard.manifest.search.service': 'Search Service',
  'stack.wizard.dashboard.memory.usage': 'Memory Usage',
  'stack.wizard.dashboard.cpu.usage': 'CPU Usage',

  'stack.wizard.dashboard.node': 'Node',

  'stack.wizard.edit.table.key': 'Key',
  'stack.wizard.edit.table.host.port': 'host port/node port',
  'stack.wizard.edit.value.host.port': 'host port',
  'stack.wizard.edit.value.node port': 'node port',
  'stack.wizard.edit.table.use.as.file': 'Use As File',
  'stack.wizard.edit.table.is.sub.path': 'Is Sub Path',
  'stack.wizard.edit.table.is.node.port': 'Is Node Port',

  'stack.wizard.manifest.diff.different':
    'This manifest component is different from deploy one',
  'stack.wizard.manifest.diff.same':
    'This manifest component is same as deploy one',
  'stack.wizard.manifest.diff.new': 'This manifest component is a new one',
  'stack.wizard.manifest.diff.label.different': 'Diff',
  'stack.wizard.manifest.diff.label.same': 'Same',
  'stack.wizard.manifest.diff.label.new': 'New',

  'stack.wizard.base.components': 'components',
  'stack.wizard.base.name': 'name',
  'stack.wizard.base.rollback': 'Rollback',
  'stack.wizard.manifest.home.component.yaml': 'Component YAML',
  'stack.wizard.manifest.home.component.yaml.diff': 'Component YAML Diff',
  'stack.wizard.base.namespace': 'Namespace',
  'stack.wizard.base.cluster.name': 'Cluster Name',
  'stack.wizard.base.tip.show.more': 'Show More',
  'stack.wizard.manifest.type.draft': 'Draft',
  'stack.wizard.manifest.type.deployed': 'Deployed',
  'stack.wizard.manifest.type.draft.deployed': 'Draft from Deployed Version',
  'stack.wizard.manifest.status.different': 'Different',
  'stack.wizard.manifest.status.same': 'Same',
  'stack.wizard.manifest.status.behind': 'Behind',
  'stack.wizard.manifest.status.undeploy': 'Undeploy',
  'stack.wizard.manifest.deploy.error': 'Deploy Error',

  'stack.wizard.event.read.more': 'Read More',
};
export default enUS;
