import React from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { hot } from 'react-hot-loader/root';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css/normalize.css';
import { history } from '@/_common/utils/routing';
import settings from '@/_common/utils/settings';
import { rootHub } from '@/app/_common/utils/mhub';
import { IntlProvider, useIntl } from 'react-intl';
import localeMessages from '@/app/_common/locale';
import AppStore from './AppStore';
import moment from 'moment';
import { MessageStub } from '@/_common/components/Message';
import ThinSpinner from '@/_common/components/ThinSpinner';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import theme from '@/app/_common/styles/theme';
import * as locales from '@material-ui/core/locale';
import RegisterPage from './RegisterPage';
import ResetPassword from './ResetPassword';
import InviteRegisterPage from './InviteRegisterPage';
import { makeStyles } from '@material-ui/core/styles';
import './app.less';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import { DataCenterProvider } from './DataCenterProvider';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
}));
const hub = rootHub();
const LoginPage = React.lazy(() => import('./LoginPage'));
const MainPage = React.lazy(() => import('./MainPage'));
const InvitePage = React.lazy(() => import('./InvitePage'));
const CallbackPage = React.lazy(() => import('./CallbackPage'));

const [appStore] = hub.useSome(AppStore);

const { model: appModel } = appStore;

const ProtectedMainPage = observer((props) => {
  const { location } = props;

  appStore.tryAuthenticate();
  if (appModel.loading) {
    return <ThinSpinner desc="Loading User Information ..." />;
  }

  if (appModel.authenticated) {
    return (
      <DataCenterProvider>
        <MainPage {...props} />
      </DataCenterProvider>
    );
  }
  return (
    <Redirect
      to={{
        pathname: settings.LOGIN_PATH,
        state: { from: location },
      }}
    />
  );
});

function AppRouterContent() {
  const intl = useIntl();
  return (
    <Router history={history}>
      <React.Suspense
        fallback={
          <ThinSpinner
            desc={intl.formatMessage({ id: 'app.resource.loading' })}
          />
        }
      >
        <Switch>
          <Route
            path={settings.LOGIN_PATH}
            render={(props) => <LoginPage {...props} />}
          />
          <Route
            path={settings.REGISTER_PATH}
            render={(props) => <RegisterPage {...props} />}
          />
          <Route
            path={settings.INVITE_REGISTER_PATH}
            render={(props) => <InviteRegisterPage {...props} />}
          />
          <Route
            path={settings.INVITE}
            render={(props) => <InvitePage {...props} />}
          />
          <Route
            path={settings.CHANGE_PWD}
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            path={settings.CALLBACK_PATH}
            render={(props) => <CallbackPage {...props} />}
          />

          <Route
            path="/main"
            render={(props) => <ProtectedMainPage {...props} />}
          />
          <Redirect to="/main" />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

function App() {
  const classes = useStyles();
  const { model } = appStore;
  const { language } = model;
  const meta = settings.META?.[language];
  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: '#2f54eb' } }}
      locale={language === 'zh-CN' ? zhCN : enUS}
      prefixCls="megacloud"
    >
      <div className={classes.root}>
        <ThemeProvider theme={createTheme(theme, locales[appModel.locale])}>
          <IntlProvider
            key={appModel.language}
            locale={appModel.language}
            messages={localeMessages[appModel.language]}
          >
            <AppRouterContent />
            <MessageStub />
          </IntlProvider>
        </ThemeProvider>
      </div>
    </ConfigProvider>
  );
}

export default settings.IS_PRO ? observer(App) : hot(observer(App));
