const zhCN = {
  'cicd.pipeline.name': '名称',
  'cicd.pipeline.template': '模板',
  'cicd.pipeline.lastSuccess': '上次成功',
  'cicd.pipeline.lastFailure': '上次失败',
  'cicd.pipeline.lastDuration': '上次持续时间',
  'cicd.env.name': '名称',
  'cicd.env.description': '描述',
  'cicd.env.value': '值',
  'cicd.credentials.name': '名称',
  'cicd.credentials.type': '类型',
  'cicd.credentials.createTime': '创建时间',
  'cicd.notification.name': '名称',
  'cicd.notification.type': '类型',
  'cicd.notification.description': '描述',
  'cicd.notification.createTime': '创建时间',
  'cicd.pipelineAuth.pipeline': '流水线',
  'cicd.pipelineAuth.user': '用户',
  'cicd.pipelineAuth.authority': '权限',
  'cicd.pipelineAuth.createTime': '创建时间',
  'cicd.template.name': '名称',
  'cicd.template.description': '描述',
  'cicd.template.status': '状态',
  'cicd.env.engine': '管线引擎',
  'cicd.env.createTime': '创建时间',
  'cicd.engine.name': '名称',
  'cicd.engine.address': '地址',
  'cicd.engine.createTime': '创建时间',
  'cicd.pipeline.selectEnv': '环境',
  'cicd.pipeline.new': '新增',
  'cicd.pipeline.basicInformation': '基本信息',
  'cicd.pipeline.sourceCode': '源码',
  'cicd.pipeline.trigger': '触发',
  // 'cicd.pipeline.template': 'Template',
  'cicd.pipeline.create.name': '名称',
  'cicd.pipeline.create.description': '描述',
  'cicd.pipeline.create.successNotification': '成功通知',
  'cicd.pipeline.create.failureNotification': '失败通知',
  'cicd.env.new': '新增',
  'cicd.auth.new': '新增授权',
  'cicd.notification.new': '新增通知',
  'cicd.engine.add': '新增',
  'cicd.engine.update': '更新',
  'cicd.env.update': '更新',
  'cicd.updateTime': '更新时间',
  'cicd.var.update': '更新',
  'cicd.update.success': '更新成功',
  'cicd.delete.success': '删除成功',
  'cicd.create.success': '创建成功',
  'cicd.delete.confirm.content': '确定删除吗？',
  'cicd.pipeline.tenant.name': '租户名称',
  'cicd.disable': '停用',
  'cicd.enable': '启用',
  'cicd.disabled': '已停用',
  'cicd.enabled': '已启用',
  'cicd.build': '构建',
  'cicd.pipeline.build': '正在构建',
  'cicd.credentials.usernamePassword': '用户名/密码',
  'cicd.credentials.secretText': '密文',
  'cicd.credentials.secretFile': '密文文件',
  'cicd.name': '名称',
  'cicd.kind': '类型',
  'cicd.username': '用户名',
  'cicd.password': '密码',
  'cicd.pipeline.build.id': '编号',
  'cicd.pipeline.build.time': '时间',
  'cicd.pipeline.build.result': '结果',
  'cicd.pipeline.build.commit': '提交',
  'cicd.pipeline.build.trigger': '触发',
  'cicd.pipeline.build.log': '日志',
  'cicd.pipeline.build.changes': '变更',
  'cicd.pipeline.build.params': '参数',
  'cicd.pipeline.build.params.name': '参数名称',
  'cicd.pipeline.build.params.desc': '描述',
  'cicd.pipeline.build.params.value': '参数值',
  'cicd.pipelineAuth.tenantName': '租户名称',
  'cicd.pipeline.enable': '启用',
  'cicd.pipeline.disable': '停用',
  'cicd.description': '描述',
  'cicd.defaultValue': '默认值',
  'cicd.repo': '仓库',
  'cicd.branch': '分支',
  'cicd.credentials': '凭据',
  'cicd.additional': '附加信息',
  'cicd.pipeline.create.template.parameters': '模板参数',
  'cicd.pipeline.create.template.configs': '模板配置',
  'cicd.pipeline.edit': '编辑',
  'cicd.notification.update': '更新',
  'cicd.email.placeholder': '多个邮箱用逗号分隔',
  'cicd.sms.placeholder': '多个手机号码用逗号分隔',
  'cicd.slack.placeholder': '请输入slack web api地址',
  'cicd.pipeline.refresh': '刷新',
  'cicd.pipeline.test': '连接测试',
  'cicd.pipeline.test.success': '连接成功',
  'cicd.notification.test': '发送测试',
  'cicd.notification.test.success': '发送成功',
  'cicd.notification.email': '邮箱',
  'cicd.notification.sms': '短信',
  'cicd.notification.slack': 'Slack',
  'cicd.notification.composite': '组合',
  'cicd.notification.select.tips': '请选择一个通知',
  'cicd.operation.success': '操作成功',
  'cicd.summary': '概览',
  'cicd.pipeline.update': '更新',
  'cicd.file.uploaded': '文件已上传',
  'cicd.file.no.uploaded': '文件未上传',
  'cicd.github.tips': `将Github配置为将POST请求传递到URL：`,
  'cicd.gitlab.tips': `配置GitLab以将POST请求传递到URL：`,
  'cicd.pipeline.status': '状态',
  'cicd.noChanges': '没有代码变更',
  'cicd.noChangesDescription': '没有相关的代码变更。',
  'cicd.callbackUrl': '回调地址',
  ManualTrigger: '手动触发',
  WebhookTrigger: 'Webhook触发',
  TimingTrigger: '定时触发',
  'cicd.configs': '配置当前模板',
  'cicd.container.image': '容器镜像管理',
  'cicd.image.name': '名称',
  'cicd.image.description': '描述',
  'cicd.image.registry': '仓库',
  'cicd.image.new': '新增',
  'cicd.image.update': '更新',
  'cicd.image.delete': '删除',
  'cicd.imageName': '镜像名称',
  'cicd.imageDescription': '描述',
  'cicd.imageRegistryUrl': '仓库地址',
  'cicd.imageRegistryCredential': '仓库凭据',
  'cicd.imageContent': '镜像内容',
  'cicd.add.containerImage: ': '新增容器镜像',
  'cicd.image.namespace': '命名空间',
  'cicd.script': '脚本',
  'cicd.script.name': '名称',
  'cicd.script.description': '描述',
  'cicd.script.new': '新增',
  'cicd.script.kind': '类型',
  'cicd.script.buildIn': '内置',
  'cicd.script.custom': '自定义',
  'cicd.script.copy': '复制',
  'cicd.template.new': '新增模板',
  'cicd.script.content': '脚本内容',
  'cicd.template.script': '关联脚本',
  'cicd.pipeline.create.template.addConfig': '添加配置',
  'cicd.pipeline.create.template.addParameter': '添加参数',
  'cicd.pipeline.create.template.editConfig': '编辑配置',
  'cicd.template.fixed': '内置',
  'cicd.template.custom': '自定义',
  'cicd.template.inputItem': '输入项',
  'cicd.template.parameter': '参数',
  'cicd.template.config': '配置',
  'cicd.script.upload': '上传文件',
  'cicd.pipeline.SelectTemplate': '选择模板',
  'cicd.myDockerfile': '我的Dockerfile',
  'cicd.dockerfile.name': '名称',
  'cicd.dockerfile.refs': '引用数',
  'cicd.dockerfile.createTime': '创建时间',
  'cicd.dockerfile.new': '新增',
  'cicd.dockerfile.content': 'Dockerfile内容',
  'cicd.dockerfile.update': '更新',
  'cicd.imageArgs': '构建参数',
  'cicd.value': '值',
  'cicd.namespace': '命名空间',
  'cicd.loading': '加载中...',
  'cicd.viewFullContent': '查看完整内容',
  basicInformation: '基本信息',
  sourceCode: '源码',
  triggers: '触发',
  template: '模板',
  'cicd.selectType': '选择类型',
  'cicd.progress.time': '运行时间',
  'cicd.progress.status': '运行状态',
  'cicd.progress.startTime': '开始时间',
  'cicd.progress.endTime': '结束时间',
};

export default zhCN;
