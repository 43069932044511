import React from 'react';
const CustomFormItem = ({
  label,
  children,
  align = 'flex-start',
  style,
  labelWidth = '160px',
  required = false,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: align,
        ...style,
      }}
      key={label}
    >
      <div
        className="alert-rule-label-wrapper"
        style={{
          marginRight: '30px',
          minWidth: labelWidth,
          textAlign: 'right',
          lineHeight: '38px',
          wordBreak: 'break-all',
        }}
      >
        <div>
          {label}
          {required ? <span style={{ color: '#FF0000' }}>*</span> : null}
        </div>
      </div>
      <div className="alert-rule-item-wrapper" style={{ flex: 1 }}>
        {children}
      </div>
    </div>
  );
};

export default CustomFormItem;
