import AppStore from '@/app/base/AppStore';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { put } from '../../utils/mhub';
import './LanguageSwitch.less';
import { withStyles } from '@material-ui/styles';
import languageENImg from '@/app/_common/assets/language-en.svg';
import languageCNImg from '@/app/_common/assets/language-cn.svg';
import languageENBlackImg from '@/app/_common/assets/language-en-black.svg';
import languageCNBlackImg from '@/app/_common/assets/language-cn-black.svg';
import ToolTipForTopbar from '@/app/_common/components/TooltipForTopbar/index.jsx';
const styles = (theme) => ({
  whiteColor: {
    color: '#404040',
    fontSize: '16px',
    fontWeight: '500',
  },
});

@observer
class LanguageSwitch extends Component {
  @put(AppStore, true) store;

  constructor() {
    super();
    this.state = { open: false };
    const lang = this.store.model.language;
    this.menuList = [
      {
        id: 'English',
        icon: languageENBlackImg,
        title: 'English',
        handler: () => {
          this.store.switchLanguage('en-US');
        },
        needRoot: false,
        selected: lang === 'en-US',
        disabled: false,
      },
      {
        id: 'Chinese',
        icon: languageCNBlackImg,
        title: '简体中文',
        handler: () => {
          this.store.switchLanguage('zh-CN');
        },
        needRoot: false,
        selected: lang === 'zh-CN',
        disabled: false,
      },
    ];
  }

  render() {
    const { model } = this.store;
    const { useTitle, imageSize = '20px' } = this.props;

    return (
      <ToolTipForTopbar menuList={this.menuList}>
        <div className="language-switch-container">
          <img
            src={model.language === 'zh-CN' ? languageCNImg : languageENImg}
            alt="language"
            style={{ cursor: 'pointer', width: imageSize, height: imageSize }}
          />
          {useTitle
            ? model.language === 'zh-CN'
              ? '简体中文'
              : 'English'
            : null}
        </div>
      </ToolTipForTopbar>
    );
  }
}

export default withStyles(styles)(LanguageSwitch);
