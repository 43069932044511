import React, { createContext, useState, useEffect } from 'react';
import { parseResponseError } from '../_common/utils/utils';
import dataCenterApi from '@/apis/dataCenter/data-center-api';
import toolbox from '@/_common/utils/toolbox';
import useSWR from 'swr';
const DataContext = createContext();

const fetchOptions = async () => {
  const res = await dataCenterApi.getDataCenterOptionsForTenant();
  return res.data;
};
const useOptions = () => {
  const { data, error } = useSWR(`/api/dataCenter/tenant`, () =>
    fetchOptions()
  );
  return {
    options: data,
    isLoading: !error && !data,
    isError: error,
  };
};

const DataCenterProvider = ({ children }) => {
  const [selectedDataCenter, setSelectedDataCenter] = useState(null);
  // const [dataCenterList, setDataCenterList] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  const { options: dataCenterList, isLoading: isLoadingOptions } = useOptions();
  useEffect(() => {
    if (dataCenterList?.length > 0) {
      const savedDataCenter = localStorage.getItem('selectedDataCenter');

      if (
        savedDataCenter &&
        dataCenterList?.some(
          (item) => item.id + '' === JSON.parse(savedDataCenter)?.id + ''
        )
      ) {
        console.log('savedDataCenter');
        setSelectedDataCenter(JSON.parse(savedDataCenter));
      }
    }
  }, [isLoadingOptions]);

  // useEffect(() => {
  //   const fetchDataCenterList = async () => {
  //     try {
  //       setIsLoading(true);
  //       const response = await dataCenterApi.getDataCenterOptionsForTenant();
  //       setDataCenterList(response?.data || []);
  //       if (response?.data?.length > 0) {
  //         const savedDataCenter = localStorage.getItem('selectedDataCenter');

  //         if (
  //           savedDataCenter &&
  //           response?.data?.some(
  //             (item) => item.id + '' === JSON.parse(savedDataCenter)?.id + ''
  //           )
  //         ) {
  //           setSelectedDataCenter(JSON.parse(savedDataCenter));
  //         } else {
  //           setSelectedDataCenter(response?.data[0]);
  //         }
  //       }
  //     } catch (error) {
  //       console.error(parseResponseError(error));
  //       toolbox.error(parseResponseError(error));
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchDataCenterList();
  // }, []);

  useEffect(() => {
    const savedDataCenter = localStorage.getItem('selectedDataCenter');
    if (savedDataCenter) {
      setSelectedDataCenter(savedDataCenter);
    }
  }, []);

  const handleDataCenterChange = (dataCenter) => {
    setSelectedDataCenter(dataCenter);
    localStorage.setItem('selectedDataCenter', JSON.stringify(dataCenter));
  };

  const contextValue = {
    selectedDataCenter,
    dataCenterList,
    handleDataCenterChange,
    selectedDataCenterId: selectedDataCenter?.id,
  };
  if (isLoadingOptions) {
    return null;
  }
  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};

export const useDataCenter = () => {
  const context = React.useContext(DataContext);
  if (context === undefined) {
    throw new Error('useDataCenter must be used within a DataCenterProvider');
  }
  return context;
};
export { DataContext, DataCenterProvider };
