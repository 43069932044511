const enMeta = (isBeta) => ({
  title: isBeta ? 'MegaEase Cloud(Beta)' : 'MegaEase Cloud',
  description:
    'MegaEase Cloud Console, including host management, service management, docker/k8s App, APM, mornitoring, alter, API, traffic, and service mesh management.',
  ogTitle: 'MegaEase Cloud',
  ogDescription:
    'MegaEase Cloud Console, including host management, service management, docker/k8s App, APM, mornitoring, alter, API, traffic, and service mesh management.',
  ogUrl: 'https://cloud.megaease.com/megacloud/app/',
  twitterCard: 'summary_large_image',
  twitterTitle: 'MegaEase Cloud',
  twitterDescription:
    'MegaEase Cloud Console, including host management, service management, docker/k8s App, APM, mornitoring, alter, API, traffic, and service mesh management.',
  ogSiteName: 'MegaEase Cloud',
});

const zhMeta = (isBeta) => ({
  title: isBeta ? 'MegaEase Cloud(公测版)' : 'MegaEase Cloud',
  description:
    'MegaEase Cloud 控制台, 包括主机管理, 服务管理, docker/k8s 应用, APM, 监控, 告警, API, 流量，和服务网格管理。',
  ogTitle: 'MegaEase Cloud',
  ogDescription:
    'MegaEase Cloud 控制台, 包括主机管理, 服务管理, docker/k8s 应用, APM, 监控, 告警, API, 流量，和服务网格管理。',
  ogUrl: 'https://cloud.megaease.cn/megacloud/app/',
  twitterCard: 'summary_large_image',
  twitterTitle: 'MegaEase Cloud',
  twitterDescription:
    'MegaEase Cloud 控制台, 包括主机管理, 服务管理, docker/k8s 应用, APM, 监控, 告警, API, 流量，和服务网格管理。',
  ogSiteName: 'MegaEase Cloud',
});

const getMeta = (isBeta) => {
  return {
    'en-US': enMeta(isBeta),
    'zh-CN': zhMeta(isBeta),
  };
};
export default getMeta;
