const zhCN = {
  'app.tenant.table.tenantName': '租户名称',
  'app.tenant.table.owner': '所有者',
  'app.tenant.table.status': '状态',
  'app.tenant.table.description': '描述',
  'app.tenant.table.createdAt': '创建时间',
  'app.tenant.table.updatedAt': '更新时间',
  'app.tenant.table.userEmail': '用户邮箱',
  'app.tenant.table.username': '用户名称',
  'app.tenant.table.teamName': '团队名称',
  'app.tenant.table.roleType': '角色类型',
  'app.tenant.table.inviteBy': '邀请人',

  'app.tenant.edit': '编辑租户',
  'app.tenant.create': '创建租户',
  'app.tenant.members': '租户成员',
  'app.tenant.pendingInvitations': '待处理邀请',
  'app.tenant.inviteUsers': '邀请用户',
  'app.tenant.team.create': '创建团队',
  'app.tenant.team.edit': '编辑团队',
  'app.tenant.team.name': '团队名称',
  'app.tenant.team.member.add': '添加成员',
  'app.tenant.team.member.invite': '邀请成员',
  'app.tenant.userInfo': '用户信息',
  'app.tenant.member': '成员',

  'app.tenant.toolbox.create.success': '创建租户成功',
  'app.tenant.toolbox.update.success': '更新租户信息成功',
  'app.tenant.toolbox.update.status': '更新租户状态成功',
  'app.tenant.toolbox.delete.success': '删除成功',
  'app.tenant.toolbox.email.success': '邮件发送成功',
  'app.tenant.toolbox.team.create.success': '团队创建成功',
  'app.tenant.toolbox.team.update.success': '团队信息更新成功',
  'app.tenant.toolbox.team.member.add.success': '成员添加成功',

  'app.tenant.toolbox.team.delete.title': '删除团队',
  'app.tenant.toolbox.team.delete.content': '确定删除该团队吗？',
  'app.tenant.toolbox.team.member.delete.title': '删除成员',
  'app.tenant.toolbox.team.member.delete.content':
    '确定删除该成员吗？该成员将无法访问该团队下的资源',
  'app.tenant.toolbox.invite.delete.confirm': '确定撤销该邀请吗？',
  'app.tenant.toolbox.invite.delete': '撤销邀请',
  'app.tenant.toolbox.invite.withdraw': '撤销',
  'app.tenant.toolbox.member.delete.title': '删除成员',

  'app.tenant.table.loginCount': '登录次数',
  'app.tenant.table.tenantStatistics': '统计',

  'app.tenant.registry.imageList': '镜像列表',
  'app.tenant.registry.registryInfo': '仓库信息',

  UsernameIsEmpty: '用户名为空',
  ConfirmSwitchTenants: '你确定要切换租户？',
};
export default zhCN;
