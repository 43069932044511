const zhCN = {
  'app.settings.notification.Targets.Type': '类型',
  'app.settings.notification.Targets.Name': '名称',
  'app.settings.notification.Targets.Config': '配置',
  'app.settings.notification.Targets.TargetsForm': '目标集表单',
  'app.settings.notification.Targets.Email': '电子邮箱',
  'app.settings.notification.Targets.PhoneNumber': '电话号码',
  'app.settings.notification.Targets.WebhookURL': '网络钩子链接',

  'app.settings.notification.History.Target': '目标',
  'app.settings.notification.History.Result': '结果',
  'app.settings.notification.History.Time': '时间',
  'app.settings.notification.History.Title': '标题',
  'app.settings.notification.History.Content': '内容',
  'app.settings.notification.History.Error': '错误',

  'app._common.components.Notification.index.Notification': '通知',
  'app._common.components.Notification.index.MarkAllAsRead': '全部标记为已读',

  'app.settings.notification.NotificationCenter.Title': '标题',
  'app.settings.notification.NotificationCenter.Category': '类型',
  'app.settings.notification.NotificationCenter.Source': '来源',
  'app.settings.notification.NotificationCenter.Content': '内容',
  'app.settings.notification.NotificationCenter.Resource': '关联资源',
  'app.settings.notification.NotificationCenter.MarkAllAsRead':
    '全部标记为已读',
  'app.settings.notification.NotificationCenter.OnlyUnread': '只看未读',
  'app.settings.notification.NotificationCenter.Application': '应用',
  'app.settings.notification.NotificationCenter.CreatedAt': '创建时间',
  'app.settings.notification.NotificationCenter.UpdatedAt': '更新时间',
  'app.settings.notification.NotificationCenter.UpdatedBy': '操作人',
  'app.settings.notification.NotificationCenter.MessageDetail': '消息详情',

  'app.settings.notification.NotificationCenterDetail.ViewDetail': '查看详情',

  'app.settings.notification.PushManagement.SMSUsagePerMonth': '短信用量(月)',
  'app.settings.notification.PushManagement.EmailUsagePerMonth': '邮件用量(月)',
  'app.settings.notification.PushManagement.SlackUsagePerMonth':
    'Slack推送量(月)',
  'app.settings.notification.PushManagement.DiscordUsagePerMonth':
    'Discord推送量(月)',
  'app.settings.notification.PushManagement.MessageType': '消息类型',
  'app.settings.notification.PushManagement.PushSubscription': '推送订阅',
  'app.settings.notification.PushManagement.PushDetail': '推送详情',
  'app.settings.notification.PushManagement.PushSetting': '推送设置',
  'app.settings.notification.PushManagement.SerialNumber': '序号',
  'app.settings.notification.PushManagement.Target': '目标',
  'app.settings.notification.PushManagement.CountryCode': '国家区号',
  'app.settings.notification.PushManagement.Addresses': '地址集',
  'app.settings.notification.PushManagement.Address': '地址',
  'app.settings.notification.PushManagement.Description': '描述',

  'app.settings.notification.PushLog.Address': '地址',
  'app.settings.notification.PushLog.Description': '描述',
  'app.settings.notification.PushLog.Fail': '是否推送失败？',
  'app.settings.notification.PushLog.FailMessage': '失败消息',
  'app.settings.notification.PushLog.JumpToMessageDetail': '跳转到消息详情',

  'app.settings.alertRule.RuleTemplate.Name': '名称',
  'app.settings.alertRule.RuleTemplate.Type': '类型',
  'app.settings.alertRule.RuleTemplate.Level': '级别',
  'app.settings.alertRule.RuleTemplate.EventType': '事件类型',
  'app.settings.alertRule.RuleTemplate.CreateRuleTemplate': '创建规则模板',
  'app.settings.alertRule.RuleTemplateForm.Rules': '规则集',
  'app.settings.alertRule.RuleTemplateForm.Description': '描述',
  'app.settings.alertRule.RuleTemplateForm.ResolvedDescription':
    '告警结束时的描述',

  'app.settings.doc.AppAccess.index.SelectLanguage': '选择语言',
  'app.settings.doc.AppAccess.index.AddData': '添加数据',
  'app.settings.doc.AppAccess.index.ApplicationName': '应用名称',
  'app.settings.doc.AppAccess.index.UniqueName':
    '使用一个唯一的名称用来在后续步骤中找到你的数据：',
  'app.settings.doc.AppAccess.index.Name': '名称',
  'app.settings.doc.AppAccess.index.Zone': '区',
  'app.settings.doc.AppAccess.index.Domain': '域',
  'app.settings.doc.AppAccess.index.Service': '服务',
  'app.settings.doc.AppAccess.index.Tracing': '链路追踪',
  'app.settings.doc.AppAccess.index.JavaConfiguration': 'Java配置',
  'app.settings.doc.AppAccess.index.GoConfiguration': 'Go配置',
  'app.settings.doc.AppAccess.index.PHPConfiguration': 'PHP配置',
  'app.settings.doc.AppAccess.index.DotNetConfiguration': '.NET配置',
  'app.settings.doc.AppAccess.index.Global': '全局',
  'app.settings.doc.AppAccess.index.TracingEnabled': '链路追踪激活',
  'app.settings.doc.AppAccess.index.MetricEnabled': '指标激活',
  'app.settings.doc.AppAccess.index.Sampled': '采样率',
  'app.settings.doc.AppAccess.index.TracingSampled': '链路追踪采样率',
  'app.settings.doc.AppAccess.index.Metric': '指标',
  'app.settings.doc.AppAccess.index.DownloadEaseAgent': '下载EaseAgent',
  'app.settings.doc.AppAccess.index.DownloadConfig': '下载配置',
  'app.settings.doc.AppAccess.index.AboutConfig': '关于配置',
  'app.settings.doc.AppAccess.index.JavaAgentConfigDoc': 'Java Agent配置文档',
  'app.settings.doc.AppAccess.index.GoSDKConfigDoc': 'Go SDK配置文档',
  'app.settings.doc.AppAccess.index.PHPSDKConfigDoc': 'PHP SDK配置文档',
  'app.settings.doc.AppAccess.index.DotNetSDKConfigDoc': '.NET SDK配置文档',
  'app.settings.doc.AppAccess.index.SubmitAndDownload': '提交并下载',
  'app.settings.doc.AppAccess.index.RestartApplication': '重启应用',
  'app.settings.doc.AppAccess.index.HowToUse': '如何使用',
  'app.settings.doc.AppAccess.index.DeployYourApplicationForJava':
    '部署你的应用进而开始使用Java Agent技术来发送数据到MegaEase Cloud',
  'app.settings.doc.AppAccess.index.DeployYourApplicationForGo':
    '部署你的应用进而开始使用Go SDK技术来发送数据到MegaEase Cloud',
  'app.settings.doc.AppAccess.index.DeployYourApplicationForPHP':
    '部署你的应用进而开始使用PHP SDK技术来发送数据到MegaEase Cloud',
  'app.settings.doc.AppAccess.index.DeployYourApplicationForDotNet':
    '部署你的应用进而开始使用.NET SDK技术来发送数据到MegaEase Cloud',
  'app.settings.doc.AppAccess.index.HowToUseEaseAgentInHost':
    '如何在主机中使用EaseAgent',
  'app.settings.doc.AppAccess.index.HowToUseEaseAgentInDocker':
    '如何在Docker中使用EaseAgent',
  'app.settings.doc.AppAccess.index.HowToUseGoSDK': '如何使用Go SDK',
  'app.settings.doc.AppAccess.index.HowToUsePHPSDK': '如何使用PHP SDK',
  'app.settings.doc.AppAccess.index.HowToUseDotNetSDK': '如何使用.NET SDK',
  'app.settings.doc.AppAccess.index.SeeData': '查看数据',
  'app.settings.doc.AppAccess.index.SeeYourData': '查看你的数据',
  'app.settings.doc.AppAccess.index.ClickTheButton':
    '点击按钮，给我们几分钟或更少的时间。当我们收到你的数据后会立即向你发送通知，届时你可以查看数据。',

  'app.settings.titleid': '描述',

  'app.settings.helpid.Sampled': '采样率的值必须在0到1之间。',

  'app.settings.twoFactorauthentication': '双因认证',
  'app.settings.twoFactorauthentication.setup': '设置',

  'app.settings.2faEnabled': '双因认证已启用。',
  'app.settings.2faDisabled': '双因认证已禁用。',
  'app.settings.2faEnabled.tips':
    '双重认证要求登录的不仅仅是一个密码，从而为您的帐户增加了一层额外的安全性。',
  'app.settings.disable2fa': '禁用双因认证',
  'app.settings.enable2fa': '启用双因认证',
  'app.settings.Two-factormethods': '双因认证方法',
  'app.settings.change': '更改',

  'app.settings.2faDisabled.warning': '警告',
  'app.settings.2faDisabled.warning.tips':
    '禁用双因认证将会立即删除您的双因认证信息，您的帐户将会变得更加不安全',
  'app.settings.2faDisabled.warning.tips1': '键入2FA代码以继续',
  'app.settings.2faDisabled.warning.tips2': '请输入您的2FA代码以继续',

  'app.settings.Setupauthenticationapp': '设置认证应用',
  'app.settings.Setupauthenticationapp.tips':
    '使用手机应用程序，如1Password、Auty、LastPass Authenticator 或 Microsoft Authenticator等可以获得2FA代码的软件来登录。',
  'app.settings.Setupauthenticationapp.scan': '扫描二维码',
  'app.settings.Setupauthenticationapp.scan.tips':
    '如果您无法扫描二维码，您可以手动输入',
  'app.settings.Setupauthenticationapp.scan.tips1': '此文本代码',
  'app.settings.Setupauthenticationapp.scan.tips2': '到您的应用程序中。',
  'app.settings.Setupauthenticationapp.verify': '验证应用程序中的两个连续代码',
  'app.settings.Setupauthenticationapp.verify.tips1': '输入第一个代码',
  'app.settings.Setupauthenticationapp.verify.tips2': '输入第二个代码',
  'app.settings.Setupauthenticationapp.secret': '你的双因认证密钥',

  'app.settings.2f2aNotEnabled.tips': '双因认证尚未启用。',
  'app.settings.2f2aNotEnabled.tips1':
    '双因认证要求登录的不仅仅是一个密码，从而为您的帐户增加了一层额外的安全性。',
  'app.settings.setupApp': '设置应用程序',
  'app.settings.setupApp.tips':
    '当出现提示时，使用手机上的应用程序获取双重认证代码。我们建议使用基于云的TOTP应用程序，例如：1Password、Auty, lastPass Authenticator或Microsoft Authenticator。',
  'app.settings.back': '返回之前的页面',

  'app.settings.NewUser': '新用户',
  'app.settings.UserName': '用户名',
  'app.settings.DisableUser': '禁用用户',
  'app.settings.EnableUser': '激活用户',
  'app.settings.DifferVersion': '差异版本',
  'app.settings.Tips': '提示',
  'app.settings.NewPasswordIs': '新的密码是',
  'app.settings.ResetPassword': '重设密码',
  'app.settings.ConfirmResetPassword': '确定重设密码？',
  'app.settings.SureResetPassword': '你确定重设密码？',
  'app.settings.DeactivateUser': '停用用户',
  'app.settings.ConfirmDeactivateUser': '确定停用此用户？',
  'app.settings.Status': '状态',
  'app.settings.Activated': '已激活',
  'app.settings.Terminated': '已终止',
  'app.settings.TenantName': '租户名称',
  'app.settings.Email': '邮箱',
  'app.settings.Password': '密码',
  'app.settings.FieldRequired': '此字段是必填的',
  'app.settings.FieldMinLength': '最小长度是6位',
  'app.settings.FieldMaxLength': '最大长度是20位',
  'app.settings.FieldPattern': '请输入正确的邮箱地址',

  'app.settings.User': '用户',
  'app.settings.Resource': '资源',
  'app.settings.Method': '方法',
  'app.settings.RequestAt': '请求时间',
  'app.settings.ElapseTime': '延迟时间',
  'app.settings.NeedParamId': '需要参数Id',
  'app.settings.Loading': '加载中',
  'app.settings.AuditLogDetail': '审计日志详情',
  'app.settings.Payload': '载荷',

  'app.settings.ImageName': '镜像名称',
  'app.settings.ArtifactCount': '产物数量',
  'app.settings.PullCount': '拉取次数',
  'app.settings.MegaeaseRegistry': 'Megaease仓库',
  'app.settings.StorageLimit': '存储空间限制',
  StorageUsageLimit: '存储使用/限额',
  'app.settings.Hide': '隐藏',
  'app.settings.Show': '显示',

  'app.settings.Address': '地址',
  'app.settings.Username': '用户名',

  'app.settings.Member': '成员',

  'app.settings.VendorName': '供应商',
  'app.settings.IDCLocation': '数据中心位置',
  'app.settings.SpecName': '规格',
  'app.settings.CPUNumber': 'CPU数量',
  'app.settings.Memory': '内存',
  'app.settings.MemoryGB': '内存 (GB)',
  'app.settings.Disk': '硬盘',
  'app.settings.DiskGB': '硬盘 (GB)',
  'app.settings.GPUType': 'GPU型号',
  'app.settings.GPUNumber': 'GPU数量',
  'app.settings.VRAM': '显存',
  'app.settings.VRAMGB': '显存 (GB)',
  'app.settings.Available': '可用库存量',
  'app.settings.Inactive': '不活跃的数量',
  'app.settings.Inused': '使用中的数量',
  'app.settings.Price': '价格',
  'app.settings.CreateStock': '创建库存',
  'app.settings.CreateSuccess': '创建成功',
  'app.settings.CreateFailure': '创建失败',
  'app.settings.UpdateStock': '更新库存',
  'app.settings.UpdateSuccess': '更新成功',
  'app.settings.UpdateFailure': '更新失败',
  'app.settings.RenewSuccess': '续费成功',
  'app.settings.RenewFailure': '续费失败',
  'app.settings.ExpandSuccess': '扩容成功',
  'app.settings.ExpandFailure': '扩容失败',
  'app.settings.DeleteSuccess': '删除成功',
  'app.settings.DeleteFailure': '删除失败',

  'app.settings.Operation': '操作',
  'app.settings.Monitor': '监控',
  'app.settings.Others': '其它',

  CreateStorage: '创建存储',
  UpdateStorage: '更新存储',
  StoragePrice: '存储价格',
  StoragePriceTips: '{price}/10GB/小时',
  StorageSize: '存储容量',

  Storage: '存储',
  UsedQuota: '已使用/配额',
  ServerUsedQuota: '服务器使用量/配额',
  CloneUsedQuota: '克隆使用量/配额',
  useLimit: '用量限制',

  Account: '账户',
  ViewHistory: '查看历史',

  NextPlan: '下次计划',
  NextPrice: '下次价格',
  BeforePlan: '前次计划',
  AfterPlan: '后次计划',
  Reason: '原因',
  NextQuota: '下次配额',
  Operator: '运算符',
  Changes: '变更',
  Current: '当前',
  WillRemove: '将被移除',
  Frozen: '冻结',
  NextPayment: '下次待支付',
  Date: '日期',
  PerMonth: '每月',

  'app.settings.openInviteCode': '开启邀请码',
  'app.settings.closeInviteCode': '关闭邀请码',

  'Invite User Information': '邀请用户信息',
  'Withdrawal List': '提现列表',
  username: '用户名',
  'Rebate amount': '返利金额',
  'Invitation time': '邀请时间',
  'Application Time': '申请时间',
  'Withdrawal amount': '提现金额',
  status: '状态',
  amount: '金额',
  invitationCode: '邀请码',
  copyLink: '复制链接',
  withdrawal: '提现',
  withdrawMgmt: '提现管理',
  notAvailable: '不可用',
  withdrawlSuccess: '提现申请已提交',
  withdrawlTips: '请输入合法的提现金额',

  Inviter: '邀请人',
  Invitee: '被邀请人',
  InvitationTime: '邀请时间',
  Amount: '消费金额',
  Rebate: '返利金额',

  WithdrawalPerson: '提现人',
  WithdrawalAmount: '提现金额',
  WithdrawalStatus: '提现状态',
  WithdrawalTime: '提现时间',

  Applicant: '申请人',
  ApplicationTime: '申请时间',
  paymentInfo: '支付信息',
  PaymentStatus: '支付状态',
  PaymentTime: '支付时间',
  orderNumber: '订单号',
  paymentSituation: '支付情况',

  Paid: '已支付',
  PaidSuccessfully: '支付成功',
  PleaseEnterPaymentSituation: '请输入支付情况',
  WithdrawalStatus0: '待审核',
  WithdrawalStatus1: '已支付',

  Number: '数值',
  'user.roles': '角色',
  'user.roles.management': '角色管理',

  'stock.onTheShelf': '上架',
  'stock.offTheShelf': '下架',
  'stock.onTheShelf.success': '上架成功',
  'stock.onTheShelf.failure': '上架失败',
  'stock.offTheShelf.success': '下架成功',
  'stock.offTheShelf.failure': '下架失败',
  'stock.onTheShelf.confirm': '确定上架此商品？',
  'stock.offTheShelf.confirm': '确定下架此商品？',
  'notification.CICD': '自动发布系统',
  onTheShelf: '已上架',
  offTheShelf: '已下架',
  shelfStatus: '上架状态',
  'app.settings.totalStock': '总库存',
  'app.settings.discount': '折扣',
  'app.settings.discountedPrice': '折后价格',
  'app.settings.Spec': '规格',
  'app.settings.SortOrder': '排序',

  'gpu.spec.id': 'ID',
  'gpu.spec.name': '名称',
  'gpu.spec.gpuType': 'GPU类型',
  'gpu.spec.cpu': 'CPU数量',
  'gpu.spec.memory': '内存（GB）',
  'gpu.spec.gpuNum': 'GPU数量',
  'gpu.spec.vram': '显存（GB）',
  'gpu.spec.disk': '临时存储（GB）',
  'gpu.spec.isVirtual': '是否虚拟',
  'gpu.spec.sortOrder': '排序',
  'gpu.spec.isVirtual.yes': '是',
  'gpu.spec.isVirtual.no': '否',

  'gpu.tenant': '租户',
  'gpu.resource': '资源',
  'gpu.resource.limit': '限制数值',

  maximumNumberOfGPUInstanceLists: 'GPU 实例列表的最大数量',
  maximumNumberOfRunningGPUInstances: '运行中 GPU 实例的最大数量',
  maximumNumberOfGPUStorageLists: 'GPU 存储列表的最大数量',
  maximumValueForASingleGPUStorageUnitIsGB: '单个 GPU 存储的最大值，单位为 GB',
  maximumTotalGPUStorageValueUnitIsGB: '总 GPU 存储值的最大值，单位为 GB',
  maximumNumberOfGPUStorageTags: 'GPU 存储标签的最大数量',
  maximumNumberOfCustomTemplates: '自定义模板的最大数量',
  maximumNumberOfTemplateCredentials: '模板凭证的最大数量',

  invitationCodeStatus: '启用状态',
  invitationCodeStatus0: '未启用',
  invitationCodeStatus1: '已启用',
  rebatePermille: '分润千分比',
  invitedAmount: '已邀请总额',
  invitedRebate: '分润总额',
  calcAt: '分润统计时间',
  'app.settings.DataCenter': '数据中心',
  'Rebate information': '返利信息',
};

export default zhCN;
