import axios from '@/_common/utils/axios-wrapper';
import settings from '@/_common/utils/settings';

export function login(data: { username: string, password: string }) {
  return axios.post(
    settings.SERVICE_API_ADDRESS + '/v1/control/sessions',
    data,
    { autoError: false }
  );
}
export function logout(username: string) {
  return axios.delete(
    settings.SERVICE_API_ADDRESS + '/v1/control/sessions/' + username
  );
}
export function loadMyAuth() {
  return axios.get(
    settings.SERVICE_API_ADDRESS + '/v1/control/my-authorizations'
  );
}

export function tenantLogin(username: string, data) {
  return axios.put(
    settings.SERVICE_API_ADDRESS + '/v1/control/sessions/' + username,
    data,
    {
      autoError: false,
    }
  );
}

export function sendCode(data) {
  return axios.post(
    settings.SERVICE_API_ADDRESS + '/v1/control/users/verification-codes',
    data,
    {
      autoError: false,
    }
  );
}

export function register(data) {
  return axios.post(
    settings.SERVICE_API_ADDRESS + '/v1/control/users/register',
    data,
    {
      autoError: false,
    }
  );
}
