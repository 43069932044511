const enUS = {
  'cicd.pipeline.name': 'Name',
  'cicd.pipeline.template': 'Template',
  'cicd.pipeline.lastSuccess': 'Last Success',
  'cicd.pipeline.lastFailure': 'Last Failure',
  'cicd.pipeline.lastDuration': 'Last Duration',
  'cicd.env.name': 'Name',
  'cicd.env.description': 'Description',
  'cicd.env.value': 'Value',
  'cicd.credentials.name': 'Name',
  'cicd.credentials.type': 'Type',
  'cicd.credentials.createTime': 'Create Time',
  'cicd.notification.name': 'Name',
  'cicd.notification.type': 'Type',
  'cicd.notification.description': 'Description',
  'cicd.notification.createTime': 'Create Time',
  'cicd.pipelineAuth.pipeline': 'Pipeline',
  'cicd.pipelineAuth.user': 'User',
  'cicd.pipelineAuth.authority': 'Authority',
  'cicd.pipelineAuth.createTime': 'Create Time',
  'cicd.template.name': 'Name',
  'cicd.template.description': 'Description',
  'cicd.template.status': 'Status',
  'cicd.env.engine': 'Pipeline Engine',
  'cicd.env.createTime': 'Create Time',
  'cicd.engine.name': 'Name',
  'cicd.engine.address': 'Address',
  'cicd.engine.createTime': 'Create Time',
  'cicd.pipeline.selectEnv': 'Environment',
  'cicd.pipeline.new': 'New',
  'cicd.pipeline.basicInformation': 'Basic Information',
  'cicd.pipeline.sourceCode': 'Source Code',
  'cicd.pipeline.trigger': 'Trigger',
  // 'cicd.pipeline.template': 'Template',
  'cicd.pipeline.create.name': 'Name',
  'cicd.pipeline.create.description': 'Description',
  'cicd.pipeline.create.successNotification': 'Success Notification',
  'cicd.pipeline.create.failureNotification': 'Failure Notification',
  'cicd.env.new': 'New',
  'cicd.auth.new': 'New',
  'cicd.notification.new': 'New',
  'cicd.engine.add': 'Add',
  'cicd.engine.update': 'Update',
  'cicd.env.update': 'Update',
  'cicd.updateTime': 'Update Time',
  'cicd.var.update': 'Update',
  'cicd.update.success': 'Update Success',
  'cicd.delete.success': 'Delete Success',
  'cicd.create.success': 'Create Success',
  'cicd.delete.confirm.content': 'Are you sure to delete?',
  'cicd.pipeline.tenant.name': 'Tenant Name',
  'cicd.disable': 'Disable',
  'cicd.enable': 'Enable',
  'cicd.disabled': 'Disabled',
  'cicd.enabled': 'Enabled',
  'cicd.build': 'Build',
  'cicd.pipeline.build': 'Building',
  'cicd.credentials.usernamePassword': 'Username/Password',
  'cicd.credentials.secretText': 'Secret Text',
  'cicd.credentials.secretFile': 'Secret File',
  'cicd.name': 'Name',
  'cicd.kind': 'Kind',
  'cicd.username': 'Username',
  'cicd.password': 'Password',
  'cicd.pipeline.build.id': 'Number',
  'cicd.pipeline.build.time': 'Time',
  'cicd.pipeline.build.result': 'Result',
  'cicd.pipeline.build.commit': 'Commit(s)',
  'cicd.pipeline.build.trigger': 'Trigger',
  'cicd.pipeline.build.log': 'Log',
  'cicd.pipeline.build.changes': 'Changes',
  'cicd.pipeline.build.params': 'Parameters',
  'cicd.pipeline.build.params.name': 'Name',
  'cicd.pipeline.build.params.desc': 'Description',
  'cicd.pipeline.build.params.value': 'Value',
  'cicd.pipelineAuth.tenantName': 'Tenant Name',
  'cicd.pipeline.enable': 'Enable',
  'cicd.pipeline.disable': 'Disable',
  'cicd.description': 'Description',
  'cicd.defaultValue': 'Default Value',
  'cicd.repo': 'Repository',
  'cicd.branch': 'Branch',
  'cicd.credentials': 'Credentials',
  'cicd.additional': 'Additional',
  'cicd.pipeline.create.template.parameters': 'Template Parameters',
  'cicd.pipeline.create.template.configs': 'Template Configs',
  'cicd.pipeline.edit': 'Edit',
  'cicd.notification.update': 'Update',
  'cicd.email.placeholder': 'Multiple emails separated by commas',
  'cicd.sms.placeholder': 'Multiple phone numbers separated by commas',
  'cicd.slack.placeholder': 'Please enter slack web api address',
  'cicd.pipeline.refresh': 'Refresh',
  'cicd.pipeline.test': 'Test Connection',
  'cicd.pipeline.test.success': 'Connection Success',
  'cicd.notification.test': 'Test',
  'cicd.notification.test.success': 'Send Success',
  'cicd.notification.email': 'Email',
  'cicd.notification.sms': 'SMS',
  'cicd.notification.slack': 'Slack',
  'cicd.notification.composite': 'Composite',
  'cicd.notification.select.tips': 'Please select a notification',
  'cicd.operation.success': 'Operation Success',
  'cicd.summary': 'Summary',
  'cicd.pipeline.update': 'Update',
  'cicd.file.uploaded': 'File Uploaded',
  'cicd.file.no.uploaded': 'No File Uploaded',
  'cicd.github.tips': `Configure Github to deliver a POST request to the URL: `,
  'cicd.gitlab.tips': `Configure GitLab to deliver a POST request to the URL: `,
  'cicd.pipeline.status': 'Status',
  'cicd.noChanges': 'No Code Changes',
  'cicd.noChangesDescription': 'There are no relevant code changes.',
  'cicd.callbackUrl': 'Callback URL',
  ManualTrigger: 'Manual Trigger',
  WebhookTrigger: 'Webhook Trigger',
  TimingTrigger: 'Timing Trigger',
  'cicd.configs': 'Configs',
  'cicd.container.image': 'Container Image Management',
  'cicd.image.name': 'Name',
  'cicd.image.description': 'Description',
  'cicd.image.registry': 'Registry',
  'cicd.image.new': 'New',
  'cicd.image.update': 'Update',
  'cicd.image.delete': 'Delete',
  'cicd.imageName': 'Image Name',
  'cicd.imageDescription': 'Image Description',
  'cicd.imageRegistryUrl': 'Image Registry Url',
  'cicd.imageRegistryCredential': 'Image Registry Credential',
  'cicd.imageContent': 'Image Content',
  'cicd.add.containerImage: ': 'Add Container Image',
  'cicd.image.namespace': 'Namespace',
  'cicd.script': 'Script',
  'cicd.script.name': 'Name',
  'cicd.script.description': 'Description',
  'cicd.script.new': 'New',
  'cicd.script.kind': 'Kind',
  'cicd.script.buildIn': 'Build-in',
  'cicd.script.custom': 'Custom',
  'cicd.script.copy': 'Copy',
  'cicd.template.new': 'New Template',
  'cicd.script.content': 'Script Content',
  'cicd.pipeline.create.template.addConfig': 'Add Config',
  'cicd.pipeline.create.template.addParameter': 'Add Parameter',
  'cicd.pipeline.create.template.editConfig': 'Edit Config',
  'cicd.template.fixed': 'Build-in',
  'cicd.template.custom': 'Custom',
  'cicd.template.inputItem': 'Input Item',
  'cicd.template.parameter': 'Parameter',
  'cicd.template.config': 'Config',
  'cicd.script.upload': 'Upload File',
  'cicd.pipeline.SelectTemplate': 'Select Template',
  'cicd.myDockerfile': '我的Dockerfile',
  'cicd.dockerfile.name': 'Name',
  'cicd.dockerfile.refs': 'Refs',
  'cicd.dockerfile.createTime': 'Create Time',
  'cicd.dockerfile.new': 'New',
  'cicd.dockerfile.content': 'Dockerfile Content',
  'cicd.dockerfile.update': 'Update',
  'cicd.imageArgs': 'Build parameters',
  'cicd.value': 'Value',
  'cicd.namespace': 'Namespace',
  'cicd.loading': 'Loading...',
  'cicd.viewFullContent': 'View Full Content',
  basicInformation: 'Basic Information',
  sourceCode: 'Source Code',
  triggers: 'Triggers',
  template: 'Template',
  'cicd.selectType': 'Select Type',
  'cicd.progress.time': 'Running time',
  'cicd.progress.status': 'Running Status',
  'cicd.progress.startTime': 'Start Time',
  'cicd.progress.endTime': 'End Time',
};

export default enUS;
