import { usage } from 'browserslist';

const enUS = {
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostCPUUtilization':
    'Host CPU Utilization',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.PodCPUUsage':
    'Pod CPU Usage',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostMemory':
    'Host Memory',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.PodMemoryUsage':
    'Pod Memory Usage',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostDiskRead':
    'Host Disk Read',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostDiskWrite':
    'Host Disk Write',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostNetRecv':
    'Host Net Recv',
  'app.easeMiddleware.base.detail.middlewareMonitor.components.InfrastructureChart.HostNetSent':
    'Host Net Sent',

  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareElasticSearchPage.ThreadPool':
    'Thread Pool',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareElasticSearchPage.Transport':
    'Transport',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareElasticSearchPage.JVM':
    'JVM',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareElasticSearchPage.OS':
    'OS',

  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareKafkaPage.Offset':
    'Offset',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareKafkaPage.Lag':
    'Total Lag',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareKafkaPage.Partition':
    'Partition',

  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.SlowQueryCount':
    'Slow Query Count',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.CommandCount':
    'Command Count',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.InnodbRows':
    'Innodb Rows',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.Connections':
    'Connections',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.Threads':
    'Threads',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMySQLPage.Storages':
    'Storages',

  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.Keys':
    'Keys',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.Memory':
    'Memory',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.KeyspaceHitsAndMisses':
    'Keyspace Hits.Misses',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.Evicted':
    'Evicted.Expired',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.Clients':
    'Clients',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareRedisPage.InstantaneousOPS':
    'Instantaneous OPS',

  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMongoDBPage.latency':
    'Latency',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMongoDBPage.repl_lag':
    'Repl Lag',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMongoDBPage.assert':
    'Assert',
  'app.easeMiddleware.base.detail.middlewareMonitor.MiddlewareMongoDBPage.queuedRequest':
    'Queued Request',

  'app.easeMiddleware.base.detail.InstanceDetailPage.restart':
    'Making configuration changes may cause your service to restart.',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Two-FactorAuthenticating':
    'Two-Factor Authenticating',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Hosts': 'Hosts',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Port': 'Port',
  'app.easeMiddleware.base.detail.InstanceDetailPage.RootUser': 'Root User',
  'app.easeMiddleware.base.detail.InstanceDetailPage.RootPassword':
    'Root Password',
  'app.easeMiddleware.base.detail.InstanceDetailPage.DatabaseUser':
    'Database User',
  'app.easeMiddleware.base.detail.InstanceDetailPage.DatabasePassword':
    'Database Password',
  'app.easeMiddleware.base.detail.InstanceDetailPage.DataSize': 'Data Size',
  'app.easeMiddleware.base.detail.InstanceDetailPage.RunningStatus':
    'Running Status',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Overview': 'Overview',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Tags': 'Tags',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Backups': 'Backups',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Monitor': 'Monitor',
  'app.easeMiddleware.base.detail.InstanceDetailPage.Logs': 'Logs',
  'app.easeMiddleware.base.detail.InstanceDetailPage.configuration':
    'Configuration',
  'app.easeMiddleware.base.detail.InstanceDetailPage.WebConsole': 'Web Console',

  'app.easeMiddleware.base.detail.components.Overview.PrimaryInfo':
    'Primary Info',
  'app.easeMiddleware.base.detail.components.Overview.HostsInfo': 'HOSTS INFO',
  'app.easeMiddleware.base.detail.components.Overview.AccountInfo':
    'Account Info',
  'app.easeMiddleware.base.detail.components.Overview.NodeInfo': 'NODE INFO',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.Restore': 'Restore',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.BackupID': 'Backup ID',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.HostID': 'Host ID',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.HostIP': 'Host IP',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.NodeID': 'Node ID',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.FilePath': 'File Path',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.BackupStatus':
    'Backup Status',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.Hours': 'Hours',
  'app.easeMiddleware.base.detail.MiddlewareBackupPage.Minutes': 'Minutes',

  'app.easeMiddleware.base.detail.MiddlewareInstanceTagPage.UnbindConfirmation':
    'Are you sure to unbind this tag?',

  'app.easeMiddleware.base.detail.TwoFactorAuthModal.Two-FactorAuthenticationCode':
    'For security reasons, you need to enter the two-factor authentication code first:',

  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.DeleteConfirmation':
    'Delete Confirmation',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.RestoreConfirmation':
    'Restore Confirmation',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.logo': 'Logo',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.SearchBy':
    'Search By',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.InstanceName':
    'Service Name',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.Tags': 'Tags',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.TagKey':
    'Tag Key',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.TagValue':
    'Tag Value',
  'app.easeMiddleware.base.instance.EaseMiddlewareInstancePage.HostName':
    'Host Name',

  'app.easeMiddleware.base.host.HostMgmtPage.host_name': 'Host Name',
  'app.easeMiddleware.base.host.HostMgmtPage.ssh_port': 'SSH Port',
  'app.easeMiddleware.base.host.HostMgmtPage.ip_addr': 'IPv4',
  'app.easeMiddleware.base.host.HostMgmtPage.cpu': 'CPU',
  'app.easeMiddleware.base.host.HostMgmtPage.memory': 'Memory',
  'app.easeMiddleware.base.host.HostMgmtPage.storage': 'Storage',
  'app.easeMiddleware.base.host.HostMgmtPage.middleware': 'Middleware',
  'app.easeMiddleware.base.host.HostMgmtPage.tag': 'Tag',

  'app.easeMiddleware.base.host.HostTagPage.tag_key': 'Tag Key',
  'app.easeMiddleware.base.host.HostTagPage.tag_value': 'Tag Value',
  'app.easeMiddleware.base.host.HostTagPage.unbind': 'Unbind',
  'app.easeMiddleware.base.host.HostTagPage.CreatingTagsinBatches':
    'Creating Tags in Batches',
  'app.easeMiddleware.base.host.HostTagPage.AddTag': 'Add Tag',

  'app.easeMiddleware.base.host.HostEditPage.UserName': 'User',
  'app.easeMiddleware.base.host.HostEditPage.Password': 'Password',
  'app.easeMiddleware.base.host.HostEditPage.ClickToChooseSSHKey':
    'Click to Select SSH Key',
  'app.easeMiddleware.base.host.HostEditPage.ClickToReChooseSSHKey':
    'Click to Reselect SSH Key',
  'app.easeMiddleware.base.host.HostEditPage.SSHKey': 'SSH Key',
  'app.easeMiddleware.base.host.HostEditPage.HostName': 'Host Name',
  'app.easeMiddleware.base.host.HostEditPage.HostIPAddress': 'IPv4',
  'app.easeMiddleware.base.host.HostEditPage.InternalIp': 'Internal IPv4',
  'app.easeMiddleware.base.host.HostEditPage.ExternalIp': 'External IPv4',
  'app.easeMiddleware.base.host.HostEditPage.SSHPort': 'SSH Port',
  'app.easeMiddleware.base.host.HostEditPage.AuthType': 'Auth Type',
  'app.easeMiddleware.base.host.HostEditPage.SystemVersion': 'System Version',
  'app.easeMiddleware.base.host.HostEditPage.CPU': 'CPU',
  'app.easeMiddleware.base.host.HostEditPage.Memory': 'Memory',
  'app.easeMiddleware.base.host.HostEditPage.Storage': 'Storage',
  'app.easeMiddleware.base.host.HostEditPage.Provider': 'Provider',
  'app.easeMiddleware.base.host.HostEditPage.Tags': 'Tags',
  'app.easeMiddleware.base.host.HostEditPage.Please_select_the_host_key_file':
    'Please select the host key file',

  'app.easeMiddleware.base.service.ServiceMgmtPage.InstanceName':
    'Instance Name',
  'app.easeMiddleware.base.service.clone.from': 'Clone From',
  'app.easeMiddleware.base.service.ServiceMgmtPage.Name': 'Name',
  'app.easeMiddleware.base.service.ServiceMgmtPage.Status': 'Status',
  'app.easeMiddleware.base.service.ServiceMgmtPage.ServiceSpec': 'Service Spec',
  'app.easeMiddleware.base.service.ServiceMgmtPage.majorVersion':
    'Major Version',
  'app.easeMiddleware.base.service.ServiceMgmtPage.minorVersion':
    'Minor Version',
  'app.easeMiddleware.base.service.ServiceMgmtPage.ServiceVersion':
    'Service Version',
  'app.easeMiddleware.base.service.ServiceMgmtPage.configTemplate':
    'Config Template',
  'app.easeMiddleware.base.service.ServiceMgmtPage.CreateService':
    'Create Service',
  'app.easeMiddleware.base.service.ServiceMgmtPage.ServiceName': 'Service Name',

  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.middleware_version':
    'Middleware Version',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.enabled': 'Status',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.remarks': 'Remarks',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.CreateServiceVersion':
    'Create Service Version',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.EditServiceVersion':
    'Edit Service Version',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.createAt':
    'Create At',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.updateAt':
    'Update At',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.VersionName':
    'Version Name',

  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.package': 'Package',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.packageName':
    'Package Name',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.packageOS': 'OS',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.packageArch': 'Arch',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.checkSum': 'CheckSum',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.addPackage':
    'Add Package',
  'app.easeMiddleware.base.service.ServiceVersionMgmtPage.editPackage':
    'Edit Package',

  'app.easeMiddleware.base.service.ServiceVersionCreateModal.ServiceVersionDescription':
    'Service Version Description',

  'app.easeMiddleware.base.spec.ServiceSpecMgmtPage.node_num': 'Node Number',
  'app.easeMiddleware.base.spec.ServiceSpecMgmtPage.MiddlewareSpecName':
    'Middleware Spec Name',

  'app.easeMiddleware.base.spec.ServiceSpecEditPage.MasterNodes':
    'Master Nodes(default=nodeNum)',
  'app.easeMiddleware.base.spec.ServiceSpecEditPage.EsPrivateMaster':
    'Es Private Master',
  'app.easeMiddleware.base.spec.ServiceSpecEditPage.RedisModel': 'Redis Model',

  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.CreateTag': 'Create Tag',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.CreateSuccess':
    'Create Success',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.EditTag': 'Edit Tag',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.EditSuccess':
    'Edit Success',

  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.tag_key': 'Tag Key',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.tag_value': 'Tag Value',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.count': 'Count',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagsPage.Resources': 'Resources',

  'app.easeMiddleware.base.tags.EaseMiddlewareTagResourcePage.resource_id':
    'Resources ID',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagResourcePage.resource_type':
    'Resources Type',
  'app.easeMiddleware.base.tags.EaseMiddlewareTagResourcePage.resource_name':
    'Resources Name',

  'app.easeMiddleware.base.tags.EaseMiddlewareResourcePage.instance_id':
    'Instance ID',
  'app.easeMiddleware.base.tags.EaseMiddlewareResourcePage.middleware_name':
    'Middleware Name',
  'app.easeMiddleware.base.tags.EaseMiddlewareResourcePage.AddTags': 'Add Tags',
  'app.easeMiddleware.base.tags.EaseMiddlewareResourcePage.host_id': 'Host ID',

  'app.easeMiddleware.base.detail.middlewareLog.MiddlewareLogPage.LogType':
    'Log Type',
  'app.easeMiddleware.base.detail.middlewareLog.MiddlewareLogPage.TraceId':
    'Trace ID',

  'app.easeMiddleware.deploy.roles.DeployRolePage.role_name': 'Role Name',
  'app.easeMiddleware.deploy.roles.DeployRolePage.service_id': 'Service ID',
  'app.easeMiddleware.deploy.roles.DeployRolePage.deploy_mode': 'Operation',
  'app.easeMiddleware.deploy.roles.DeployRolePage.deploy_role': 'Deploy Role',
  'app.easeMiddleware.deploy.roles.DeployRolePage.version': 'Version',
  'app.easeMiddleware.deploy.roles.DeployRolePage.script_path': 'Script Path',
  'app.easeMiddleware.deploy.roles.DeployRolePage.subdirectory':
    'Sub Directory',

  'app.easeMiddleware.deploy.roles.DeployRoleEditPage.MiddlewareType':
    'Middleware Type',
  'app.easeMiddleware.deploy.roles.DeployRoleEditPage.Memo': 'Memo',

  'app.easeMiddleware.deploy.deploy.DeployPage.NamePrefix': 'Name Prefix',

  'app.easeMiddleware.base.wizard.CreateInstanceWizardPage.Before': 'Back',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardPage.NodeType':
    'Node Type',

  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.SelectMiddleware':
    'Select Middleware',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.SelectServiceSpec':
    'Select Service Spec',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.SelectHost':
    'Select Host',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.EnableMonitor':
    'Enable Monitor',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.MonitorPath':
    'Monitor Path',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.EnableLogs':
    'Enable Logs',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.LogPath': 'Log Path',
  'app.easeMiddleware.base.wizard.CreateInstanceWizardView.CreateService':
    'Create Service',
  'app.easeMiddleware.tags.team': 'Team',
  'app.easeMiddleware.tags.project': 'Project',
  'app.easeMiddleware.tags.env': 'Env',
  'app.easeMiddleware.tags.name': 'Name',

  'app.easeMiddleware.base.detail.baseConfig': 'Configuration',
  'app.easeMiddleware.tags.tagEdit': 'Tag Edit',
  'app.easeMiddleware.tags.addMore': 'Add More',
  'app.easeMiddleware.tags.batchCreate': 'Creating Tags in Batches',
  'app.easeMiddleware.host.hostEdit': 'Host Edit',

  'app.easeMiddleware.backup.backupId': 'Backup ID',
  'app.easeMiddleware.backup.time': 'Backup Time',
  'app.easeMiddleware.backup.restore': 'Restore',
  'app.easeMiddleware.backup.restore.snapshot.desc':
    'The backup will be restored to',
  'app.easeMiddleware.backup.restore.pointInTime.desc':
    'Please select a time point to restore',
  'app.easeMiddleware.backup.restore.pointInTime.later': ' or later',
  'app.easeMiddleware.backup.restore.latestTime': 'Latest Time',
  'app.easeMiddleware.backup.restore.specifiedTime': 'Specified Time',
  'app.easeMiddleware.backup.restore.unavailableTime':
    'The current date time is not available',
  'app.easeMiddleware.backup': 'Backup',

  'app.easeMiddleware.jumpServer': 'Jump Server',
  'app.easeMiddleware.jumpServerName': 'Jump Server Name',
  'app.easeMiddleware.jumpServer.testConnection': 'Test Connection',
  'app.easeMiddleware.jumpServer.testAndAddToList': 'Test and Add to List',
  'app.easeMiddleware.jumpServer.connectSuccess': 'Connect Success',
  'app.easeMiddleware.jumpServer.connectFailed': 'Connect Failed',
  'app.easeMiddleware.jumpServer.edit': 'Jump Server Edit',
  'app.easeMiddleware.jumpServer.HostName': 'Jump Server Name',
  'app.easeMiddleware.jumpServer.HostIPAddress': 'IPv4',
  'app.easeMiddleware.jumpServer.SSHPort': 'SSH Port',
  'app.easeMiddleware.jumpServer.AuthType': 'Auth Type',

  'app.easeMiddleware.instance.info.detail': 'Detail',
  'app.easeMiddleware.instance.info.delete': 'Delete',
  'app.easeMiddleware.allFilters': 'All Filters',
  'app.easeMiddleware.fold': 'Fold',
  'app.easeMiddleware.Unfold': 'Unfold',
  'app.easeMiddleware.moreTags': 'More Tags',
  'app.easeMiddleware.tags.filter': 'Add Tags Filter',

  'app.easeMiddleware.base.host.add': 'Add Host',
  'app.easeMiddleware.base.server.list': 'Server List',
  'app.easeMiddleware.base.jumpHost.add': 'Add Jump Server',
  'app.easeMiddleware.base.jumpHost.add1': 'Add',

  'app.easeMiddleware.host.ServerName': 'Server Name',
  'app.easeMiddleware.noResult': 'Oops!  The result does not exist',

  'app.easeMiddleware.base.service': 'Service',
  'app.easeMiddleware.base.status': 'Status',
  'app.easeMiddleware.base.spec': 'SPEC',
  'app.easeMiddleware.base.currentVersion': 'Current Version',

  'app.easeMiddleware.base.detail.baseConfig.noConfig':
    'No related configuration is available',

  'app.easeMiddleware.app.name': 'App Name',
  'app.easeMiddleware.app.create': 'Create App',
  'app.easeMiddleware.app.previewAndEdit': 'Preview/Edit',
  'app.easeMiddleware.app.preview': 'Preview',
  'app.easeMiddleware.app.selectSource': 'Select Source',
  'app.easeMiddleware.app.version': 'Version',
  'app.easeMiddleware.app.spec': 'Select Service Spec',
  'app.easeMiddleware.app.host': 'Select Hosts',
  'app.easeMiddleware.app.host.edit': 'Edit Hosts',
  'app.easeMiddleware.app.dockerComposeFile': 'Docker Compose File',
  'app.easeMiddleware.app.configFiles': 'Config Files',
  'app.easeMiddleware.app.tags': 'Tags',
  'app.easeMiddleware.app.appName': 'Name',

  'app.easeMiddleware.app.overview': 'Overview',
  'app.easeMiddleware.app.fileReady': 'File is ready',

  'app.easeMiddleware.metric.throughputRate': 'Throughput Rate',
  'app.easeMiddleware.metric.throughputErrorRate': 'Throughput Error Rate',
  'app.easeMiddleware.metric.responseTime': 'Response Time',

  'app.easeMiddleware.app.serviceName': 'Service Name',
  'app.easeMiddleware.app.containerName': 'Container Name',

  'app.easeMiddleware.keyPair.name': 'Key Pair Name',
  'app.easeMiddleware.keyPair.create': 'Create Key Pair',
  'app.easeMiddleware.keyPair.create1': 'Create',
  'app.easeMiddleware.keyPair.keyPair': 'Key Pair',
  'app.easeMiddleware.keyPair.secretKeyType': 'Secret Key Type',
  'app.easeMiddleware.keyPair.privatePair': 'Private Key',
  'app.easeMiddleware.events': 'Events',
  'app.easeMiddleware.Applications': 'Applications',
  'app.easeMiddleware.Tracing': 'Tracing',
  'app.easeMiddleware.base.uploadFile': 'Upload File',
  'app.easeMiddleware.base.file': 'File',
  'app.easeMiddleware.base.upload': 'Upload',
  'app.easeMiddleware.base.apply': 'Apply',

  'app.easeMiddleware.AlertRule': 'Alert Rule',
  'app.easeMiddleware.AlertRules': 'Alert Rules',

  'app.easeMiddleware.DashBoard': 'DashBoard',
  'app.easeMiddleware.Manifest': 'Manifest',
  'app.easeMiddleware.Events': 'Events',

  'app.easeMiddleware.KubernetesInformation': 'Kubernetes Information',
  'app.easeMiddleware.StackStructure': 'Stack Structure',
  'app.easeMiddleware.ChooseOneStackCluster': 'Choose One Stack Cluster',
  'app.easeMiddleware.ServiceList': 'Service List',
  'app.easeMiddleware.NodesList': 'Nodes List',

  'app.easeMiddleware.StackList': 'Stack List',
  'app.easeMiddleware.SearchStack': 'Search Stack',

  'app.easeMiddleware.Stack': 'Stack',
  'app.easeMiddleware.StackInformation': 'Stack Information',
  'app.easeMiddleware.StackName': 'Stack Name',
  'app.easeMiddleware.ClusterName': 'Cluster Name',
  'app.easeMiddleware.KubernatesNamespace': 'Kubernates Namespace',
  'app.easeMiddleware.EnabledEaseMesh': 'Enabled EaseMesh',

  'app.easeMiddleware.ManifestVersion': 'Manifest Version',
  'app.easeMiddleware.ByMegaEaseTeam': 'By MegaEase Team',
  'app.easeMiddleware.NotDeployYet': 'Not Deploy Yet',

  'app.easeMiddleware.History': 'History',
  'app.easeMiddleware.AllEventsHappendToThisService':
    'All events happend to this service',
  'app.easeMiddleware.From': 'From',
  'app.easeMiddleware.ReadMore': 'Read More',
  'app.easeMiddleware.HideDetails': 'Hide Details',

  'app.easeMiddleware.base.action': 'Action',

  'app.easeMiddleware.base.containerInfo': 'Container Info',
  'app.easeMiddleware.base.containerInfo.host': 'Host',
  'app.easeMiddleware.base.containerInfo.name': 'Name',
  'app.easeMiddleware.base.containerInfo.status': 'Status',
  'app.easeMiddleware.base.containerInfo.startTime': 'Start Time',
  'app.easeMiddleware.base.containerInfo.finishTime': 'Finish Time',

  'app.easeMiddleware.base.copy': 'Copy',

  'app.easeMiddleware.base.directory': 'Directory',
  'app.easeMiddleware.base.configuration': 'Configuration',
  'app.easeMiddleware.base.version': 'Version',
  'app.easeMiddleware.base.overview': 'Overview',
  'app.easeMiddleware.base.clusterDeployMode': 'Cluster Deploy Mode',

  'app.easeMiddleware.base.start': 'Start',
  'app.easeMiddleware.base.stop': 'Stop',
  'app.easeMiddleware.base.restart': 'Restart',
  'app.easeMiddleware.base.state': 'State',
  'app.easeMiddleware.base.remarks': 'Description',
  'app.easeMiddleware.base.operation': 'Operation',
  'app.easeMiddleware.base.BackupMode': 'Backup Mode',
  'app.easeMiddleware.base.backup': 'Backup',
  'app.easeMiddleware.base.installMonitor': 'Install Monitor',
  'app.easeMiddleware.base.edit': 'Edit',
  'app.easeMiddleware.base.instance.clone': 'Clone',

  'app.easeMiddleware.mesh.ServcieForm.BasicInformation': 'Basic Information',
  'app.easeMiddleware.mesh.ServcieForm.Name': 'Name',
  'app.easeMiddleware.mesh.ServcieForm.Description': 'Description',
  'app.easeMiddleware.mesh.ServcieForm.LoadBalance': 'Load Balance',
  'app.easeMiddleware.mesh.ServcieForm.Policy': 'Policy',
  'app.easeMiddleware.mesh.ServcieForm.HeaderHashKey': 'Header Hash Key',

  'app.easeMiddleware.base.deployMode': 'Deploy Mode',
  'app.easeMiddleware.base.fileName': 'File Name',

  /*
   *progress steps code
   */
  // middleware system
  SUBMIT_DEPLOYMENT_TASK: 'Start',
  EXECUTE_DEPLOYMENT_TASK: 'Execute Task',
  UNSUPPORTED_OPERATION: 'Unsupported Operation',
  NOTHING_TO_DO: 'No operation',
  UNSUPPORTED_LEGACY_DATA: 'Unsupported Legacy Data',
  RESTORE_SUBMIT_DOWNLOAD_TASK: 'Start downloading task',
  RESTORE_EXECUTE_DOWNLOAD_TASK: 'Execute downloading task',
  RESTORE_SUBMIT_RESTORE_TASK: 'Start restoring task',
  RESTORE_EXECUTE_RESTORE_TASK: 'Execute restoring task',
  DELETE_SUBMIT_UNMONITOR_TASK: 'Start removing monitor task',
  DELETE_EXECUTE_UNMONITOR_TASK: 'Execute removing monitor task',
  DELETE_SUBMIT_UNINSTALL_TASK: 'Start uninstallation task',
  DELETE_EXECUTE_UNINSTALL_TASK: 'Execute uninstallation task',
  HOST_CONNECTIVITY_CHECK: 'Host connectivity check',
  'host-sysctl.yml': 'Update sysctl',
  STATE_SYNCHRONISATION: 'Done',
  // deployment system
  'precheck.yml': 'Provision - Prerequisite',
  'os-users.yml': 'Provision - Users',
  'directory.yml': 'Provision - Directories',
  'installation.yml': 'Deploy - Service',
  'configuration.yml': 'Deploy - Configuration',
  'file.yml': 'Deploy - Operation Scripts',
  'systemd.yml': 'Start - Service',
  'initialization.yml': 'Start - Cluster',
  'postcheck.yml': 'Health Checking',
  'uninstallation.yml': 'Uninstall service',
  'update_configuration.yml': 'Update configuration',
  'add-monitor-config.yml': 'Install monitor configuration',
  'remove-monitor-config.yml': 'Uninstall monitor',
  'telegraf.yml': 'Install monitor',
  'middleware-restart.yml': 'Restart service',
  'middleware-start.yml': 'Start service',
  'middleware-status.yml': 'Check service status',
  'middleware-stop.yml': 'Stop service',
  'restore-mysql.yml': 'Restore MySQL data',

  //backup
  BACKUP_SUBMIT_BACKUP_TASK: 'Submit backup task',
  BACKUP_EXECUTE_BACKUP_TASK: 'Execute backup task',
  BACKUP_SUBMIT_UPLOAD_TASK: 'Submit uploading task',
  BACKUP_EXECUTE_UPLOAD_TASK: 'Execute uploading task',
  'restore-redis.yml': 'Restore Redis data',
  'backup-redis.yml': 'Backup Redis data',

  'cronjob.yml': 'Deploy - Timers',
  'backup.yml': 'Back up data',
  //easemesh
  'empty.yml': 'Execute task',
  'easemesh.yml': 'Install EaseMesh',

  IN_THE_WAITING_QUEUE: 'In the execution queue',
  FAIL_BEFORE_EXECUTION: 'Failed before execution',

  'restore.yml': 'Restore data',

  //clone
  CLONE_SUBMIT_DOWNLOAD_TASK: 'Start data downloading task',
  CLONE_EXECUTE_DOWNLOAD_TASK: 'Execute data downloading task',
  CLONE_SUBMIT_CLONE_TASK: 'Start cloning task',
  CLONE_EXECUTE_CLONE_TASK: 'Cloning task',

  //web console
  'web-console-delete.yml': 'Disable web console',
  'web-console-deploy.yml': 'Enable web console',
  EXECUTE_TASK: 'Execute task',

  'continuous_deploy.yml': 'Continuous deployment',
  CONTINUOUS_DEPLOY_SUBMIT_CONTINUOUS_DEPLOY_TASK:
    'Start continuous deployment task',
  CONTINUOUS_DEPLOY_EXECUTE_CONTINUOUS_DEPLOY_TASK:
    'Execute continuous deployment task',
  EXECUTE_PRECHECK_TASK: 'Execute precheck task',

  //mesh
  'app.easeMiddleware.mesh.version': 'EaseMesh Version',
  'app.easeMiddleware.mesh.controlPlaneReplicas': 'Control Plane Replicas',
  'app.easeMiddleware.mesh.addons': 'Addons',
  'app.easeMiddleware.mesh.persistentVolumeSize': 'Persistent Volume Size',
  'app.easeMiddleware.mesh.persistentVolumePath': 'Persistent Volume Path',
  'app.easeMiddleware.mesh.installCoreDNS': 'Install CoreDNS',
  'app.easeMiddleware.mesh.CoreDNSVersion': 'CoreDNS Version',
  'app.easeMiddleware.mesh.CoreDNSReplicas': 'CoreDNS Replicas',
  'app.easeMiddleware.mesh.kubernetesCluster': 'Kubernetes Cluster',
  'app.easeMiddleware.mesh.Occupied': '(Occupied)',
  'app.easeMiddleware.mesh.coreDNSTips':
    'Please note that CoreDNS-EaseMesh cannot be reverted after installation.',

  'app.easeMiddleware.detail.config.dockerComposePortMapping':
    'Docker Compose Port Mapping',
  'app.easeMiddleware.detail.config.serviceName': 'Service Name',
  'app.easeMiddleware.detail.config.portMapping': 'Port mapping',
  'app.easeMiddleware.detail.webTerminal': 'Web Terminal',

  'app.easeMiddleware.detail.addNodes': 'Add nodes',
  'app.easeMiddleware.detail.removeNodes': 'Remove nodes',
  'app.easeMiddleware.detail.addNodes.prev': 'Previous',
  'app.easeMiddleware.detail.addNodes.next': 'Next',

  'app.easeMiddleware.detail.config.history.version': 'Version',
  'app.easeMiddleware.detail.config.history.version.date': 'Date',
  'app.easeMiddleware.detail.config.history.version.digest': 'Digest',
  'app.easeMiddleware.detail.config.history.version.rollback': 'Rollback',
  'app.easeMiddleware.detail.config.history.version.rollback.config':
    'Rollback configuration',
  'app.easeMiddleware.detail.config.history.version.rollback.config.confirm':
    'Are you sure rollback to this configuration?',
  'app.easeMiddleware.detail.config.history.version.detail':
    'Configuration BalanceDetail',
  'app.easeMiddleware.detail.webConsole.tips':
    '  Please reinstall the console after expansion and reduction.',
  'app.easeMiddleware.detail.webConsole.uninstall': 'Uninstall',
  'app.easeMiddleware.detail.webConsole.browse': 'Browse',
  'app.easeMiddleware.detail.webConsole.reinstall': 'Reinstall',
  'app.easeMiddleware.detail.webConsole.uninstalling': 'Uninstalling',
  'app.easeMiddleware.detail.webConsole.installing': 'Installing',
  'app.easeMiddleware.detail.webConsole.install': 'Install',
  'app.easeMiddleware.detail.webConsole.delete': 'Delete',

  'app.easeMiddleware.delete.confirm.host': 'Confirm deletion of host {name} ?',
  'app.easeMiddleware.typeToProcess': 'Type { name } to process',

  'app.easeMiddleware.delete.confirm.keyPair':
    'Confirm deletion of keyPair {name} ?',

  'app.easeMiddleware.delete.confirm.middleware':
    'Confirm deletion of service { name }.',
  'app.easeMiddleware.delete.confirm.middleware1':
    'Upon confirmation the following will happen immediately:',
  'app.easeMiddleware.delete.confirm.middleware2': '1. Uninstall service',
  'app.easeMiddleware.delete.confirm.middleware3':
    '2. Delete service configuration',
  'app.easeMiddleware.delete.confirm.middleware4':
    '3. Delete service data and logs',

  'app.easeMiddleware.delete.confirm.middleware.stack':
    'WARNING: There are opening canaries related this stack.',

  'app.easeMiddleware.detail.removeNodes.tips.kafka':
    'Please move the replica for all partitions hosted on the broker to be removed to the rest of the brokers.',
  'app.easeMiddleware.detail.removeNodes.tips.redis.master':
    'Please reshard data away from the master node to be removed to all the other master nodes before.',

  'app.easeMiddleware.detail.removeNodes.confirm.kafka':
    'Have all replica been moved? ',
  'app.easeMiddleware.detail.removeNodes.confirm.redis':
    'Have all slots been resharded? ',
  'app.easeMiddleware.detail.adjustNodes.confirm': 'Are you sure to submit?',

  'app.easeMiddleware.nodeList': 'Node List',
  'app.easeMiddleware.hostList': 'Host List',
  'app.easeMiddleware.nodeList.keyboard': 'Keyboard Shortcuts',
  'app.easeMiddleware.nodeList.keyboard.tips1':
    'Multi Selection: Press and hold Command key.',
  'app.easeMiddleware.nodeList.keyboard.tips2':
    'Batch Selection: Press and hold Shift key.',

  'app.easeMiddleware.hostList.tips': 'Please select node name first.',

  //clone
  'app.easeMiddleware.clone.backup': 'Backup',
  CloudBackup: 'Cloud Backup',
  'app.easeMiddleware.clone.name': 'Name',
  'app.easeMiddleware.clone.selectHost': 'Select Hosts',
  'app.easeMiddleware.clone.selectHost.tip': 'Please Select Host',
  'app.easeMiddleware.clone.directory': 'Directory',
  'app.easeMiddleware.clone.overview': 'Overview',
  'app.easeMiddleware.clone.from': 'From',
  'app.easeMiddleware.clone.middleware': 'Middleware',
  'app.easeMiddleware.clone.deployMode': 'Deploy Mode',
  'app.easeMiddleware.clone.hostGroup': 'Host Group',

  //scale
  ADDNODE_SUBMIT_ADDNODE_TASK: 'Start add node task',
  ADDNODE_EXECUTE_ADDNODE_TASK: 'Execute add node task',
  REMOVENODE_SUBMIT_REMOVENODE_TASK: 'Start remove node task',
  REMOVENODE_EXECUTE_REMOVENODE_TASK: 'Execute remove node task',
  REMOVENODE_SUBMIT_UNMONITOR_TASK: 'start to uninstall monitor',
  REMOVENODE_EXECUTE_UPDATE_TASK: 'Execute update task',

  'reset_no_prompt.yml': 'Uninstall Service',
  'cluster.yml': 'Cluster installation',

  'add-node.yml': 'Add node',
  'remove-node.yml': 'Remove node',

  //web-console
  'web-console.100101.description':
    'phpMyAdmin is a free software tool written in PHP, intended to handle the administration of MySQL over the Web. phpMyAdmin supports a wide range of operations on MySQL and MariaDB.',
  'web-console.100201.description':
    'Kibana is a free and open user interface that lets you visualize your Elasticsearch data and navigate the Elastic Stack. Do anything from tracking query load to understanding the way requests flow through your apps.',
  'web-console.100401.description':
    'Redis web management tool written in node.js. Web-UI to display and edit data within multiple different Redis servers.',
  'web-console.100501.description':
    'ZooNavigator is a web-based ZooKeeper UI and editor/browser with many features.',
  'web-console.100601.description':
    'Versatile, fast and lightweight web UI for managing Apache Kafka® clusters. Built by developers, for developers.',
  'web-console.100701.description':
    'Web-based MongoDB admin interface written with Node.js, Express and Bootstrap3',
  'web-console.100801.description':
    'Dashboard is a web-based Kubernetes user interface. You can use Dashboard to deploy containerized applications to a Kubernetes cluster, troubleshoot your containerized application, and manage the cluster resources.',
  'web-console.101401.description':
    'The expression browser is available at /graph on the Prometheus server, allowing you to enter any expression and see its result either in a table or graphed over time. This is primarily useful for ad-hoc queries and debugging.',
  'web-console.101701.description':
    'Portainer Community Edition is a lightweight service delivery platform for containerized applications that can be used to manage Docker, Swarm, Kubernetes and ACI environments. It is designed to be as simple to deploy as it is to use.',
  'web-console.101901.description':
    'The MinIO Console is a rich graphical user interface that provides similar functionality to the mc command line tool.You can use the MinIO Console for administration tasks like Identity and Access Management, Metrics and Log Monitoring, or Server Configuration.',
  'web-console.102101.description':
    "pgAdmin is the most popular and feature rich Open Source administration and development platform for PostgreSQL. After installation the username is 'pgAdminUser@megaease.com' and the password same as the pgAdminUser password.",
  'app.alert.service.rules.AddRule': 'Add Rule',
  'app.alert.service.rules.AddAlertRule': 'Add Alert Rule',
  'app.alert.service.rules.editAlertRule': 'Edit Alert Rule',

  'app.middleware.dashboard.category': 'Category',
  'app.middleware.dashboard.service': 'Service',
  'app.middleware.dashboard.metrics': 'Metrics',
  'app.middleware.dashboard.metrics.tips': 'Please select metrics (at most 5)',
  'app.middleware.dashboard.createPanel.tips':
    'Please create panel to view metrics.',
  'app.middleware.dashboard.createDashboard.tips':
    'Create a new dashboard to place panels.',
  'app.middleware.dashboard.createDashboard': 'Create Dashboard',
  'app.middleware.dashboard.createPanel': 'New Panel',
  'app.middleware.dashboard.panel': 'Panel',

  'app.middleware.jumpServer': 'Jump Server',
  'app.middleware.imageRegistry': 'Image Registry',
  'app.middleware.k8sFile.tips': 'Please choose your admin.conf',
  'app.middleware.upload': 'Upload',
  'app.middleware.reupload': 'Reupload',
  'app.middleware.create': 'Create',
  'app.middleware.import': 'Import',
  'app.middleware.import.tips':
    'The IP address of the server in admin.conf must be a public IP or a private IP accessible to MegaCloud.',
  'app.middleware.import.tips1':
    'The IP address of the server in admin.conf must be 127.0.0.1.',
  'app.middleware.import.tips2':
    'The kube_control_plane_address ip cannot be 127.0.0.1. eg: 172.1.1.118:6443.',
  'app.middleware.import.tips3':
    'Please enter the correct IP address and port number',

  'app.middleware.detail.backup.autoBackup': 'Auto Backup',
  BackupToCloud: 'Backup To Cloud',

  'app.middleware.host.result': 'Results',
  'app.middleware.host.goToHostList': 'Go to Host List',
  'app.middleware.host.Processing': 'Processing',
  'app.middleware.host.tips':
    'Batch addition is supported, and multiple IPs are separated by commas.',

  'app.middleware.event.redo': 'Redo',
  'app.middleware.event.redo.tips': 'Are you sure to redo this operation?',

  'app.easeMiddleware.base.application.components.MonitorConfigPanel.AgentDesc':
    'Choose agent to mount in your docker',
  'app.easeMiddleware.base.application.components.MonitorConfigPanel.AgentType':
    'Agent Type',

  'app.easeMiddleware.event.success': 'Success',
  'app.easeMiddleware.event.failure': 'Failure',
  'app.easeMiddleware.event.recovery': 'Recovery',
  'app.easeMiddleware.event.doing': 'Doing',
  'app.easeMiddleware.event.terminated': 'Terminated',
  'app.easeMiddleware.event.stopped': 'Stopped',
  'app.easeMiddleware.event.pending': 'Pending',

  'app.easeMiddleware.host.edit': 'Edit Host',
  'app.easeMiddleware.host.osVersion': 'OS Version',
  'app.easeMiddleware.host.osArch': 'OS Arch',
  'app.easeMiddleware.host.osVersionArch': 'OS Version/Arch',

  'app.easeMiddleware.base.application.components.Topology.NodeMenu.ConfigMap':
    'Config Map',
  'app.easeMiddleware.base.application.AppDetailPage.ApplicationDetailForApps.Manifest.StackInfo.helpid.enable':
    'Turning on this switch will activate the mesh function under the current stack and affect the default Annotations related to the mesh in Manifest/Manifest Version/Edit(Open Topology)/Topology on any component in Edit/Edit Component/Command & Resource/Annotation on page 2.',
  'app.easeMiddleware.base.application.AppDetailPage.ApplicationDetailForApps.Manifest.Warning':
    'Right-click on a node in the topology diagram to perform more operations.',

  'app.middleware.event.cancel.tips': 'Are you sure to cancel this task?',
  'app.middleware.event.stop': 'Stop',
  'app.middleware.event.stop.tips': 'Are you sure to stop this task?',

  'app.middleware.terminal.commands': 'Commands',
  'app.middleware.terminal.commands.title': 'Useful Linux Command Line',

  'app.settings.cicd.confirm.content':
    'MegaEase Cloud will create an image registry for you, please confirm.',
  'app.settings.cicd.confirm.nevigatorToRegistry':
    "You haven't opened a registry yet. Please click the button below to go to the registry page and activate your registry",

  'app.settings.cicd.registry.title': 'Image Registry',
  'app.settings.cicd.registry.content':
    'Please save the username and password of your image registry. You can go to [Settings] ->[Image Registry] to view the details.',
  'app.settings.cicd.tips':
    'MegaEase will provide a private image registry address to replace your original one. The updated image can be accessed using the new address.',
  'app.settings.cicd.imageList': 'Image List',

  'app.settings.cicd.title.tips':
    'The deployment will be triggered automatically when the image of the same name:tag is pushed.',

  'app.settings.cicd.title.tips1': 'Explain information',

  'app.settings.cicd.howToUse': 'How to use integration operation',
  'app.settings.cicd.confirm.file': 'Please upload docker compose file first',

  'app.settings.cicd.open.success': 'CI/CD starts successfully',
  'app.settings.cicd.close.success': 'CI/CD stops successfully',
  'app.settings.cicd.close.confirm': 'Are you sure to stop CI/CD?',
  'app.settings.cicd.blankPage.title': 'Image Registry',
  'app.settings.cicd.blankPage.desc':
    "You haven't opened a registry yet. Please click the button below to activate your registry.",
  'app.settings.cicd.blankPage.create': 'Open Image Registry',

  'app.settings.cicd.registry.info': 'Registry Info',
  'app.middleware.k8s.install.tips':
    'Kubernetes deployment requires downloading files and images. Please ensure that the deployed server can access the public network normally.',

  'app.middleware.minio.storeVolumes': 'Store Volumes',
  'app.middleware.minio.sequentialHostnames': 'Sequential Hostnames',

  'app.middleware.cicd.trigger.tips':
    'The Continuous Deployment (CD) process will only be triggered when an image with a matching tag is pushed. It is advisable to set a stable tag, such as `latest`',
  'app.middleware.backup.storage': 'Storage',
  'app.middleware.backup.storage.local': 'Local',
  'app.middleware.backup.storage.online': 'Online',

  'app.middleware.app.title': 'App',
  'app.middleware.app.description':
    'Use Docker Compose or Kubernetes to deploy applications',
  'app.middleware.app.button': 'Create App',

  'app.middleware.service.title': 'Service',
  'app.middleware.service.description':
    'Deploy software services on the hosts.',
  'app.middleware.service.button': 'Create Service',

  'app.middleware.resource.title': 'Resource',
  'app.middleware.resource.description':
    'Add hosts to deploy services or applications.',
  'app.middleware.resource.button': 'Add Resource',

  'app.middleware.keyPair.title': 'Key Pairs',
  'app.middleware.keyPair.description':
    'You need to create/add a key pair to access the host.',
  'app.middleware.keyPair.button': 'Create/Add Key Pair',

  'app.middleware.jumpServer.title': 'Jump Server',
  'app.middleware.jumpServer.description':
    'You need to create a jump server to access the host.',
  'app.middleware.jumpServer.button': 'Add Jump Server',

  Type: 'Type',
  PublicKey: 'Public Key',
  Refresh: 'Refresh',
  refresh: 'refresh',

  'app.easeMiddleware.detail.config.history.version.title': 'view',
  nodeInfo: 'Node Info',
  'app.easeMiddleware.detail.removeNodes.tips.minio':
    'To avoid data loss, please migrate your data to other buckets or nodes before performing the shrink operation.',
  'app.easeMiddleware.detail.removeNodes.tips.minio.link': 'Learn more',

  'app.easeMiddleware.detail.config.add': 'Add Config',

  'app.easeMiddleware.detail.config.tips1':
    'Please enter config before saving config item',

  'app.easeMiddleware.detail.config.tips2':
    'Are you sure you want to discard this config item?',
  'app.easeMiddleware.detail.config.tips3':
    'Please save config item before submitting',
  'app.easeMiddleware.detail.config.tips4':
    'Please enter config before saving config item',
  'app.easeMiddleware.detail.config.tips5':
    'Are you sure you want to discard all changes',
  'app.easeMiddleware.detail.config.tips6':
    'Config item already exists, please enter another config item',

  'Offline deployment': 'Offline deployment',
  SelectOfflineRepo: 'Select repo host',
  installDocker: 'Go to install docker',

  'app.easeMiddleware.k8s.host.tips':
    'Kubernetes deployment requires access to registry.k8s.io, quay.io , and other addresses to download the file. If your server cannot access the above address, please enable offline deployment.',

  'app.easeMiddleware.k8s.host.tips2':
    'Please ensure that the server does not have Docker installed, otherwise the Docker will be removed during the deployment of Kubernetes.',
  'app.easeMiddleware.k8s.host.tips3':
    'We will deploy nginx and docker registry on offline nodes as file download servers and image repositories for Kubernetes deployment. The service will listen on ports 5000 and 8090. Please ensure that the port is not occupied.',

  'app.easeMiddleware.noHost.tips': 'No hosts are available.',
  'app.easeMiddleware.k8s.offlinehost.selected': 'Selected',

  'app.easeMiddleware.nginx.config.fileName': 'File Name',
  'app.easeMiddleware.nginx.config.fileContent': 'File Content',

  //cdn
  ENABLE_CDN_SETUP_CLOUDFLARE: 'Setup Cloudflare',
  ENABLE_CDN_SUBMIT_CREATING_TUNNEL_TASK: 'Submit creating tunnel task',
  ENABLE_CDN_EXECUTE_CREATING_TUNNEL_TASK: 'Execute creating tunnel task',
  'cloudflared.yml': 'Install and start the cloudflared',
  DISABLE_CDN_SUBMIT_REMOVING_TUNNEL_TASK: 'Submit removing tunnel task',
  DISABLE_CDN_EXECUTE_REMOVING_TUNNEL_TASK: 'Execute removing tunnel task',
  'remove-cloudflared.yml': 'Stop and uninstall the cloudflared',
  DISABLE_CDN_CLEANUP_CLOUDFLARE: 'Cleanup Cloudflare',

  oneClickCDN: 'Cloudflare CDN',
  'app.cdn.tips':
    "This service is based on Cloudflare's free CDN plan.The following points should be noted: ",
  'app.cdn.tips1': 'The default caching behavior is followed',
  'app.cdn.tips2': 'The Cache Level is set to "Standard"',
  'app.cdn.tips3': 'The minimum cache time is 2 hours',
  'app.cdn.tips4': '(reference)',
  'app.cdn.tips0': 'The bucket’s Access Policy must be Public',

  'app.cdn.status.enabling': 'enabling',
  'app.cdn.status.enabled': 'enabled',
  'app.cdn.status.disabling': 'disabling',
  'app.cdn.status.failed': 'failed',
  'app.cdn.status.disabled': 'disabled',

  'app.k8s.host.tips':
    'Kubernetes must be installed on machines with the same architecture.',
  'app.easegress.host.tips':
    'Easegress cannot be installed on a machine with Kubernetes already installed.',
  'app.nodeCount': 'Node Count',
  'app.minio.deoploy.optional': ' (optional)',
  'app.minio.deoploy.tips':
    'Before deployment, please carefully read the official manual to understand the concepts and quantity requirements related to Node and Drive.',
  'app.minio.deoploy.tips.more': ' Learn more',

  'app.config.table.name.tips': 'Enter a valid file name, e.g. "test.conf".',

  NodeStatus: 'Node Status',
  NodeName: 'Node Name',
  BucketName: 'Bucket Name',
  BucketMetrics: 'Bucket Metrics',
  ObjectsCount: 'Objects Count',
  UsageTotalBytes: 'Usage Total Bytes',
  GlobalMetrics: 'Global Metrics',
  ServersCount: 'Servers Count',
  DisksCount: 'Disks Count',
  Capacity: 'Capacity',
  DisksAvailable: 'Disks Available',
  DataReceived: 'Data Received',
  DataSent: 'Data Sent',
  NodeMemoryUsed: 'Node Memory Used',
  NodeCPUTime: 'Node CPU Time',
  HostName: 'Host Name',
  HostIP: 'Host IP',
  Port: 'Port',
  Roles: 'Roles',
  Directories: 'Directories',

  Memory: 'Memory',
  Network: 'Network',
  DiskIO: 'Disk IO',
  Storage: 'Storage',

  ChooseFilesToMountInYourDocker: 'Choose files to mount in your docker',
  PleaseChooseYourFile: 'Please choose your file',
  LocalPath: 'Local Path',
  BothContainerPathAndFileAreRequired:
    'Both Container Path and File are required',
  ServiceName: 'Service Name',
  HostPort: 'Host Port',
  ContainerPort: 'Container Port',

  FormatError: 'Format Error',
  AccountInfo: 'Account Info',

  KubernetesCluster: 'Kubernetes Cluster',
  Namespace: 'Namespace',
  Manifest: 'Manifest',
  SaveAsManifestSuccess: 'Save as manifest success',

  DockerFile: 'Docker File',
  StackFile: 'Stack File',
  SourceRequired: 'The Source is required',
  PleaseChooseYourDockerComposeFile: 'Please choose your docker-compose file',
  Components: 'Components',
  Configuration: 'Configuration',
  PleaseChooseAWayToCreateAStack: 'Please choose a way to create a Stack',
  ReuploadYAMLFile: 'Reupload YAML File',
  UploadYAMLFile: 'Upload YAML File',
  EditFromYAMLTemplate: 'Edit From YAML Template',
  CreateFromYAMLTemplate: 'Create From YAML Template',
  EditStepByStep: 'Edit Step by Step',
  CreateStepByStep: 'Create Step by Step',
  EditYAMLText: 'Edit YAML Text',
  TheAboveThreeItemsWillGenerateTheFollowingAppName:
    'The above three items will generate the following app name',
  AppName: 'App Name',
  CurrentNumberOfCharacters: 'Current Number of Characters',
  ImportKubernetes: 'Import Kubernetes',

  Username: 'Username',
  Password: 'Password',
  CPUUsageMillicores: 'CPU Usage (millicores)',
  ServerConnection: 'Server Connection',
  Responses: 'Responses',
  Requests: 'Requests',
  ServerBytes: 'Server Bytes',
  UpstreamBytes: 'Upstream Bytes',
  ValueContent: 'Value Content',
  SlowQuery: 'Slow Query',
  InnoDBData: 'InnoDB Data',
  AbortedAndLock: 'Aborted And Lock',
  LockingRate: 'Locking Rate',
  NetworkTraffic: 'Network Traffic',
  'app.easeMiddleware.base.CopySuccess': 'Copy Success',
  'app.easeMiddleware.forceDelete': 'Force Delete',
  'app.easeMiddleware.forceDelete.tips.title': 'Attention',
  'app.easeMiddleware.forceDelete.tips':
    'Forced deletion will only remove the service from the platform, will leave data on your server, and need to handle it on your own! Force deletion to be used only in the case of normal deletion failure.',
  'app.easeMiddleware.forceDelete.host.tips':
    'Forced deletion will only remove the resource from the platform, will leave data on your server, and need to handle it on your own! Force deletion to be used only in the case of normal deletion failure.',

  HitRate: 'Hit Rate',
  MemFragmentationRatio: 'Mem Fragmentation Ratio',
  CommandsProcessed: 'Commands Processed',
  Connections: 'Connections',
  ConnectedSlaves: 'Connected Slaves',

  TopicPartitionOffset: 'Topic Partition Offset',
  ConsumerGroupLag: 'Consumer Group Lag',
  ConsumerGroupPartitionCount: 'Consumer Group Partition Count',
  MessagesInM1Rate: 'Messages In M1 Rate',
  BytesInM1Rate: 'Bytes In M1 Rate',
  BytesOutM1Rate: 'Bytes Out M1 Rate',
  ProduceRequestsPerSec: 'Produce Requests Per Sec',
  ProduceMessageConversionsPerSec: 'Produce Message Conversions Per Sec',
  TotalProduceRequestsPerSec: 'Total Produce Requests Per Sec',
  UnderReplicatedPartitions: 'Under Replicated Partitions',
  OfflineReplicaCount: 'Offline Replica Count',
  LeaderCount: 'Leader Count',
  PartitionCount: 'Partition Count',
  LeaderElectionRateAndTime: 'Leader Election Rate and Time',
  UncleanLeaderElectionsPerSec: 'Unclean Leader Elections Per Sec',
  ActiveControllerCount: 'Active Controller Count',
  GlobalTopicCount: 'Global Topic Count',
  GlobalPartitionCount: 'Global Partition Count',
  JavaCpuUseParSecond: 'Java Cpu Use Par Second',
  JavaMemoryPool: 'Java Memory Pool',
  JavaThreadPool: 'Java Thread Pool',
  JavaGCCount: 'Java GC Count',
  JavaGCTime: 'Java GC Time',
  JVM: 'JVM',
  Brokers: 'Brokers',
  ReplicaManager: 'Replica Manager',
  PerTopic: 'Per Topic',
  AllTopics: 'All Topics',
  ConsumerAndLag: 'Consumer and Lag',

  SearchQuery: 'Search Query',
  SearchCount: 'Search Count',
  SearchTime: 'Search Time',
  GCCountRate: 'GC Count Rate',
  GCTimeRate: 'GC Time Rate',
  Translog: 'Translog',
  TranslogOperation: 'Translog Operation',
  TranslogOperationSizeInBytes: 'Translog Operation Size In Bytes',
  Documents: 'Documents',
  DocumentsCount: 'Documents Count',
  DocumentsIndexedRate: 'Documents Indexed Rate',
  DocumentsDeletedRate: 'Documents Deleted Rate',
  DocumentsMergedRate: 'Documents Merged Rate',
  DocumentsMergedBytes: 'Documents Merged Bytes',

  HttpServer: 'Http Server',
  HttpServerStatus: 'Http Server Status',
  BackendRequest: 'Backend Request',
  BackendResponse: 'Backend Response',
  BackendRequestDuration: 'Backend Request Duration',
  Proxy: 'Proxy',
  ProxyErrorConnections: 'Proxy Error Connections',
  ProxyTotalConnections: 'Proxy Total Connections',
  GolangRuntime: 'Golang Runtime',

  Connection: 'Connection',
  CommandThroughputRate: 'Command Throughput Rate',
  Cursor: 'Cursor',
  Document: 'Document',
  DatabaseNet: 'Database Net',
  ResidentMegabytes: 'Resident Megabytes',
  PageFaults: 'Page Faults',
  DBDataSize: 'DB Data Size',

  SamplesAppended: 'Samples Appended',
  MemoryProfile: 'Memory Profile',
  WALCorruptions: 'WAL Corruptions',
  ActiveAppenders: 'Active Appenders',
  BlocksLoaded: 'Blocks Loaded',
  HeadChunks: 'Head Chunks',
  HeadBlockGCActivity: 'Head Block GC Activity',
  CompactionActivity: 'Compaction Activity',
  ReloadCount: 'Reload Count',
  QueryDurations: 'Query Durations',
  RuleGroupEvalDuration: 'Rule Group Eval Duration',
  FailuresAndErrors: 'Failures and Errors',
  SeriesHead: 'Series Head',
  TargetScrapePoolSync: 'Target Scrape Pool Sync',

  ZNodeCount: 'ZNode Count',
  ApproximateDataSize: 'Approximate Data Size',
  FileDescriptor: 'File Descriptor',
  Packets: 'Packets',
  Sessions: 'Sessions',
  WatchCount: 'Watch Count',
  BytesReceived: 'Bytes Received',
  TimeAndLatency: 'Time and Latency',
  Latency: 'Latency',
  SnapshotTime: 'Snapshot Time',
  FsyncTime: 'Fsync Time',
  JVMPauseTime: 'JVM Pause Time',

  UsedFileDescriptors: 'Used File Descriptors',
  Containers: 'Containers',
  Images: 'Images',

  OPERATING_SYSTEM: 'OPERATING SYSTEM',
  JUMP_SERVER: 'JUMP SERVER',
  STATUS: 'STATUS',
  SPEC: 'SPEC',
  HOST_INFO: 'HOST INFO',

  SingleNode: 'Single Node',
  Standalone: 'Standalone',
  PrimaryReplication: 'Primary/Replication',
  ShardCluster: 'Shard Cluster',
  Distributed: 'Distributed',
  SelectHost: 'Select Host',
  PleaseSelectHost: 'Please Select Host',
  PrimaryNodes: 'Primary Nodes',
  PrimaryNodeNumber: 'Primary Node Number',
  PrimaryNodeCount: 'Primary Node Count',
  ReplicaNodes: 'Replica Nodes',
  ReplicaNodesNumber: 'Replica Nodes Number',
  BufferPoolSize: 'Buffer Pool Size',
  SelectAll: 'Select All',
  NodeConfiguration: 'Node Configuration',
  Cluster: 'Cluster',
  ShardNumber: 'Shard Number',
  ReplicaNumber: 'Replica Number',
  SelectReplicaHost: 'Select Replica Host',
  MaxMemory: 'Max Memory',
  Broker: 'Broker',
  BrokerNumber: 'Broker Number',
  ZookeeperNumber: 'Zookeeper Number',
  KafkaMaxHeapSize: 'Kafka Max Heap Size',
  NodesCount: 'Nodes Count',
  ShardCount: 'Shard Count',
  NodesPerShard: 'Nodes Per Shard',
  CustomizeConfigServer: 'Customize Config Server',
  ConfigServerCount: 'Config Server Count',
  CustomizeMongos: 'Customize Mongos',
  MongosCount: 'Mongos Count',
  EtcdNodesCount: 'Etcd Nodes Count',
  ControlPlaneCount: 'Control Plane Count',
  KubeNodeCount: 'Kube Node Count',
  PleaseMakeSureTheOfflineRepoIsAvailableBefore:
    'Please make sure the offline repo is available before',
  KubeNetworkPlugin: 'Kube Network Plugin',
  KubernetesFile: 'Kubernetes File',
  SecondaryNodes: 'Secondary Nodes',

  Summary: 'Summary',
  'app.easeMiddleware.gpu.timeSelect.title': 'Time Select (Hours):',
  'app.easeMiddleware.gpu.timeSelect.increase': 'Increase 1 hour',
  'app.easeMiddleware.gpu.timeSelect.decrease': 'Decrease 1 hour',
  'app.easeMiddleware.gpu.timeSelect.reset': 'Reset',
  'app.easeMiddleware.gpu.timeSelect.total': 'Total',
  'app.easeMiddleware.gpu.timeSelect.total.hours': 'Hours',
  'app.easeMiddleware.gpu.timeSelect.total.hour': 'Hour',

  VendorLocation: 'Datacenter Location',
  GPUSpecs: 'GPU Specs',
  OutOfStock: 'Out of stock',
  OutOfStockDesc:
    'The current data center GPU is sold out, please try switching the data center or try again later.',
  OopsSomethingWrong: 'Oops, something wrong!',
  Hour: 'hour',
  BuyNow: 'Buy Now',
  Payment: 'Payment',
  Hours: 'Hours',
  PaymentMethod: 'Payment Method',
  Pay: 'Pay',
  Alipay: 'Alipay',
  WechatPay: 'Wechat Pay',
  UseWeChatToScan: 'Scan the WeChat QR code to pay',
  OrderNumber: 'Order Number',
  OrderStatus: 'Order Status',
  ToBePaid: 'Unpaid',
  AlreadyPaid: 'Paid',
  PaymentFailure: 'Payment failure',
  Timeout: 'Timeout',
  Kind: 'Kind',
  Fee: 'Fee',
  CreateAt: 'Create At',
  UpdateAt: 'Update At',
  PaymentSuccessful: 'Payment Successful!',
  ThankYouForPurchasingGPUService:
    'Congratulations! Thank you very much for purchasing GPU service.',
  StartUsingNow: 'Start Using Now!',
  PaymentFailed: 'Payment Failed!',
  SomethingWentWrongTryAgain:
    "Oops! Something went wrong. Let's try one more again.",
  TryAgain: 'Try again',
  PaymentTimeout: 'Payment Timeout!',

  RemainingTime: 'Remaining Time',
  Renew: 'Renew',
  Combine: 'Combine',
  SelectImage: 'Select Image',
  ImageName: 'Image Name',
  PerformingStartOperation: 'Performing start operation',
  PerformingStopOperation: 'Performing stop operation',
  TIME: 'TIME',
  'gpu.time': 'Time',
  VENDOR: 'VENDOR',
  Vendor: 'Vendor',
  InstanceID: 'Instance ID',
  StartType: 'Start Type',
  StopType: 'Stop Type',
  StartTime: 'Start Time',
  StopTime: 'Stop Time',
  NoComputingResousesFound: 'No Computing Resouses Found',
  ComputingResouses: 'Computing Resouses',

  CombineTips:
    'The remaining usage time of the selected GPU will be merged into the target GPU and deleted after confirmation.',
  NoAvailableGPU: 'No available GPU',
  TotalRemainTime: 'Total Remaining Time',
  'app.easeMiddleware.usageLog': 'Usage Log',
  'app.gpu.image': 'Image',
  'app.gpu.remainingTime': 'Remaining Time',
  'app.gpu.totalTime': 'Total Time',
  'app.gpu.usedTime': 'Elapsed Time',
  'app.gpu.spec.error': 'Please select the GPU spec',
  jupyter_pytorch_description:
    'Jupyter Notebook for pytorch is a deep learning development environment based on Jupyter Notebook with pre-installed PyTorch framework and dependencies, supporting interactive programming and data visualization.',
  jupyter_tensorflow_description:
    'Jupyter Notebook for tensorflow is a deep learning development environment based on Jupyter Notebook with pre-installed TensorFlow framework and dependencies, supporting interactive programming and data visualization.',
  jupyter_description:
    'Jupyter Notebook is a web-based interactive computing environment that supports running more than 40 programming languages, including Python, R, Julia, etc. Jupyter Notebook can be used for data cleaning and transformation, numerical simulation, statistical modeling, machine learning, and many other scenarios.',
  stable_diffusion_description:
    'Stable Diffusion is an amazing tool for quickly generating high-resolution digital paintings based on descriptions.',
  'app.gpu.webconsole.open': 'Open',
  'app.gpu.webconsole.unavailable': 'The web console is currently unavailable.',

  StatusDetail: 'Status BalanceDetail',

  GoroutinesCount: 'Goroutines Count',
  ThreadsCount: 'Threads Count',
  TotalAlloc: 'Total Alloc',
  TotalHeapAlloc: 'Total Heap Alloc',
  TotalHeapObjects: 'Total Heap Objects',
  TotalLookupsCount: 'Total Lookups Count',
  MCacheInuse: 'MCache Inuse',
  MSpanInuse: 'MSpan Inuse',
  StackInuse: 'Stack Inuse',
  GCDuration: 'GC Duration',

  ProxyName: 'Proxy Name',
  HttpServerName: 'Http Server Name',

  Size: 'Size',
  ConfirmRestoreOfService: 'Confirm restore of service',
  RestoringDataTo: 'Restoring data to',
  RestoringDataWarning:
    'Warning: all the current data will be removed and replaced by the backup data.',
  RestoreType: 'Type',
  ToProcessed: 'to processed',
  'app.gpu.changeLog.type': 'Type',
  'app.gpu.changeLog.duration': 'Duration',
  'app.gpu.changeLog.remarks': 'Remarks',
  'app.gpu.Log': 'Logs',
  'app.gpu.usageLog': 'Usage Log',
  'app.gpu.operationLog': 'Operation Record',
  'app.webconsle.delete.tips':
    'Are you sure you want to delete this web console?',

  'app.paymentAmount': 'Payment Amount',
  'app.payee': 'Payee:',
  'app.orderNumber': 'Order Number:',
  'app.createTime': 'Create Time:',
  'app.usageLog': 'Usage Log',
  'app.operationLog': 'Operation Log',

  'app.payment.description': 'Description',
  'app.payment.unitPrice': 'Unit Price',
  'app.payment.quantity': 'Quantity',
  'app.payment.price': 'Price',
  'app.payment.total': 'Total',
  'app.payment.tips': `Please pay within  {timeout}, or the order will be cancelled after timeout.`,
  'app.payment.or': 'Or',
  'app.gpu.all': 'All',

  Inc: 'Inc.',
  RegistrationAddress: 'Registration address',
  RegistrationAddressContent1: '2nd Floor, Room 212, ',
  RegistrationAddressContent2: 'No.31 Haidian Street, Haidian District, ',
  RegistrationAddressContent3: 'Beijing, China, 100081',
  Amount: 'Amount',
  PayType: 'Pay Type',
  PayTime: 'Pay Time',
  ReceiptNumber: 'Receipt Number',
  UnitPrice: 'Unit Price',
  TotalPrice: 'Total Price',
  Quantity: 'Quantity',
  BillTo: 'Bill to',
  ShipTo: 'Ship to',
  DateOfIssue: 'Date of issue',
  DateDue: 'Date due',
  DueTo: 'due to',
  Paid: 'paid',
  PayOnline: 'Pay online',
  PayNow: 'Pay Now',
  Invoice: 'Invoice',
  InvoiceNumber: 'Invoice number',
  Subtotal: 'Subtotal',
  TotalExcludingTax: 'Total excluding tax',
  Tax: 'Tax',
  Total: 'Total',
  AmountDue: 'Amount due',
  AmountPaid: 'Amount paid',
  DownloadPDFFile: 'Download PDF file',
  ViewInvoice: 'View Invoice',
  OrderTime: 'Order Time',
  PaidOn: 'paid on',
  Plan: 'Plan',
  PlanQuota: 'Extra Quota',
  GPUResource: 'GPU Resource',
  TechSupport: 'Tech Support',
  MonthlyCost: 'Monthly Cost',
  PerHour: '/ h',
  MegaEaseInc: 'MegaEase, Inc.',

  'app.payeeName': 'MegaEase',
  'app.beijing': 'Beijing',
  'app.stop': 'Stop Instance',
  'app.stop.tips':
    'After stopping the instance, the data in the unmounted storage will be destroyed. Please back up the data first.',
  'app.stop.confirm': 'Confirm Stop',
  'app.payment.alreadyPaid.tips':
    'If the page does not redirect after payment, please click {here}',
  'app.payment.alreadyPaid.here': 'here',

  OrderDetailRefreshTips: 'Error in order status? Click {refresh} to try',
  PaymentRefreshTips:
    'No payment information found, please try again later or contact the administrator',
  vRAMUtilizationRate: 'Video RAM Utilization',
  vRAMUsage: 'Video RAM Usage',

  powerUsage: 'Power Usage',

  BillingSummary: 'Billing Summary',
  YourNextPayment: 'Your Next Payment',
  PaymentInformation: 'Payment Information',
  YourRecurringChargesAndDiscounts: 'Your recurring charges & discounts',
  CurrentPlan: 'Current Plan',
  CompareAllPlans: 'Compare All Plans',
  Upgrade: 'Upgrade',
  Downgrade: 'Downgrade',
  Free: 'Free',
  Standard: 'Standard',
  Advance: 'Advance',
  UpgradeToAdvance: 'Upgrade to Advance',
  DowngradeToFree: 'Downgrade to Free',
  TechnicalSupport: 'Technical Support',
  TechnicalSupportTips:
    'Obtain technical support from the MegaEase Cloud Team when you needed',
  AddOns: 'Add-ons',
  APMIntegration: 'APM Integration',
  APMIntegrationTips: 'You are eligible to use APM Integration for free',
  EaseMeshIntegration: 'EaseMesh Integration',
  EaseMeshIntegrationTips: 'Integrate EaseMesh with your K8S applications',
  EaseMeshIntegrationTips2:
    'You are eligible to use EaseMesh Integration for free',
  CloudflareCDNCustomizedDomain: 'Cloudflare CDN Customized Domain',
  CloudflareCDNCustomizedDomainTips:
    'You are eligible to customize your Cloudflare CDN Domains for free',
  CloudflareCDNCustomizedDomainTips2: 'Customize your Cloudflare CDN domains',
  MonitoringDataRetainingDays: 'Monitoring Data Retaining Days',
  MonitoringDataRetainingDaysTips:
    'Your monitoring data will be retained for 3 days',
  MonitoringDataRetainingDaysTips2:
    'Your monitoring data will be retained for 24 days',
  Usage: 'Usage',
  DockerApp: 'Docker App',
  K8SApp: 'K8S App',
  DockerRegistrySpace: 'Docker Registry Space',
  CloudflareCDNDomain: 'Cloudflare CDN Domain',
  Seats: 'Seats',
  ViewPaymentHistory: 'View Payment History',
  Charge: 'Charge',
  PriceOfCurrentPlanMonth: 'Price of current plan (month)',
  PriceOfDestinationPlanMonth: 'Price of destination plan (month)',
  ChangesWillBeReflectedOnNextMonth: 'Changes will be reflected on next month',
  YourDowngradeToFreePlanWillBeEffectiveOn:
    'Your downgrade to Free plan will be effective on ',
  TechnicalSupportForCurrentPlan: 'Technical Support for Current Plan',
  TechnicalSupportForExtraQuotas: 'Technical Support for Extra Quotas',
  AllExtraQuotaFees: '10% of all extra quota fees',
  EnableTechnicalSupport: 'Enable Technical Support',
  DisableTechnicalSupport: 'Disable Technical Support',
  TechnicalSupportForCurrentPlanMonth:
    'Technical support for current plan (month)',
  TechnicalSupportForExtraQuotasMonth:
    'Technical support for extra quotas (month)',
  DisableTechnicalSupportEffectiveOn:
    'Disable technical support, effective on ',
  EnableAPMIntegration: 'Enable APM Integration',
  DisableAPMIntegration: 'Disable APM Integration',
  IntegrateAPMWithYourApplications: 'Integrate APM with your applications',
  PriceMonth: 'Price (month)',
  AddDays: 'Add Days',
  RemoveDays: 'Remove Days',
  PlanIncluded: 'Plan Included',
  Purchased: 'Purchased',
  TotalWillBe: 'Total will be',
  EnableEaseMeshIntegration: 'Enable EaseMesh Integration',
  DisableEaseMeshIntegration: 'Disable EaseMesh Integration',
  EnableCDNCustomizedDomain: 'Enable CDN Customized Domain',
  DisableCDNCustomizedDomain: 'Disable CDN Customized Domain',
  AddMonitoringDataRetainingDays: 'Add monitoring data retaining days',
  RemoveMonitoringDataRetainingDays: 'Remove monitoring data retaining days',
  AddHostSeats: 'Add host seats',
  RemoveHostSeats: 'Remove host seats',
  AddServiceSeats: 'Add service seats',
  RemoveServiceSeats: 'Remove service seats',
  AddDockerRegistrySpace: 'Add Docker registry space',
  RemoveDockerRegistrySpace: 'Remove Docker registry space',
  AddCloudBackupSpace: 'Add cloud backup space',
  RemoveCloudBackupSpace: 'Remove cloud backup space',
  AddDockerAppSeats: 'Add Docker App seats',
  RemoveDockerAppSeats: 'Remove Docker App seats',
  AddK8SAppSeats: 'Add K8S App seats',
  RemoveK8SAppSeats: 'Remove K8S App seats',
  AddCloudflareCDNDomainSeats: 'Add Cloudflare CDN domain seats',
  RemoveCloudflareCDNDomainSeats: 'Remove Cloudflare CDN domain seats',
  BillingInformation: 'Billing Information',
  PayWith: 'Pay with',
  CardNumber: 'Card Number',
  FirstName: 'First Name',
  LastName: 'Last Name',
  Address: 'Address',
  City: 'City',
  PostalZipcode: 'Postal/Zipcode',
  CountryRegion: 'Country/Region',
  StateProvince: 'State/Province',
  LearnMore: 'Learn More',
  PerformingDeleteOperation: 'Performing delete operation',
  DeleteJumpServerSuccess: 'Delete jump server success',

  'plan.free': 'Free',
  'plan.standard': 'Standard',
  'plan.advanced': 'Advanced',
  'plan.advance': 'Advance',

  'plan.host': 'Host',
  'plan.service': 'Service',
  'plan.docker_app': 'Docker App',
  'plan.k8s_app': 'K8S App',
  'plan.monitor_retaining_days': 'Monitoring Data Retaining Days',
  'plan.docker_registry_space': 'Docker Registry Space',
  'plan.cloud_backup_space': 'Cloud Backup Space',
  'plan.apm': 'APM',
  'plan.easemesh': 'EaseMesh',
  'plan.cdn_domain': 'CDN Domain',
  'plan.cdn_customized_domain': 'CDN Customized Domain',
  'plan.support_base': 'Technical Support',

  'plan.balance': 'Balance',
  'plan.charge': 'Top Up',
  'plan.history': 'History',

  'plan.description': 'Description',
  'plan.price': 'Price',
  'plan.dueBy': 'Due by',

  'plan.balance.history': 'History',

  CreateStorage: 'Create Storage',
  SearchForNameTags: 'Search for name, tags...',
  RemainingSize: 'Remaining Size',
  VolumeName: 'Volume Name',
  PurchaseDurationHour: 'Purchase Duration(Hour)',
  MountPath: 'Mount Path',
  EditStorage: 'Edit Storage',
  Expand: 'Expand',
  ExpandErrorTips:
    'The expanded capacity cannot be less than or equal to the previous value',
  ExpandStorage: 'Expand Storage',
  RenewStorage: 'Renew Storage',
  DeleteStorage: 'Delete Storage',
  StorageUsage: 'Storage Usage',
  UsedStorageSpace: 'Used Storage Space',
  ExpiredAt: 'Expired At',
  MountedBy: 'Mounted By',
  OpenWebConsole: 'Open Web Console',
  DataSize: 'Data Size',
  DataSizeTips: 'Data Size ($0.07/GB/Month, $0.05/GB/Month for 1TB or more)',
  DeleteStorageTips:
    'Are you sure you want to delete the cloud storage disk? After deletion, all files and data stored in the disk will be permanently removed and cannot be recovered.',
  TypeToProcess: 'Type {data} to process',
  SelectDataCenter: 'Select Data Center',
  StartGPU: 'Start GPU',
  SelectStorage: 'Select Storage',
  Active: 'Active',
  Inactive: 'Inactive',
  GPUStorage: 'GPU Storage',
  Preparing: 'Preparing',
  Ready: 'Ready',
  Failed: 'Failed',
  Deleted: 'Deleted',
  Expired: 'Expired',
  Attention: 'Attention',
  FileBrowserTips:
    'FileBrowser provides a file managing interface within a specified directory and it can be used to upload, delete, preview, rename and edit your files. It allows the creation of multiple users and each user can have its own directory. It can be used as a standalone app.',
  MountPathValidatorMessage:
    'Must start with / and contain only a-z, A-Z, 0-9 and /, cannot start with /home, does not end with /, does not contain //, and is limited to 63 characters.',
  'plan.balance.charge': 'Charge',
  'plan.balance.charge.amount': 'Charge Amount',
  'plan.balance.other': 'Other',
  'plan.balance.enter.amount': 'Enter Amount',
  'plan.balance.enter.amount.error': 'Charge amount must be greater than 0',
  'plan.balance.pay': 'Pay Now',

  'plan.usage': 'Usage',
  enableTechSupport: 'Enable Technical Support',
  disableTechSupport: 'Disable Technical Support',
  TechnicalsupportCurrentplan: 'Technical support for current plan (month)',
  TechnicalsupportExtraquota: 'Technical support for extra quotas (month)',
  TechnicalsupportTips: '10% of all extra quota fees',
  'plan.chargeToday': 'Charge Today(prorated for {days} days)',

  'plan.price.month': 'Price (month)',
  'plan.onNextMonth': 'Changes will be reflected on next month',

  'plan.add': 'Add ',
  'plan.remove': 'Remove ',

  //usage
  'plan.usage.price.month': 'Price (month)',
  'plan.usage.plan.included': 'Plan Included',
  'plan.usage.purchased': 'Purchased',
  'plan.usage.total.will.be': 'Total will be',
  'plan.usage.add': 'Add',
  'plan.usage.remove': 'Remove',

  UpgradeToStandard: 'Upgrade to Standard',
  DowngradeToStandard: 'Downgrade to Standard',

  'plan.addSeats': 'Add Seats',
  'plan.removeSeats': 'Remove Seats',

  'plan.techSupport': 'Technical Support',
  'plan.addons': 'Add-ons',
  BindSuccess: 'Bind success',
  UnbindSuccess: 'Unbind success',
  OperationCompleted: 'Operation completed',
  AddCredits: 'Add Credits',

  'plan.techSupport.base': 'Technical Support for Current Plan',
  'plan.techSupport.extra': 'Technical Support for Extra Quotas',

  //addons
  'plan.apm.intergration': 'APM Integration',
  'plan.apm.intergration.desc': 'Integrate with APM with your application',
  'plan.easemesh.intergration': 'EaseMesh Integration',
  'plan.easemesh.intergration.desc':
    'Integrate with EaseMesh with your K8S application',
  'plan.cdn.intergration.customized': 'Cloudflare CDN Customized Domain',
  'plan.cdn.intergration.customized.desc':
    'Customize your Cloudflare CDN domains',
  'plan.cdn.intergration': 'Cloudflare CDN Domain',
  'plan.cdn.intergration.desc': 'Integrate with Cloudflare CDN',
  'plan.addons.tips': 'Disable {name}, effective on {date}.',

  'payment.dueBy': '(Due by {date})',

  changePlanOn: 'Your downgrade to {planName} plan will be effective on {date}',

  'tips.deferredPlan': 'Cancel plan successfully',
  cancelSuccess: 'Cancel successfully',

  'techSupport.content':
    'Obtain technical support from the MegaEase Cloud Team when you needed.',
  'plan.unit.seats': 'seat{s}',
  'plan.unit.days': 'day{s}',
  'plan.unit.days2': 'day',
  'plan.unit.day': 'day',
  'plan.storage': 'Storage',

  'plan.monitor': 'Your monitoring data will be retained for {days} day(s).',
  'plan.changePlanSuccess': 'Change plan successfully',
  ThankYouForPurchasingService:
    'Congratulations! Recharge successfully, thank you for using our service',

  orderNo: 'Order No',
  description: 'Description',
  amount: 'Amount',
  date: 'Date',

  'operation.success': 'Operation success',

  'techSupport.deferred.tips':
    '{operation} technical support, effective on {date}.',

  'plan.usage.tips': 'Remove {count}, effective on {date}.',

  'packs.add': 'Add data packs',
  'packs.remove': 'Remove data packs',
  'days.add': 'Add day(s)',
  'days.remove': 'Remove day(s)',
  maxDays: '(Max 14 days)',

  Balance: 'Balance',

  'plan.apm.intergration.included':
    'You are eligible to use APM Integration for free.',
  'plan.easemesh.intergration.included':
    'You are eligible to use EaseMesh Integration for free.',
  'plan.cdn.intergration.included':
    'You are eligible to customize your Cloudflare CDN Domains for free.',

  'plan.month': 'Month',

  proratedFor: ' (prorated for {days} day{s})',

  'plan.gb': 'GB',
  noUnit: ' ',

  'host.ip.cn':
    'Your IP address is in mainland China, we recommend using the {link} platform.',
  'host.ip.com':
    'Your IP address is not in mainland China, we recommend using the {link} platform.',
  'host.ip.bogon': 'The IP address is a bogon IP.',
  'host.ip.continue': 'Continue',
  'host.ip.title': 'Notification',
  QuotaControllTips:
    'Sorry, {module} usage limit({used}/{quota}) reached.Please {Purchase} or {Upgrade}',
  Supported: 'Supported',
  supported: 'supported',
  AppQuotaControllTips:
    'Sorry, {module} {UsageLimit} reached.Please {Purchase} or {Upgrade}',
  APMQuotaControllTips:
    'Sorry, APM is not {supported}.Please {Purchase} or {Upgrade}',
  UsageLimit: 'usage limit',
  RegistryInfoQuotaControllTips:
    'Need more capacity? [Purchase](/megacloud/app/main/billing/plansAndUsage) or [Upgrade](/megacloud/app/main/billing/plansAndUsage) for larger storage.',
  NeedMoreCapacity:
    'Need more capacity? {Purchase} or {Upgrade} for larger storage.',
  EnableNewFeature:
    'You can enable the custom domain feature by {Purchase} or {Upgrade}.',
  'quota.confirm.upgradePlan': 'Upgrade Plan',
  'quota.confirm.purchaseMore': 'Purchase More',
  'quota.confirm.tips.useLimit': '{module}{useLimit}',
  'quota.confirm.tips.support': 'Sorry, {module} not {supported}.',
  'quota.confirm.tips.daysLimit':
    'To retain and view earlier data, {Purchase} or {Upgrade}.',
  FrozenTips:
    'This {Module} is frozen, please {GetMoreQuota} and click {Unfreeze}.',
  GetMoreQuota: 'Get more quota',
  Unfreeze: 'Unfreeze',
  UnfreezeSuccess: 'Unfreeze success',
  UnfreezeFailed: 'Unfreeze failed',
  DataRetention: 'Data retention:',
  DaysLimitPrefix: 'only',
  DaysLimitSuffix: 'day(s).',
  only: 'only',
  days: 'days',
  'quota.confirm.title': 'Out of quota',
  Used: 'Used',
  Quota: 'Quota',
  Sorry: 'Sorry,',
  reached: 'reached.',

  'addons.enable': 'Enable ',
  'addons.disable': 'Disable ',

  NoGPUStorageFound: 'No GPU Storage Found',

  'GPU.refund': 'Refund',
  refundApplication: 'Refund Application',
  'refundApplication.success': 'Refund Application Success',

  'refund.content.refundable':
    '{unused} minutes unused, {refundable} minutes refundable. {order_num} refund will be generated with a total refund amount of {amount} yuan.',
  'refund.content.unrefundable':
    '{unused} minutes unused, {refundable} minutes refundable. Refunds are not supported due to the refundable time being less than 60 minutes.',
  'refund.tips.refundable':
    'Currently, refunds are only supported on an hourly basis, and refunds cannot be made for less than one hour. After successful application, {refundable} minutes will be deducted from the current GPU available time, with {remaining} minutes remaining. Refunds will be returned in 5-7 business days according to the original payment method.',
  'refund.tips.unrefundable':
    'Currently, refunds are only supported on an hourly basis, and refunds cannot be made for less than one hour. After a successful application, refunds will be made in 5-7 business days in the original payment method.',

  'storage.refund.content.refundable':
    'The current expiration time is {expire_at}, with {unused} minutes unused and {refundable} minutes eligible for refund. After the refund, {remaining} minutes will be left, and the expiration time will be updated to {expire_at_after_refund}. {order_num} refunds will be generated, with a total refund amount of {amount} yuan.',
  'storage.refund.content.unrefundable':
    '{unused} minutes unused, {refundable} minutes refundable. Refunds are not supported due to the refundable time being less than 60 minutes.',
  'storage.refund.tips.refundable':
    'We currently only support refunds in hourly units, and refunds for durations less than one hour cannot be processed. After a successful application, {refundable} minutes will be deducted from the available GPU storage time, and the expiration time will be updated to {expire_at_after_refund}. Refunds will be returned to the original payment method within 5-7 business days.',
  'storage.refund.tips.unrefundable':
    'Currently, refunds are only supported on an hourly basis, and refunds cannot be made for less than one hour. After a successful application, refunds will be made in 5-7 business days in the original payment method.',

  refundNum: 'Refund Number',
  tenantName: 'Tenant Name',
  quotaName: 'Quota Name',
  quantity: 'Quantity',
  refundAmount: 'Amount',
  status: 'Status',
  refundTime: 'Refund Time',

  RefundInit: 'Init',
  RefundSuccess: 'Refund Success',
  RefundFailed: 'Refund Failed',

  GPUInfomation: 'GPU Infomation',
  RefundOrderAssociatedOrderList: 'Refund Order Associated Order List',
  'remarks.refund': 'Remarks',
  saveRemarks: 'Save Remarks',
  'remarks.tips': 'Please fill in the refund remarks',
  RefundProcessing: 'Refund Processing',

  buy: 'Buy',
  usage: 'Usage',
  merge: 'Merge',
  refund: 'Refund',

  'refresh.success': 'Refresh Success',
  templateDetail: 'Image Template BalanceDetail',
  templateDesc: 'Description',
  templatePorts: 'Ports',
  templateEnvs: 'Environments',
  portPublic: 'Public',
  portProbe: 'Probe',

  SelectTemplate: 'Select Template',
  'noENV.tips': 'No environment variables',
  'env.key': 'Key',
  'env.value': 'Value',
  'port.name': 'Name',
  'port.port': 'Port',

  InsufficientInventory:
    'No GPU currently available in stock, please try again later.',
  AdequateInventory:
    'The current number of GPU is sufficient, select a template and start your service.',
  TightInventory:
    'The current GPU inventory is tight, select a template and start the service.',

  Discount: '{rate1}% Off',
  publicTips:
    'If selected, it will open the node port for external access on that port.',
  probeTips:
    'If checked, we will use this port as a probe to check if the container is ready when it starts.',

  DeleteCloudBackup: 'Delete cloud backups',
  ConfirmDeleteCloudBackup: 'Confirm to delete cloud backup?',

  AutoBackupSwitchOff: 'The auto backup switch is off',
  AutoBackupSwitchOn: 'The auto backup switch is on',
  BackupToCloudSwitchOff: 'The backup to cloud switch is off',
  BackupToCloudSwitchOn: 'The backup to cloud switch is on',
  UnknownError: 'Unknown error',
  RestoreRequestSent: 'The restore request has been sent',

  EnableCDNForCurrentInstance: 'Enable CDN for the current instance:',
  RandomDomain: 'Random Domain',
  CustomizedDomain: 'Customized Domain',
  CustomSubdomainMessage:
    'The format of the custom domain name is required to be: 1.lowercase letters, numbers, or middle strikes. 2.the middle stroke cannot be at the beginning or the end of the. 3.Total length is 4-17',
  DomainFormatError: 'Domain format error',
  DomainAlreadyOccupied: 'Domain name is already occupied',

  'refund.gpu': 'GPU',
  'refund.storage': 'GPU Storage',
  'refund.type': 'Type',
  power: 'Power',

  templateTitle: 'Template Title',
  templateImageName: 'Image Name',

  portName: 'Name',
  port: 'Port',
  envName: 'Name',
  envValue: 'Value',
  registryCredential: 'Registry Credential',
  registryCredentialManage: 'Credential Management',
  command: 'Command',
  createTemplate: 'Create Template',
  addCredentials: 'Add Credentials',
  DeleteCredentialSuccessfully: 'Delete credential successfully',
  AddCredentialsSuccessfully: 'Add credential successfully',
  credentialName: 'Name',
  credentialUsername: 'Username',
  credentialPassword: 'Password',
  createTemplateSuccessfully: 'Create template successfully',
  deleteTemplateSuccessfully: 'Delete template successfully',
  updateTemplateSuccessfully: 'Update template successfully',
  officialTemplate: 'Official Template',
  customTemplate: 'Custom Template',
  templateType: 'Template Type',
  deleteTemplate: 'Delete Template',
  deleteTemplateTips: 'Are you sure to delete the template?',

  insufficientBalance:
    'The current tenant balance is insufficient. Please top-up and try again.',
  change: 'Change',
  'port.type': 'Type',
  isRequired: ' is required',
  isUnique: ' must be unique',
  isInvalid: ' is invalid',
  baseUrl: 'Base URL',

  pending: 'Pending',
  WithdrawalSuccess: 'Withdrawal Success',
  WithdrawalFailed: 'Withdrawal Failed',

  'app.gpu.tips':
    'According to national laws and regulations, it is strictly forbidden to use generation algorithms to generate prohibited pictures or any content that violates laws and regulations. In addition, any form of mining activities is strictly prohibited. Once a violation is found, measures will be taken to close the name immediately. Be sure to abide by this rule to maintain the health and safety of the network environment. Thank you for your cooperation!',
  Sync: 'Sync',
  StorageSync: 'Storage Sync',
  baidunetdisk: 'Baidu Netdisk',
  aliyundrive: 'Aliyun Drive',
  copyTo: 'Upload to ',
  copyFrom: 'Download from ',
  copyToLocal: 'Copy to local',
  copyFromLocal: 'Copy from local',
  goBack: 'Go back',
  localPath: 'MegaCloud storage path',
  remotePath: 'Remote Path',
  loginSuccess: 'Login Success',
  operation: 'Operation',
  state: 'State',
  cloudPath: 'Network disk path',
  startTime: 'Start Time',
  endTime: 'End Time',
  taskType: 'Task Type',
  diskType: 'Disk Type',

  SyncStart: 'Sync Start',
  SyncAt: 'Start at {time}',
  SyncFileList: 'Syncing File List',
  SyncDone: 'Sync Done',
  SyncDoneAt: 'Sync Done at {time}',
  SyncFailed: 'Sync Failed',
  SyncStop: 'Sync Stop',

  OutOfService:
    'To provide more stable GPU services, we plan to upgrade our hardware resources from July 24, 2023 to July 26, 2023. During this time, the purchase and usage of your GPU will be unavailable, and we expect to resume service at 0:00 on July 27, 2023. We apologize for any inconvenience this may cause and appreciate your understanding and support.',
  ChangeAccount: 'Change Account',

  unauthorized: 'Unauthorized',
  authorizedFailed: 'Authorized Failed, Please Try Again.',
  folderId: 'Folder ID',

  aliyunTips: 'The remote path can not be / root directory.',
  baiduTips: `The upload path is '/app/MegaEaseCloud' application directory`,
  execute: 'Execute',
  baiduUploadTips: 'The file will be uploaded to the {dir} directory',
  stopTaskSuccess: 'Stop task success',
  stopTaskFailed: 'Stop task failed',
  authSuccess: 'If the authorization is successful, ',
  Upload: 'Upload',
  Download: 'Download',
  getAuth: 'Get Authorization......',
  closePage:
    'The current page will automatically close after {countdown} seconds.',

  'monitor.connections': 'Connections',
  'monitor.transactions': 'Transactions',
  'monitor.queryRows': 'Query Rows',
  'monitor.modifyRows': 'Modify Rows',
  'monitor.templateFileInfo': 'Template File Info',
  'monitor.deadlocks': 'Deadlocks',

  'storage.size': 'SIZE: ',
  uploadTo: 'Upload to',
  downloadFrom: 'Download from',

  startGPU: 'Create a new GPU instance',
  'app.billingLog': 'Billing Record',

  'app.billingTime': 'Billing Time',
  'app.billingAmount': 'Amount',
  'app.billingTarget': 'Billing Target',
  'plan.balance.charge.orderNo': 'Order No',
  'plan.balance.charge.cost': 'Cost',
  'plan.balance.charge.order.status': 'Order Status',
  day_billing: 'Day Billing',
  week_billing: 'Week Billing',
  month_billing: 'Month Billing',
  'balance.refund': 'Refund',
  balanceHours:
    'According to the current billing situation, the remaining running time is expected to be {hours} hours.',
  ApplicationForRefund: 'Refund',
  'gpu.cost': 'Cost',

  'refund.content':
    'The refundable amount is {amount}, and the non-refundable amount is {non_refundable_amount}. A {order_num} refund will be generated.',
  'refund.tips1': 'Refunds can only be applied for the amount in the balance.',
  'refund.tips2':
    'The non-refundable amount refers to the amount generated by orders that are more than 90 days old. If you wish to refund this portion, please contact our customer service offline.',
  'refund.tips3': 'The refundable amount will be deducted from the balance.',
  'refund.tips4':
    'The refund will be processed within 5-7 business days and will be returned using the original payment method.',
  'app.gpu.mountPath': 'Mount Path',
  viewOrder: 'View Order',
  'balance.cost': 'Cost',
  'balance.topUp': 'Top Up',

  'storage.start': 'Start',
  'storage.stop': 'Stop',
  'storage.delete': 'Delete',
  'storage.expand': 'Expand',
  'storage.suspend': 'Suspend',
  'storage.reinstate': 'reinstate',

  'storage.stopped': 'Stopped',
  'storage.running': 'Running',
  'storage.suspended': 'Suspended',
  'storage.provision': 'Provision',
  'storage.ready': 'Billing in progress', //ready
  'storage.deleted': 'Deleted',
  PerformingDelOperation: 'Performing delete operation',
  'refund.balance': 'Balance',
  'expand.success': 'Expand Success',
  'gpu.deductions': 'Deductions',
  'gpu.refund': 'Refund',
  'gpu.log.cost': 'Cost',
  'gpu.log.summary': 'Summary',
  'gpu.log.type': 'Type',
  'gpu.log.spec': 'Specification',
  'gpu.log.deductionTime': 'Deduction Time',
  'gpu.log.billingRange': 'Billing Range',
  'gpu.log.amount': 'Amount',
  'gpu.log.time': 'Time',
  'gpu.log.operation': 'Operation',
  'gpu.storage.mountPath.tips':
    'The mount point may overwrite directories in the template. Please pay attention to the location of the mount point.',
  'refund.on': 'refund on',
  Minutes: 'm',
  'balance.Amount': 'Amount',
  'balance.migrate': 'Data Migration',
  start_normal: 'Normal start',
  stop_normal: 'Normal stop',
  delete_normal: 'Normal delete',
  suspend: 'Suspend',
  reinstate: 'Reinstate',
  expand: 'Expand',
  stop_unpaid: 'Unpaid stop',
  delete_unpaid: 'Unpaid delete',
  stop_underfloor: 'Underfloor stop',
  start_underfloor: 'Underfloor start',
  'spec.name': 'Name',
  'spec.spec': 'Spec',
  'spec.template': 'Template',
  'spec.capacity': 'Capacity',
  'spec.mountPath': 'Mount Path',
  InsufficientBalance: 'Insufficient Balance',
  InsufficientBalanceTips: 'Insufficient Balance, Please Top Up',

  CreateCustomizableApp: 'Create a persistent application',
  newStorage: 'New Storage',
  otherStorage: 'Existing Storage',
  CreateCustomizableAppTips:
    'All your data changes will be saved to persistent storage.',
  customizableApp: 'Persistent application',
  storageForCustomizableApp: 'Can only be mounted by persistent applications',
  'gpu.createStorage.tips': 'Storage creation',
  'gpu.start.tips': 'Starting the instance',
  'gpu.start.success': 'Started successfully',
  'gpu.progress.tips':
    'It is expected to take 2-5 minutes. Please wait a moment.',
  'app.stop.tips.persist':
    'Even if the GPU is stopped, the mounted storage will continue to incur charges and cannot be stopped. If you no longer need the GPU and the mounted storage, you can delete them from the storage menu. Once deleted, all the content stored on the mounted storage will be permanently removed and cannot be recovered.',
  'gpu.template.chatglm.tips':
    'The template has a higher requirement for GPU memory, so it is recommended to choose an instance specification with dual GPUs.',
  'gpu.tempStorage': 'Temporary Storage',
  aliyunTips2: 'Must be on the resource disk and cannot be / root.',
  RunningLog: 'Running Log',
  operationRecords: 'Operation Records',
  autoScroll: 'Auto Scroll',
  newLogsAvailable: 'new logs available',
  'gpu.log.latest': 'Latest',
  'gpu.log.lines': 'Lines',
  advancedSettings: 'Advanced Settings',
  changePlanTipsTitle: 'Change Plan',
  changePlanTipsContent:
    'The switching package will be deducted immediately (excluding GPU rental business). Please confirm your needs before switching.',
  viewDetail: 'View Detail',
  'maxPortCount.tips': '(Max 2)',
  'maxEnvCount.tips': '(Max 10)',
  'portLimit.tips': 'Port range is 1024-65535',
  'gpu.env.tips':
    'Environment variables cannot reference other variables. For example, PATH=/usr/bin:/bin is valid, but PATH=/usr/bin:$PATH is not.',
  'gpu.agreements1': 'I have read and agree to the ',
  'gpu.agreements2': 'User Agreement for Rental of GPU and Storage',
  'gpu.agreements.title': 'Notes for users',
  'gpu.shutdownTimer': 'Shutdown Timer',
  'gpu.shutdownTimer.set': 'Set Shutdown Timer',
  'gpu.select.date.time': 'Select Date and Time',
  'gpu.shutdownTimer.time': 'Shutdown Time',
  'select.time.error': 'Selected time must be later than current time',
  'cicd.env.sync': 'Sync',
  'cicd.env.sync.success': 'Sync Success',
  'cicd.discord.placeholder': 'Please enter the webhook address',
  'gpu.EstimatedCost': 'Estimated cost',
  AccountBalance: 'Account balance',
  'gpu.time.cancel.success': 'Cancel success',
  'gpu.shutdownTimer.set.success': 'Set success',
  gpuBillingPrompt:
    'Please note that GPU and storage services will incur ongoing charges. If you no longer need GPU, please stop it promptly. Likewise, if you no longer require storage, please delete it in a timely manner.',
  billingInProgress: 'Billing in progress',
  auto_stop: 'Auto stop',
  'start.console': 'Start Web Console',
  RecordsOfConsumption: 'Records of consumption',
  BalanceReminder: 'The balance is about to be insufficient.',
  'gpu.start.continue': 'Continue',
  'gpu.start.balanceNotification.description':
    'According to the current billing situation, the system expects that the running time of your remaining GPU is less than 1 hour. When the balance is insufficient, the operation of GPU will be terminated unexpectedly, resulting in data loss. Please plan your use or recharge reasonably.',
  'gpu.storage.share': 'Share',
  passwordSharing: 'Code Sharing',
  CodeSharingTip:
    'Other users can copy your current storage with your shared Code',
  copyPassword: 'Copy Code',
  copyStorage: 'Copy Storage',
  enterCodeTips: 'please enter your code',
  enterCode: 'Enter Code',
  codeExpire_at: 'Code will expire at {expire_at}',
  'gpu.reminder': 'Reminder',
  'gpu.reminder.title': 'Long-term operation reminder',
  'gpu.reminder.content': `The system will remind you of instances that have been running continuously for more than 24 hours. You can turn it off if you don't need it.`,
  'gpu.reminder.success': 'Set reminder success',
  'gpu.reminder.storage.title': 'Insufficient space reminder',
  'gpu.reminder.storage.content':
    'The system will issue a reminder for storage that exceeds 99% usage, and can be turned off if it is not needed.',
  'gpu.storage.tips.error':
    'The storage {name} you selected has a capacity of {total}. It has been fully used up and cannot start GPU. Please do so in time {expand}.',
  'gpu.storage.tips.warning':
    'The storage {name} capacity you selected is {total}, and the remaining free space is less than 500MB, which may lead to GPU startup failure. Please {expand} in time.',
  'gpu.storage.tips.expand': 'expand',
  NotUsable: 'Not Usable',
  'gpu.persist.storage.tips':
    'The currently selected template requires at least {min} storage space',
  'gpu.persist.storage.tips2':
    'The storage {name} capacity you choose is {total}, and the template you choose needs at least {min} capacity. This storage does not meet the requirements, please choose another storage.',
  'disk.total': 'Total',
  'disk.expand': 'Expand',
  capacity_used: 'Used',
  aliYunDrivePath: 'Aliyun Drive Path',
  baiduNetdiskPath: 'Baidu Netdisk Path',
  envNameRequired: 'Env name is required',
  envNamePattern: `The startup environment variable name is illegal and must be a letter, number,'_','-'or'.' Composition and cannot start with a number.`,
  'gpu.selectDataCenter': 'Select Data Center:',
  'gpu.selectType': 'Select GPU Type:',
  'gpu.outOfStock':
    'The current specification stock is insufficient, please select another specification.',
  'gpu.notUsable':
    'The current specification is not available, please select another specification.',
  'gpu.open': 'Open',
  'gpu.status.storageReady': 'Storage Ready',
  'gpu.initializing': 'Initializing',
  'gpu.event.createStorage': 'Create Storage',
  reinstateStorage: 'Reinstate Storage',
  start_with_new_storage: 'Start with new storage',
  'gpu.log.errorCode': 'Result',
  'gpu.log.errorCode.0': 'Success',
  'gpu.log.errorCode.1': 'Failed',
  'gpu.storage.create.limit':
    'Please note that storage creation and deletion operations can only be performed 5 times a day.',
  'gpu.storage.delete.limit':
    'Please note that storage deletion can only be done 5 times a day.',
};

export default enUS;
