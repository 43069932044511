import axios from '@/_common/utils/axios-wrapper';
import settings from '@/_common/utils/settings';

class MeshApi {
  getStackDeleteCheck(k8sInstanceId) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/cluster/k8s-instance-id/${k8sInstanceId}/stack-delete-check`,
      {
        autoError: false,
      }
    );
  }
  getClusters(params) {
    return axios.get(settings.GATEWAY_API_ADDRESS + '/v1/mesh/clusters', {
      params,
      autoError: false,
    });
  }
  createCluster(params) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS + `/v1/mesh/clusters`,
      params,
      { autoError: false }
    );
  }
  getCluster(clusterId) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS + `/v1/mesh/clusters/${clusterId}`,
      { autoError: false }
    );
  }
  updateCluster(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS + `/v1/mesh/clusters/${params.id}`,
      params,
      { autoError: false }
    );
  }
  deleteCluster(id) {
    return axios.delete(
      settings.GATEWAY_API_ADDRESS + `/v1/mesh/clusters/${id}`,
      { autoError: false }
    );
  }
  getKubernetesOccupy(instanceIds) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/kubernetes-occupy/${instanceIds}`,
      { autoError: false }
    );
  }
  getAClusterUnderTheK8S(k8sInstanceId) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/k8s-instance-id/${k8sInstanceId}/clusters`,
      { autoError: false }
    );
  }
  createClusterWithGlobalConfig(params) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS + `/v1/mesh/cluster-global-config`,
      params,
      { autoError: false }
    );
  }
  updateClusterWithGlobalConfig(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/cluster-global-config/${params.clusterId}`,
      params,
      { autoError: false }
    );
  }

  getTenants(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/tenants`,
      {
        params,
        autoError: false,
      }
    );
  }
  createTenant(params) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/tenants`,
      params,
      { autoError: false }
    );
  }
  getTenant(clusterId, name) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/tenants/${name}`,
      { autoError: false }
    );
  }
  updateTenant(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/tenants/${params.name}`,
      params,
      { autoError: false }
    );
  }
  deleteTenant(clusterId, name) {
    return axios.delete(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/tenants/${name}`,
      { autoError: false }
    );
  }

  getServices(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/services`,
      {
        params,
        autoError: false,
      }
    );
  }
  createService(params) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/services`,
      params,
      { autoError: false }
    );
  }
  getService(clusterId, name) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/services/${name}`,
      { autoError: false }
    );
  }
  updateService(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/services/${params.name}`,
      params,
      { autoError: false }
    );
  }
  deleteService(clusterId, name) {
    return axios.delete(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/services/${name}`,
      { autoError: false }
    );
  }
  syncService(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/tenants/${params.tenantId}/service/sync-service`,
      params,
      { autoError: false }
    );
  }

  getServiceInstances(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/serviceinstances`,
      { params, autoError: false }
    );
  }

  getIngresses(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/ingresses`,
      { params, autoError: false }
    );
  }
  createIngress(params) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/ingresses`,
      params,
      { autoError: false }
    );
  }
  getIngress(clusterId, name) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/ingresses/${name}`,
      { autoError: false }
    );
  }
  updateIngress(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/ingresses/${params.name}`,
      params,
      { autoError: false }
    );
  }
  deleteIngress(clusterId, name) {
    return axios.delete(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/ingresses/${name}`,
      { autoError: false }
    );
  }

  getTraffictargets(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/traffictargets`,
      {
        params,
        autoError: false,
      }
    );
  }
  createTraffictarget(params) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/traffictargets`,
      params,
      { autoError: false }
    );
  }
  getTraffictarget(clusterId, name) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/Traffictargets/${name}`,
      { autoError: false }
    );
  }
  updateTraffictarget(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/Traffictargets/${params.name}`,
      params,
      { autoError: false }
    );
  }
  deleteTraffictarget(clusterId, name) {
    return axios.delete(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/Traffictargets/${name}`,
      { autoError: false }
    );
  }

  getHTTPRouteGroups(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/httproutegroups`,
      {
        params,
        autoError: false,
      }
    );
  }
  createHTTPRouteGroup(params) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/httproutegroups`,
      params,
      { autoError: false }
    );
  }
  getHTTPRouteGroup(clusterId, name) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/httproutegroups/${name}`,
      { autoError: false }
    );
  }
  updateHTTPRouteGroup(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/httproutegroups/${params.name}`,
      params,
      { autoError: false }
    );
  }
  deleteHTTPRouteGroup(clusterId, name) {
    return axios.delete(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/httproutegroups/${name}`,
      { autoError: false }
    );
  }

  getGlobalConfig(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/objects/easemesh-controller`,
      { autoError: false }
    );
  }
  updateGlobalConfig(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/objects/easemesh-controller`,
      params,
      { autoError: false }
    );
  }

  getServiceCanaries(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/servicecanaries`,
      {
        params,
        autoError: false,
      }
    );
  }
  createServiceCanary(params) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/servicecanaries`,
      params,
      { autoError: false }
    );
  }
  getServiceCanary(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/servicecanaries/${params.name}`,
      { params, autoError: false }
    );
  }
  updateServiceCanary(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/servicecanaries/${params.name}`,
      params,
      { autoError: false }
    );
  }
  deleteServiceCanary(clusterId, name) {
    return axios.delete(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/servicecanaries/${name}`,
      { autoError: false }
    );
  }
  closeServiceCanary(name, apiStackServiceCanary) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS + `/v1/mesh/service-canaries/${name}/close`,
      apiStackServiceCanary,
      { autoError: false }
    );
  }
  rolloutServiceCanary(name, apiStackServiceCanary) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/service-canaries/${name}/rollout`,
      apiStackServiceCanary,
      { autoError: false }
    );
  }

  getShadowServices(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/customresources/${params.kind}`,
      {
        params,
        autoError: false,
      }
    );
  }
  createShadowService(params) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/customresources`,
      {
        custom_resources: params,
        shadow_service: params.serviceName,
        traffic_header_value: params.trafficHeaderValue,
      },
      { autoError: false }
    );
  }
  getShadowService(clusterId, kind, name) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/customresources/${kind}/${name}`,
      { autoError: false }
    );
  }
  updateShadowService(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/customresources`,
      params,
      { autoError: false }
    );
  }
  deleteShadowService(clusterId, kind, name, params) {
    return axios.delete(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/customresources/${kind}/${name}`,
      { params, autoError: false }
    );
  }

  getCustomresourceKinds(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/customresourcekinds`,
      {
        params,
        autoError: false,
      }
    );
  }
  createCustomresourceKind(params) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/customresourcekinds`,
      params,
      { autoError: false }
    );
  }
  getAgentType(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/service/${params.name}/agent-type`,
      { autoError: false }
    );
  }
  getServiceDeploymentSpec(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${params.clusterId}/service/${params.name}/deployment`,
      { autoError: false }
    );
  }
  getTrafficHeaderValues(clusterId) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/mesh/clusters/${clusterId}/service-header-values`,
      { autoError: false }
    );
  }
}

export default new MeshApi();
