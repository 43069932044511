import CircuitBreaker from '@/app/service/mesh/Service/Governance/CircuitBreaker';

const zhCN = {
  'base.create': '创建',
  'base.update': '更新',
  'base.delete': '删除',
  'base.query': '查询',
  'base.search': '查询',
  'base.confirm': '确认',
  'base.back': '返回',
  'base.next': '下一步',
  'base.ok': '确定',
  'base.save': '保存',
  'base.save.and.close': '保存并关闭',
  'base.close': '关闭',
  'base.cancel': '取消',
  'base.add': '添加',
  'base.submit': '提交',
  'base.reset': '重置',
  'base.apply': '应用',
  'base.choose': '选中',
  'base.refresh': '刷新',
  'base.detail': '详情',

  circuitbreaker: '熔断器',
  'circuitbreaker.enabled.only': '只看熔断器打开的服务',
  'circuitbreaker.system': '项目名',
  'circuitbreaker.enabled': '熔断器当前打开',
  'circuitbreaker.service': '服务',
  'circuitbreaker.details.name': '熔断器名称',
  'circuitbreaker.details.type': '采样类型',
  'circuitbreaker.details.type.quantity': '以数量为单位(调用次数）',
  'circuitbreaker.details.type.time': '以时间为单位（秒）',
  'circuitbreaker.details.window': '采样个数(周期)',
  'circuitbreaker.details.halfopen': '半开关允许请求个数',
  'circuitbreaker.details.waitduration': '熔断持续时长',
  'circuitbreaker.details.waitduration.helper': '输入整数以s或ms结束',
  'circuitbreaker.details.failureratethreshold': '失败率阈值',
  'circuitbreaker.details.minimumnumberofcalls': '最小采样个数',
  'circuitbreaker.details.failurehttpstatus': '记录异常HTTP状态码',
  'circuitbreaker.details.failurehttpstatus.helper': '输入多个状态码以逗号分隔',
  'circuitbreaker.details.onoff': '启用',
  'circuitbreaker.details.on': '是',
  'circuitbreaker.details.off': '否',
  'circuitbreaker.details.op': '操作',
  'circuitbreaker.details.add': '新增熔断器',
  'circuitbreaker.details.edit': '熔断器设置',
  'circuitbreaker.details.warning': '警告⚠️',
  'circuitbreaker.details.warning.helper': '确定删除熔断器？',
  'circuitbreaker.details.last.status': '熔断状态',
  'circuitbreaker.details.update.time': '状态更新时间',
  'circuitbreaker.details.instance.ip': '服务实例IP',
  'circuitbreaker.details.status': '熔断情况',

  'head.logo.desc': '用技术推进商业进步',

  'sla.service.bar': 'SLA 站点',
  'sla.throughput': '吞吐量',
  'sla.throughput.less': '负荷轻',
  'sla.throughput.heavy': '负荷重',
  'sla.glance': '系统一览',
  'sla.glance.compared.with': '与',
  'sla.glance.compared.change_trend': '相比',
  'sla.glance.Instances': '实例',
  'sla.glance.Response_Times': '响应时间',
  'sla.glance.Requests': '请求',
  'sla.glance.Error_Rate': '错误数',
  'sla.glance.no_change': '没有变化',
  'sla.glance.Min': '最小值',
  'sla.glance.Max': '最大值',
  'sla.glance.Avg': '平均值',

  'sla.graph.site.diagram': '图表',
  'sla.graph.site.through.m1.rate': '全站m1吞吐率',
  'sla.graph.site.through.err.rate': '全站吞吐错误数',
  'sla.graph.site.200.response': '全站200响应',
  'sla.graph.site.total.response': '全站总响应',
  'sla.graph.site.p99.response': 'P99的响应时间',
  'sla.graph.site.p75.response': 'P75的响应时间',
  'sla.graph.service.through.m1.rate': 'M1吞吐率',
  'sla.graph.service.through.err.rate': '错误数',
  'sla.graph.service.200.response': '200响应',
  'sla.graph.service.total.response': '总响应',
  'sla.graph.service.p99.response': 'P99的响应时间',
  'sla.graph.service.p75.response': 'P75的响应时间',

  'sla.graph.item.through.m1.rate.max': 'M1吞吐率 [最大]',
  'sla.graph.item.through.m1.rate.min': 'M1吞吐率 [最小]',
  'sla.graph.item.through.m1.rate.avg': 'M1吞吐率 [平均]',

  'sla.graph.item.through.err.rate.max': '错误数 [最大]',
  'sla.graph.item.through.err.rate.min': '错误数 [最小]',
  'sla.graph.item.through.err.rate.avg': '错误数 [平均]',

  'sla.graph.item.200.response.max': '200 [最大]',
  'sla.graph.item.200.response.min': '200 [最小]',
  'sla.graph.item.200.response.avg': '200 [平均]',

  'sla.graph.item.total.response.max': '总计 [最大]',
  'sla.graph.item.total.response.min': '总计 [最小]',
  'sla.graph.item.total.response.avg': '总计 [平均]',

  'sla.graph.item.p99.duration.max': 'P99执行持续时间 [最大]',
  'sla.graph.item.p99.duration.min': 'P99执行持续时间 [最小]',
  'sla.graph.item.p99.duration.avg': 'P99执行持续时间 [平均]',

  'sla.graph.item.p75.duration.max': 'P75执行持续时间 [最大]',
  'sla.graph.item.p75.duration.min': 'P75执行持续时间 [最小]',
  'sla.graph.item.p75.duration.avg': 'P75执行持续时间 [平均]',

  'sla.glance.instance': '实例数',
  'sla.glance.instances': '实例集',
  'sla.glance.requests': '请求数',
  'sla.glance.response_time': '响应时间',
  'sla.glance.error_rate': '错误数',
  'sla.glance.apdex_max': 'APDEX(最大)',
  'sla.glance.apdex_min': 'APDEX(最小)',
  'sla.glance.apdex_avg': 'APDEX(平均)',

  'sla.events.service': '服务事件',
  'sla.events.middleware': '中间件事件',
  'sla.events.detail': '详情',

  'app.service.config.ratelimiter.addRateLimiter': '新增限流器',
  'app.service.config.ratelimiter.editRateLimiter': '编辑限流器',
  'app.service.config.ratelimiter.label.name': '限流器名称',
  'app.service.config.ratelimiter.label.type': '类型',
  'app.service.config.ratelimiter.label.limitRefreshPeriod': '令牌周期',
  'app.service.config.ratelimiter.label.limitForPeriod': '令牌个数',
  'app.service.config.ratelimiter.label.timeoutDuration': '等待令牌最大时间',
  'app.service.config.ratelimiter.label.timeoutDuration.formatTips':
    '请输入以s或ms结尾的数字',
  'app.service.config.ratelimiter.label.enabled': '启用',

  'app.service.config.retry.addRetry': '新增重试器',
  'app.service.config.retry.editRetry': '编辑重试器',
  'app.service.config.retry.label.name': '重试器名称',
  'app.service.config.retry.label.maxAttempts': '最多重试次数',
  'app.service.config.retry.label.retryDelayPolicy': '重试延时策略',
  'app.service.config.retry.label.waitDuration': '等待重试的时间周期',
  'app.service.config.retry.label.waitDuration.formatTips':
    '请输入以s或ms结尾的数字',
  'app.service.config.retry.label.enableExponentialBackoff': '启用指数退避',
  'app.service.config.retry.label.exponentialBackoffMultiplier': '指数退避倍率',
  'app.service.config.retry.label.exponentialBackoffMultiplier.formatTips':
    '请输入大于1的数字',
  'app.service.config.retry.label.enableRandomizeWait': '启用随机延迟',
  'app.service.config.retry.label.randomizeWaitFactor': '随机延迟因子',
  'app.service.config.retry.label.randomizeWaitFactor.formatTips':
    '请输入0和1之间的数字',
  'app.service.config.retry.label.retryHttpStatus': '重试HTTP状态码',
  'app.service.config.retry.label.retryHttpStatus.formatTips':
    '输入多个状态码以逗号分隔',
  'app.service.config.retry.label.enabled': '启用',

  'app.service.show.title.instances': '实例',
  'app.service.show.title.configItems': '配置项',
  'app.service.show.title.description': '描述',
  'app.service.show.title.labels': '标签',
  'app.service.show.title.labels.no-label-tips': '暂无标签',
  'app.service.show.title.metrics': '指标',
  'app.service.show.title.tracing': '调用链',
  'app.service.show.title.faultTolerance': '容错',
  'app.service.select.title.selectedServices': '已选择的服务',
  'app.service.select.title.selectedServices.empty-tips': '没有选择任何服务',
  'app.service.select.btn.configure': '进行配置',
  'app.service.select.btn.select': '选择',
  'app.service.select.btn.unselect': '取消',

  'app.service.management.ServicesManagementPage.card-menu.instances':
    '实例管理',
  'app.service.management.ServicesManagementPage.card-menu.configurations':
    '参数管理',

  'app.service.management.service.ServiceGovernanceTabs.Metrics': '指标集',
  'app.service.management.service.ServiceGovernanceTabs.Tracing': '跟踪',
  'app.service.management.service.ServiceGovernanceTabs.CircuitBreaker':
    '熔断器',
  'app.service.management.service.ServiceGovernanceTabs.RateLimiter':
    '速率限制器',
  'app.service.management.service.ServiceGovernanceTabs.Retry': '重试器',
  'app.service.management.service.ServiceGovernanceTabs.Canary': '灰度',

  'app.service.management.service.governance.CircuitBreakerConfigPanel.Policy':
    '策略',
  'app.service.management.service.governance.CircuitBreakerConfigPanel.Policies':
    '策略集',
  'app.service.management.service.governance.CircuitBreakerConfigPanel.Ctrl':
    '控制',
  'app.service.management.service.governance.CircuitBreakerConfigPanel.PolicyRef':
    '策略引用',

  'app.service.management.service.governance.CanaryConfigPanel.Headers':
    '请求头集',
  'app.service.management.service.governance.CanaryConfigPanel.URLs': '链接集',
  'app.service.management.service.governance.CanaryConfigPanel.ServiceInstanceLabels':
    '服务标签集',

  'app.service.management.service.governance.governance-utils.GlobalSwitch':
    '全局开关',
  'app.service.management.service.governance.governance-utils.DefaultPolicy':
    '默认策略',
  'app.service.management.service.governance.governance-utils.CreatePolicy':
    '创建策略',
  'app.service.management.service.governance.governance-utils.CreateCanary':
    '创建灰度',
  'app.service.management.service.governance.governance-utils.CreateCtrl':
    '创建控制',

  'app.service.management.registry.RegistryManagementPage.Registry': '注册中心',
  'app.service.management.registry.RegistryManagementPage.CreateRegistry':
    '创建注册中心',
  'app.service.management.registry.RegistryManagementPage.Services': '服务集',
  'app.service.management.registry.RegistryManagementPage.Type': '类型',
  'app.service.management.registry.RegistryManagementPage.Description': '描述',
  'app.service.management.registry.RegistryManagementPage.LatestFetch':
    '最近一次拉取',
  'app.service.management.registry.RegistryManagementPage.At': '在',
  'app.service.management.registry.RegistryManagementPage.Used': '用时',
  'app.service.management.registry.RegistryManagementPage.Inventory': '清单',

  'app.service.management.registry.RegistryInventoryPage.Service': '服务',
  'app.service.management.registry.RegistryInventoryPage.InMaintenance':
    '维护中',
  'app.service.management.registry.RegistryInventoryPage.Healthy': '健康',

  'app.service.management.registry.ServiceInstanceTable.Raw': '原数据',
  'app.service.management.registry.ServiceInstanceTable.Metadata': '元数据',
  'app.service.management.registry.ServiceInstanceTable.Host': '主机',
  'app.service.management.registry.ServiceInstanceTable.Port': '端口',
  'app.service.management.registry.ServiceInstanceTable.maintenance_mode':
    '维护模式',
  'app.service.management.registry.ServiceInstanceTable.UpdatedAt': '更新于',

  'app.service.management.configServer.ConfigServerPage.ConfigServer':
    '配置服务器',
  'app.service.management.configServer.ConfigServerPage.CreateConfigServer':
    '创建配置服务器',

  'app.service.management.ServiceEditForm.ServiceName': '服务名称',
  'app.service.management.ServiceEditForm.Description': '描述',
  'app.service.tags.add': '添加标签',
  'app.service.tags.edit': '修改标签',
  'app.service.management.ServiceEditForm.tags': '标签',

  'app.service.apiManagement.ApiManagementPage.Reloading': '重新加载',
  'app.service.apiManagement.ApiManagementPage.PageBaseUrl': '页面基础资源定位',

  'app.service.monitor.dashboard.ServiceRelatedServicesPanel.JumpToUpstream':
    '跳转到上游服务',
  'app.service.monitor.dashboard.ServiceRelatedServicesPanel.JumpToDownstream':
    '跳转到下游服务',
  'app.service.monitor.dashboard.ServiceRelatedServicesPanel.UnableToJump':
    '无法跳转',

  'app.service._common.ClusterAutocomplete.ZoneCluster': '区（集群）',

  'app.service.mesh.Cluster.index.Connect': '连接',
  'app.service.mesh.Cluster.index.ConnectCluster': '连接集群',
  'app.service.mesh.Cluster.index.ConnectZone': '连接区',
  'app.service.mesh.Cluster.index.ClusterForm': '集群表单',
  'app.service.mesh.Cluster.index.Address': '地址',
  'app.service.mesh.Cluster.index.RegistryType': '注册中心类型',
  'app.service.mesh.Cluster.index.Security': '安全',
  'app.service.mesh.Cluster.index.Description': '描述',

  'app.service.mesh.Tenant.index.TenantForm': '租户表单',

  'app.service.management.Service.SelectSource': '选择源',
  'app.service.management.Service.Configuration': '配置',
  'app.service.management.Service.Cluster': '集群',
  'app.service.management.Service.Zone': '区',
  'app.service.management.Service.Domain': '域',
  'app.service.management.Service.Tenant': '租户',
  'app.service.management.Service.Name': '名称',
  'app.service.management.Service.Tags': '标签',
  'app.service.management.Service.Governance': '治理',
  'app.service.management.Service.Observability': '可观测性',

  'app.service.mesh.Service.index.NoServices': '无服务',
  'app.service.mesh.Service.index.NoServicesDesc':
    '请在操作前至少添加一个服务。',
  'app.service.mesh.Service.index.DeleteServicesTipIngress':
    '服务正在被Ingress引用，无法删除。',
  'app.service.mesh.Service.index.DeleteServicesTipTraffictarget':
    '服务正在被Traffictarget引用，无法删除。',
  'app.service.mesh.Service.index.DeleteServicesTipServiceCanary':
    '服务正在被ServiceCanary引用，无法删除。',
  'app.service.mesh.Service.index.DeleteServicesTipShadowService':
    '服务正在被ShadowService引用，无法删除。',
  'app.service.mesh.Service.index.Namespace': '命名空间',
  'app.service.mesh.Service.index.NamespaceName': '命名空间名称',
  'app.service.mesh.Service.index.CreateNamespace': '创建命名空间',
  'app.service.mesh.Service.index.Namespaces': '命名空间集',
  'app.service.mesh.Service.index.Services': '服务集',
  'app.service.mesh.Service.index.CreateService': '创建服务',
  'app.service.mesh.Service.index.ServiceName': '服务名称',
  'app.service.mesh.Service.index.PleaseCreateANamespaceFirst':
    '请先创建一个命名空间',
  'app.service.mesh.Service.index.YAML': 'YAML',
  'app.service.mesh.Service.index.Name': '名称',
  'app.service.mesh.Service.index.APIAddress': 'API地址',
  'app.service.mesh.Service.index.Enviroment': '环境',
  'app.service.mesh.Service.index.ClusterName': '集群名称',
  'app.service.mesh.Service.index.Token': '令牌',
  'app.service.mesh.Service.index.Operator': '操作人',
  'app.service.mesh.Service.index.Value': '值',
  'app.service.mesh.Service.index.Instances': '实例集',
  'app.service.mesh.Service.index.Mock': '模拟',
  'app.service.mesh.Service.index.RateLimiter': '速率限制器',
  'app.service.mesh.Service.index.CircuitBreaker': '熔断器',
  'app.service.mesh.Service.index.Retryer': '重试器',
  'app.service.mesh.Service.index.TimeLimiter': '时间限制器',
  'app.service.mesh.Service.index.LoadBalance': '负载均衡',

  'app.service.mesh.Service.index.DataCenter': '数据中心',
  'app.service.mesh.Service.index.Authentication': '认证',
  'app.service.mesh.Service.index.Prefix': '前缀',
  'app.service.mesh.Service.index.ProfileSeparator': '档案分割符',
  'app.service.mesh.Service.index.Profile': '档案',

  'app.service.mesh.Service.ServiceForm.FollowingConfigItemsOfService':
    '以下是所有配置的服务项目',
  'app.service.mesh.Service.ServiceForm.InConfigServer': '在配置服务器',
  'app.service.mesh.Service.ServiceForm.UpdatedAt': '更新于',

  'app.service.mesh.Service.ServiceForm.MaxSamplingPerSecond':
    '最大采样次数/秒',

  'app.service.mesh.Service.ServiceForm.BasicInfos': '基本信息',
  'app.service.mesh.Service.ServiceForm.RegistryName': '注册名称',
  'app.service.mesh.Service.ServiceForm.RegisterTenant': '注册租户',

  'app.service.mesh.Service.ServiceForm.Mock': '模拟',
  'app.service.mesh.Service.ServiceForm.Enabled': '启用',
  'app.service.mesh.Service.ServiceForm.Rules': '规则集',
  'app.service.mesh.Service.ServiceForm.Match': '匹配',
  'app.service.mesh.Service.ServiceForm.MatchAllHeaders': '匹配所有数据头',
  'app.service.mesh.Service.ServiceForm.MatchHeaders': '匹配数据头',
  'app.service.mesh.Service.ServiceForm.Path': '路径',
  'app.service.mesh.Service.ServiceForm.PathPrefix': '路径前缀',
  'app.service.mesh.Service.ServiceForm.Code': '代码',
  'app.service.mesh.Service.ServiceForm.Headers': '数据头集',
  'app.service.mesh.Service.ServiceForm.Body': '数据体',
  'app.service.mesh.Service.ServiceForm.Delay': '延迟',

  'app.service.mesh.Service.ServiceForm.FormChanged': '记录已修改但未提交。',

  'app.service.mesh.Service.ServiceForm.Sidecar': '边车',
  'app.service.mesh.Service.ServiceForm.DiscoveryType': '发现类型',
  'app.service.mesh.Service.ServiceForm.Address': '地址',
  'app.service.mesh.Service.ServiceForm.IngressPort': '流量入口端口',
  'app.service.mesh.Service.ServiceForm.IngressProtocol': '流量入口协议',
  'app.service.mesh.Service.ServiceForm.EgressPort': '流量网关端口',
  'app.service.mesh.Service.ServiceForm.EgressProtocol': '流量网关协议',

  'app.service.mesh.Service.ServiceForm.OutputServer': '输出服务器',
  'app.service.mesh.Service.ServiceForm.BootstrapServer': '启动服务器',
  'app.service.mesh.Service.ServiceForm.Timeout': '超时',

  'app.service.mesh.Service.ServiceForm.Tracings': '追踪',
  'app.service.mesh.Service.ServiceForm.Topic': '主题',
  'app.service.mesh.Service.ServiceForm.SampleByQPS': '样品由QPS提供',

  'app.service.mesh.Service.ServiceForm.Output': '输出',
  'app.service.mesh.Service.ServiceForm.MessageMaxBytes': '信息最大字节数',
  'app.service.mesh.Service.ServiceForm.ReportThread': '报告线程',
  'app.service.mesh.Service.ServiceForm.QueuedMaxSpans': '排队的最大跨度',
  'app.service.mesh.Service.ServiceForm.QueuedMaxSize': '排队的最大尺寸',
  'app.service.mesh.Service.ServiceForm.MessageTimeout': '消息超时',

  'app.service.mesh.Service.ServiceForm.Request': '请求',
  'app.service.mesh.Service.ServiceForm.JDBC': 'JDBC',
  'app.service.mesh.Service.ServiceForm.Redis': 'Redis',
  'app.service.mesh.Service.ServiceForm.RemoteInvoke': '远程调用',
  'app.service.mesh.Service.ServiceForm.Kafka': 'Kafka',
  'app.service.mesh.Service.ServiceForm.Rabbit': 'Rabbit',
  'app.service.mesh.Service.ServiceForm.ServicePrefix': '服务前缀',

  'app.service.mesh.Service.ServiceForm.Metrics': '指标',
  'app.service.mesh.Service.ServiceForm.Access': '访问',
  'app.service.mesh.Service.ServiceForm.JDBCStatement': 'JDBC声明',
  'app.service.mesh.Service.ServiceForm.JDBCConnection': 'JDBC连接',
  'app.service.mesh.Service.ServiceForm.JVMGC': 'JVM垃圾回收',
  'app.service.mesh.Service.ServiceForm.JVMMemory': 'JVM内存',
  'app.service.mesh.Service.ServiceForm.MD5Dictionary': 'MD5字典',
  'app.service.mesh.Service.ServiceForm.Interval': '间隔',

  'app.service.mesh.Service.ServiceForm.CircuitBreaker': '熔断器',
  'app.service.mesh.Service.ServiceForm.DefaultPolicyRef': '默认策略引用',
  'app.service.mesh.Service.ServiceForm.CreatePolicy': '创建策略',
  'app.service.mesh.Service.ServiceForm.SlidingWindowType': '滑动窗口类型',
  'app.service.mesh.Service.ServiceForm.CreateUrl': '创建链接',
  'app.service.mesh.Service.ServiceForm.Methods': '方法集',
  'app.service.mesh.Service.ServiceForm.Exact': '精确',
  'app.service.mesh.Service.ServiceForm.Prefix': '前缀',
  'app.service.mesh.Service.ServiceForm.Regex': '正则表达式',
  'app.service.mesh.Service.ServiceForm.PolicyRef': '策略引用',
  'app.service.mesh.Service.ServiceForm.PolicyForm': '策略表单',
  'app.service.mesh.Service.ServiceForm.FailureRateThreshold': '失败率阈值',
  'app.service.mesh.Service.ServiceForm.SlowCallRateThreshold':
    '慢速呼叫率阈值',
  'app.service.mesh.Service.ServiceForm.SlidingWindowSize': '滑动窗口大小',
  'app.service.mesh.Service.ServiceForm.PermittedNumberOfCallsInHalfOpenState':
    '半开放状态下允许的通话次数',
  'app.service.mesh.Service.ServiceForm.MinimumNumberOfCalls': '最低通话次数',
  'app.service.mesh.Service.ServiceForm.SlowCallDurationThreshold':
    '慢速呼叫持续时间阈值',
  'app.service.mesh.Service.ServiceForm.MaxWaitDurationInHalfOpenState':
    '半开状态下的最大等待时间',
  'app.service.mesh.Service.ServiceForm.WaitDurationInOpenState':
    '开放状态下的等待时间',
  'app.service.mesh.Service.ServiceForm.UrlForm': '链接表单',
  'app.service.mesh.Service.ServiceForm.FailureHttpStatusCode':
    '失败Http状态码',
  'app.service.mesh.Service.ServiceForm.FailureHttpStatusCode.help':
    '请使用英语逗号分隔多个状态码',
  'app.service.mesh.Service.ServiceForm.RetryHttpStatusCode':
    '重试器Http状态码',
  'app.service.mesh.Service.ServiceForm.Mode': '模式',

  'app.service.mesh.Service.ServiceForm.RateLimiter': '速率限制器',
  'app.service.mesh.Service.ServiceForm.TimeoutDuration': '超时时间',
  'app.service.mesh.Service.ServiceForm.LimitRefreshPeriod': '限制刷新周期',
  'app.service.mesh.Service.ServiceForm.LimitForPeriod': '期限',

  'app.service.mesh.Service.ServiceForm.Retryer': '重试器',
  'app.service.mesh.Service.ServiceForm.MaxAttempts': '最大尝试次数',
  'app.service.mesh.Service.ServiceForm.WaitDuration': '等待时间',
  'app.service.mesh.Service.ServiceForm.BackOffPolicy': '后退政策',
  'app.service.mesh.Service.ServiceForm.RetryDelayPolicy': '重试延迟政策',
  'app.service.mesh.Service.ServiceForm.ExponentialBackoffMultiplier':
    '指数后退倍数',
  'app.service.mesh.Service.ServiceForm.RandomizedWaitFactor': '随机等待因子',
  'app.service.mesh.Service.ServiceForm.RandomizationFactor': '随机化因子',
  'app.service.mesh.Service.ServiceForm.CountingNetworkError': '网络错误统计',
  'app.service.mesh.Service.ServiceForm.FailureStatusCodes': '失败状态码',
  'app.service.mesh.Service.ServiceForm.FailureCodes': '失败状态码',

  'app.service.mesh.Service.ServiceForm.TimeLimiter': '时间限制器',
  'app.service.mesh.Service.ServiceForm.DefaultTimeoutDuration': '默认超时时间',

  'app.service.mesh.Service.ServiceForm.LoadBalance': '负载均衡',
  'app.service.mesh.Service.ServiceForm.Policy': '策略',
  'app.service.mesh.Service.ServiceForm.HeaderHashKey': '头部哈希键',

  'app.service.mesh.Service.Governance.CircuitBreaker.Warning':
    '我们使用resilience4j的成熟设计，请参考：',

  'app.service.mesh.Service.Governance.Retry.Warning':
    '我们使用resilience4j的成熟设计，请参考：',

  'app.service.mesh.Service.Governance.FailureCodes.Warning':
    '故障代码由断路器和重试共享，空意味着所有代码为5xx。',

  'app.service.mesh.Service.Governance.RateLimiter.WarningStart':
    '左侧策略窗口用于创建限制策略，指定具体的限制策略，请参考：',
  'app.service.mesh.Service.Governance.RateLimiter.WarningEnd':
    '右侧的URLs窗口用于创建不同的流量组，这些流量组参考了相应的策略。默认策略引用和链接表单中的策略引用值必须存在于策略集中。',

  'app.service.mesh.Service.Instances.NotFoundInRegistry':
    '在已存在的注册中心找不到该应用',
  'app.service.mesh.Service.Instances.NotFoundInConfigurationCenter':
    '在已存在的配置中心找不到该应用',

  'app.service.mesh.Service.Instances.RegistryName': '注册名称',
  'app.service.mesh.Service.Instances.InstanceID': '实例ID',
  'app.service.mesh.Service.Instances.IP': 'IP',
  'app.service.mesh.Service.Instances.Port': '端口',
  'app.service.mesh.Service.Instances.Labels': '标签',
  'app.service.mesh.Service.Instances.Status': '状态',
  'app.service.mesh.Service.Instances.RegistryTime': '注册时间',

  'app.service.mesh.Service.Instances.Raw': '原始数据',
  'app.service.mesh.Service.Instances.Metadata': '元数据',

  'app.service.mesh.Tenant.index.CreateTenant': '创建租户',
  'app.service.mesh.Tenant.index.CreateDomain': '创建域',
  'app.service.mesh.Tenant.index.TenantName': '租户名称',
  'app.service.mesh.Tenant.index.DomainName': '域名称',
  'app.service.mesh.Tenant.index.DomainTenant': '域(租户)',
  'app.service.mesh.Tenant.index.Description': '描述',
  'app.service.mesh.Tenant.index.Services': '服务集',

  'app.service.mesh.Ingress.index.Ingress': '流量入口',
  'app.service.mesh.Ingress.index.CreateIngress': '创建流量入口',
  'app.service.mesh.Ingress.index.IngressName': '流量入口名称',
  'app.service.mesh.Ingress.index.Rules': '规则集',
  'app.service.mesh.Ingress.index.BackendServices': '后端服务集',
  'app.service.mesh.Ingress.index.ServiceName': '服务名称',

  'app.service.mesh.Ingress.IngressForm.RulesManagement': '规则集管理',
  'app.service.mesh.Ingress.IngressForm.EditRule': '编辑规则',
  'app.service.mesh.Ingress.IngressForm.EditMatch': '编辑匹配',
  'app.service.mesh.Ingress.IngressForm.RuleItem': '规则项',
  'app.service.mesh.Ingress.IngressForm.Host': '主机',
  'app.service.mesh.Ingress.IngressForm.Paths': '路径集',
  'app.service.mesh.Ingress.IngressForm.RewriteTarget': '重写目标',
  'app.service.mesh.Ingress.IngressForm.BackendService': '后端服务',
  'app.service.mesh.Ingress.IngressForm.Path': '路径',
  'app.service.mesh.Ingress.IngressForm.ExactPath': '精确路径',
  'app.service.mesh.Ingress.IngressForm.AddPath': '添加路径',
  'app.service.mesh.Ingress.IngressForm.Rule': '规则',
  'app.service.mesh.Ingress.IngressForm.Rules': '规则集',
  'app.service.mesh.Ingress.IngressForm.AddRule': '添加规则',

  'app.service.mesh.TrafficControl.index.TrafficControl': '流量控制',
  'app.service.mesh.TrafficControl.index.TrafficGroup': '流量规则组',
  'app.service.mesh.TrafficControl.index.TrafficGroups': '流量规则组集',
  'app.service.mesh.TrafficControl.index.CreateTrafficControl': '创建流量控制',
  'app.service.mesh.TrafficControl.index.CreateTrafficGroup': '创建流量规则组',
  'app.service.mesh.TrafficControl.index.TrafficGroupManagement':
    '流量规则组管理',
  'app.service.mesh.TrafficControl.index.RefreshTip':
    '刷新获取最新的流量规则组',
  'app.service.mesh.TrafficControl.index.TrafficControlName': '流量控制名称',
  'app.service.mesh.TrafficControl.index.TrafficGroupName': '流量规则组名称',
  'app.service.mesh.TrafficControl.index.SourceServices': '源服务集',
  'app.service.mesh.TrafficControl.index.DestinationService': '目标服务',
  'app.service.mesh.TrafficControl.index.ReferredByTrafficControls':
    '被流量控制集引用',

  'app.service.mesh.TrafficControl.TrafficControlForm.GoToTrafficGroup':
    '打开流量规则组',
  'app.service.mesh.TrafficControl.TrafficGroupForm.Matches': '匹配集',
  'app.service.mesh.TrafficControl.TrafficControlForm.RuleAndGroupManagement':
    '规则和组管理',
  'app.service.mesh.TrafficControl.TrafficControlForm.RuleManagement':
    '规则管理',
  'app.service.mesh.TrafficControl.TrafficControlForm.AddRule': '添加规则',
  'app.service.mesh.TrafficControl.TrafficControlForm.GroupName': '组名称',
  'app.service.mesh.TrafficControl.TrafficControlForm.GroupManagement':
    '组管理',
  'app.service.mesh.TrafficControl.TrafficControlForm.AddGroup': '添加组',
  'app.service.mesh.TrafficControl.TrafficControlForm.RuleForm': '规则表单',
  'app.service.mesh.TrafficControl.TrafficControlForm.GroupForm': '组表单',
  'app.service.mesh.TrafficControl.TrafficControlForm.MatchManagement':
    '匹配管理',
  'app.service.mesh.TrafficControl.TrafficControlForm.AddMatch': '添加匹配',
  'app.service.mesh.TrafficControl.TrafficControlForm.MatchForm': '匹配表单',
  'app.service.mesh.TrafficControl.TrafficControlForm.SourceServices':
    '源服务集',
  'app.service.mesh.TrafficControl.TrafficControlForm.DestinationService':
    '目标服务',
  'app.service.mesh.TrafficControl.TrafficControlForm.TrafficRules':
    '流量规则集',
  'app.service.mesh.TrafficControl.TrafficControlForm.Kind': '类型',
  'app.service.mesh.TrafficControl.TrafficControlForm.Matches': '匹配集',
  'app.service.mesh.TrafficControl.TrafficControlForm.HTTPRouteGroupManagement':
    'HTTP路由组管理',
  'app.service.mesh.TrafficControl.TrafficControlForm.Group': '组',
  'app.service.mesh.TrafficControl.TrafficControlForm.EditGroup': '编辑组',
  'app.service.mesh.TrafficControl.TrafficControlForm.PathRegex': '路径正则',

  'app.service.mesh.TrafficControl.TrafficGroup.DeleteError':
    '当前记录已被引用，无法删除。',

  'app.service.mesh.GlobalConfig.index.Kind': '类型',
  'app.service.mesh.GlobalConfig.index.HeartbeatInterval': '心跳间隔',
  'app.service.mesh.GlobalConfig.index.RegistryType': '注册类型',
  'app.service.mesh.GlobalConfig.index.APIPort': 'API端口',
  'app.service.mesh.GlobalConfig.index.IngressPort': '流量入口端口',
  'app.service.mesh.GlobalConfig.index.ExternalServiceRegistry': '外部服务注册',
  'app.service.mesh.GlobalConfig.index.CleanExternalRegistry':
    '清理外部服务注册',
  'app.service.mesh.GlobalConfig.index.Security': '安全',
  'app.service.mesh.GlobalConfig.index.MTLSMode': 'MTLS模式',
  'app.service.mesh.GlobalConfig.index.CertProvider': '证书供应商',
  'app.service.mesh.GlobalConfig.index.RootCertTTL': '根证书TTL',
  'app.service.mesh.GlobalConfig.index.AppCertTTL': '应用证书TTL',

  'app.service.mesh.MultiCanaryRelease.index.CreateMultiCanaryRelease':
    '创建多灰度发布',
  'app.service.mesh.CanaryRelease.index.CanaryRelease': '灰度发布',
  'app.service.mesh.CanaryRelease.index.CreateCanaryRelease': '创建灰度发布',
  'app.service.mesh.index.CreateAndEditWarning':
    '由于存在非Stack创建的服务，无法继续创建或查看，请先将非Stack创建的服务处理掉或切换集群再进行尝试',
  'app.service.mesh.index.SnapshotWarning':
    '相关的Stack被删除了，当前记录作为一个快照存在，无法操作。',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Rollout': '替换',
  'app.service.mesh.MultiCanaryRelease.index.MultiCanaryReleaseName':
    '多灰度发布名称',
  'app.service.mesh.CanaryRelease.index.CanaryReleaseName': '灰度发布名称',
  'app.service.mesh.MultiCanaryRelease.index.ServiceNames': '服务名称集',
  'app.service.mesh.MultiCanaryRelease.index.InstanceLabels': '实例标签集',
  'app.service.mesh.MultiCanaryRelease.index.TrafficRules': '流量规则集',

  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.AddLabel':
    '添加标签',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.Exact': '准确',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.Prefix': '前缀',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.RegEx': '正则',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.Empty': '空值',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.AddHeader':
    '添加头',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.Priority':
    '优先级',
  'app.service.mesh.CanaryRelease.index.MatchServices': '匹配服务集',
  'app.service.mesh.CanaryRelease.index.Status': '状态',
  'app.service.mesh.CanaryRelease.index.CloseTime': '关闭时间',

  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Canary': '灰度',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Name': '名称',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.ServiceName': '服务名称',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Services': '服务集',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Service': '服务',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.ServicesDescription':
    '选择要灰度发布的服务',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.DockerImage': 'Docker镜像',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.CurrentVersion': '当前版本',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.CanaryVersion': '灰度版本',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.TrafficRules': '流量规则集',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Header': '头',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.ExXLocation':
    '例如：X-Location',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Value': '值',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Exact': '匹配',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Prefix': '前缀',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Regex': '正则表达式',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.PriortyDescription':
    '高优先级将使灰度版本提前获得流量',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Preview': '预览',

  'app.service.mesh.ShadowService.index.ShadowService': '影子服务',
  'app.service.mesh.ShadowService.index.CreateShadowService': '创建影子服务',
  'app.service.mesh.ShadowService.index.ShadowServiceName': '影子服务名称',
  'app.service.mesh.ShadowService.index.SourceServiceName': '源服务名称',
  'app.service.mesh.ShadowService.index.TrafficHeaderValue': '流量头值',

  'app.service.mesh.ShadowService.ShadowServiceForm.TrafficHeaderValueError':
    '同一个trafficHeaderValue下，一个service只能shadow一次',

  'app.service.mesh.ShadowService.ShadowServiceForm.Middleware': '中间件',
  'app.service.mesh.ShadowService.ShadowServiceForm.CanaryRules': '灰度规则集',
  'app.service.mesh.ShadowService.ShadowServiceForm.MiddlewareManagement':
    '中间件管理',
  'app.service.mesh.ShadowService.ShadowServiceForm.EditMiddleware':
    '编辑中间件',
  'app.service.mesh.ShadowService.ShadowServiceForm.Type': '类型',
  'app.service.mesh.ShadowService.ShadowServiceForm.Uris': '统一资源识别号集',
  'app.service.mesh.ShadowService.ShadowServiceForm.Uris.help':
    '如果要填写多个uri，请将它们用英文逗号连接',
  'app.service.mesh.ShadowService.ShadowServiceForm.YAML': 'YAML',
  'app.service.mesh.ShadowService.ShadowServiceForm.YAML.help':
    '请以YAML格式组织内容',
  'app.service.mesh.ShadowService.ShadowServiceForm.UserName': '用户名',
  'app.service.mesh.ShadowService.ShadowServiceForm.Password': '密码',

  'app.service.mesh.ShadowService.ShadowServiceForm.CanaryRuleManagement':
    '灰度规则管理',
  'app.service.mesh.ShadowService.ShadowServiceForm.EditCanaryRule':
    '编辑灰度规则',
  'app.service.mesh.ShadowService.ShadowServiceForm.UrlPrefix':
    '统一资源定位符前缀',

  'app.service.mesh.ShadowService.ShadowServiceForm.Envs': '环境变量集',
  'app.service.mesh.ShadowService.ShadowServiceForm.ConfigMaps': '配置映射集',
  'app.service.mesh.ShadowService.ShadowServiceForm.Secrets': '密钥集',

  'app.service.mesh.titleid': '描述',

  'app.service.mesh.helpid.APIAddress': '当前选择的类型对应的应用地址。',
  'app.service.mesh.helpid.Enviroment': '当前选择的类型对应的环境。',
  'app.service.mesh.helpid.ClusterName': '当前选择的类型对应的集群名称。',
  'app.service.mesh.helpid.NamespaceName': '当前选择的类型对应的命名空间名称。',
  'app.service.mesh.helpid.Token': '当前选择的类型对应的令牌。',
  'app.service.mesh.helpid.DataCenter': '当前选择的类型对应的数据中心。',
  'app.service.mesh.helpid.Namespace': '当前选择的类型对应的命名空间。',
  'app.service.mesh.helpid.Authentication': '选择是否认证。',
  'app.service.mesh.helpid.KeyPrefix': '设置配置值的基础文件夹。',
  'app.service.mesh.helpid.ProfileSeparator':
    '设置分隔符的值，用于在有配置文件的属性源中分隔配置文件的名称。',
  'app.service.mesh.helpid.Profile': '当前选择的类型对应的档案。',
  'app.service.mesh.helpid.Operator': '当前选择的类型对应的操作人。',
  'app.service.mesh.helpid.DiscoveryType':
    'DiscoveryType配置服务注册/发现类型，其值在 "eureka"、"consul "和 "nacos "之间。',
  'app.service.mesh.helpid.Address': '地址是登记中心的地址，供工作量访问。',
  'app.service.mesh.helpid.IngressPort': '入站端口是入站流量的端口。',
  'app.service.mesh.helpid.IngressProtocol':
    'IngressProtocol是入口流量的协议。它的值是 "http"。',
  'app.service.mesh.helpid.EgressPort': 'EgressPort是用于出口流量的端口。',
  'app.service.mesh.helpid.EgressProtocol':
    'EgressProtocol是出口流量的协议。它的值是 "http"。',

  'app.service.mesh.helpid.SlidingWindowType':
    'SlidingWindowType是该断点的滑动窗口类型，只允许 "COUNT_BASED "或 "TIME_BASED"。',
  'app.service.mesh.helpid.FailureRateThreshold':
    '故障率阈值（FailureRateThreshold）配置故障率阈值的百分比。当故障率等于或大于阈值时，断路器转换为开路并开始短路调用。',
  'app.service.mesh.helpid.SlowCallRateThreshold':
    'SlowCallRateThreshold 配置一个百分比的阈值。当呼叫持续时间大于slowCallDurationThreshold时，断路器认为呼叫是慢速的，当慢速呼叫的百分比等于或大于阈值时，断路器过渡到开放并开始短路呼叫。',
  'app.service.mesh.helpid.SlidingWindowSize':
    'SlidingWindowSize（滑动窗口尺寸）配置滑动窗口的尺寸，当断路器关闭时，该窗口用于记录呼叫的结果。',
  'app.service.mesh.helpid.PermittedNumberOfCallsInHalfOpenState':
    'PermittedNumberOfCallsInHalfOpenState配置断路器半开时允许的呼叫数量。',
  'app.service.mesh.helpid.MinimumNumberOfCalls':
    'MinimumNumberOfCalls配置在CircuitBreaker计算错误率或慢速呼叫率之前需要的最小呼叫数（每个滑动窗口周期）。例如，如果minimumNumberOfCalls是10，那么在计算故障率之前，必须至少记录10次呼叫。如果只记录了9个呼叫，即使9个呼叫都失败了，断路器也不会过渡到开放。',
  'app.service.mesh.helpid.SlowCallDurationThreshold':
    'SlowCallDurationThreshold配置持续时间阈值，超过这个阈值的呼叫被认为是慢速，并增加慢速呼叫的速率。',
  'app.service.mesh.helpid.MaxWaitDurationInHalfOpenState':
    '半开状态下的最大等待时间（MaxWaitDurationInHalfOpenState）配置了一个最大的等待时间，控制断路器在半开状态下停留的最长时间，然后再切换到开状态。值为0意味着断路器将在半开状态下无限等待，直到所有允许的呼叫都完成。',
  'app.service.mesh.helpid.WaitDurationInOpenState':
    'WaitDurationInOpenState（在打开状态下的等待时间）配置断路器在从打开状态过渡到半打开状态之前应该等待的时间，例如，60000ms。',

  'app.service.mesh.helpid.MaxAttempts':
    'MaxAttempts配置了最大的尝试次数。(包括作为第一次尝试的初始呼叫)',
  'app.service.mesh.helpid.WaitDuration':
    'WaitDuration配置了重试之间的基于和固定的等待时间。',
  'app.service.mesh.helpid.BackOffPolicy':
    'BackOffPolicy配置了两种策略，随机和指数。',
  'app.service.mesh.helpid.RandomizationFactor':
    '随机化因子（RandomizationFactor）配置用于后退的因子，值在0和1之间。',

  'app.service.mesh.helpid.DefaultPolicyRef':
    'DefaultPolicyRef是默认的参考策略名称。',
  'app.service.mesh.helpid.TimeoutDuration':
    'TimeoutDuration是指一个请求应该等待一个权限的时间，例如，500ms。',
  'app.service.mesh.helpid.LimitRefreshPeriod':
    'LimitRefreshPeriod是一个极限刷新的周期。在每个周期之后，速率限制器将其权限计数设回limitForPeriod值。',
  'app.service.mesh.helpid.LimitForPeriod':
    'LimitForPeriod是指在一个限制刷新期间可用的权限数量。',
  'app.service.mesh.helpid.PolicyRef': 'PolicyRef配置这个URLRule参考的策略。',
  'app.service.mesh.helpid.Exact': '确切地配置要匹配的URL值。',
  'app.service.mesh.helpid.Prefix': '前缀配置URL的前缀，以便匹配。',
  'app.service.mesh.helpid.Regex':
    '正则表达式的语法是由https://pkg.go.dev/regexp 支持的。',

  'app.service.mesh.helpid.Timeout': '超时配置超时的持续时间，例如，500ms。',

  'app.service.mesh.helpid.Host': '主机是指RFC3986定义的主机名。',
  'app.service.mesh.helpid.Path':
    'Path是一个正则表达式，用于匹配目标HTTP URL。',
  'app.service.mesh.helpid.RewriteTarget':
    'RewriteTarget是一个正则表达式，当HTTP URL路径与Path字段匹配时，用于重写原始URL。',
  'app.service.mesh.helpid.Backend': 'Backend是网格服务的名称。',

  'app.service.mesh.helpid.SourceServices': '来源是允许出口流量的服务。',
  'app.service.mesh.helpid.DestinationService': '目的地是允许入口流量的服务。',
  'app.service.mesh.helpid.PathRegex':
    'PathRegex是一个定义路由的正则表达式。正则表达式的语法是由https://pkg.go.dev/regexp 支持的。',

  'app.service.mesh.helpid.Priority':
    '优先级必须是[1, 9]，如果用户没有设置，默认是5。数字越小，优先级越高。顺序是按名字的字母顺序排列的，优先级相同。',
  'app.service.mesh.helpid.CanaryVersion':
    '替换后，将用灰度版本替换原始镜像标签。',

  'app.service.mesh.helpid.TrafficHeaderValue':
    '添加标题X-Mesh-Shadow: {Traffic Header Value}，让流量通过影子服务。',

  NoServiceSelected: '没有服务被选中',
  NoChartsSelected: '没有图表被选中',

  TopSlowTraces: '最慢调用链',
  Trace: '跟踪',
  Start: '开始',
  Elapse: '延时',
  Show: '显示',
};

export default zhCN;
