const zhCN = {
  'global.edit': '编辑',
  'global.apply': '应用',
  'global.create': '创建',
  'global.update': '更新',
  'global.save': '保存',
  'global.submit': '提交',
  'global.return': '返回',
  'global.delete': '删除',
  'global.query': '查询',
  'global.advancedSearch': '高级搜索',
  'global.download': '下载',
  'global.open': '打开',
  'global.confirm': '确定',
  'global.confirm.tip': '确认提示',
  'global.search': '搜索',
  'global.cancel': '取消',
  'global.reset': '重置',
  'global.detail': '详情',
  'global.summary': '摘要',
  'global.settings': '设置',
  'global.close': '关闭',
  'global.image': '图片',
  'global.all': '全部',
  'global.loading': '加载中',
  'global.upload.image': '上传图片',
  'global.modify': '修改',
  'global.file.select': '选择文件',
  'global.file.select.empty': '未选择任何文件',
  'global.add': '添加',
  'global.key': '关键字',
  'global.value': '值',
  'global.action': '操作',
  'global.status': '状态',
  'global.process': '处理',
  'global.duplicate': '复制为',
  'global.copy': '复制',
  'global.yes': '是',
  'global.no': '否',
  'global.no-data': '暂无可用数据',
  'global.tips': '提示',
  'global.import': '导入',
  'global.export': '导出',
  'global.preview': '预览',
  'global.no-change': '数据无变化',

  'global.field.required': '{name}为必填项',
  'global.field.format.error': '{name}格式错误',
  'global.field.number': '{name}必须是数字',
  'global.field.number.integer': '{name}应是一个整数',
  'global.field.number.positive': '{name}应是一个正数',
  'global.field.number.natural': '{name}应是一个自然数',
  'global.field.number.min': '{name}最小为{min}',
  'global.field.number.max': '{name}最大为{max}',
  'global.field.string.min': '{name}至少应有{length}位',
  'global.field.string.max': '{name}最多应有{length}位',
  'global.field.telphone': '请输入手机号',

  'global.table.data.empty': '没有符合条件的数据',
  'global.table.data.loading': '数据正在加载中',

  'global.op.success': '数据操作成功',
  'global.op.fail': '数据操作失败',
  'global.confirm.delete.text': '确认删除此数据？',
  // https://en.wikipedia.org/wiki/ISO_4217[ISO 4217 - Wikipedia]
  'global.currency.cny.name': '人民币',
  'global.currency.cny.unit': '元',

  'global.confirm.warn': '警告',
  'global.info.empty': '没有信息!',

  'global.enable': '启用',
  'global.disable': '禁用',

  'global.config': '配置',
  'global.back': '后退',
  'global.next': '下一步',
  'global.more': '更多',
  'global.clone': '克隆',
  'global.contactUs': '联系我们',
  'global.setting': '设置',

  'global.defaultAccount': '默认账号',
  'global.defaultPassword': '默认密码',
  'global.manual': '用户手册',
  'global.learnMore': '了解更多',
};
export default zhCN;
