import _ from 'lodash';
export const traversalTree = (
  arr = [],
  extractFunc = (one) => {},
  processFunc = (one, parents) => {},
  parents = []
) => {
  if (arr.length < 1) {
    return;
  }
  arr.forEach((one) => {
    processFunc(one, parents);
    const children = extractFunc(one);
    if (_.isEmpty(children) || !_.isArray(children)) {
      return;
    }
    traversalTree(children, extractFunc, processFunc, [...parents, one]);
  });
};

export const depthFirstSearch = (
  arr = [],
  extractFunc = () => {},
  searchFunc = () => {},
  parents = []
) => {
  for (let i in arr) {
    const one = arr[i];
    const children = extractFunc(one);
    if (!_.isEmpty(children) && _.isArray(children)) {
      const rst = depthFirstSearch(children, extractFunc, searchFunc, [
        ...parents,
        one,
      ]);
      if (rst != null) {
        return rst;
      }
    } else if (searchFunc(one)) {
      return [...parents, one];
    }
  }
  return null;
};

export const isEmpty = (data) => {
  return !data ? true : data.length === 0;
};
//未完成
// export const breadthFirstSearch = (
//   arr = [],
//   extractFunc = () => {},
//   searchFunc = () => {},
//   parents = [],
// ) => {
//   arr.forEach((one) => {
//     if (searchFunc(one, parents)) {
//       return [...parents, one];
//     }
//     const children = extractFunc(one);
//     if (_.isEmpty(children) || !_.isArray(children)) {
//       return [];
//     }
//     breadthFirstSearch(children, extractFunc, searchFunc, [...parents, one]);
//   });
// };

export const deleteFor = (data, index) => {
  data.splice(index, 1);
};
export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
