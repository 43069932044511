import React from 'react';
import Retry from '@/app/service/mesh/Service/Governance/Retry';

const enUS = {
  'base.create': 'Create',
  'base.update': 'Update',
  'base.delete': 'Delete',
  'base.query': 'Query',
  'base.search': 'Search',
  'base.confirm': 'Confirm',
  'base.back': 'Back',
  'base.next': 'Next',
  'base.ok': 'OK',
  'base.save': 'Save',
  'base.save.and.close': 'Save and Close',
  'base.close': 'Close',
  'base.cancel': 'Cancel',
  'base.add': 'Add',
  'base.submit': 'Submit',
  'base.reset': 'Reset',
  'base.apply': 'Apply',
  'base.choose': 'Choose',
  'base.refresh': 'Refresh',
  'base.detail': 'Detail',

  circuitbreaker: 'Circuirbreaker',
  'circuitbreaker.enabled.only': 'Show Circuitbreaker-enabled Service Only',
  'circuitbreaker.system': 'System',
  'circuitbreaker.enabled': 'Enabled',
  'circuitbreaker.service': 'Service',
  'circuitbreaker.details.name': 'Circuirbreaker Name',
  'circuitbreaker.details.type': 'Sliding Window Type',
  'circuitbreaker.details.type.quantity': 'Count-based (calls)',
  'circuitbreaker.details.type.time': 'Time-based (seconds)',
  'circuitbreaker.details.window': 'Sliding Window',
  'circuitbreaker.details.halfopen': 'Permitted Calls in Half Open',
  'circuitbreaker.details.waitduration': 'Wait Duration in Open',
  'circuitbreaker.details.waitduration.helper':
    'Input integers ended with s or ms',
  'circuitbreaker.details.failureratethreshold': 'Failure Rate Threshold',
  'circuitbreaker.details.minimumnumberofcalls': 'Minimum Number of Calls',
  'circuitbreaker.details.failurehttpstatus': 'Failure HTTP Status Code',
  'circuitbreaker.details.failurehttpstatus.helper':
    'Use comma to seperate multiple codes',
  'circuitbreaker.details.onoff': 'Enabled?',
  'circuitbreaker.details.on': 'On',
  'circuitbreaker.details.off': 'Off',
  'circuitbreaker.details.op': 'Operation',
  'circuitbreaker.details.add': 'Add a New Circuitbreaker',
  'circuitbreaker.details.edit': 'Edit Circuitbreaker',
  'circuitbreaker.details.warning': 'Warning⚠️',
  'circuitbreaker.details.warning.helper':
    'Confirm to remove the Circuitbreaker?',
  'circuitbreaker.details.last.status': 'Last Status',
  'circuitbreaker.details.update.time': 'Update Time',
  'circuitbreaker.details.instance.ip': 'Instance IP',
  'circuitbreaker.details.status': 'Circuitbreaker Status',

  'head.logo.desc': 'Enterprise Cloud-Native Platform Provider',

  'sla.service.bar': 'Site SLA',
  'sla.throughput': 'Throughput',
  'sla.throughput.less': 'Less',
  'sla.throughput.heavy': 'Heavy',
  'sla.glance': 'System at Glance',
  'sla.glance.compared.with': 'Compared with',
  'sla.glance.compared.change_trend': 'change trend',
  'sla.glance.Instances': 'Instances',
  'sla.glance.Response_Times': 'Response Times',
  'sla.glance.Requests': 'Requests',
  'sla.glance.Error_Rate': 'Errors',
  'sla.glance.no_change': 'No change',
  'sla.glance.Min': 'Min',
  'sla.glance.Max': 'Max',
  'sla.glance.Avg': 'Avg',

  'sla.graph.site.diagram': 'Diagram',
  'sla.graph.site.through.m1.rate': 'Site Throughput M1 Rate',
  'sla.graph.site.through.err.rate': 'Site Throughput Errors',
  'sla.graph.site.200.response': 'The 200 Response of Each Service',
  'sla.graph.site.total.response': 'The Total Response of Each Service',
  'sla.graph.site.p99.response': 'The P99 Response Time of Each Service',
  'sla.graph.site.p75.response': 'The P75 Response Time',
  'sla.graph.service.through.m1.rate': 'Throughput M1 Rate',
  'sla.graph.service.through.err.rate': 'Throughput Errors',
  'sla.graph.service.200.response': 'The 200 Response',
  'sla.graph.service.total.response': 'The Total Response',
  'sla.graph.service.p99.response': 'The P99 Response Time',
  'sla.graph.service.p75.response': 'The P75 Response Time',

  'sla.graph.item.through.m1.rate.max': 'M1 Rate [Max]',
  'sla.graph.item.through.m1.rate.min': 'M1 Rate [Min]',
  'sla.graph.item.through.m1.rate.avg': 'M1 Rate [Avg]',

  'sla.graph.item.through.err.rate.max': 'Errors [Max]',
  'sla.graph.item.through.err.rate.min': 'Errors [Min]',
  'sla.graph.item.through.err.rate.avg': 'Errors [Avg]',

  'sla.graph.item.200.response.max': '200 [Max]',
  'sla.graph.item.200.response.min': '200 [Min]',
  'sla.graph.item.200.response.avg': '200 [Avg]',

  'sla.graph.item.total.response.max': 'total [Max]',
  'sla.graph.item.total.response.min': 'total [Min]',
  'sla.graph.item.total.response.avg': 'total [Avg]',

  'sla.graph.item.p99.duration.max': 'P99 Execution Duration [Max]',
  'sla.graph.item.p99.duration.min': 'P99 Execution Duration [Min]',
  'sla.graph.item.p99.duration.avg': 'P99 Execution Duration [Avg]',

  'sla.graph.item.p75.duration.max': 'P75 Execution Duration [Max]',
  'sla.graph.item.p75.duration.min': 'P75 Execution Duration [Min]',
  'sla.graph.item.p75.duration.avg': 'P75 Execution Duration [Avg]',

  'sla.glance.instance': 'Instance',
  'sla.glance.instances': 'Instances',
  'sla.glance.requests': 'Requests',
  'sla.glance.response_time': 'Response Time',
  'sla.glance.error_rate': 'Errors',
  'sla.glance.apdex_max': 'APDEX(Max)',
  'sla.glance.apdex_min': 'APDEX(Min)',
  'sla.glance.apdex_avg': 'APDEX(Avg)',

  'sla.events.service': 'Service Events',
  'sla.events.middleware': 'Middleware Events',
  'sla.events.detail': 'Detail',

  'app.service.config.ratelimiter.addRateLimiter': 'Create Index',
  'app.service.config.ratelimiter.editRateLimiter': 'Edit Index',
  'app.service.config.ratelimiter.label.name': 'Index Name',
  'app.service.config.ratelimiter.label.type': 'Type',
  'app.service.config.ratelimiter.label.limitRefreshPeriod':
    'Limit Refresh Period',
  'app.service.config.ratelimiter.label.limitForPeriod': 'Limit For Period',
  'app.service.config.ratelimiter.label.timeoutDuration': 'Timeout Duration',
  'app.service.config.ratelimiter.label.enabled': 'Enabled?',
  'app.service.config.ratelimiter.label.timeoutDuration.formatTips': `Enter an integer ending in 's' or 'ms'`,

  'app.service.config.retry.addRetry': 'Create Retry',
  'app.service.config.retry.editRetry': 'Edit Retry',
  'app.service.config.retry.label.name': 'Retry Name',
  'app.service.config.retry.label.maxAttempts': 'Max Retry Attempts',
  'app.service.config.retry.label.retryDelayPolicy': 'Retry Delay Policy',
  'app.service.config.retry.label.waitDuration':
    'Wait Duration Between Retry Attempts',
  'app.service.config.retry.label.waitDuration.formatTips': `Enter an integer ending in 's' or 'ms'`,
  'app.service.config.retry.label.enableExponentialBackoff':
    'Enable Exponential Backoff',
  'app.service.config.retry.label.exponentialBackoffMultiplier':
    'Exponential Backoff Multiplier',
  'app.service.config.retry.label.exponentialBackoffMultiplier.formatTips':
    'Enter a number greater than 1',
  'app.service.config.retry.label.enableRandomizeWait': 'Enable Randomize Wait',
  'app.service.config.retry.label.randomizeWaitFactor': 'Randomize Wait Factor',
  'app.service.config.retry.label.randomizeWaitFactor.formatTips':
    'Enter a decimal between 0 and 1',
  'app.service.config.retry.label.retryHttpStatus': 'Retry HTTP Codes',
  'app.service.config.retry.label.retryHttpStatus.formatTips':
    'Use commas to separate multiple values',
  'app.service.config.retry.label.enabled': 'Enable?',

  'app.service.show.title.instances': 'Instances',
  'app.service.show.title.configItems': 'Configurations',
  'app.service.show.title.description': 'Description',
  'app.service.show.title.labels': 'Tags',
  'app.service.show.title.labels.no-label-tips': 'No Labels',
  'app.service.show.title.metrics': 'Metrics',
  'app.service.show.title.tracing': 'Tracing',
  'app.service.show.title.faultTolerance': 'Fault Tolerance',
  'app.service.select.title.selectedServices': 'Selected Services',
  'app.service.select.title.selectedServices.empty-tips':
    'No selected services.',
  'app.service.select.btn.configure': 'Configure',
  'app.service.select.btn.select': 'Select',
  'app.service.select.btn.unselect': 'Unselect',

  'app.service.management.ServicesManagementPage.card-menu.instances':
    'Instances',
  'app.service.management.ServicesManagementPage.card-menu.configurations':
    'Configurations',

  'app.service.management.service.ServiceGovernanceTabs.Metrics': 'Metrics',
  'app.service.management.service.ServiceGovernanceTabs.Tracing': 'Tracing',
  'app.service.management.service.ServiceGovernanceTabs.CircuitBreaker':
    'Circuit Breaker',
  'app.service.management.service.ServiceGovernanceTabs.RateLimiter':
    'Rate Limiter',
  'app.service.management.service.ServiceGovernanceTabs.Retry': 'Retry',
  'app.service.management.service.ServiceGovernanceTabs.Canary': 'Canary',

  'app.service.management.service.governance.CircuitBreakerConfigPanel.Policy':
    'Policy',
  'app.service.management.service.governance.CircuitBreakerConfigPanel.Policies':
    'Policies',
  'app.service.management.service.governance.CircuitBreakerConfigPanel.Ctrl':
    'Ctrl',
  'app.service.management.service.governance.CircuitBreakerConfigPanel.PolicyRef':
    'Policy Ref',

  'app.service.management.service.governance.CanaryConfigPanel.Headers':
    'Headers',
  'app.service.management.service.governance.CanaryConfigPanel.URLs': 'URLs',
  'app.service.management.service.governance.CanaryConfigPanel.ServiceInstanceLabels':
    'Service Instance Labels',

  'app.service.management.service.governance.governance-utils.GlobalSwitch':
    'Global Switch',
  'app.service.management.service.governance.governance-utils.DefaultPolicy':
    'Default Policy',
  'app.service.management.service.governance.governance-utils.CreatePolicy':
    'Create Policy',
  'app.service.management.service.governance.governance-utils.CreateCtrl':
    'Create Ctrl',

  'app.service.management.registry.RegistryManagementPage.Registry': 'Registry',
  'app.service.management.registry.RegistryManagementPage.CreateRegistry':
    'Create Registry',
  'app.service.management.registry.RegistryManagementPage.Services': 'Services',
  'app.service.management.registry.RegistryManagementPage.Type': 'Type',
  'app.service.management.registry.RegistryManagementPage.Description':
    'Description',
  'app.service.management.registry.RegistryManagementPage.LatestFetch':
    'Latest Fetch',
  'app.service.management.registry.RegistryManagementPage.At': 'At ',
  'app.service.management.registry.RegistryManagementPage.Used': 'Used ',
  'app.service.management.registry.RegistryManagementPage.Inventory':
    'Inventory',

  'app.service.management.registry.RegistryInventoryPage.Service': 'Service',
  'app.service.management.registry.RegistryInventoryPage.InMaintenance':
    'In Maintenance',
  'app.service.management.registry.RegistryInventoryPage.Healthy': 'Healthy',

  'app.service.management.registry.ServiceInstanceTable.Raw': 'Raw',
  'app.service.management.registry.ServiceInstanceTable.Metadata': 'Metadata',
  'app.service.management.registry.ServiceInstanceTable.Host': 'Host',
  'app.service.management.registry.ServiceInstanceTable.Port': 'Port',
  'app.service.management.registry.ServiceInstanceTable.maintenance_mode':
    'Maintenance',
  'app.service.management.registry.ServiceInstanceTable.UpdatedAt': 'UpdatedAt',

  'app.service.management.configServer.ConfigServerPage.ConfigServer':
    'Config Server',
  'app.service.management.configServer.ConfigServerPage.CreateConfigServer':
    'Create Config Server',

  'app.service.management.ServiceEditForm.ServiceName': 'Service Name',
  'app.service.management.ServiceEditForm.Description': 'Description',
  'app.service.tags.add': 'Add Tag',
  'app.service.tags.edit': 'Edit Tag',
  'app.service.management.ServiceEditForm.tags': 'Tags',

  'app.service.apiManagement.ApiManagementPage.Reloading': 'Reloading',
  'app.service.apiManagement.ApiManagementPage.PageBaseUrl': 'PageBaseUrl',

  'app.service.monitor.dashboard.ServiceRelatedServicesPanel.JumpToUpstream':
    'Jump To Upstream Service',
  'app.service.monitor.dashboard.ServiceRelatedServicesPanel.JumpToDownstream':
    'Jump To Downstream Service',
  'app.service.monitor.dashboard.ServiceRelatedServicesPanel.UnableToJump':
    'Unable To Jump',

  'app.service._common.ClusterAutocomplete.ZoneCluster': 'Zone(Cluster)',

  'app.service.mesh.Cluster.index.Connect': 'Connect',
  'app.service.mesh.Cluster.index.ConnectCluster': 'Connect Cluster',
  'app.service.mesh.Cluster.index.ConnectZone': 'Connect Zone',
  'app.service.mesh.Cluster.index.ClusterForm': 'Cluster Form',
  'app.service.mesh.Cluster.index.Address': 'Address',
  'app.service.mesh.Cluster.index.RegistryType': 'Registry Type',
  'app.service.mesh.Cluster.index.Security': 'Security',
  'app.service.mesh.Cluster.index.Description': 'Description',

  'app.service.mesh.Tenant.index.TenantForm': 'Tenant Form',

  'app.service.management.Service.SelectSource': 'Select Source',
  'app.service.management.Service.Configuration': 'Configuration',
  'app.service.management.Service.Cluster': 'Cluster',
  'app.service.management.Service.Zone': 'Zone',
  'app.service.management.Service.Domain': 'Domain',
  'app.service.management.Service.Tenant': 'Tenant',
  'app.service.management.Service.Name': 'Name',
  'app.service.management.Service.Tags': 'Tags',
  'app.service.management.Service.Governance': 'Governance',
  'app.service.management.Service.Observability': 'Observability',

  'app.service.mesh.Service.index.NoServices': 'No Services',
  'app.service.mesh.Service.index.NoServicesDesc':
    'Please add at least one service before operation.',
  'app.service.mesh.Service.index.DeleteServicesTipIngress':
    'The service is being referenced by Ingress and cannot be deleted.',
  'app.service.mesh.Service.index.DeleteServicesTipTraffictarget':
    'The service is being referenced by Traffictarget and cannot be deleted.',
  'app.service.mesh.Service.index.DeleteServicesTipServiceCanary':
    'The service is being referenced by ServiceCanary and cannot be deleted.',
  'app.service.mesh.Service.index.DeleteServicesTipShadowService':
    'The service is being referenced by ShadowService and cannot be deleted.',
  'app.service.mesh.Service.index.Namespace': 'Namespace',
  'app.service.mesh.Service.index.NamespaceName': 'Namespace Name',
  'app.service.mesh.Service.index.CreateNamespace': 'Create Namespace',
  'app.service.mesh.Service.index.Namespaces': 'Namespaces',
  'app.service.mesh.Service.index.Services': 'Services',
  'app.service.mesh.Service.index.CreateService': 'Create Service',
  'app.service.mesh.Service.index.ServiceName': 'Service Name',
  'app.service.mesh.Service.index.PleaseCreateANamespaceFirst':
    'Please create a namespace first',
  'app.service.mesh.Service.index.YAML': 'YAML',
  'app.service.mesh.Service.index.Name': 'Name',
  'app.service.mesh.Service.index.APIAddress': 'API Address',
  'app.service.mesh.Service.index.Enviroment': 'Enviroment',
  'app.service.mesh.Service.index.ClusterName': 'Cluster Name',
  'app.service.mesh.Service.index.Token': 'Token',
  'app.service.mesh.Service.index.Operator': 'Operator',
  'app.service.mesh.Service.index.Value': 'Value',
  'app.service.mesh.Service.index.Instances': 'Instances',
  'app.service.mesh.Service.index.Mock': 'Mock',
  'app.service.mesh.Service.index.RateLimiter': 'Rate Limiter',
  'app.service.mesh.Service.index.CircuitBreaker': 'Circuit Breaker',
  'app.service.mesh.Service.index.Retryer': 'Retryer',
  'app.service.mesh.Service.index.TimeLimiter': 'Time Limiter',
  'app.service.mesh.Service.index.LoadBalance': 'Load Balance',

  'app.service.mesh.Service.index.DataCenter': 'Data Center',
  'app.service.mesh.Service.index.Authentication': 'Authentication',
  'app.service.mesh.Service.index.Prefix': 'Prefix',
  'app.service.mesh.Service.index.ProfileSeparator': 'Profile Separator',
  'app.service.mesh.Service.index.Profile': 'Profile',

  'app.service.mesh.Service.ServiceForm.FollowingConfigItemsOfService':
    'The following are all config items of service',
  'app.service.mesh.Service.ServiceForm.InConfigServer': 'in Config Server ',
  'app.service.mesh.Service.ServiceForm.UpdatedAt': 'updated at ',

  'app.service.mesh.Service.ServiceForm.MaxSamplingPerSecond':
    'Max Sampling / Second',

  'app.service.mesh.Service.ServiceForm.BasicInfos': 'Basic Infos',
  'app.service.mesh.Service.ServiceForm.RegistryName': 'Registry Name',
  'app.service.mesh.Service.ServiceForm.RegisterTenant': 'Register Tenant',

  'app.service.mesh.Service.ServiceForm.Mock': 'Mock',
  'app.service.mesh.Service.ServiceForm.Enabled': 'Enabled',
  'app.service.mesh.Service.ServiceForm.Rules': 'Rules',
  'app.service.mesh.Service.ServiceForm.Match': 'Match',
  'app.service.mesh.Service.ServiceForm.MatchAllHeaders': 'Match All Headers',
  'app.service.mesh.Service.ServiceForm.MatchHeaders': 'Match Headers',
  'app.service.mesh.Service.ServiceForm.Path': 'Path',
  'app.service.mesh.Service.ServiceForm.PathPrefix': 'PathPrefix',
  'app.service.mesh.Service.ServiceForm.Code': 'Code',
  'app.service.mesh.Service.ServiceForm.Headers': 'Headers',
  'app.service.mesh.Service.ServiceForm.Body': 'Body',
  'app.service.mesh.Service.ServiceForm.Delay': 'Delay',

  'app.service.mesh.Service.ServiceForm.FormChanged':
    'Record was modified but not submitted.',

  'app.service.mesh.Service.ServiceForm.Sidecar': 'Sidecar',
  'app.service.mesh.Service.ServiceForm.DiscoveryType': 'DiscoveryType',
  'app.service.mesh.Service.ServiceForm.Address': 'Address',
  'app.service.mesh.Service.ServiceForm.IngressPort': 'IngressPort',
  'app.service.mesh.Service.ServiceForm.IngressProtocol': 'IngressProtocol',
  'app.service.mesh.Service.ServiceForm.EgressPort': 'EgressPort',
  'app.service.mesh.Service.ServiceForm.EgressProtocol': 'EgressProtocol',

  'app.service.mesh.Service.ServiceForm.OutputServer': 'Output Server',
  'app.service.mesh.Service.ServiceForm.BootstrapServer': 'Bootstrap Server',
  'app.service.mesh.Service.ServiceForm.Timeout': 'Timeout',

  'app.service.mesh.Service.ServiceForm.Tracings': 'Tracings',
  'app.service.mesh.Service.ServiceForm.Topic': 'Topic',
  'app.service.mesh.Service.ServiceForm.SampleByQPS': 'Sample By QPS',

  'app.service.mesh.Service.ServiceForm.Output': 'Output',
  'app.service.mesh.Service.ServiceForm.MessageMaxBytes': 'Message Max Bytes',
  'app.service.mesh.Service.ServiceForm.ReportThread': 'Report Thread',
  'app.service.mesh.Service.ServiceForm.QueuedMaxSpans': 'Queued Max Spans',
  'app.service.mesh.Service.ServiceForm.QueuedMaxSize': 'Queued Max Size',
  'app.service.mesh.Service.ServiceForm.MessageTimeout': 'Message Timeout',

  'app.service.mesh.Service.ServiceForm.Request': 'Request',
  'app.service.mesh.Service.ServiceForm.JDBC': 'JDBC',
  'app.service.mesh.Service.ServiceForm.Redis': 'Redis',
  'app.service.mesh.Service.ServiceForm.RemoteInvoke': 'Remote Invoke',
  'app.service.mesh.Service.ServiceForm.Kafka': 'Kafka',
  'app.service.mesh.Service.ServiceForm.Rabbit': 'Rabbit',
  'app.service.mesh.Service.ServiceForm.ServicePrefix': 'Service Prefix',

  'app.service.mesh.Service.ServiceForm.Metrics': 'Metrics',
  'app.service.mesh.Service.ServiceForm.Access': 'Access',
  'app.service.mesh.Service.ServiceForm.JDBCStatement': 'JDBCStatement',
  'app.service.mesh.Service.ServiceForm.JDBCConnection': 'JDBCConnection',
  'app.service.mesh.Service.ServiceForm.JVMGC': 'JVMGC',
  'app.service.mesh.Service.ServiceForm.JVMMemory': 'JVMMemory',
  'app.service.mesh.Service.ServiceForm.MD5Dictionary': 'MD5Dictionary',
  'app.service.mesh.Service.ServiceForm.Interval': 'Interval',

  'app.service.mesh.Service.ServiceForm.CircuitBreaker': 'Circuit Breaker',
  'app.service.mesh.Service.ServiceForm.DefaultPolicyRef': 'Default Policy Ref',
  'app.service.mesh.Service.ServiceForm.CreatePolicy': 'Create Policy',
  'app.service.mesh.Service.ServiceForm.CreateCanary': 'Create Canary',
  'app.service.mesh.Service.ServiceForm.SlidingWindowType':
    'Sliding Window Type',
  'app.service.mesh.Service.ServiceForm.CreateUrl': 'Create Url',
  'app.service.mesh.Service.ServiceForm.Methods': 'Methods',
  'app.service.mesh.Service.ServiceForm.Exact': 'Exact',
  'app.service.mesh.Service.ServiceForm.Prefix': 'Prefix',
  'app.service.mesh.Service.ServiceForm.Regex': 'Regex',
  'app.service.mesh.Service.ServiceForm.PolicyRef': 'Policy Ref',
  'app.service.mesh.Service.ServiceForm.PolicyForm': 'Policy Form',
  'app.service.mesh.Service.ServiceForm.FailureRateThreshold':
    'Failure Rate Threshold',
  'app.service.mesh.Service.ServiceForm.SlowCallRateThreshold':
    'Slow Call Rate Threshold',
  'app.service.mesh.Service.ServiceForm.SlidingWindowSize':
    'Sliding Window Size',
  'app.service.mesh.Service.ServiceForm.PermittedNumberOfCallsInHalfOpenState':
    'Permitted Number Of Calls In Half Open State',
  'app.service.mesh.Service.ServiceForm.MinimumNumberOfCalls':
    'Minimum Number Of Calls',
  'app.service.mesh.Service.ServiceForm.SlowCallDurationThreshold':
    'Slow Call Duration Threshold',
  'app.service.mesh.Service.ServiceForm.MaxWaitDurationInHalfOpenState':
    'Max Wait Duration In Half Open State',
  'app.service.mesh.Service.ServiceForm.WaitDurationInOpenState':
    'Wait Duration In Open State',
  'app.service.mesh.Service.ServiceForm.UrlForm': 'Url Form',
  'app.service.mesh.Service.ServiceForm.FailureHttpStatusCode':
    'Failure Http Status Code',
  'app.service.mesh.Service.ServiceForm.FailureHttpStatusCode.help':
    'Use comma to seperate multiple codes',
  'app.service.mesh.Service.ServiceForm.RetryHttpStatusCode':
    'Retry Http Status Code',
  'app.service.mesh.Service.ServiceForm.Mode': 'Mode',

  'app.service.mesh.Service.ServiceForm.RateLimiter': 'Rate Limiter',
  'app.service.mesh.Service.ServiceForm.TimeoutDuration': 'Timeout Duration',
  'app.service.mesh.Service.ServiceForm.LimitRefreshPeriod':
    'Limit Refresh Period',
  'app.service.mesh.Service.ServiceForm.LimitForPeriod': 'Limit For Period',

  'app.service.mesh.Service.ServiceForm.Retryer': 'Retryer',
  'app.service.mesh.Service.ServiceForm.MaxAttempts': 'Max Attempts',
  'app.service.mesh.Service.ServiceForm.WaitDuration': 'Wait Duration',
  'app.service.mesh.Service.ServiceForm.BackOffPolicy': 'Back Off Policy',
  'app.service.mesh.Service.ServiceForm.RetryDelayPolicy': 'Retry Delay Policy',
  'app.service.mesh.Service.ServiceForm.ExponentialBackoffMultiplier':
    'Exponential Backoff Multiplier',
  'app.service.mesh.Service.ServiceForm.RandomizedWaitFactor':
    'Randomized Wait Factor',
  'app.service.mesh.Service.ServiceForm.RandomizationFactor':
    'Randomization Factor',
  'app.service.mesh.Service.ServiceForm.CountingNetworkError':
    'Counting Network Error',
  'app.service.mesh.Service.ServiceForm.FailureStatusCodes':
    'Failure Status Codes',
  'app.service.mesh.Service.ServiceForm.FailureCodes': 'Failure Codes',

  'app.service.mesh.Service.ServiceForm.TimeLimiter': 'Time Limiter',
  'app.service.mesh.Service.ServiceForm.DefaultTimeoutDuration':
    'Default Timeout Duration',

  'app.service.mesh.Service.ServiceForm.LoadBalance': 'Load Balance',
  'app.service.mesh.Service.ServiceForm.Policy': 'Policy',
  'app.service.mesh.Service.ServiceForm.HeaderHashKey': 'HeaderHashKey',

  'app.service.mesh.Service.Governance.CircuitBreaker.Warning':
    'We use the mature design from resilience4j, please refer to: ',

  'app.service.mesh.Service.Governance.Retry.Warning':
    'We use the mature design from resilience4j, please refer to: ',

  'app.service.mesh.Service.Governance.FailureCodes.Warning':
    'The Failure Codes is shared by Circuit Breaker and Retry, and empty means all codes of 5xx.',

  'app.service.mesh.Service.Governance.RateLimiter.WarningStart':
    'Left-side Policies window is to create limit policies which specify concrete limit policy, please refer to:',
  'app.service.mesh.Service.Governance.RateLimiter.WarningEnd':
    'Right-side URLs Window is to create different traffic groups referred to corresponding policies.Default Policy Ref and the value of the Policy Ref in the Url Form must exist in the Policies.',

  'app.service.mesh.Service.Instances.NotFoundInRegistry':
    'The application was not found in the registered registry',
  'app.service.mesh.Service.Instances.NotFoundInConfigurationCenter':
    'The application was not found in the registered configuration center',

  'app.service.mesh.Service.Instances.RegistryName': 'Registry Name',
  'app.service.mesh.Service.Instances.InstanceID': 'Instance ID',
  'app.service.mesh.Service.Instances.IP': 'IP',
  'app.service.mesh.Service.Instances.Port': 'Port',
  'app.service.mesh.Service.Instances.Labels': 'Labels',
  'app.service.mesh.Service.Instances.Status': 'Status',
  'app.service.mesh.Service.Instances.RegistryTime': 'Registry Time',

  'app.service.mesh.Service.Instances.Raw': 'Raw',
  'app.service.mesh.Service.Instances.Metadata': 'Metadata',

  'app.service.mesh.Tenant.index.CreateTenant': 'Create Tenant',
  'app.service.mesh.Tenant.index.CreateDomain': 'Create Domain',
  'app.service.mesh.Tenant.index.TenantName': 'Tenant Name',
  'app.service.mesh.Tenant.index.DomainName': 'Domain Name',
  'app.service.mesh.Tenant.index.DomainTenant': 'Domain(Tenant)',
  'app.service.mesh.Tenant.index.Description': 'Description',
  'app.service.mesh.Tenant.index.Services': 'Services',

  'app.service.mesh.Ingress.index.Ingress': 'Ingress',
  'app.service.mesh.Ingress.index.CreateIngress': 'Create Ingress',
  'app.service.mesh.Ingress.index.IngressName': 'Ingress Name',
  'app.service.mesh.Ingress.index.Rules': 'Rules',
  'app.service.mesh.Ingress.index.BackendServices': 'Backend Services',
  'app.service.mesh.Ingress.index.ServiceName': 'Service Name',

  'app.service.mesh.Ingress.IngressForm.RulesManagement': 'Rules Management',
  'app.service.mesh.Ingress.IngressForm.EditRule': 'Edit Rule',
  'app.service.mesh.Ingress.IngressForm.EditMatch': 'Edit Match',
  'app.service.mesh.Ingress.IngressForm.RuleItem': 'Rule Item',
  'app.service.mesh.Ingress.IngressForm.Host': 'Host',
  'app.service.mesh.Ingress.IngressForm.Paths': 'Paths',
  'app.service.mesh.Ingress.IngressForm.RewriteTarget': 'Rewrite Target',
  'app.service.mesh.Ingress.IngressForm.BackendService': 'Backend Service',
  'app.service.mesh.Ingress.IngressForm.Path': 'Path',
  'app.service.mesh.Ingress.IngressForm.ExactPath': 'Exact Path',
  'app.service.mesh.Ingress.IngressForm.AddPath': 'Add Path',
  'app.service.mesh.Ingress.IngressForm.Rule': 'Rule',
  'app.service.mesh.Ingress.IngressForm.Rules': 'Rules',
  'app.service.mesh.Ingress.IngressForm.AddRule': 'Add Rule',

  'app.service.mesh.TrafficControl.index.TrafficControl': 'Traffic Control',
  'app.service.mesh.TrafficControl.index.TrafficGroup': 'Traffic Group',
  'app.service.mesh.TrafficControl.index.TrafficGroups': 'Traffic Groups',
  'app.service.mesh.TrafficControl.index.CreateTrafficControl':
    'Create Traffic Control',
  'app.service.mesh.TrafficControl.index.CreateTrafficGroup':
    'Create Traffic Group',
  'app.service.mesh.TrafficControl.index.TrafficGroupManagement':
    'Traffic Group Management',
  'app.service.mesh.TrafficControl.index.RefreshTip':
    'Refresh to get the latest traffic groups',
  'app.service.mesh.TrafficControl.index.TrafficControlName':
    'Traffic Control Name',
  'app.service.mesh.TrafficControl.index.TrafficGroupName':
    'Traffic Group Name',
  'app.service.mesh.TrafficControl.index.SourceServices': 'Source Services',
  'app.service.mesh.TrafficControl.index.DestinationService':
    'Destination Service',
  'app.service.mesh.TrafficControl.index.ReferredByTrafficControls':
    'Referred by Traffic Controls',

  'app.service.mesh.TrafficControl.TrafficControlForm.GoToTrafficGroup':
    'Go to Traffic Group',
  'app.service.mesh.TrafficControl.TrafficGroupForm.Matches': 'Matches',
  'app.service.mesh.TrafficControl.TrafficControlForm.RuleAndGroupManagement':
    'Rule And Group Management',
  'app.service.mesh.TrafficControl.TrafficControlForm.RuleManagement':
    'Rule Management',
  'app.service.mesh.TrafficControl.TrafficControlForm.AddRule': 'Add Rule',
  'app.service.mesh.TrafficControl.TrafficControlForm.GroupName': 'Group Name',
  'app.service.mesh.TrafficControl.TrafficControlForm.GroupManagement':
    'Group Management',
  'app.service.mesh.TrafficControl.TrafficControlForm.AddGroup': 'Add Group',
  'app.service.mesh.TrafficControl.TrafficControlForm.RuleForm': 'Rule Form',
  'app.service.mesh.TrafficControl.TrafficControlForm.GroupForm': 'Group Form',
  'app.service.mesh.TrafficControl.TrafficControlForm.MatchManagement':
    'Match Management',
  'app.service.mesh.TrafficControl.TrafficControlForm.AddMatch': 'Add Match',
  'app.service.mesh.TrafficControl.TrafficControlForm.MatchForm': 'Match Form',
  'app.service.mesh.TrafficControl.TrafficControlForm.SourceServices':
    'Sources',
  'app.service.mesh.TrafficControl.TrafficControlForm.DestinationService':
    'Destination Service',
  'app.service.mesh.TrafficControl.TrafficControlForm.TrafficRules':
    'Traffic Rules',
  'app.service.mesh.TrafficControl.TrafficControlForm.Kind': 'Kind',
  'app.service.mesh.TrafficControl.TrafficControlForm.Matches': 'Matches',
  'app.service.mesh.TrafficControl.TrafficControlForm.HTTPRouteGroupManagement':
    'HTTP Route Group Management',
  'app.service.mesh.TrafficControl.TrafficControlForm.Group': 'Group',
  'app.service.mesh.TrafficControl.TrafficControlForm.EditGroup': 'Edit Group',
  'app.service.mesh.TrafficControl.TrafficControlForm.PathRegex': 'Path Regex',

  'app.service.mesh.TrafficControl.TrafficGroup.DeleteError':
    'The current record is referenced and cannot be deleted.',

  'app.service.mesh.GlobalConfig.index.Kind': 'Kind',
  'app.service.mesh.GlobalConfig.index.HeartbeatInterval': 'Heartbeat Interval',
  'app.service.mesh.GlobalConfig.index.RegistryType': 'Registry Type',
  'app.service.mesh.GlobalConfig.index.APIPort': 'API Port',
  'app.service.mesh.GlobalConfig.index.IngressPort': 'Ingress Port',
  'app.service.mesh.GlobalConfig.index.ExternalServiceRegistry':
    'External Service Registry',
  'app.service.mesh.GlobalConfig.index.CleanExternalRegistry':
    'Clean External Registry',
  'app.service.mesh.GlobalConfig.index.Security': 'Security',
  'app.service.mesh.GlobalConfig.index.MTLSMode': 'MTLS Mode',
  'app.service.mesh.GlobalConfig.index.CertProvider': 'Cert Provider',
  'app.service.mesh.GlobalConfig.index.RootCertTTL': 'Root Cert TTL',
  'app.service.mesh.GlobalConfig.index.AppCertTTL': 'App Cert TTL',

  'app.service.mesh.MultiCanaryRelease.index.CreateMultiCanaryRelease':
    'Create Multi Canary Release',
  'app.service.mesh.CanaryRelease.index.CanaryRelease': 'Canary Release',
  'app.service.mesh.CanaryRelease.index.CreateCanaryRelease':
    'Create Canary Release',
  'app.service.mesh.index.CreateAndEditWarning':
    'Since there are non-Stack created services, you cannot continue to create or view them, please dispose of the non-Stack created services first or switch the cluster and try again.',
  'app.service.mesh.index.SnapshotWarning':
    'The related stack is deleted, this record exists as a snapshot without operations.',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Rollout': 'Rollout',
  'app.service.mesh.MultiCanaryRelease.index.MultiCanaryReleaseName':
    'Multi Canary Release Name',
  'app.service.mesh.CanaryRelease.index.CanaryReleaseName':
    'Canary Release Name',
  'app.service.mesh.MultiCanaryRelease.index.ServiceNames': 'Service Names',
  'app.service.mesh.MultiCanaryRelease.index.InstanceLabels': 'Instance Labels',
  'app.service.mesh.MultiCanaryRelease.index.TrafficRules': 'Traffic Rules',

  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.AddLabel':
    'Add Label',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.Exact': 'Exact',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.Prefix': 'Prefix',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.RegEx': 'RegEx',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.Empty': 'Empty',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.AddHeader':
    'Add Header',
  'app.service.mesh.MultiCanaryRelease.MultiCanaryReleaseForm.Priority':
    'Priority',
  'app.service.mesh.CanaryRelease.index.MatchServices': 'Match Services',
  'app.service.mesh.CanaryRelease.index.Status': 'Status',
  'app.service.mesh.CanaryRelease.index.CloseTime': 'Close Time',

  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Canary': 'Canary',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Name': 'Name',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.ServiceName':
    'Service Name',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Services': 'Services',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Service': 'Service',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.ServicesDescription':
    'Select services that you want to canary',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.DockerImage':
    'Docker Image',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.CurrentVersion':
    'Current Version',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.CanaryVersion':
    'Canary Version',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.TrafficRules':
    'Traffic Rules',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Header': 'Header',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.ExXLocation':
    'Ex. X-Location',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Value': 'Value',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Exact': 'Exact',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Prefix': 'Prefix',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Regex': 'Regex',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.PriortyDescription':
    'High priorty will make canary recieve traffic in advanced',
  'app.service.mesh.CanaryRelease.CanaryReleaseForm.Preview': 'Preview',

  'app.service.mesh.ShadowService.index.ShadowService': 'Shadow Service',
  'app.service.mesh.ShadowService.index.CreateShadowService':
    'Create Shadow Service',
  'app.service.mesh.ShadowService.index.ShadowServiceName':
    'Shadow Service Name',
  'app.service.mesh.ShadowService.index.SourceServiceName':
    'Source Service Name',
  'app.service.mesh.ShadowService.index.TrafficHeaderValue':
    'Traffic Header Value',

  'app.service.mesh.ShadowService.ShadowServiceForm.TrafficHeaderValueError':
    'Under the same trafficHeaderValue, a service can only shadow once.',

  'app.service.mesh.ShadowService.ShadowServiceForm.Middleware': 'Middleware',
  'app.service.mesh.ShadowService.ShadowServiceForm.CanaryRules':
    'Canary Rules',
  'app.service.mesh.ShadowService.ShadowServiceForm.MiddlewareManagement':
    'Middleware Management',
  'app.service.mesh.ShadowService.ShadowServiceForm.EditMiddleware':
    'Edit Middleware',
  'app.service.mesh.ShadowService.ShadowServiceForm.Type': 'Type',
  'app.service.mesh.ShadowService.ShadowServiceForm.Uris': 'Uris',
  'app.service.mesh.ShadowService.ShadowServiceForm.Uris.help':
    'If you want to fill in more than one uri, please connect them with English commas',
  'app.service.mesh.ShadowService.ShadowServiceForm.YAML': 'YAML',
  'app.service.mesh.ShadowService.ShadowServiceForm.YAML.help':
    'Please organize the content in YAML format',
  'app.service.mesh.ShadowService.ShadowServiceForm.UserName': 'UserName',
  'app.service.mesh.ShadowService.ShadowServiceForm.Password': 'Password',

  'app.service.mesh.ShadowService.ShadowServiceForm.CanaryRuleManagement':
    'Canary Rule Management',
  'app.service.mesh.ShadowService.ShadowServiceForm.EditCanaryRule':
    'Edit Canary Rule',
  'app.service.mesh.ShadowService.ShadowServiceForm.UrlPrefix': 'Url Prefix',

  'app.service.mesh.ShadowService.ShadowServiceForm.Envs': 'Envs',
  'app.service.mesh.ShadowService.ShadowServiceForm.ConfigMaps': 'ConfigMaps',
  'app.service.mesh.ShadowService.ShadowServiceForm.Secrets': 'Secrets',

  'app.service.mesh.titleid': 'Description',

  'app.service.mesh.helpid.APIAddress':
    'The API address corresponding to the currently selected type.',
  'app.service.mesh.helpid.Enviroment':
    'The environment corresponding to the currently selected type.',
  'app.service.mesh.helpid.ClusterName':
    'The name of the cluster corresponding to the currently selected type.',
  'app.service.mesh.helpid.NamespaceName':
    'The namespace name corresponding to the currently selected type.',
  'app.service.mesh.helpid.Token':
    'The token corresponding to the currently selected type.',
  'app.service.mesh.helpid.DataCenter':
    'The data center corresponding to the currently selected type.',
  'app.service.mesh.helpid.Namespace':
    'The namespace corresponding to the currently selected type.',
  'app.service.mesh.helpid.Authentication': 'Select whether to certify.',
  'app.service.mesh.helpid.KeyPrefix':
    'Sets the base folder for configuration values.',
  'app.service.mesh.helpid.ProfileSeparator':
    'Sets the value of the separator used to separate the profile name in property sources with profiles.',
  'app.service.mesh.helpid.Profile':
    'The profile corresponding to the currently selected type.',
  'app.service.mesh.helpid.Operator':
    'The operator corresponding to the currently selected type.',
  'app.service.mesh.helpid.DiscoveryType':
    'DiscoveryType configures the type of service register/discovery type, its value are among "eureka","consul", and "nacos".',
  'app.service.mesh.helpid.Address':
    'Address is the registry center address for workload to visit.',
  'app.service.mesh.helpid.IngressPort':
    'IngressPort is the port for ingress traffic.',
  'app.service.mesh.helpid.IngressProtocol':
    'IngressProtocol is the protocol for ingress traffic. Its value is "http".',
  'app.service.mesh.helpid.EgressPort':
    'EgressPort is the port for egress traffic.',
  'app.service.mesh.helpid.EgressProtocol':
    'EgressProtocol is the protocol for egress traffic. Its value is "http"',

  'app.service.mesh.helpid.SlidingWindowType':
    'SlidingWindowType is the sliding window type of this break, only "COUNT_BASED" or "TIME_BASED" allowed.',
  'app.service.mesh.helpid.FailureRateThreshold':
    'FailureRateThreshold configures the failure rate threshold in percentage. When the failure rate is equal or greater than the threshold the CircuitBreaker transitions to open and starts short-circuiting calls.',
  'app.service.mesh.helpid.SlowCallRateThreshold':
    'SlowCallRateThreshold Configures a threshold in percentage. The CircuitBreaker considers a call as slow when the call duration is greater than slowCallDurationThreshold When the percentage of slow calls is equal or greater the threshold, the CircuitBreaker transitions to open and starts short-circuiting calls.',
  'app.service.mesh.helpid.SlidingWindowSize':
    'SlidingWindowSize configures the size of the sliding window which is used to record the outcome of calls when the CircuitBreaker is closed.',
  'app.service.mesh.helpid.PermittedNumberOfCallsInHalfOpenState':
    'PermittedNumberOfCallsInHalfOpenState configures the number of permitted calls when the CircuitBreaker is half open.',
  'app.service.mesh.helpid.MinimumNumberOfCalls':
    'MinimumNumberOfCalls configures the minimum number of calls which are required (per sliding window period) before the CircuitBreaker can calculate the error rate or slow call rate. For example, if minimumNumberOfCalls is 10, then at least 10 calls must be recorded, before the failure rate can be calculated. If only 9 calls have been recorded the CircuitBreaker will not transition to open even if all 9 calls have failed.',
  'app.service.mesh.helpid.SlowCallDurationThreshold':
    'SlowCallDurationThreshold configures the duration threshold above which calls are considered as slow and increase the rate of slow calls.',
  'app.service.mesh.helpid.MaxWaitDurationInHalfOpenState':
    'MaxWaitDurationInHalfOpenState configures a maximum wait duration which controls the longest amount of time a CircuitBreaker could stay in Half Open state, before it switches to open. Value 0 means Circuit Breaker would wait infinitely in HalfOpen State until all permitted calls have been completed.',
  'app.service.mesh.helpid.WaitDurationInOpenState':
    'WaitDurationInOpenState configures the duration that the CircuitBreaker should wait before transitioning from open to half-open,e.g.,60000ms.',

  'app.service.mesh.helpid.MaxAttempts':
    'MaxAttempts configures the maximum number of attempts. (including the initial call as the first attempt)',
  'app.service.mesh.helpid.WaitDuration':
    'WaitDuration configures the based and fixed wait duration between retry attempts.',
  'app.service.mesh.helpid.BackOffPolicy':
    'BackOffPolicy configures the two kinds of policy, random and exponential.',
  'app.service.mesh.helpid.RandomizationFactor':
    'RandomizationFactor configures the factor used for backoff, value between 0 and 1.',

  'app.service.mesh.helpid.DefaultPolicyRef':
    'DefaultPolicyRef is the default reference policy name.',
  'app.service.mesh.helpid.TimeoutDuration':
    'TimeoutDuration is the duration for one request should wait for a permission,e.g.,500ms.',
  'app.service.mesh.helpid.LimitRefreshPeriod':
    'LimitRefreshPeriod is the period of a limit refresh. After each period the rate limiter sets its permissions count back to the limitForPeriod value.',
  'app.service.mesh.helpid.LimitForPeriod':
    'LimitForPeriod is the number of permissions available during one limit refresh period.',
  'app.service.mesh.helpid.PolicyRef':
    'PolicyRef configures which policy this URLRule references to.',
  'app.service.mesh.helpid.Exact':
    'Exact configures the exactly URL value to match.',
  'app.service.mesh.helpid.Prefix':
    'Prefix configures the prefix for URL to match.',
  'app.service.mesh.helpid.Regex':
    'The grammar of Regular Expression is supported by https://pkg.go.dev/regexp.',

  'app.service.mesh.helpid.Timeout':
    'Timeout configures the duration for timeout, e.g.,500ms.',

  'app.service.mesh.helpid.Host': 'Host is the RFC3986 defined host name.',
  'app.service.mesh.helpid.Path':
    'Path is a regular expression for matching the target HTTP URL.',
  'app.service.mesh.helpid.RewriteTarget':
    'RewriteTarget is a regular expression for rewriting original URL when the HTTP URL path match the Path field.',
  'app.service.mesh.helpid.Backend': "Backend is the mesh service's name.",

  'app.service.mesh.helpid.SourceServices':
    'Sources are the services to allow egress traffic.',
  'app.service.mesh.helpid.DestinationService':
    'Destination is the service to allow ingress traffic.',
  'app.service.mesh.helpid.PathRegex':
    'PathRegex is a regular expression defining the route.The grammar of Regular Expression is supported by https://pkg.go.dev/regexp.',

  'app.service.mesh.helpid.Priority':
    'Priority must be [1, 9], the default is 5 if user does not set it. The smaller number get higher priority. The order is sorted by name alphabetically in the same priority.',
  'app.service.mesh.helpid.CanaryVersion':
    'Rollout will replace the original image tag with the canary version.',

  'app.service.mesh.helpid.TrafficHeaderValue':
    'Add header X-Mesh-Shadow: {Traffic Header Value} to let traffic through the shadow service.',

  NoServiceSelected: 'No service selected',
  NoChartsSelected: 'No charts selected',

  TopSlowTraces: 'Top Slow Traces',
  Trace: 'Trace',
  Start: 'Start',
  Elapse: 'Elapse',
  Show: 'Show',
};

export default enUS;
