import { getTenantId } from '@/app/_common/utils/utils';
import axios from '@/_common/utils/axios-wrapper';
import settings from '@/_common/utils/settings';

class TenantApi {
  //user
  createUser(data) {
    return axios.post(settings.EASE_TENANT_MGMT + '/users', data);
  }

  registerUser(data) {
    return axios.post(settings.EASE_TENANT_MGMT + '/users/register', data);
  }

  updateUser(data) {
    return axios.put(settings.EASE_TENANT_MGMT + '/users', data);
  }

  verificationCodes(data) {
    return axios.post(
      settings.EASE_TENANT_MGMT + '/users/verification-codes',
      data
    );
  }

  //Tenant
  createTenant(data) {
    return axios.post(settings.EASE_TENANT_MGMT + '/tenants', data);
  }

  updateTenant(id, data) {
    return axios.put(settings.EASE_TENANT_MGMT + '/tenants/' + id, data);
  }

  getTenants(params) {
    return axios.get(settings.EASE_TENANT_MGMT + '/tenants', {
      params: {
        pageSize: 20,
        ...params,
      },
    });
  }

  getTenantListUsingGET(params) {
    return axios.get(settings.EASE_TENANT_MGMT + '/tenants', {
      params: {
        ...params,
      },
    });
  }

  updateTenantStatus(id, data) {
    return axios.put(settings.EASE_TENANT_MGMT + `/tenants/${id}/status`, data);
  }
  getTenantById(id) {
    return axios.get(settings.EASE_TENANT_MGMT + `/tenants/${id}`);
  }

  //Team

  createTeam(data) {
    return axios.post(settings.EASE_TENANT_MGMT + '/teams', data);
  }

  updateTeam(id, data) {
    return axios.put(settings.EASE_TENANT_MGMT + '/teams/' + id, data);
  }

  getTeams(params) {
    return axios.get(settings.EASE_TENANT_MGMT + '/teams/', { params });
  }

  getTeam(id, params) {
    return axios.get(settings.EASE_TENANT_MGMT + '/teams/' + id, {
      params,
    });
  }

  deleteTeam(id) {
    return axios.delete(settings.EASE_TENANT_MGMT + '/teams/' + id);
  }

  //members
  invitationMember(data) {
    return axios.post(
      settings.EASE_TENANT_MGMT + '/tenant-members/invitations',
      data
    );
  }

  getMembers(params) {
    return axios.get(settings.EASE_TENANT_MGMT + '/tenant-members', { params });
  }

  getInvitationMembers(params) {
    return axios.get(
      settings.EASE_TENANT_MGMT + '/tenant-members/invitations',
      { params }
    );
  }

  deleteMember(id) {
    return axios.delete(settings.EASE_TENANT_MGMT + '/tenant-members/' + id);
  }
  acceptInvitation(data) {
    return axios.post(
      settings.EASE_TENANT_MGMT + '/tenant-members/invitations/accept',
      data,
      { autoError: false }
    );
  }
  deletePendingInvitation(id) {
    return axios.delete(
      settings.EASE_TENANT_MGMT + '/tenant-members/invitations/' + id
    );
  }
  addTeamMembers(data) {
    return axios.post(settings.EASE_TENANT_MGMT + '/tenant-members', data, {
      autoError: false,
    });
  }
  getTenantUsers(params: { exceptTeamId: string }) {
    return axios.get(settings.EASE_TENANT_MGMT + '/users/tenant-users', {
      params,
    });
  }

  getLoginStatistics(tenantId) {
    return axios.get(
      settings.EASE_TENANT_MGMT + '/tenant-login-statistics/' + tenantId,
      {
        autoError: false,
      }
    );
  }
  getTenantStatistics(tenantId) {
    return axios.get(
      settings.EASE_MIDDLEWARE_MGMT + '/tenant-statistics/' + tenantId,
      {
        autoError: false,
      }
    );
  }
}

export default new TenantApi();
