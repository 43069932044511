function randomString(length) {
  const loop = Math.ceil(length / 10);
  const result = [];
  for (let i = 0; i < loop; i++) {
    result.push(Math.random().toString(36).slice(2));
  }
  return result.join('').substring(0, length);
}

export default randomString;
