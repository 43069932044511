import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiButton-textSecondary.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
  },
}));
export default function TextButton({
  onClick,
  text,
  color = 'secondary',
  disabled = false,
  ...rest
}) {
  const cs = useStyles();
  return (
    <MuiButton
      color={color}
      onClick={onClick}
      disabled={disabled}
      className={cs.button}
      {...rest}
    >
      {text}
    </MuiButton>
  );
}
