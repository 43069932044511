export default {
  'dashboard.customized.panel.groupBy.service': '按服务分组',
  'dashboard.customized.panel.groupBy.instance': '按实例分组',
  'tracing.list.query.status.code.escape.info': '已经转义成英文字符串“normal”',
  'tracing.list.query.status.code.info':
    'MegaEase定义的状态码范围：200～299 作为“正常的状态”. 当状态码输入该取值范围时，会自动转换成英文字符串“normal”进行数据查询.',
  'access.log.details.title': '访问日志详情',

  'app.service.topn.table.label.service': '服务',
  'app.service.topn.table.label.instance': '实例',
  'app.service.topn.table.label.hostName': '主机名',
  'app.service.topn.table.label.sortValue': '排序值',
  'app.service.topn.table.label.percent': '百分比',
  'app.service.topn.table.label.method': '方法',

  'app.service.topn.sort.options.highThroughput': '高吞吐',
  'app.service.topn.sort.options.errors': '错误',
  'app.service.topn.sort.options.slowResponse': '慢响应',
  'app.service.topn.sort.options.gcHighCount': '垃圾回收次数多',
  'app.service.topn.sort.options.gcTimeLongest': '垃圾回收时间长',
  'app.service.topn.sort.options.memoryHighUsed': '内存使用多',

  'app.alert.rules.submit': '提交',
  'app.alert.rules.return': '返回',
  'app.alert.rules.confirm': '确定',
  'app.alert.rules.cancel': '取消',
  'app.alert.rules.tips': '提示',
  'app.alert.rules.tips.return.desc':
    '确定要放弃当前编辑内容并返回警告规则页面？',
  'app.alert.rules.tips.confirm.desc': '确定要提交当前数据吗？',

  'app.alert.rules.status': '规则状态',
  'app.alert.rules.status.running': '运行',
  'app.alert.rules.status.stopped': '停用',
  'app.alert.rules.name': '规则名称',
  'app.alert.rules.type': '类别',
  'app.alert.rules.level': '级别',
  'app.alert.rules.service': '指定服务',
  'app.alert.rules.desc': '规则说明',
  'app.alert.rules.notification': '通知',
  'app.alert.rules.metricsSetting': '指标设置',
  'app.alert.rules.LogMatch': '日志匹配',
  'app.alert.rules.Prefix': '前缀',
  'app.alert.rules.Suffix': '后缀',
  'app.alert.rules.MatchType': '匹配类型',
  'app.alert.rules.Text': '文本',
  'app.alert.rules.RegularExpression': '正则表达式',

  'app.alert.rules.metrics': '指标',
  'app.alert.rules.log': '日志',
  'app.alert.rules.metricsName': '指标名',
  'app.alert.rules.function': '函数',
  'app.alert.rules.groupBy': '分组条件',
  'app.alert.rules.predicate': '操作符',
  'app.alert.rules.threshold': '阈值',
  'app.alert.rules.count': '发生次数',
  'app.alert.rules.duration': '持续时长',
  'app.alert.rules.addExtension': '增加扩展',
  'app.alert.rules.re': '告警关键字正则表达式',
  'app.alert.rules.promql': 'PromQL 表达式',
  'app.alert.service.rules.binding': '服务规则绑定',

  'app.alert.service.rules.AlertRuleName': '告警规则名称',
  'app.alert.service.rules.CreateAlertRule': '创建告警规则',
  'app.alert.service.rules.Name': '名称',
  'app.alert.service.rules.Level': '级别',
  'app.alert.service.rules.Status': '状态',

  'app.alert.service.rules.Examples': '示例集',
  'app.alert.service.rules.Builder': '构造规则',
  'app.alert.service.rules.Functions': '函数集',
  'app.alert.service.rules.Grammar': '语法',
  'app.alert.service.rules.helpid.Grammar':
    '[QUERYING PROMETHEUS](https://prometheus.io/docs/prometheus/latest/querying/basics/)',
  'app.alert.service.rules.ReplaceParameters': '替换下方表达式中的参数',

  'app.easeMonitor.logs.access.AccessLogPage.Category': '类别',
  'app.easeMonitor.logs.access.AccessLogPage.ServicePrefix': '服务前缀',
  'app.easeMonitor.logs.access.AccessLogPage.TraceId': '跟踪Id',
  'app.easeMonitor.logs.access.AccessLogPage.SpanId': '跨度Id',
  'app.easeMonitor.logs.access.AccessLogPage.ParentSpanId': '父级跨度Id',
  'app.easeMonitor.logs.access.AccessLogPage.ClientIp': '客户端Ip',
  'app.easeMonitor.logs.access.AccessLogPage.HostIpv4': '主机Ipv4',
  'app.easeMonitor.logs.access.AccessLogPage.HostNamePrefix': '主机名前缀',
  'app.easeMonitor.logs.access.AccessLogPage.URLMethod': 'URL方法',
  'app.easeMonitor.logs.access.AccessLogPage.URLKeyword': 'URL关键词',
  'app.easeMonitor.logs.access.AccessLogPage.RequestTimeGt': '请求时间大于',
  'app.easeMonitor.logs.access.AccessLogPage.RequestTimeLt': '请求时间小于',
  'app.easeMonitor.logs.access.AccessLogPage.ResponseSizeGt': '请求大小大于',
  'app.easeMonitor.logs.access.AccessLogPage.ResponseSizeLt': '请求大小小于',

  'app.easeMonitor.eventAlerts.alertRules.edit.AlertRuleEditStepper.RuleProperties':
    '规则属性',
  'app.easeMonitor.eventAlerts.alertRules.edit.AlertRuleEditStepper.RuleDetails':
    '规则详情',
  'app.easeMonitor.eventAlerts.alertRules.edit.AlertRuleEditStepper.PreviewAndConfirm':
    '预览和确认',

  'app.easeMonitor.eventAlerts.alertRules.edit.RegexConfigForm.AlarmKeywordRegularExpression':
    '告警关键字正则表达式',

  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.ScheduleSettings':
    '调度设置',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.HasBeenSet':
    '已设定',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.NotSet':
    '未设定',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.RuleName':
    '规则名称',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.Level': '级别',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.RuleStatus':
    '规则状态',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.NotTakeEffect':
    '不生效',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.TakeEffect':
    '生效',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.Category':
    '类别',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.Metric':
    '指标',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.Log': '日志',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.TakeEffectService':
    '生效服务',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.AlarmCycle':
    '报警周期',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.RuleDesc':
    '规则说明',

  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.MetricName':
    '指标名',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.Operator':
    '操作符',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.Threshold':
    '阈值',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.OccurrenceNumber':
    '发生次数',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.Duration':
    '持续时长',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.Next': '下一个',

  'app.easeMonitor.eventAlerts.alertRules.edit.ScheduleSettingPanel.StartTime':
    '开始时间',
  'app.easeMonitor.eventAlerts.alertRules.edit.ScheduleSettingPanel.EndTime':
    '结束时间',
  'app.easeMonitor.eventAlerts.alertRules.edit.ScheduleSettingPanel.Weekly':
    '每周',
  'app.easeMonitor.eventAlerts.alertRules.edit.ScheduleSettingPanel.Monthly':
    '每月',

  'app.easeMonitor.eventAlerts.alertRules.AlertRulesDetailPanel.Types': '类型',
  'app.easeMonitor.eventAlerts.alertRules.AlertRulesDetailPanel.SpecifiedServices':
    '指定服务',
  'app.easeMonitor.eventAlerts.alertRules.AlertRulesDetailPanel.MetricSettings':
    '指标设置',
  'app.easeMonitor.eventAlerts.alertRules.AlertRulesDetailPanel.AlertRules':
    '告警规则',

  'app.easeMonitor.eventAlerts.hotEvents.HotEventsPage.LastOccurrenceTime':
    '最近发生时间',
  'app.easeMonitor.eventAlerts.hotEvents.HotEventsPage.LastOccurrenceService':
    '最近发生服务',
  'app.easeMonitor.eventAlerts.hotEvents.HotEventsPage.LastOccurrenceInstance':
    '最近发生实例',

  'app.easeMonitor.tracing.zipkin-lens.components.TracePage.TraceSummaryHeader.enterFullscreen':
    '进入全屏',
  'app.easeMonitor.tracing.zipkin-lens.components.TracePage.TraceSummaryHeader.exitFullscreen':
    '退出全屏',

  'app.easeMonitor.logs.application.ApplicationLogPage.Service': '服务',
  'app.easeMonitor.logs.application.ApplicationLogPage.Level': '日志级别',
  'app.easeMonitor.logs.application.ApplicationLogPage.TraceId': '跟踪ID',

  'app.easeMonitor.topology.TopoGraph.InputServiceThenEnter':
    '输入服务名然后按回车键',
  'app.easeMonitor.topology.TopoGraph.InputNameThenEnter':
    '输入名称然后按回车键',

  TraceID: '追踪ID',
  Method: '方法',
  Url: '地址',
  StatusCode: '状态码',
  SqlMd5: 'Sql Md5',
  JdbcUrl: 'Jdbc地址',
  RedisMethod: 'Redis方法',
  RedisKey: 'Redis关键词',
  KafkaTopic: 'Kafka主题',
  RabbitmqQueue: 'Rabbitmq队列',
  RabbitmqRouteKey: 'Rabbitmq路由关键词',
  MaxCount: '最大数量',

  Duration: '持续时间',
  Services: '服务集',
  Depth: '深度',
  TotalSpans: '总跨度',
  Span: '跨度',
  Map: '拓扑图',
  Logs: '日志集',
  Request: '请求',
  Cache: '缓存',
  Resource: '资源',

  SpanId: '跨度Id',
  ParentId: '父级Id',
  SpanName: '跨度名称',
  ServiceName: '服务名称',
  Timestamp: '时间戳',
  Tags: '标签',
  Annotations: '注解',
  HideAnnotations: '隐藏注解',
  ShowAllAnnotations: '显示所有的注解',

  StartTime: '开始时间',
  RelativeTime: '相对时间',
  Address: '地址',
  Service: '服务',
  Instance: '实例',
  Time: '时间',
  NoTimestampInCurrentSpan: '当前跨度中没有时间戳',
  GoToLogs: '跳转到日志集',

  JVMMemoryUtilizationAvg: 'JVM内存利用率[平均]',
  JVMGCCountChangeRatio: 'JVM垃圾回收计数变化[比率]',
  JVMGCTimeChangeRatio: 'JVM垃圾回收时间变化[比率]',

  RequestThroughtout: '请求吞吐量',
  APIResponseCodes: 'API响应码',
  RequestExecutionTime: '请求执行时间',

  JDBCRequestThroughtout: 'JDBC请求吞吐量',
  JDBCRequestErrorRate: 'JDBC请求错误率',
  JDBCRequestExecutionTime: 'JDBC请求执行时间',

  CacheKey: '缓存关键词',

  RedisClientThroughtout: 'Redis客户端吞吐量',
  RedisClientExecutionTime: 'Redis客户端执行时间',

  CPUUtilization: 'CPU利用率',
  Memory: '内存',
  Network: '网络',
  Disk: '硬盘',

  ThroughputRatio: '吞吐率',
  APIResponseCode: 'API响应码',
  ExecutionsMilliseconds: '执行毫秒',
  ExecutionsErrorRatio: '执行错误率',
  ErrorRatio: '错误率',

  ExchangeRoutingKeyThroughputRatio: '交换-路由密钥的吞吐量比率',
  ExchangeRoutingKeyExecutionsMilliseconds: '交换-路由密钥的执行毫秒',
  QueueThroughputRatio: '队列吞吐率',
  QueueExecutionsMilliseconds: '队列执行毫秒',

  JVMGCCountRatio: 'JVM垃圾回收计数[比率]',
  JVMGCTimeRatio: 'JVM垃圾回收时间[比率]',
  JVMUsedMemoryAvg: 'JVM内存使用量[平均]',
  JVMInitialMemoryAvg: 'JVM初始内存大小[平均]',

  ExchangeRoutingKey: '交换-路由密钥',
  Queue: '队列',
  Topic: '主题',

  SlowQuery: '慢查询',
  InnoDBData: 'InnoDB数据',
  AbortedAndLock: '中止并锁定',
  Connection: '连接',
  LockingRate: '锁定率',
  NetworkTraffic: '网络流量',

  HostCPUUtilization: '主机CPU利用率',
  PodCPUUsage: 'Pod的CPU使用率',
  HostMemory: '主机内存',
  PodMemoryUsage: 'Pod的内存使用率',
  HostDiskRead: '主机磁盘读取',
  HostDiskWrite: '主机磁盘写入',
  HostNetRecv: '主机网络接收',
  HostNetSent: '主机网络发送',
  HostDisk: '主机磁盘',
  HostDiskTotal: '主机磁盘总量',
  HostDiskUsed: '主机磁盘使用量',
  HostDiskIO: '主机磁盘输入输出',
  HostNetErrIn: '主机网络传入错误',
  HostNetErrOut: '主机网络传出错误',
  HostSystemLoad: '主机系统加载',

  HitRate: '命中率',
  MemFragmentationRatio: '内存碎片率',
  CommandsProcessed: '已处理的命令',
  Connections: '连接',
  ConnectedSlaves: '连接的副本数量',

  ConsumerAndLag: '消费者和滞后性',
  TopicPartitionOffset: '主题分区偏移',
  ConsumerGroupLag: '消费者群体滞后',
  ConsumerGroupPartitionCount: '消费者群体分区计数',
  AllTopics: '所有主题',
  AllTopicsMessagesInM1Rate: '所有主题信息在M1速率中',
  AllTopicsBytesInM1Rate: '所有主题字节在M1速率中',
  AllTopicsBytesOutM1Rate: '所有主题字节输出M1速率',
  AllTopicsProduceRequestsPerSec: '所有主题每秒钟产生的请求',
  AllTopicsProduceMessageConversionsPerSec: '所有主题每秒钟产生的信息转换量',
  PerTopic: '每个主题',
  PerTopicMessagesInM1Rate: '每个主题的信息在M1中的比率',
  PerTopicBytesInM1Rate: '每一主题的字节数在M1速率中',
  PerTopicBytesOutM1Rate: '每个主题的字节输出M1速率',
  PerTopicTotalProduceRequestsPerSec: '每一主题每秒的生产请求总数',
  ReplicaManager: '副本管理器',
  ReplicaManagerUnderReplicatedPartitions: '副本分区下的副本管理器',
  ReplicaManagerOfflineReplicaCount: '副本管理器离线副本计数',
  ReplicaManagerLeaderCount: '副本管理器领导者计数',
  ReplicaManagerPartitionCount: '副本管理器分区计数',
  Brokers: '代理集',
  LeaderElectionRateAndTime: '领导人选举率和时间',
  UncleanLeaderElectionsPerSec: '每秒不合格领导人的选举',
  ActiveControllerCount: '活跃的控制器数量',
  GlobalTopicCount: '全局主题计数',
  GlobalPartitionCount: '全局分区计数',
  JavaCpuUseParSecond: 'Java Cpu使用量每秒',
  JavaMemoryPool: 'Java内存池',
  JavaThreadCount: 'Java线程计数',
  JavaGCCount: 'Java垃圾回收计数',
  JavaGCTime: 'Java垃圾回收时间',
  Host: '主机',
  ConsumerGroup: '消费者组',

  GlobalCounts: '全局计数',
  QueuedMessages: '排队的消息',
  Messages: '消息集',
  Exchange: '交换',
  FileDescriptors: '文件描述符',
  Processes: '进程集',

  RequestsRatio: '请求集(率)',
  ConnectionsRatio: '连接数(率)',

  SearchQuery: '搜索查询',
  SearchCount: '搜索计数',
  SearchTime: '搜索时间',
  JVMAndGC: 'Java虚拟机和垃圾回收',
  GCCountRate: '垃圾回收计数率',
  GCTimeRate: '垃圾回收时间率',
  Translog: '翻译',
  TranslogOperation: '翻译操作',
  TranslogOperationSizeInBytes: '翻译操作大小（以字节为单位）',
  Documents: '文档集',
  DocumentsCount: '文档计数',
  DocumentsIndexedRate: '文档索引率',
  DocumentsDeletedRate: '文档删除率',
  DocumentsMergedRate: '文档合并率',
  DocumentsMergedBytes: '文档合并字节',

  ClusterName: '集群名称',
  PodName: 'Pod名称',
  NodeName: 'Node名称',
  Name: '名称',
  CPUUsageMilliCores: 'CPU使用量(毫核)',
  MemoryUsage: '内存使用量',
  NetworkingThroughputByThePod: '每个Pod的网络吞吐量',
  SendingThroughput: '发送吞吐量',
  ReceivingThroughput: '接收吞吐量',

  HTTPThroughputRatio: 'HTTP吞吐率',
  HTTPAPIResponseCode: 'HTTP API响应码',
  HTTPExecutionsMilliseconds: 'HTTP执行毫秒',
  JDBCThroughputRatio: 'JDBC吞吐率',
  JDBCExecutionsErrorRatio: 'JDBC执行错误率',
  JDBCExecutionsMilliseconds: 'JDBC执行毫秒',
  RedisClient: 'Redis客户端',
  RedisThroughputRatio: 'Redis吞吐率',
  RedisExecutionsMilliseconds: 'Redis执行毫秒',
  KafkaProducer: 'Kafka生产者',
  KafkaProducerThroughputRatio: 'Kafka生产者吞吐率',
  KafkaProducerExecutionsMilliseconds: 'Kafka生产者执行毫秒',
  KafkaConsumer: 'Kafka消费者',
  KafkaConsumerThroughputRatio: 'Kafka消费者吞吐率',
  KafkaConsumerExecutionsMilliseconds: 'Kafka消费者执行毫秒',
  RabbitMQProducer: 'RabbitMQ生产者',
  RabbitMQConsumer: 'RabbitMQ消费者',

  TagFilters: '标签过滤器集',
  TagName: '标签名称',
  Operator: '操作符',
  TagValue: '标签值',
  Interval: '时间间隔',
  Count: '计数',
  Unit: '单位',
  GroupBy: '分组',
  GroupByValue: '分组值',

  MoreFilters: '更多过滤条件',
  Error: '是否有错误',
  CopyTagKey: '复制标签键',
  CopyTagValue: '复制标签值',
  Attention: '注意',
  FailWithAlreadyBound: '如果当前页面中的元素已经绑定了服务和实例，则此选择器的值可能会失效',
};
