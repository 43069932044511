import ResetPassword from '@/app/base/ResetPassword';

const enUS = {
  'base.LoginLeftContainer.CloudNativeManagementPlatform':
    'Cloud Native Management Platform',
  'base.LoginLeftContainer.OpenSourceFreedom': 'Open Source, Freedom',
  'base.LoginLeftContainer.LowCostHighAvailability':
    'Low Cost, High Availability',
  'base.LoginLeftContainer.CloudNativePlatform': 'Cloud Native Platform',

  'base.LoginPage.Welcome': 'Welcome',
  'base.LoginPage.UsernameOrEmail': 'Username/Email',
  'base.LoginPage.Password': 'Password',
  'base.LoginPage.ForgotPassword': 'Forgot Password?',
  'base.LoginPage.Login': 'Login',
  "base.LoginPage.Don'tHaveAnAccountYet": "Don't have an account yet?",
  'base.LoginPage.SignUp': 'Sign Up',
  'base.LoginPage.PleaseSelectATenant': 'Please select a tenant',
  'base.LoginPage.Select': 'Select',
  'base.LoginPage.Back': 'Back',

  'base.RegisterPage.CreateAccount': 'Create Account',
  'base.RegisterPage.Username': 'Username',
  'base.RegisterPage.Email': 'Email',
  'base.RegisterPage.Password': 'Password',
  'base.RegisterPage.ConfirmPassword': 'Confirm Password',
  'base.RegisterPage.Register': 'Register',
  'base.RegisterPage.AlreadyAMember': 'Already a member?',
  'base.RegisterPage.LogIn': 'Log in',

  'base.ResetPassword.ResetPassword': 'Reset Password',
  'base.ResetPassword.Username': 'Username',
  'base.ResetPassword.Email': 'Email',
  'base.ResetPassword.Password': 'Password',
  'base.ResetPassword.ConfirmPassword': 'Confirm Password',
  'base.ResetPassword.ChangePassword': 'Change Password',
  'base.ResetPassword.LogIn': 'Log in',

  'base.VerifyCode.Code': 'Input Email Verification Code',
  'base.VerifyCode.GetCode': 'Get Code',
  'base.email.send.success':
    'The verification code has been sent to your mailbox. Please check it.',
  'base.VerifyCode': 'Verify Code',
  'base.VerifyCode.tips':
    'Please check your verification code in your mailbox.',
  'global.switchTenant': 'Switch Tenant',
  'global.currentTenant': 'Current Tenant',

  'base.LoginPage.twofactorAuthentication': 'Two-factor Authentication',
  'base.LoginPage.AuthenticationCode': 'Authentication Code',
  'base.LoginPage.Entertheauthenticationcode': 'Enter the authentication code',
  'base.LoginPage.Verify': 'Verify',
  'base.LoginPage.Verify.tips':
    ' Open the two-factor authenticator (TOTP) app on your mobile device to view your authentication code.',
  LoginFailure: 'Login failure',
  VerifyFailure: 'Verify failure',
  PleaseTryAgain: 'please try again',

  TotalIncome: 'Total Income',
  PlanIncome: 'Plan Income',
  GPUIncome: 'GPU Income',
  GPUVendor: 'GPU Vendor',
  VendorBinding: 'Vendor Binding',
  VendorIncome: 'Income',
  GPUUsage: 'GPU Usage',
  GPUUsageHistory: 'GPU Usage History',
  GPUStartUpStatus: 'GPU StartUp Status',
  GPUUtilization: 'GPU utilization',
  SuspendedStorage: 'Suspended Storage',
};

export default enUS;
