import throughput from './en_md/throughput.md';
import latency from './en_md/latency.md';
import uv from './en_md/uv.md';
import error_rate from './en_md/error_rate.md';
import http_status_code from './en_md/http_status_code.md';
import service_health from './en_md/service_health.md';
import top from './en_md/top.md';
const enUS = {
  'home.page.metric.throughput.title': 'Throughput',
  'home.page.metric.latency.title': 'Latency',
  'home.page.metric.uv.title': 'Unique Visitor',
  'home.page.metric.error.rate.title': 'Error Rate',
  'home.page.metric.http.status.code.title': 'HTTP Status Code',
  'home.page.metric.http.service.health.title': 'Service Health',
  'home.page.metric.top.title': 'Top 5',
  'home.page.metric.throughput.help.md': throughput,
  'home.page.metric.latency.help.md': latency,
  'home.page.metric.uv.help.md': uv,
  'home.page.metric.error.rate.help.md': error_rate,
  'home.page.metric.http.status.code.help.md': http_status_code,
  'home.page.metric.http.service.health.help.md': service_health,
  'home.page.metric.top.help.md': top,

  'home.page.traffic': 'Traffic',
  'home.page.service': 'Service',
  'home.page.top': 'Top 5',
  'home.page.topology': 'Topology',
  'home.page.alertTrend': 'Alert Trend',
  'home.page.recentEvents': 'Recent Events',

  'home.page.topology.tracing': 'Tracing',
  'home.page.topology.architecture': 'Architecture',

  'home.page.top.hot': 'Hot',
  'home.page.top.slow': 'Slow',
  'home.page.top.error': 'Error',

  'home.page.autoRefresh': 'Auto Refresh',

  'home.page.sla.siteEventStatus': 'Site Event Status',
};

export default enUS;
