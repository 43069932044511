const zhCN = {
  Order: '订单',
  Refund: '退款',
  GPU: 'GPU',

  Time: '时间',
  PrepaidAmount: '预付金额',
  RefundAmount: '退款金额',
  ActualIncome: '实际总收入',
  IncomeSource: '收入来源',
  AllSource: '全部',
  GPUSource: 'GPU',
  PlanSource: '付费计划',
  detailsByDay: '详情（按日）',
  detailsByTenant: '详情（按租户）',
  Tenant: '租户',
  UnrealizedRevenue: '总在途收入',
  ActualRevenue: '总实际收入',
  TotalPrepaid: '总预付金额',
  TotalRefund: '总退款金额',

  TotalTenants: '总租户数',
  NewTenantsPerMonth: '新增租户/月',
  NewTenantsPerWeek: '新增租户/周',
  NewTenant: '新增租户',
  PayingTenantPlan: '付费租户 - 付费计划',
  PayingTenantGPU: '付费租户 - GPU',
  NewTenantsPerDay: '新增租户/日',
  NewPayingTenantsPerDay: '付费计划 - 新增租户/日 ',
  NewPayingTenantsPerWeek: '付费计划 -  新增租户/周 ',
  NewPayingTenantsPerMonth: '付费计划 - 新增租户/月',
  NewPayingTenantsPerDayGPU: 'GPU - 新增租户/日',
  NewPayingTenantsPerWeekGPU: 'GPU - 新增租户/周',
  NewPayingTenantsPerMonthGPU: 'GPU - 新增租户/月',
  Date: '日期',

  HostRanking: '主机排行',
  InstanceRanking: '实例排行',
  'table.tenant': '租户',
  'table.total': '总数',
  'table.running': '运行中',

  'total.income': '总收入',
  'total.month.income': '月收入',
  'total.day.income': '日收入',
  'actual.income': '实际收入',
  'gpu.ranking.cost': 'GPU付费排行',
  'gpu.ranking.pod': 'GPU Pod 排行',
  'gpu.ranking.storage': 'GPU 存储排行',
  'gpu.ranking.template': 'GPU 模板排行',
  'gpu.TotalPayment': '总付费',
  'gpu.number': '数量',
  'gpu.capacity': '容量',
  'gpu.income': 'GPU收入',
  'store.income': '存储收入',

  'gpu.total': 'GPU总数',
  'gpu.used': 'GPU已用',
  'gpu.available': 'GPU可用',
  'gpu.inactive': 'GPU不活跃',
  'gpu.usage.total': '{name}总量',
  'gpu.usage.history': '历史数据',
  'gpu.usage.allStockSnapShot': '全部',
  'gpu.usage.modelStockSnapShot': '型号',
  'gpu.usage.specStockSnapShot': '规格',
  'gpu.previous': '较前一刻',
  'gpu.model.used': '已使用',
  'gpu.model.available': '未使用',
  'gpu.model.inactive': '不活跃',
  'gpu.startup.success': '启动成功',
  'gpu.startup.failure': '启动失败',
  'gpu.startup.count': '启动次数',
  'gpu.startup.time': '启动耗时',
  'gpu.startup.topSlow': '最慢记录',
  'gpu.startup.topSlow.time': '耗时',
  'gpu.startup.topSlow.tenant': '租户',
  'gpu.startup.topSlow.spec': '规格',
  'gpu.startup.topSlow.template': '模板',
  'gpu.startup.topSlow.gpuPodId': 'GPU Pod ID',
  'gpu.startup.topSlow.instanceId': '实例 ID',
  'gpu.startup.topSlow.vendor': '供应商',
  'gpu.startup.topSlow.dataCenter': '数据中心',
  'gpu.startup.topSlow.result': '结果',
  'gpu.startup.topSlow.startTime': '启动时间',
  'gpu.startup.topSlow.success': '成功',
  'gpu.startup.topSlow.failure': '失败',
  'gpu.startup.topSlow.size': '记录条数',
  'gpu.revenue.download': '下载明细',
  'gpu.rebate.download': '下载返利明细',
  'gpu.rebate': '推广返利',
};

export default zhCN;
