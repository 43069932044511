import axios from '@/_common/utils/axios-wrapper';
import settings from '@/_common/utils/settings';

class DataCenterApi {
  getDataCenterOptions() {
    return axios.get(settings.BillingOperations + '/data-center-options');
  }
  getDataCenterList(params) {
    return axios.get(settings.BillingOperations + '/data-centers', {
      params,
    });
  }
  getDataCenterDetail(id) {
    return axios.get(settings.BillingOperations + '/data-centers/' + id);
  }
  createDataCenter(data) {
    return axios.post(settings.BillingOperations + '/data-centers', data);
  }
  updateDataCenter(id, data) {
    return axios.put(settings.BillingOperations + '/data-centers/' + id, data);
  }
  getDataCenterOptionsForTenant() {
    return axios.get(settings.EASE_BILLING + '/data-centers/options');
  }
  updateDataCenterPublishStatus(id, data) {
    return axios.put(
      settings.BillingOperations + '/data-centers/' + id + '/publish-status',
      data
    );
  }
}

export default new DataCenterApi();
