import React from 'react';
import { makeStyles } from '@material-ui/core';
import manualImg from '@/app/_common/assets/manual.svg';
import { Link } from 'react-router-dom';
import TooltipPicker from '@/app/_common/components/tooltipPickerForTopbar/TooltipPicker';
import { useIntl } from 'react-intl';
import settings from '@/_common/utils/settings';
import { rootHub } from '@/app/_common/utils/mhub';
import AppStore from '@/app/base/AppStore';

const address = settings.API_ADDRESS;
console.log(address, 'address');
const hub = rootHub();
export default function Manual({ size = 28 }) {
  const intl = useIntl();
  const [appStore] = hub.useSome(AppStore);
  const { model } = appStore;
  const { language } = model;
  const url = `${address}/docs/${language}/index`;
  return (
    <div>
      <TooltipPicker value={intl.formatMessage({ id: 'global.manual' })}>
        <img
          src={manualImg}
          alt="help"
          onClick={() => {
            window.open(url);
          }}
          style={{ width: size, height: size, cursor: 'pointer' }}
        />
      </TooltipPicker>
    </div>
  );
}
