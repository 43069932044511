// @flow
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { Button } from 'react-bootstrap';
import './ConfirmModal.less';

type Props = {
  dividers: boolean,
  confirmShow: boolean,
  title?: string,
  onHide?: Function,
  onOk?: Function,
  okText?: string,
  cancelText?: string,
  children: any,
  bsSize: 'large' | 'small' | 'lg' | 'sm' | 'md',
  maxWidth: 'lg' | 'sm' | 'md',
  scroll: 'body' | 'paper',
  okTextType:
    | 'Default'
    | 'primary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'link',
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});
class ConfirmModal extends React.PureComponent<Props> {
  static defaultProps = {
    onCancel: undefined,
    onHide: undefined,
    onOk: undefined,
    title: undefined,
    okText: undefined,
    cancelText: undefined,
    showCloseIcon: true,
  };

  get footer() {
    const {
      okText,
      cancelText,
      onCancel,
      onOk,
      okTextType = 'primary',
    } = this.props;
    const rst = [];
    if (cancelText && onCancel) {
      rst.push(
        <Button key="cancel" onClick={onCancel}>
          {cancelText}
        </Button>
      );
    }
    if (okText && onOk) {
      rst.push(
        <Button key="submit" bsStyle={okTextType} onClick={onOk}>
          {okText}
        </Button>
      );
    }
    return rst;
  }

  render() {
    const {
      confirmShow,
      onHide,
      children,
      title,
      bsSize,
      scroll = 'body',
      dividers = false,
      showCloseIcon = true,
      ...rest
    } = this.props;
    delete rest.cancelText;
    delete rest.onCancel;
    delete rest.okText;
    delete rest.onOk;
    if (bsSize) {
      if (bsSize === 'large') {
        rest.maxWidth = 'lg';
      } else if (bsSize === 'small') {
        rest.maxWidth = 'sm';
      } else if (!rest.maxWidth) {
        rest.maxWidth = bsSize;
      }
    }
    return (
      <Dialog
        className="m-confirm-modal"
        open={confirmShow}
        onClose={onHide}
        TransitionComponent={Transition}
        {...rest}
        fullWidth
        scroll={scroll}
      >
        {title && (
          <div className="title-container">
            <div className="title-label">{title}</div>
            {onHide ? (
              showCloseIcon ? (
                <IconButton
                  size="small"
                  className="title-close-btn"
                  onClick={onHide}
                >
                  <CloseIcon />
                </IconButton>
              ) : null
            ) : null}
          </div>
        )}
        <DialogContent dividers={dividers}>{children}</DialogContent>
        {this.footer.length > 0 && (
          <DialogActions>{this.footer.map((e) => e)}</DialogActions>
        )}
      </Dialog>
    );
  }
}

export default ConfirmModal;
