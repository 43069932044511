import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import keyImg from '@/app/_common/assets/key.svg';
import TextField from '@material-ui/core/TextField';
import './VerifyCode.less';
import { useIntl } from 'react-intl';
import HelpPicker from '@/app/_common/components/helper/helpPicker2/HelpPicker.js';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 37,
  },
  loading: {
    lineHeight: '37px',
  },
  textField: {
    '& .MuiInputBase-root': {
      height: '46px',
      fontSize: 14,
    },
  },
}));

let timer = null;

const COUNT_DOWN = 60;
const INTERVAL = 1000;

const VerifyCode = ({ onClick, formik, passwordField }) => {
  const classes = useStyles();

  const intl = useIntl();

  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    timer && clearInterval(timer);
    return () => timer && clearInterval(timer);
  }, []);

  useEffect(() => {
    if (time === COUNT_DOWN)
      timer = setInterval(() => setTime((time) => --time), INTERVAL);
    else if (time <= 0) {
      clearInterval(timer);
      setSubmitted(false);
    }
  }, [time]);

  const getCode = () => {
    setSubmitted(true);

    onClick &&
      onClick(
        () => {
          setTime(COUNT_DOWN);
        },
        time,
        setLoading,
        () => setSubmitted(false)
      );
  };

  return loading ? (
    <TextField
      fullWidth
      id="code"
      name="code"
      placeholder={intl.formatMessage({
        id: 'base.VerifyCode.Code',
      })}
      type="code"
      className={classes.textField}
      value={formik.values.code}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.code && Boolean(formik.errors.code)}
      helperText={formik.touched.code && formik.errors.code}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ width: '58px', justifyContent: 'center' }}
          >
            <img src={keyImg} alt="key" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="start"
            style={{ width: '58px', justifyContent: 'center' }}
          >
            <CircularProgress size={20} className={classes.loading} />
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <TextField
      fullWidth
      id="code"
      name="code"
      className={classes.textField}
      placeholder={intl.formatMessage({
        id: 'base.VerifyCode.Code',
      })}
      type="code"
      value={formik.values.code}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.code && Boolean(formik.errors.code)}
      helperText={formik.touched.code && formik.errors.code}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{
              width: '58px',
              justifyContent: 'center',
              margin: '0 12px 0 4px',
            }}
          >
            <img
              src={keyImg}
              alt="key"
              style={{
                width: '20px',
              }}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start" style={{ marginRight: 0 }}>
            <Box display={'flex'} alignItems="center">
              {formik.values.email &&
              formik.values.password &&
              formik.values.confirmPassword &&
              !formik.errors.email &&
              !formik.errors.password &&
              !formik.errors.confirmPassword &&
              !submitted ? (
                <div onClick={getCode} className="get-code">
                  {time
                    ? `${time}s`
                    : intl.formatMessage({
                        id: 'base.VerifyCode.GetCode',
                      })}
                </div>
              ) : (
                <div className="get-code get-code-disabled">
                  {time
                    ? `${time}s`
                    : intl.formatMessage({
                        id: 'base.VerifyCode.GetCode',
                      })}
                </div>
              )}
              <HelpPicker
                titleid="base.VerifyCode"
                helpid="base.VerifyCode.tips"
              ></HelpPicker>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default VerifyCode;
