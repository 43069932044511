import React from 'react';
import logoImg from '@/app/_common/assets/logo.svg';
import './LoginLeftContainer.less';
import { useIntl } from 'react-intl';
import betaCN from '@/app/_common/assets/beta-cn.svg';
import betaEN from '@/app/_common/assets/beta-en.svg';
import AppStore from '@/app/base/AppStore';
import { rootHub } from '@/app/_common/utils/mhub';
import settings from '@/_common/utils/settings';
import { getLogo, getProviderTitle } from '../_common/utils/utils';
const hub = rootHub();
const [appStore] = hub.useSome(AppStore);
export default function LoginLeftContainer({ page }) {
  const intl = useIntl();
  const { model } = appStore;
  const { language } = model;
  const beta = language === 'zh-CN' ? betaCN : betaEN;
  const logo = getLogo();
  const title = getProviderTitle();
  return (
    <div className="portal-img">
      <div className="logo-wrapper">
        <img src={logo} alt="logo" className="logo-login" />
        {/* {settings.IS_BETA ? (
          <img src={beta} alt="beta" className="logo-beta" />
        ) : null} */}
      </div>

      <div className="left-wrapper">
        <div
          className="name"
          style={{
            margin:
              page == 'login'
                ? '68px 0 0 0'
                : page == 'invite-register'
                ? '35px 0 0 0'
                : page == 'register'
                ? '-5px 0 0 0'
                : '',
          }}
        >
          {title}
        </div>
        <div className="title">
          {intl.formatMessage({
            id: 'base.LoginLeftContainer.CloudNativeManagementPlatform',
          })}
        </div>
        <div className="subtitle">
          <div className="">
            {intl.formatMessage({
              id: 'base.LoginLeftContainer.OpenSourceFreedom',
            })}
          </div>
          <div className="subtitle-item">
            {intl.formatMessage({
              id: 'base.LoginLeftContainer.LowCostHighAvailability',
            })}
          </div>
          <div className="subtitle-item">
            {intl.formatMessage({
              id: 'base.LoginLeftContainer.CloudNativePlatform',
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
