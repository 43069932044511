import { deepClone, getTenantId } from '@/app/_common/utils/utils';
import meshApi from '@/apis/service/mesh-api';
import toolbox from '@/_common/utils/toolbox';
import useSWR from 'swr';
import ServiceApi from '@/apis/service/service-api';
import React, { useCallback, useEffect, useState } from 'react';
import { messageConfirm } from '@/app/_common/utils/tips-utils';
import { makeStyles } from '@material-ui/styles';
import configServerApi from '@/apis/service/config-server-api';
import registryApi from '@/apis/service/registry-api';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export function getValueFromExtras(service, key) {
  // console.log('service', service);
  let extras = service.extras;
  // console.log('extras', extras);
  let value = '';
  if (extras) {
    let parsedExtras = JSON.parse(extras);
    // console.log('parsedExtras', parsedExtras);
    if (Object.keys(parsedExtras).length > 0 && parsedExtras[key]) {
      value = parsedExtras[key];
    }
  }
  return value;
}

export function initMeshServiceData(data) {
  let dataCopy = deepClone(data);

  if (!dataCopy.mock) {
    dataCopy.mock = {};
  }
  if (!dataCopy.mock.rules) {
    dataCopy.mock.rules = [];
  } else {
    dataCopy.mock.rules.forEach((rule) => {
      if (!rule.match) {
        rule.match = {};
      }
      if (!rule.match.headers) {
        rule.match.headers = [];
      } else {
        let headers = rule.match.headers;
        let headersArray = [];
        Object.keys(headers).forEach((key) => {
          headersArray.push({
            key: key,
            value: headers[key],
          });
        });
        rule.match.headers = headersArray;
      }

      if (!rule.headers) {
        rule.headers = [];
      }
      let headers = rule.headers;
      let headersArray = [];
      Object.keys(headers).forEach((key) => {
        headersArray.push({
          key: key,
          value: headers[key],
        });
      });
      rule.headers = headersArray;

      // console.log('rule', rule);
    });
  }

  if (!dataCopy.sidecar) {
    dataCopy.sidecar = {};
  }

  if (!dataCopy.observability) {
    dataCopy.observability = {};
  }
  if (!dataCopy.observability.outputServer) {
    dataCopy.observability.outputServer = {};
    dataCopy.observability.outputServer.enabled = false;
  } else {
    dataCopy.observability.outputServer.enabled = true;
  }
  if (!dataCopy.observability.tracings) {
    dataCopy.observability.tracings = {};
    dataCopy.observability.tracings.enabled = false;
  } else {
    dataCopy.observability.tracings.enabled = true;
  }
  if (!dataCopy.observability.tracings.output) {
    dataCopy.observability.tracings.output = {};
  }
  if (!dataCopy.observability.tracings.request) {
    dataCopy.observability.tracings.request = {};
  }
  if (!dataCopy.observability.tracings.jdbc) {
    dataCopy.observability.tracings.jdbc = {};
  }
  if (!dataCopy.observability.tracings.redis) {
    dataCopy.observability.tracings.redis = {};
  }
  if (!dataCopy.observability.tracings.remoteInvoke) {
    dataCopy.observability.tracings.remoteInvoke = {};
  }
  if (!dataCopy.observability.tracings.kafka) {
    dataCopy.observability.tracings.kafka = {};
  }
  if (!dataCopy.observability.tracings.rabbit) {
    dataCopy.observability.tracings.rabbit = {};
  }
  if (!dataCopy.observability.metrics) {
    dataCopy.observability.metrics = {};
    dataCopy.observability.metrics.enabled = false;
  } else {
    dataCopy.observability.metrics.enabled = true;
  }
  if (!dataCopy.observability.metrics.access) {
    dataCopy.observability.metrics.access = {};
  }
  if (!dataCopy.observability.metrics.request) {
    dataCopy.observability.metrics.request = {};
  }
  if (!dataCopy.observability.metrics.jdbcStatement) {
    dataCopy.observability.metrics.jdbcStatement = {};
  }
  if (!dataCopy.observability.metrics.jdbcConnection) {
    dataCopy.observability.metrics.jdbcConnection = {};
  }
  if (!dataCopy.observability.metrics.rabbit) {
    dataCopy.observability.metrics.rabbit = {};
  }
  if (!dataCopy.observability.metrics.kafka) {
    dataCopy.observability.metrics.kafka = {};
  }
  if (!dataCopy.observability.metrics.redis) {
    dataCopy.observability.metrics.redis = {};
  }
  if (!dataCopy.observability.metrics.jvmGc) {
    dataCopy.observability.metrics.jvmGc = {};
  }
  if (!dataCopy.observability.metrics.jvmMemory) {
    dataCopy.observability.metrics.jvmMemory = {};
  }
  if (!dataCopy.observability.metrics.md5Dictionary) {
    dataCopy.observability.metrics.md5Dictionary = {};
  }

  if (!dataCopy.resilience) {
    dataCopy.resilience = {};
  }
  if (!dataCopy.resilience.circuitBreaker) {
    dataCopy.resilience.circuitBreaker = {};
    dataCopy.resilience.circuitBreaker.enabled = false;
  } else {
    dataCopy.resilience.circuitBreaker.enabled = true;
  }
  if (!dataCopy.resilience.retry) {
    dataCopy.resilience.retry = {};
    dataCopy.resilience.retry.enabled = false;
  } else {
    dataCopy.resilience.retry.enabled = true;
  }
  if (!dataCopy.resilience.failureCodes) {
    dataCopy.resilience.failureCodes = [];
  }
  if (!dataCopy.resilience.rateLimiter) {
    dataCopy.resilience.rateLimiter = {};
    dataCopy.resilience.rateLimiter.enabled = false;
  } else {
    dataCopy.resilience.rateLimiter.enabled = true;
  }
  if (!dataCopy.resilience.rateLimiter.policies) {
    dataCopy.resilience.rateLimiter.policies = [];
  }
  if (!dataCopy.resilience.rateLimiter.urls) {
    dataCopy.resilience.rateLimiter.urls = [];
  }
  if (!dataCopy.resilience.timeLimiter) {
    dataCopy.resilience.timeLimiter = {};
    dataCopy.resilience.timeLimiter.enabled = false;
  } else {
    dataCopy.resilience.timeLimiter.enabled = true;
  }

  if (!dataCopy.loadBalance) {
    dataCopy.loadBalance = {};
  }

  return dataCopy;
}

export function cleanVoidObjectAtForm(params) {
  if (
    (params.mock && Object.keys(params.mock).length == 0) ||
    !params.mock.enabled
  ) {
    delete params.mock;
  }

  if (params.sidecar && Object.keys(params.sidecar).length == 0) {
    delete params.sidecar;
  }

  let observabilityFieldLength = Object.keys(params.observability).length;
  let deleteObservabilityFieldCount = 0;
  if (params.observability && Object.keys(params.observability).length == 0) {
    delete params.observability;
  } else {
    Object.keys(params.observability).forEach((key) => {
      if (key == 'outputServer') {
        if (
          Object.keys(params.observability[key]).length == 0 ||
          !params.observability[key].enabled
        ) {
          delete params.observability[key];
          deleteObservabilityFieldCount++;
        }
      }

      if (key == 'tracings') {
        if (
          Object.keys(params.observability[key]).length == 0 ||
          !params.observability[key].enabled
        ) {
          delete params.observability[key];
          deleteObservabilityFieldCount++;
        }
      }

      if (key == 'metrics') {
        if (
          Object.keys(params.observability[key]).length == 0 ||
          !params.observability[key].enabled
        ) {
          delete params.observability[key];
          deleteObservabilityFieldCount++;
        }
      }
    });
  }
  if (deleteObservabilityFieldCount == observabilityFieldLength) {
    delete params.observability;
  }

  let resilienceFieldLength = Object.keys(params.resilience).length;
  let deleteResilienceFieldCount = 0;
  if (params.resilience && Object.keys(params.resilience).length == 0) {
    delete params.resilience;
  } else {
    Object.keys(params.resilience).forEach((key) => {
      // if (key == 'circuitBreaker' || key == 'retry') {
      if (
        key == 'circuitBreaker' ||
        key == 'retry' ||
        key == 'rateLimiter' ||
        key == 'timeLimiter'
      ) {
        if (!params.resilience[key].enabled) {
          delete params.resilience[key];
          deleteResilienceFieldCount++;
        }
      } else if (key == 'failureCodes') {
        if (params.resilience[key].length == 0) {
          delete params.resilience[key];
          deleteResilienceFieldCount++;
        }
        // } else if (key == 'rateLimiter') {
        //   if (
        //     params.resilience[key].length == 0 ||
        //     !params.resilience[key].enabled
        //   ) {
        //     delete params.resilience[key];
        //   } else {
        //     let rateLimiterFieldLength = Object.keys(params.resilience[key])
        //       .length;
        //     let deleteRateLimiterCount = 0;
        //     Object.keys(params.resilience[key]).forEach((key2) => {
        //       if (params.resilience[key][key2].length == 0) {
        //         delete params.resilience[key][key2];
        //         deleteRateLimiterCount++;
        //       }
        //     });
        //
        //     if (deleteRateLimiterCount == rateLimiterFieldLength) {
        //       delete params.resilience[key];
        //       deleteResilienceFieldCount++;
        //     }
        //   }
        // } else if (key == 'timeLimiter') {
        //   if (
        //     Object.keys(params.resilience[key]).length == 0 ||
        //     !params.resilience[key].enabled
        //   ) {
        //     delete params.resilience[key];
        //     deleteResilienceFieldCount++;
        //   }
      }
    });
  }
  if (deleteResilienceFieldCount == resilienceFieldLength) {
    delete params.resilience;
  }

  if (params.loadBalance && Object.keys(params.loadBalance).length == 0) {
    delete params.loadBalance;
  }
}

export function useUpdateMesh(setSnapshotForm) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleUpdateMesh(form, clusterId) {
    let meshSuccess = false;

    let params = deepClone(form);
    if (setSnapshotForm) {
      setSnapshotForm(form);
    }
    params.clusterId = clusterId;
    params.tenantId = getTenantId();

    cleanVoidObjectAtForm(params);

    if (params.mock && params.mock.enabled) {
      if (params.mock.rules && params.mock.rules.length > 0) {
        params.mock.rules.forEach((rule) => {
          let matchHeaders = rule.match.headers;
          let matchHeadersMap = {};
          if (matchHeaders && matchHeaders.length > 0) {
            matchHeaders.forEach((header) => {
              matchHeadersMap[header.key] = header.value;
            });
          }
          rule.match.headers = matchHeadersMap;

          let headers = rule.headers;
          let headersMap = {};
          if (headers && headers.length > 0) {
            headers.forEach((header) => {
              headersMap[header.key] = header.value;
            });
          }
          rule.headers = headersMap;
        });
      }
    }
    // console.log('params', params);

    setLoading(true);
    try {
      await meshApi.updateService(params);
      await meshApi.syncService(params);
      meshSuccess = true;
    } catch (error) {
      setLoading(false);
      let response = error.response;
      if (
        response &&
        response.data &&
        (response.data.message || response.data.detail)
      ) {
        let message = response.data.message || response.data.detail;
        toolbox.error(message);
      }
      meshSuccess = false;
    }

    if (meshSuccess) {
      setLoading(false);
      toolbox.success('Update success!');
    }
  }

  return {
    loading,
    handleUpdateMesh,
  };
}

export function useUpdateSDKService() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleUpdateSDKService(
    service,
    config_server,
    remark,
    changes
  ) {
    let sdkServiceSuccess = false;
    setLoading(true);

    try {
      await configServerApi.updateServiceConfig({
        service,
        config_server,
        remark,
        changes,
      });
      sdkServiceSuccess = true;
    } catch (error) {
      setLoading(false);
      let response = error.response;
      if (
        response &&
        response.data &&
        (response.data.message || response.data.detail)
      ) {
        let message = response.data.message || response.data.detail;
        toolbox.error(message);
      }
      sdkServiceSuccess = false;
    }

    if (sdkServiceSuccess) {
      setLoading(false);
      toolbox.success('Update success!');
    }
  }

  return {
    loading,
    handleUpdateSDKService,
  };
}

export function useMeshServices(clusterId) {
  const {
    data,
    error,
    mutate: mutateGetMeshServices,
  } = useSWR(['getServices', clusterId], async (key, clusterId) => {
    try {
      const tenantId = getTenantId();
      const response = await ServiceApi.getServices({
        current_page: 1,
        page_size: 9999,
        tenantId,
        type: 'MESH',
      });
      if (
        response &&
        response.data &&
        response.data.list &&
        response.data.list.length > 0
      ) {
        return response.data.list.filter((service) => {
          let cluster_id = getValueFromExtras(service, 'cluster_id');
          return cluster_id == clusterId;
        });
      }
      return [];
    } catch (error) {
      let response = error.response;
      if (
        response &&
        response.data &&
        (response.data.message || response.data.detail)
      ) {
        let message = response.data.message || response.data.detail;
        toolbox.error(message);
      }
    }
  });

  return {
    data,
    loading: !data && !error,
    mutateGetMeshServices,
  };
}

export function useBasicServices() {
  const {
    data,
    error,
    mutate: mutateGetBasicServices,
  } = useSWR(['getBasicServices'], async (key) => {
    try {
      const tenantId = getTenantId();
      let response = await ServiceApi.getBasicServices({
        tenantId,
      });
      if (
        response &&
        response.data &&
        response.data.list &&
        response.data.list.length > 0
      ) {
        return response.data.list;
      }
      return [];
    } catch (error) {
      let response = error.response;
      if (
        response &&
        response.data &&
        (response.data.message || response.data.detail)
      ) {
        let message = response.data.message || response.data.detail;
        toolbox.error(message);
      }
    }
  });

  return {
    data,
    loading: !data && !error,
    mutateGetBasicServices,
  };
}

export function useZonesAndDomains(basicServices) {
  const [zones, setZones] = React.useState([]);
  const [domains, setDomains] = React.useState([]);
  useEffect(() => {
    if (basicServices && basicServices.length > 0) {
      let zones = [];
      let domains = [];
      basicServices.forEach((basicService) => {
        if (basicService.zone) {
          if (
            !zones.find((zoneOption) => {
              return zoneOption.value == basicService.zone;
            })
          ) {
            zones.push({
              value: basicService.zone,
              label: basicService.zone,
            });
          }
        }
        if (basicService.domain) {
          if (
            !domains.find((domainOption) => {
              return domainOption.value == basicService.domain;
            })
          ) {
            domains.push({
              value: basicService.domain,
              label: basicService.domain,
            });
          }
        }
      });
      setZones(zones);
      setDomains(domains);
    }
  }, [basicServices]);

  return {
    zones,
    setZones,
    domains,
    setDomains,
  };
}

export const loadBalancePolicyList = [
  {
    value: 'random',
    desc: 'Matches services by random.',
  },
  {
    value: 'weightedRandom',
    desc: 'Matches services by weighted random.',
  },
  {
    value: 'ipHash',
    desc: "The IP Hash policy uses an incoming request's source IP address as a hashing key to route non-sticky traffic to the same backend server.",
  },
  {
    value: 'headerHash',
    desc: 'This command determines the value that the Web Service Proxy uses for hash calculations to load balance traffic to servers.',
  },
];

export function useMeshServiceForm(
  clusterId,
  name,
  defaultMeshServiceData,
  type
) {
  const {
    data,
    error,
    mutate: mutateGetMeshService,
  } = useSWR(
    ['getService', clusterId, name, type],
    async (key, clusterId, name, type) => {
      try {
        if (type == 'MESH') {
          const response = await meshApi.getService(clusterId, name);
          return response.data;
        } else {
          return {};
        }
      } catch (error) {
        let response = error.response;
        if (
          response &&
          response.data &&
          (response.data.message || response.data.detail)
        ) {
          let message = response.data.message || response.data.detail;
          toolbox.error(message);
        }
      }
    }
  );
  const [form, setForm] = useState(null);
  const [snapshotForm, setSnapshotForm] = useState({});
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      let dataCopy = initMeshServiceData(data);
      setForm(dataCopy);
      setSnapshotForm(dataCopy);
    } else {
      setForm(defaultMeshServiceData);
    }
  }, [data]);

  return {
    form,
    setForm,
    snapshotForm,
    setSnapshotForm,
    loading: !data && !error,
    mutateGetMeshService,
  };
}

export async function onServiceFormClusterChange(
  cluster,
  basicInfo,
  setBasicInfo,
  getTenants,
  setTenants
) {
  if (basicInfo && cluster && cluster.id) {
    let basicInfoCopy = deepClone(basicInfo);
    basicInfoCopy.clusterId = cluster.id;
    basicInfoCopy.clusterName = cluster.name;
    setBasicInfo(basicInfoCopy);

    let originTenants = await getTenants(cluster.id);
    // console.log('originTenants', originTenants);
    if (originTenants && originTenants.length > 0) {
      setTenants(originTenants);
      setBasicInfo({
        ...basicInfoCopy,
        registerTenant: originTenants[0].name,
      });
    } else {
      setTenants([]);
      setBasicInfo({
        ...basicInfoCopy,
        registerTenant: '',
      });
    }
  }
}

export function useClusters(intl, history) {
  const {
    data,
    error,
    mutate: mutateGetClusters,
  } = useSWR(['getClusters'], async (key) => {
    const response = await meshApi.getClusters();
    if (
      response &&
      response.data &&
      response.data.list &&
      response.data.list.length > 0
    ) {
      return response.data.list;
    } else {
      messageConfirm({
        intl,
        callback: () => {
          history.push(`/main/service/mesh/zone`);
        },
        message: intl.formatMessage({
          id: 'app.traffic.cluster.needConnectFirst',
        }),
      });
    }
  });

  return {
    data,
    loading: !data && !error,
  };
}

export function getShortMeshServiceName(service) {
  // console.log('service', service);
  let shortName = '';

  if (service && service.name) {
    let nameArray = service.name.split('.');
    // console.log('nameArray', nameArray);
    if (nameArray.length >= 3) {
      shortName = nameArray[2];
    } else {
      shortName = service.name;
    }
  }

  return shortName;
}

export function getLongMeshServiceName(services, shortServiceName) {
  // console.log('services', services);
  // console.log('shortServiceName', shortServiceName);
  return services.find((service) => {
    return service.name.indexOf(shortServiceName) > -1;
  }).name;
}

export function handleTagsChange(setTags, tags, setTagsValid) {
  setTags(tags);
  if (
    (tags &&
      tags.length > 0 &&
      tags.every((tag) => {
        return tag.tag_key && tag.tag_value;
      })) ||
    tags.length == 0
  ) {
    setTagsValid(true);
  } else {
    setTagsValid(false);
  }
}

export function allServiceRight(services) {
  return (
    services &&
    services.length > 0 &&
    services.every((service) => {
      let create_type = getValueFromExtras(service, 'create_type');
      return create_type && parseInt(create_type) == 2;
    })
  );
}

export const useBreadcrumbsStyles = makeStyles((theme) => ({
  breadcrumbs: {
    '& .MuiBreadcrumbs-separator': {
      fontSize: 32,
    },
    marginBottom: 32,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '32px',
    fontWeight: '400',
    color: '#2f54eb',
    cursor: 'pointer',
    '&:hover': {
      color: '#2442bf',
    },
    '&:focus': {
      color: '#2442bf',
      outline: 'none',
    },
  },
  typo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '32px',
    fontWeight: '400',
  },
}));

export const useDisabledStyles = makeStyles((theme) => ({
  root: {
    '& .Mui-disabled': {
      color: '#646464',
    },
  },
}));

export function useSDKServiceConfigs({ service, type }) {
  const { data, error } = useSWR(
    ['getServiceConfigsByConditions', service, type],
    async (key, service, type) => {
      try {
        if (type == 'SERVICE') {
          const response = await configServerApi.getServiceConfigsByConditions({
            service,
          });
          return response.data;
        }
      } catch (error) {
        let response = error.response;
        if (
          response &&
          response.data &&
          (response.data.message || response.data.detail)
        ) {
          let message = response.data.message || response.data.detail;
          toolbox.error(message);
        }
      }
    }
  );
  const [registryList, setRegistryList] = useState([]);
  const [selectedRegistry, setSelectedRegistry] = useState({});
  const [originSelectedRegistry, setOriginSelectedRegistry] = useState({});
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setRegistryList(data);
      setSelectedRegistry(data[0]);
      setOriginSelectedRegistry(data[0]);
    } else {
      setRegistryList([]);
      setSelectedRegistry({});
    }
  }, [data]);

  return {
    registryList,
    selectedRegistry,
    setSelectedRegistry,
    originSelectedRegistry,
    setOriginSelectedRegistry,
    loading: !data && !error,
  };
}

export function useRegistryTypes() {
  const { data, error } = useSWR(['getRegistryTypes'], async (key) => {
    try {
      const response = await registryApi.getRegistryTypes();
      if (response && response.data && response.data.length > 0) {
        // console.log('getRegistryTypes', response.data);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      let response = error.response;
      if (
        response &&
        response.data &&
        (response.data.message || response.data.detail)
      ) {
        let message = response.data.message || response.data.detail;
        toolbox.error(message);
      }
    }
  });

  return {
    data,
    loading: !data && !error,
  };
}

export function useConfigServerTypes() {
  const { data, error } = useSWR(['getConfigServerTypes'], async (key) => {
    try {
      const response = await configServerApi.getConfigServerTypes();
      if (response && response.data && response.data.length > 0) {
        // console.log('getConfigServerTypes', response.data);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      let response = error.response;
      if (
        response &&
        response.data &&
        (response.data.message || response.data.detail)
      ) {
        let message = response.data.message || response.data.detail;
        toolbox.error(message);
      }
    }
  });

  return {
    data,
    loading: !data && !error,
  };
}

export function useFormChange(form, snapshotForm) {
  // console.log('form', form);
  // console.log('snapshotForm', snapshotForm);
  const [formChanged, setFormChanged] = useState(false);
  useEffect(() => {
    if (JSON.stringify(form) != JSON.stringify(snapshotForm)) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  }, [form, snapshotForm]);
  return { formChanged };
}

export const LABEL_WIDTH50 = '50px';
export const LABEL_WIDTH100 = '100px';
export const LABEL_WIDTH150 = '150px';
export const LABEL_WIDTH350 = '350px';

export const handleServiceTagClick = (history, clusterId, name) => {
  if (clusterId && name) {
    window.open(
      `/megacloud/app/main/service/management/service/form/${clusterId}/${name}`
    );
  }
};
