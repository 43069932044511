// @flow

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ConfirmModal from './ConfirmModal';
import { rootHub } from '@/app/_common/utils/mhub';
import localeMessages from '@/app/_common/locale';
import { IntlProvider } from 'react-intl';
import AppStore from '@/app/base/AppStore';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import theme from '@/app/_common/styles/theme';

type ConfirmProps = {
  title?: string,
  onHide?: Function,
  onOk?: Function,
  okText?: string,
  cancelText?: string,
  dividers?: boolean,
  render: () => any | string,
};

export default function confirm(config: ConfirmProps) {
  const div = document.createElement('div');
  if (document && document.body && document.body.appendChild) {
    document.body.appendChild(div);
  }
  let currentConfig = { ...config, confirmShow: true };

  function render(props: any) {
    const { render: childrenSource, ...rest } = props;
    const children =
      typeof childrenSource === 'function'
        ? childrenSource(destroy)
        : childrenSource;
    const hub = rootHub();
    const [appStore] = hub.useSome(AppStore);
    const { model } = appStore;
    ReactDOM.render(
      <ThemeProvider theme={createTheme(theme)}>
        <IntlProvider
          key={model.language}
          locale={model.language}
          messages={localeMessages[model.language]}
        >
          <ConfirmModal {...rest}>{children}</ConfirmModal>
        </IntlProvider>
      </ThemeProvider>,
      div
    );
  }

  function destroy(...args: any[]) {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  const onHide = (...args) => {
    if (args && args[1] !== 'backdropClick' && args[1] !== 'escapeKeyDown') {
      if (config.onHide) {
        let close = config.onHide(...args);
        if (close === true) {
          destroy(args);
        }
      } else {
        destroy(args);
      }
    }
  };
  const onOk = (...args) => {
    if (config.onOk) {
      let close = config.onOk(...args);
      if (close === true) {
        destroy(args);
      }
    } else {
      destroy(args);
    }
  };

  const onCancel = (...args) => {
    if (config.onCancel) {
      let close = config.onCancel(...args);
      if (close === true) {
        destroy(args);
      }
    } else {
      destroy(args);
    }
  };

  currentConfig = { ...currentConfig, onHide, onOk, onCancel };
  render(currentConfig);

  return destroy;
}
