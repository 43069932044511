// flow
import axios from '@/_common/utils/axios-wrapper';
import settings from '@/_common/utils/settings';

class ConfigServerApi {
  getConfigServerTypes() {
    return axios.get(
      settings.GATEWAY_API_ADDRESS + '/v1/governance/config-server-types'
    );
  }
  getServiceNamesUnderConfigServer(name) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/governance/config-servers/${name}/service-names`
    );
  }
  getSummaryByConfigServer(name) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/governance/config-servers/${name}/summary`,
      { autoError: false }
    );
  }
  instantFetchByConfigServer(name) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS +
        `/v1/governance/config-servers/${name}/instant-fetch`,
      { autoError: false }
    );
  }
  getServiceConfigsByConditions({ name, service }) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS + `/v1/governance/service-configs`,
      {
        params: { config_server: name, service },
      }
    );
  }
  updateServiceConfig({
    service,
    config_server,
    remark,
    changes,
  }: {
    service: string,
    config_server: string,
    remark: string,
    changes: [
      {
        item_name: string,
        item_value: string,
        type: 'add' | 'modify' | 'delete',
      }
    ],
  }) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/governance/service-configs/${service}`,
      { config_server, remark, changes },
      {
        autoError: false,
      }
    );
  }
}
export default new ConfigServerApi();
