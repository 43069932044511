import Confirm from '@/_common/components/Modal/confirm';
import message from '@/_common/components/Message';
class Toolbox {
  tips = (msg) => {
    message.info(msg);
  };

  alert = (msg) => {
    message.info(msg);
  };

  confirm = Confirm;

  alertError = (msg, error) => {
    this.error(msg);
    if (error) {
      console.error(error);
    }
  };
  info = (msg) => {
    message.info(msg);
  };
  warn = (msg) => {
    message.warning(msg);
  };
  success = (msg) => {
    message.success(msg);
  };
  error = (msg) => {
    message.warning(msg);
  };
}
export default new Toolbox();
