import React from 'react';
import { Button, Paper, Tooltip, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import shapeSVG from '@/app/_common/assets/shape.svg';
import shape2SVG from '@/app/_common/assets/shape2.svg';
import ReactMarkdown from 'react-markdown';
import 'github-markdown-css';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: '#3b3b3b',
    fontSize: 14,
    padding: 0,
    boxShadow: 'none',
  },
  arrow: {
    color: '#FFF',
    bottom: '1px !important',
    zIndex: 3,

    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
    },
  },
}))(Tooltip);

const useTooltipContentStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid #ECEDF0',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    padding: '16px',
  },
  title: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#3B3B3B',
    margin: '0 0 5px 0',
  },
  content: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#646464',
  },
}));
function TooltipContent({ titleid, helpid, linkTarget }) {
  const intl = useIntl();

  const classes = useTooltipContentStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.title}>
        {intl.formatMessage({
          id: titleid,
        })}
      </div>
      <div className={classes.content}>
        <ReactMarkdown
          children={intl.formatMessage({
            id: helpid,
          })}
          linkTarget={linkTarget}
        />
      </div>
    </Paper>
  );
}

function HelpPicker({ titleid, helpid, style, linkTarget = '_blank' }) {
  const [helpIcon, setHelpIcon] = React.useState(shapeSVG);

  return (
    <LightTooltip
      title={
        <TooltipContent
          titleid={titleid}
          helpid={helpid}
          linkTarget={linkTarget}
        />
      }
      arrow
      placement="top-start"
      interactive
    >
      <img
        src={helpIcon}
        onMouseEnter={() => setHelpIcon(shape2SVG)}
        onMouseLeave={() => setHelpIcon(shapeSVG)}
        alt="help"
        style={{
          ...style,
          cursor: 'pointer',
        }}
        className="mr-2"
      />
    </LightTooltip>
  );
}

export default HelpPicker;
