import axios from '@/_common/utils/axios-wrapper';
import settings from '@/_common/utils/settings';

class UserAPI {
  loadUserList(params: any) {
    return axios.get(settings.EASE_USER_CENTER + '/users', {
      params,
      autoError: false,
    });
  }

  activateUser(params: any) {
    return axios.post(
      settings.EASE_USER_CENTER + `/users/${params.username}/activation`,
      params,
      {
        autoError: false,
      }
    );
  }
  createUser(params: {
    username: string,
    password: string,
    email: string,
    new_tenant_name: string,
  }) {
    return axios.post(settings.EASE_USER_CENTER + `/users`, params, {
      autoError: false,
    });
  }

  deactivateUser(userName: string) {
    return axios.post(
      settings.EASE_USER_CENTER + `/users/${userName}/deactivation`,
      {
        autoError: false,
      }
    );
  }
  resetPassword(username: string) {
    return axios.post(
      settings.EASE_USER_CENTER + `/users/${username}/reset-password`
    );
  }
  changePassword(params) {
    return axios.post(
      settings.EASE_USER_CENTER + `/users/${params.userName}/change-password`,
      null,
      { params, autoError: false }
    );
  }

  getUserInfo() {
    return axios.get(settings.EASE_USER_CENTER + '/users/userInfo', {
      autoError: false,
    });
  }

  getAllRoles() {
    return axios.get(settings.EASE_USER_CENTER + '/roles', {
      autoError: false,
    });
  }
  getUserRoles(id) {
    return axios.get(settings.EASE_USER_CENTER + '/users/' + id + '/roles', {
      autoError: false,
    });
  }

  updateUserRoles(id, data) {
    return axios.put(
      settings.EASE_USER_CENTER + '/users/' + id + '/roles',
      data,
      {
        autoError: false,
      }
    );
  }
  getTenantUsersById(params) {
    return axios.get(
      settings.EASE_TENANT_MGMT + '/users/tenant-users/' + params.tenantId,
      {
        params,
      }
    );
  }
  changePasswordWithoutUsername(params) {
    return axios.post(
      settings.EASE_USER_CENTER + `/users/change-password`,
      null,
      { params, autoError: false }
    );
  }
}

export default new UserAPI();
