const enUS = {
  'app.tenant.table.tenantName': 'Tenant Name',
  'app.tenant.table.owner': 'Owner',
  'app.tenant.table.status': 'Status',
  'app.tenant.table.description': 'Description',
  'app.tenant.table.createdAt': 'Created At',
  'app.tenant.table.updatedAt': 'Updated At',
  'app.tenant.table.userEmail': 'User Email',
  'app.tenant.table.username': 'Username',
  'app.tenant.table.teamName': 'Team Name',
  'app.tenant.table.roleType': 'Role Type',
  'app.tenant.table.inviteBy': 'Invite By',

  'app.tenant.edit': 'Edit Tenant',
  'app.tenant.create': 'Create Tenant',
  'app.tenant.members': 'Members',
  'app.tenant.pendingInvitations': 'Pending Invitations',
  'app.tenant.inviteUsers': 'Invite Users',
  'app.tenant.team.create': 'Create Team',
  'app.tenant.team.edit': 'Edit Team',
  'app.tenant.team.name': 'Team Name',
  'app.tenant.team.member.add': 'Add Member',
  'app.tenant.team.member.invite': 'Invite Member',
  'app.tenant.userInfo': 'User Info',
  'app.tenant.member': 'Member',

  'app.tenant.toolbox.create.success': 'Create Tenant Success',
  'app.tenant.toolbox.update.success': 'Update Tenant Success',
  'app.tenant.toolbox.update.status': 'Update Tenant Status Success',
  'app.tenant.toolbox.delete.success': 'Delete Success',
  'app.tenant.toolbox.email.success': 'Email Send Success',
  'app.tenant.toolbox.team.create.success': 'Team Create Success',
  'app.tenant.toolbox.team.update.success': 'Team Update Success',
  'app.tenant.toolbox.team.member.add.success': 'Member Add Success',

  'app.tenant.toolbox.team.delete.title': 'Delete Team',
  'app.tenant.toolbox.team.delete.content': 'Are you sure to delete this team?',
  'app.tenant.toolbox.team.member.delete.title': 'Delete Member',
  'app.tenant.toolbox.team.member.delete.content':
    'Are you sure to delete this member?',
  'app.tenant.toolbox.invite.delete.confirm':
    'Are you sure to withdraw the invitation?',
  'app.tenant.toolbox.invite.delete': 'Withdraw Invitation',
  'app.tenant.toolbox.invite.withdraw': 'Withdraw',
  'app.tenant.toolbox.member.delete.title': 'Delete Member',

  'app.tenant.table.loginCount': 'Login Count',
  'app.tenant.table.tenantStatistics': 'Statistics',

  'app.tenant.registry.imageList': 'Image List',
  'app.tenant.registry.registryInfo': 'Registry Info',

  UsernameIsEmpty: 'Username is empty',
  ConfirmSwitchTenants: 'Are you sure you want to switch tenants?',
};
export default enUS;
