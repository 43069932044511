import { useIntl } from 'react-intl';
import React, { useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import Spacer from '@/_common/components/Spacer';
import { deepClone, getTenantId } from '@/app/_common/utils/utils';
import CustomFormItem from '@/app/_common/components/customFormItem';
import { useHistory } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import SimpleReactValidator from 'simple-react-validator';
import HelpPicker from '@/app/_common/components/helper/helpPicker2/HelpPicker';
import WarningSVG from '@/app/service/_common/assets/warning.svg';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LABEL_WIDTH150, LABEL_WIDTH350 } from '@/app/service/_common/util';

function CircuitBreaker({
  classes,
  clusterId,
  form,
  setForm,
  handleReturnToServiceList,
  handleUpdateMesh,
  formChanged,
}) {
  const intl = useIntl();

  const history = useHistory();

  const circuitBreakerValidator = useRef(
    new SimpleReactValidator({
      validators: {},
    })
  );
  const [, forceUpdate] = useState();

  return (
    <div className={classes.formContainer}>
      <div className={classes.tipsWrapper}>
        <img src={WarningSVG} />
        <div className={classes.tips}>
          {intl.formatMessage({
            id: 'app.service.mesh.Service.Governance.CircuitBreaker.Warning',
          })}
        </div>
        <div
          className={classes.tipsLink}
          onClick={() => {
            window.open(
              'https://resilience4j.readme.io/docs/circuitbreaker',
              '_blank'
            );
          }}
        >
          https://resilience4j.readme.io/docs/circuitbreaker
        </div>
      </div>

      <Spacer size={8} />

      <CustomFormItem
        label={intl.formatMessage({
          id: 'app.service.mesh.Service.ServiceForm.Enabled',
        })}
        labelWidth={LABEL_WIDTH350}
        style={{
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '20px',
          color: '#3B3B3B',
        }}
      >
        <div
          style={{
            margin: '0 0 0 -12px',
          }}
        >
          <Switch
            checked={form?.resilience?.circuitBreaker?.enabled ? true : false}
            onChange={(event) => {
              let formCopy = deepClone(form);
              if (!formCopy.resilience) {
                formCopy.resilience = {};
              }
              if (!formCopy.resilience.circuitBreaker) {
                formCopy.resilience.circuitBreaker = {};
              }
              formCopy.resilience.circuitBreaker.enabled = event.target.checked;
              if (!event.target.checked) {
                formCopy.resilience.circuitBreaker = {
                  enabled: false,
                };
              } else {
                formCopy.resilience.circuitBreaker = {
                  enabled: true,
                  slidingWindowType: 'COUNT_BASED',
                  failureRateThreshold: 50,
                  slowCallRateThreshold: 100,
                  slidingWindowSize: 100,
                  permittedNumberOfCallsInHalfOpenState: 10,
                  minimumNumberOfCalls: 100,
                  slowCallDurationThreshold: '60000ms',
                  maxWaitDurationInHalfOpenState: '0ms',
                  waitDurationInOpenState: '60000ms',
                };
              }
              setForm(formCopy);
            }}
            color="primary"
          />
        </div>
      </CustomFormItem>

      <Spacer size={8} />

      {form?.resilience?.circuitBreaker?.enabled && (
        <>
          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.SlidingWindowType',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <Autocomplete
              options={['COUNT_BASED', 'TIME_BASED']}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              value={form?.resilience?.circuitBreaker?.slidingWindowType}
              onChange={(event, value) => {
                let formCopy = deepClone(form);
                formCopy.resilience.circuitBreaker.slidingWindowType = value;
                setForm(formCopy);
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.FailureRateThreshold',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={form?.resilience?.circuitBreaker?.failureRateThreshold}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.circuitBreaker.failureRateThreshold = event
                  .target.value
                  ? parseInt(event.target.value)
                  : 0;
                setForm(formCopy);
              }}
              type="number"
              onBlur={() =>
                circuitBreakerValidator.current.showMessageFor(
                  'failureRateThreshold'
                )
              }
              error={circuitBreakerValidator.current.message(
                'failureRateThreshold',
                form?.resilience?.circuitBreaker?.failureRateThreshold,
                `required|min:1,num|max:100,num`
              )}
              helperText={circuitBreakerValidator.current.message(
                'failureRateThreshold',
                form?.resilience?.circuitBreaker?.failureRateThreshold,
                `required|min:1,num|max:100,num`
              )}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.FailureRateThreshold"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.SlowCallRateThreshold',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={form?.resilience?.circuitBreaker?.slowCallRateThreshold}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.circuitBreaker.slowCallRateThreshold = event
                  .target.value
                  ? parseInt(event.target.value)
                  : 0;
                setForm(formCopy);
              }}
              type="number"
              onBlur={() =>
                circuitBreakerValidator.current.showMessageFor(
                  'slowCallRateThreshold'
                )
              }
              error={circuitBreakerValidator.current.message(
                'slowCallRateThreshold',
                form?.resilience?.circuitBreaker?.slowCallRateThreshold,
                `required|min:1,num|max:100,num`
              )}
              helperText={circuitBreakerValidator.current.message(
                'slowCallRateThreshold',
                form?.resilience?.circuitBreaker?.slowCallRateThreshold,
                `required|min:1,num|max:100,num`
              )}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.SlowCallRateThreshold"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.SlowCallRateThreshold',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={form?.resilience?.circuitBreaker?.slowCallRateThreshold}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.circuitBreaker.slowCallRateThreshold = event
                  .target.value
                  ? parseInt(event.target.value)
                  : 0;
                setForm(formCopy);
              }}
              type="number"
              onBlur={() =>
                circuitBreakerValidator.current.showMessageFor(
                  'slowCallRateThreshold'
                )
              }
              error={circuitBreakerValidator.current.message(
                'slowCallRateThreshold',
                form?.resilience?.circuitBreaker?.slowCallRateThreshold,
                `required|min:1,num|max:100,num`
              )}
              helperText={circuitBreakerValidator.current.message(
                'slowCallRateThreshold',
                form?.resilience?.circuitBreaker?.slowCallRateThreshold,
                `required|min:1,num|max:100,num`
              )}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.SlowCallRateThreshold"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.SlidingWindowSize',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={form?.resilience?.circuitBreaker?.slidingWindowSize}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.circuitBreaker.slidingWindowSize = event
                  .target.value
                  ? parseInt(event.target.value)
                  : 0;
                setForm(formCopy);
              }}
              type="number"
              onBlur={() =>
                circuitBreakerValidator.current.showMessageFor(
                  'slidingWindowSize'
                )
              }
              error={circuitBreakerValidator.current.message(
                'slidingWindowSize',
                form?.resilience?.circuitBreaker?.slidingWindowSize,
                `required|min:1,num`
              )}
              helperText={circuitBreakerValidator.current.message(
                'slidingWindowSize',
                form?.resilience?.circuitBreaker?.slidingWindowSize,
                `required|min:1,num`
              )}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.SlidingWindowSize"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.PermittedNumberOfCallsInHalfOpenState',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={
                form?.resilience?.circuitBreaker
                  ?.permittedNumberOfCallsInHalfOpenState
              }
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.circuitBreaker.permittedNumberOfCallsInHalfOpenState =
                  event.target.value ? parseInt(event.target.value) : 0;
                setForm(formCopy);
              }}
              type="number"
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.PermittedNumberOfCallsInHalfOpenState"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.MinimumNumberOfCalls',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={form?.resilience?.circuitBreaker?.minimumNumberOfCalls}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.circuitBreaker.minimumNumberOfCalls = event
                  .target.value
                  ? parseInt(event.target.value)
                  : 0;
                setForm(formCopy);
              }}
              type="number"
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.MinimumNumberOfCalls"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.SlowCallDurationThreshold',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={
                form?.resilience?.circuitBreaker?.slowCallDurationThreshold
              }
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.circuitBreaker.slowCallDurationThreshold =
                  event.target.value;
                setForm(formCopy);
              }}
              placeholder="Ex. 100ms"
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.SlowCallDurationThreshold"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.MaxWaitDurationInHalfOpenState',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={
                form?.resilience?.circuitBreaker?.maxWaitDurationInHalfOpenState
              }
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.circuitBreaker.maxWaitDurationInHalfOpenState =
                  event.target.value;
                setForm(formCopy);
              }}
              placeholder="Ex. 100ms"
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.MaxWaitDurationInHalfOpenState"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.WaitDurationInOpenState',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={form?.resilience?.circuitBreaker?.waitDurationInOpenState}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.circuitBreaker.waitDurationInOpenState =
                  event.target.value;
                setForm(formCopy);
              }}
              placeholder="Ex. 100ms"
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.WaitDurationInOpenState"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>
        </>
      )}

      <Spacer size={8} />

      <CustomFormItem labelWidth={LABEL_WIDTH350}>
        {formChanged ? (
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              if (
                form.resilience &&
                form.resilience.circuitBreaker &&
                form.resilience.circuitBreaker.enabled
              ) {
                if (circuitBreakerValidator.current.allValid()) {
                  circuitBreakerValidator.current.hideMessages();
                  handleUpdateMesh(form, clusterId, handleReturnToServiceList);
                } else {
                  forceUpdate(1);
                  circuitBreakerValidator.current.showMessages();
                }
              } else {
                handleUpdateMesh(form, clusterId, handleReturnToServiceList);
              }
            }}
          >
            {intl.formatMessage({ id: 'global.submit' })}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{
              opacity: 0.3,
            }}
          >
            {intl.formatMessage({ id: 'global.submit' })}
          </Button>
        )}

        <Spacer size={8} />

        <Button
          variant="outlined"
          color="primary"
          disableElevation
          onClick={() => {
            handleReturnToServiceList();
          }}
        >
          {intl.formatMessage({
            id: 'base.cancel',
          })}
        </Button>
      </CustomFormItem>
    </div>
  );
}

export default CircuitBreaker;
