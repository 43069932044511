import throughput from './zh_md/throughput.md';
import latency from './zh_md/latency.md';
import uv from './zh_md/uv.md';
import error_rate from './zh_md/error_rate.md';
import http_status_code from './zh_md/http_status_code.md';
import service_health from './zh_md/service_health.md';
import top from './zh_md/top.md';
const zhCN = {
  'home.page.metric.throughput.title': '吞吐量',
  'home.page.metric.latency.title': '延时',
  'home.page.metric.uv.title': '唯一访客',
  'home.page.metric.error.rate.title': '异常率',
  'home.page.metric.http.status.code.title': 'HTTP状态码',
  'home.page.metric.http.service.health.title': '服务健康状态',
  'home.page.metric.top.title': '排行榜前5',
  'home.page.metric.throughput.help.md': throughput,
  'home.page.metric.latency.help.md': latency,
  'home.page.metric.uv.help.md': uv,
  'home.page.metric.error.rate.help.md': error_rate,
  'home.page.metric.http.status.code.help.md': http_status_code,
  'home.page.metric.http.service.health.help.md': service_health,
  'home.page.metric.top.help.md': top,

  'home.page.traffic': '流量',
  'home.page.service': '服务',
  'home.page.top': '排名',
  'home.page.topology': '拓扑图',
  'home.page.alertTrend': '警报趋势',
  'home.page.recentEvents': '最近事件',

  'home.page.topology.tracing': '追踪',
  'home.page.topology.architecture': '部署',

  'home.page.top.hot': '最热',
  'home.page.top.slow': '最慢',
  'home.page.top.error': '错误',

  'home.page.autoRefresh': '自动刷新',

  'home.page.sla.siteEventStatus': '网站事件状态',
};

export default zhCN;
