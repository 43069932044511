import { SLoginApi as LoginApi } from '@/apis/service';
import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import toolbox from '../../_common/utils/toolbox';
import { parseResponseError } from '../_common/utils/utils';
import './InviteRegisterPage.less';
import { useFormik, useFormikContext } from 'formik';
import TextField from '@material-ui/core/TextField';
import * as yup from 'yup';
import settings from '@/_common/utils/settings';
import LoginLeftContainer from '@/app/base/LoginLeftContainer';
import { makeStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import userImg from '@/app/_common/assets/user.svg';
import emailImg from '@/app/_common/assets/email.svg';
import passwordImg from '@/app/_common/assets/password.svg';
import LightTooltip from '@/app/_common/components/LightTooltip';
import validateCorrectImg from '@/app/_common/assets/validate-correct.svg';
import usePasswordValidator from './usePasswordValidator';
import PasswordTooltipTitle from './PasswordTooltipTitle';
import LanguageSwitch from '@/app/_common/components/languageSwitch/LanguageSwitch';
import Manual from '@/app/_common/components/Manual';

import { useIntl } from 'react-intl';
const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiInputBase-root': {
      height: '46px',
      fontSize: 14,
    },
  },
}));

yup.addMethod(yup.string, 'strongPassword', strongPasswordMethod);

function strongPasswordMethod() {
  return this.test('strongPasswordTest', null, function (value) {
    const { path, createError } = this;
    switch (Boolean(value)) {
      case !/^(?=.*[a-z])/.test(value):
        return createError({
          path,
          message: 'Password must include lowercase letter',
        });
      case !/^(?=.*[A-Z])/.test(value):
        return createError({
          path,
          message: 'Password must include uppercase letter',
        });
      case !/^(?=.*[0-9])/.test(value):
        return createError({ path, message: 'Password must include digit' });
      case !/^(?=.*[^a-zA-Z0-9])/.test(value):
        return createError({
          path,
          message: 'Password must include special character',
        });
      default:
        return true;
    }
  });
}

const validationSchema = yup.object({
  username: yup
    .string('Enter your username')
    .trim()
    .min(4, 'Username must be at least 4 characters')
    .max(64, 'Username contains a maximum of 64 characters')
    .matches(
      /^[\w]+$/,
      'Username must contain only letters, numbers, and underscores'
    )
    .required('Username is required'),
  email: yup.string('Enter your email').email().required('Email is required'),

  //密码必须是ascii字符
  password: yup
    .string('Enter your password')
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(64, 'Password contains a maximum of 64 characters')
    // eslint-disable-next-line no-control-regex
    .matches(/^[\x00-\x7F]+$/, 'Password must contain only ASCII characters')
    .strongPassword(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export default function InviteRegisterPage({ history, location }) {
  const classes = useStyles();

  const intl = useIntl();

  const state = location.state;
  const handleRegister = async (values) => {
    const { username, password, email } = values;
    try {
      const resp = await LoginApi.register({
        username,
        password,
        email,
        invitation_code: state?.code,
      });
      toolbox.success('Register success');
      history.replace(settings.LOGIN_PATH);
    } catch (err) {
      const message = parseResponseError(err);
      console.error('do register failure', message, err);
      toolbox.warn(`Register failure: ${message}`);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      email: state?.email,
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleRegister,
  });

  const { passwordField, validatorPassword } = usePasswordValidator();
  return (
    <div className="register-container">
      <LoginLeftContainer page="invite-register" />

      <div className="register-wrapper">
        <div className="register-header-language-switch">
          <div className="register-header-manual">
            <Manual size={20} />
          </div>
          <LanguageSwitch />
        </div>
        <div className="register-form">
          <div className="register-title">
            {intl.formatMessage({
              id: 'base.RegisterPage.CreateAccount',
            })}
          </div>
          <form onSubmit={formik.handleSubmit} className="custom-register-form">
            <div className="custom-register-item">
              <TextField
                fullWidth
                id="username"
                name="username"
                className={classes.textField}
                placeholder={intl.formatMessage({
                  id: 'base.RegisterPage.Username',
                })}
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ width: '58px', justifyContent: 'center' }}
                    >
                      <img src={userImg} alt="user" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="custom-register-item">
              <TextField
                fullWidth
                id="email"
                name="email"
                className={classes.textField}
                placeholder={intl.formatMessage({
                  id: 'base.RegisterPage.Email',
                })}
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ width: '58px', justifyContent: 'center' }}
                    >
                      <img src={emailImg} alt="email" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="custom-register-item">
              <LightTooltip
                disableHoverListener
                disableTouchListener
                title={<PasswordTooltipTitle passwordField={passwordField} />}
                placement="top-end"
                arrow
              >
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  className={classes.textField}
                  placeholder={intl.formatMessage({
                    id: 'base.RegisterPage.Password',
                  })}
                  type="password"
                  value={formik.values.password}
                  onChange={(e) => {
                    formik.handleChange(e);
                    validatorPassword(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ width: '58px', justifyContent: 'center' }}
                      >
                        <img src={passwordImg} alt="password" />
                      </InputAdornment>
                    ),
                  }}
                />
              </LightTooltip>
            </div>

            <div className="custom-register-item">
              <TextField
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                className={classes.textField}
                placeholder={intl.formatMessage({
                  id: 'base.RegisterPage.ConfirmPassword',
                })}
                type="password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ width: '58px', justifyContent: 'center' }}
                    >
                      <img src={passwordImg} alt="password" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <Button
              type="submit"
              bsStyle="primary"
              className="register-button"
              size="normal"
            >
              {intl.formatMessage({
                id: 'base.RegisterPage.Register',
              })}
            </Button>
          </form>

          <div className="other-option">
            {intl.formatMessage({
              id: 'base.RegisterPage.AlreadyAMember',
            })}
            <Button
              bsStyle="link"
              className="login-button"
              onClick={() => history.push(settings.LOGIN_PATH)}
            >
              {intl.formatMessage({
                id: 'base.RegisterPage.LogIn',
              })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
