import randomString from '@/app/_common/utils/random';
import _ from 'lodash';
import React from 'react';

const put = (id, global = false) => {
  return (target, name, descriptor) => {
    return {
      get: function () {
        const hub = global ? rootHub() : this.hub || this.props.hub;
        if (!hub) {
          throw new Error("Can't found available hub for @put !");
        }
        return hub.useSome(id)[0];
      },
      set: function () {
        throw new Error('can not set!');
      },
    };
  };
};

const toName = (id) => {
  if (typeof id === 'string') {
    return id;
  }
  if (typeof id === 'function') {
    return id.name;
  }
  return 'unknown';
};

class Hub {
  __name = null;
  __parent = null;
  __storeInstances = new Map();
  constructor(name, parent) {
    this.__name = name;
    this.__parent = parent;
  }
  get name() {
    return this.__name;
  }
  get parent() {
    return this.__parent;
  }
  createSubHub = (name = 'def-sub-hub') => {
    return new Hub(name, this);
  };
  removeSome = (...ids) => {
    if (!Array.isArray(ids)) {
      throw new Error('param ids must be an array');
    }
    return ids.map((id) => {
      return this.__removeStore(id);
    });
  };
  useSome = (...ids) => {
    if (!Array.isArray(ids)) {
      throw new Error('param ids must be an array');
    }
    return ids.map((id) => {
      return this.__useStore(id);
    });
  };
  __useStore = (id) => {
    const me = this;
    if (this.hasStoreInstance(id, false)) {
      return this.__storeInstances.get(id);
    }
    if (this.parent && this.parent.hasStoreInstance(id)) {
      return this.parent.__useStore(id);
    }
    let target;
    if (typeof id === 'function') {
      target = id;
    } else {
      throw new Error(`mstore:${toName(id)} is not a function!`);
    }

    const instance = new target(this);

    Object.defineProperty(instance, 'hub', {
      value: me,
      writable: false,
    });

    this.__storeInstances.set(id, instance);
    return instance;
  };
  __removeStore = (id) => {
    const me = this;
    if (this.hasStoreInstance(id, false)) {
      this.__storeInstances.delete(id);
      return true;
    }
    if (this.parent && this.parent.hasStoreInstance(id)) {
      return this.parent.__removeStore(id);
    }

    return false;
  };

  hasStoreInstance = (id, searchParent = true) => {
    if (searchParent && this.parent && this.parent.hasStoreInstance(id)) {
      return true;
    }
    return this.__storeInstances.has(id);
  };

  release = () => {
    this.__storeInstances.clear();
  };
}
const createHub = (name = 'root-hub') => {
  return new Hub(name, null);
};

const withNewHub = (WrappedComponent, specialName = null) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      if (props.hub) {
        this.hub = props.hub;
      } else {
        const name =
          specialName || `${WrappedComponent.name}-${randomString(5)}`;
        this.hub = rootHub().createSubHub(name);
      }
    }

    componentWillUnmount() {
      if (!this.props.hub) {
        this.hub.release();
      }
      // delete this.hub;
    }
    render() {
      return <WrappedComponent {...this.props} hub={this.hub} />;
    }
  };
};

let _rootHub = null;

const rootHub = () => {
  if (!_rootHub) {
    _rootHub = createHub();
  }
  return _rootHub;
};
export { put, createHub, withNewHub, rootHub };
