import PaperComponent from '@/app/_common/components/PaperComponent';
const theme = {
  props: {
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
    },
    MuiButtonBase: {
      // 需要应用的属性
      disableRipple: true, // 在整个应用中都不会有涟漪效果 💣！
    },
    MuiAutocomplete: {
      PaperComponent: PaperComponent,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: 'rgba(47, 84, 235, 0.85)',
      main: '#2F54EB',
      dark: '#334f70',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FF0000',
    },
  },
  typography: {
    fontSize: 12,
    htmlFontSize: 16,
    fontFamily:
      'Outfit, Roboto ,"Helvetica Neue", "Helvetica", "Arial", sans-serif',
  },
  overrides: {
    MuiCard: {
      root: {
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
      },
    },
    MuiInputBase: {
      root: {
        height: '40px',
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: '#dedede',
        },
        '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: '#2F54EB',
        },
        '&.MuiOutlinedInput-root.Mui-error fieldset.MuiOutlinedInput-notchedOutline':
          {
            borderColor: '#FF0000 ',
          },
        '&.Mui-disabled input.Mui-disabled ': {
          cursor: 'not-allowed',
          borderColor: '#dedede',
        },
        '&:hover.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: '#dedede',
        },
      },
      multiline: {
        height: 'auto',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '2px',
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: '#dedede',
        },
      },
    },

    MuiFormHelperText: { contained: { marginLeft: 0 } },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
    },
  },
};
export const primaryColor = theme.palette.primary.main;
export const primaryLightColor = theme.palette.primary.light;
export const secondaryColor = theme.palette.secondary.main;

export default theme;
