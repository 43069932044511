//flow
import React from 'react';

export default function Spacer(props: {
  size: number,
  style: Object,
  alignRight: Boolean,
}) {
  const { size = 8 } = props;
  const style = {};
  if (props.alignRight) {
    style.marginLeft = 'auto';
  }
  return (
    <div
      style={{
        display: 'inline-block',
        width: `${size}px`,
        ...style,
        ...props.style,
      }}
    ></div>
  );
}
