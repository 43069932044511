export default {
  'dashboard.customized.panel.groupBy.service': 'Group by Service',
  'dashboard.customized.panel.groupBy.instance': 'Group by Instance',
  'tracing.list.query.status.code.escape.info': 'has been changed to normal.',
  'tracing.list.query.status.code.info':
    'MegaEase defines the range of statusCode: 200～299 as the "normal state". When the value range of statusCode is entered, it will be automatically escaped to normal for data query.',
  'access.log.details.title': 'Access Log Details',

  'app.service.topn.table.label.service': 'Service',
  'app.service.topn.table.label.instance': 'Instance',
  'app.service.topn.table.label.hostName': 'Host Name',
  'app.service.topn.table.label.sortValue': 'Sort Value',
  'app.service.topn.table.label.percent': 'Percent',
  'app.service.topn.table.label.method': 'Method',

  'app.service.topn.sort.options.highThroughput': 'High Throughput',
  'app.service.topn.sort.options.errors': 'Errors',
  'app.service.topn.sort.options.slowResponse': 'Slow Response',
  'app.service.topn.sort.options.gcHighCount': 'GC High Count',
  'app.service.topn.sort.options.gcTimeLongest': 'GC Time Longest',
  'app.service.topn.sort.options.memoryHighUsed': 'Memory High Used',

  'app.alert.rules.submit': 'Submit',
  'app.alert.rules.return': 'Return',
  'app.alert.rules.confirm': 'Confirm',
  'app.alert.rules.cancel': 'Cancel',
  'app.alert.rules.tips': 'Tips',
  'app.alert.rules.tips.return.desc':
    'Discard current edit content and return to the alert rules page?',
  'app.alert.rules.tips.confirm.desc': 'Are you sure to sumbit current data?',

  'app.alert.rules.status': 'Status',
  'app.alert.rules.status.running': 'Running',
  'app.alert.rules.status.stopped': 'Stopped',
  'app.alert.rules.name': 'Name',
  'app.alert.rules.type': 'Type',
  'app.alert.rules.level': 'Level',
  'app.alert.rules.service': 'Service',
  'app.alert.rules.desc': 'Description',
  'app.alert.rules.notification': 'Notification',
  'app.alert.rules.metricsSetting': 'Metrics',
  'app.alert.rules.LogMatch': 'Log Match',
  'app.alert.rules.Prefix': 'Prefix',
  'app.alert.rules.Suffix': 'Suffix',
  'app.alert.rules.MatchType': 'Match Type',
  'app.alert.rules.Text': 'Text',
  'app.alert.rules.RegularExpression': 'Regular Expression',

  'app.alert.rules.metrics': 'Metrics',
  'app.alert.rules.log': 'Log',
  'app.alert.rules.metricsName': 'Metrics Name',
  'app.alert.rules.function': 'Function',
  'app.alert.rules.groupBy': 'Group By',
  'app.alert.rules.predicate': 'Predicate',
  'app.alert.rules.threshold': 'Threshold',
  'app.alert.rules.count': 'Count',
  'app.alert.rules.duration': 'Duration',
  'app.alert.rules.addExtension': 'Add Extension',
  'app.alert.rules.re': 'Regular Expression',
  'app.alert.rules.promql': 'PromQL Expression',
  'app.alert.service.rules.binding': 'Service Rules Binding',

  'app.alert.service.rules.AlertRuleName': 'Alert Rule Name',
  'app.alert.service.rules.CreateAlertRule': 'Create Alert Rule',
  'app.alert.service.rules.Name': 'Name',
  'app.alert.service.rules.Level': 'Level',
  'app.alert.service.rules.Status': 'Status',

  'app.alert.service.rules.Examples': 'Examples',
  'app.alert.service.rules.Builder': 'Builder',
  'app.alert.service.rules.Functions': 'Functions',
  'app.alert.service.rules.Grammar': 'Grammar',
  'app.alert.service.rules.helpid.Grammar':
    '[QUERYING PROMETHEUS](https://prometheus.io/docs/prometheus/latest/querying/basics/)',
  'app.alert.service.rules.ReplaceParameters':
    'Replace the parameters in the following expressions',

  'app.easeMonitor.logs.access.AccessLogPage.Category': 'Category',
  'app.easeMonitor.logs.access.AccessLogPage.ServicePrefix': 'Service Prefix',
  'app.easeMonitor.logs.access.AccessLogPage.TraceId': 'Trace Id',
  'app.easeMonitor.logs.access.AccessLogPage.SpanId': 'Span Id',
  'app.easeMonitor.logs.access.AccessLogPage.ParentSpanId': 'Parent Span Id',
  'app.easeMonitor.logs.access.AccessLogPage.ClientIp': 'Client Ip',
  'app.easeMonitor.logs.access.AccessLogPage.HostIpv4': 'Host Ipv4',
  'app.easeMonitor.logs.access.AccessLogPage.HostNamePrefix':
    'Host Name Prefix',
  'app.easeMonitor.logs.access.AccessLogPage.URLMethod': 'URL Method',
  'app.easeMonitor.logs.access.AccessLogPage.URLKeyword': 'URL Keyword',
  'app.easeMonitor.logs.access.AccessLogPage.RequestTimeGt': 'Request Time Gt',
  'app.easeMonitor.logs.access.AccessLogPage.RequestTimeLt': 'Request Time Lt',
  'app.easeMonitor.logs.access.AccessLogPage.ResponseSizeGt':
    'Response Size Gt',
  'app.easeMonitor.logs.access.AccessLogPage.ResponseSizeLt':
    'Response Size Lt',

  'app.easeMonitor.eventAlerts.alertRules.edit.AlertRuleEditStepper.RuleProperties':
    'Rule Properties',
  'app.easeMonitor.eventAlerts.alertRules.edit.AlertRuleEditStepper.RuleDetails':
    'Rule Details',
  'app.easeMonitor.eventAlerts.alertRules.edit.AlertRuleEditStepper.PreviewAndConfirm':
    'Preview And Confirm',

  'app.easeMonitor.eventAlerts.alertRules.edit.RegexConfigForm.AlarmKeywordRegularExpression':
    'Alarm Keyword Regular Expression',

  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.ScheduleSettings':
    'Schedule Settings',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.HasBeenSet':
    'Has Been Set',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.NotSet':
    'Not Set',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.RuleName':
    'Rule Name',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.Level':
    'Level',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.RuleStatus':
    'RuleStatus',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.NotTakeEffect':
    'NotTakeEffect',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.TakeEffect':
    'TakeEffect',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.Category':
    'Category',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.Metric':
    'Metric',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.Log': 'Log',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.TakeEffectService':
    'TakeEffectService',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.AlarmCycle':
    'AlarmCycle',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleBasicInfoForm.RuleDesc':
    'RuleDesc',

  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.MetricName':
    'Metric Name',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.Operator':
    'Operator',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.Threshold':
    'Threshold',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.OccurrenceNumber':
    'Occurrence Number',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.Duration':
    'Duration',
  'app.easeMonitor.eventAlerts.alertRules.edit.RuleMetricsForm.Next': 'Next',

  'app.easeMonitor.eventAlerts.alertRules.edit.ScheduleSettingPanel.StartTime':
    'Start Time',
  'app.easeMonitor.eventAlerts.alertRules.edit.ScheduleSettingPanel.EndTime':
    'End Time',
  'app.easeMonitor.eventAlerts.alertRules.edit.ScheduleSettingPanel.Weekly':
    'Weekly',
  'app.easeMonitor.eventAlerts.alertRules.edit.ScheduleSettingPanel.Monthly':
    'Monthly',

  'app.easeMonitor.eventAlerts.alertRules.AlertRulesDetailPanel.Types': 'Types',
  'app.easeMonitor.eventAlerts.alertRules.AlertRulesDetailPanel.SpecifiedServices':
    'Specified Services',
  'app.easeMonitor.eventAlerts.alertRules.AlertRulesDetailPanel.MetricSettings':
    'Metric Settings',
  'app.easeMonitor.eventAlerts.alertRules.AlertRulesDetailPanel.AlertRules':
    'Alert Rules',

  'app.easeMonitor.eventAlerts.hotEvents.HotEventsPage.LastOccurrenceTime':
    'Last Occurrence Time',
  'app.easeMonitor.eventAlerts.hotEvents.HotEventsPage.LastOccurrenceService':
    'Last Occurrence Service',
  'app.easeMonitor.eventAlerts.hotEvents.HotEventsPage.LastOccurrenceInstance':
    'Last Occurrence Instance',

  'app.easeMonitor.tracing.zipkin-lens.components.TracePage.TraceSummaryHeader.enterFullscreen':
    'Enter Fullscreen',
  'app.easeMonitor.tracing.zipkin-lens.components.TracePage.TraceSummaryHeader.exitFullscreen':
    'Exit Fullscreen',

  'app.easeMonitor.logs.application.ApplicationLogPage.Service': 'Service',
  'app.easeMonitor.logs.application.ApplicationLogPage.Level': 'Level',
  'app.easeMonitor.logs.application.ApplicationLogPage.TraceId': 'Trace ID',

  'app.easeMonitor.topology.TopoGraph.InputServiceThenEnter':
    'Input Service Then Enter',
  'app.easeMonitor.topology.TopoGraph.InputNameThenEnter':
    'Input Name Then Enter',

  TraceID: 'Trace ID',
  Method: 'Method',
  Url: 'Url',
  StatusCode: 'Status Code',
  SqlMd5: 'Sql Md5',
  JdbcUrl: 'Jdbc Url',
  RedisMethod: 'Redis Method',
  RedisKey: 'Redis Key',
  KafkaTopic: 'Kafka Topic',
  RabbitmqQueue: 'Rabbitmq Queue',
  RabbitmqRouteKey: 'Rabbitmq Route Key',
  MaxCount: 'Max Count',

  Duration: 'Duration',
  Services: 'Services',
  Depth: 'Depth',
  TotalSpans: 'Total Spans',
  Span: 'Span',
  Map: 'Map',
  Logs: 'Logs',
  Request: 'Request',
  Cache: 'Cache',
  Resource: 'Resource',

  SpanId: 'Span Id',
  ParentId: 'Parent Id',
  SpanName: 'Span Name',
  ServiceName: 'Service Name',
  Timestamp: 'Timestamp',
  Tags: 'Tags',
  Annotations: 'Annotations',
  HideAnnotations: 'Hide Annotations',
  ShowAllAnnotations: 'Show All Annotations',

  StartTime: 'Start Time',
  RelativeTime: 'Relative Time',
  Address: 'Address',
  Service: 'Service',
  Instance: 'Instance',
  Time: 'Time',
  NoTimestampInCurrentSpan: 'No timestamp in current span',
  GoToLogs: 'Go To Logs',

  JVMMemoryUtilizationAvg: 'JVM Memory Utilization [avg]',
  JVMGCCountChangeRatio: 'JVM GC Count Change [ratio]',
  JVMGCTimeChangeRatio: 'JVM GC Time Change [ratio]',

  RequestThroughtout: 'Request Throughtout',
  APIResponseCodes: 'API Response Codes',
  RequestExecutionTime: 'Request Execution Time',

  JDBCRequestThroughtout: 'JDBC Request Throughtout',
  JDBCRequestErrorRate: 'JDBC Request Error Rate',
  JDBCRequestExecutionTime: 'JDBC Request Execution Time',

  CacheKey: 'Cache Key',

  RedisClientThroughtout: 'Redis Client Throughtout',
  RedisClientExecutionTime: 'Redis Client Execution Time',

  CPUUtilization: 'CPU Utilization',
  Memory: 'Memory',
  Network: 'Network',
  Disk: 'Disk',

  ThroughputRatio: 'Throughput Ratio',
  APIResponseCode: 'API Response Code',
  ExecutionsMilliseconds: 'Executions Milliseconds',
  ExecutionsErrorRatio: 'Executions Error Ratio',
  ErrorRatio: 'Error Ratio',

  ExchangeRoutingKeyThroughputRatio: 'Exchange-RoutingKey Throughput Ratio',
  ExchangeRoutingKeyExecutionsMilliseconds:
    'Exchange-RoutingKey Executions Milliseconds',
  QueueThroughputRatio: 'Queue Throughput Ratio',
  QueueExecutionsMilliseconds: 'Queue Executions Milliseconds',

  JVMGCCountRatio: 'JVM GC Count [ratio]',
  JVMGCTimeRatio: 'JVM GC Time [ratio]',
  JVMUsedMemoryAvg: 'JVM Used Memory [avg]',
  JVMInitialMemoryAvg: 'JVM Initial Memory [avg]',

  ExchangeRoutingKey: 'Exchange-RoutingKey',
  Queue: 'Queue',
  Topic: 'Topic',

  SlowQuery: 'Slow Query',
  InnoDBData: 'InnoDB Data',
  AbortedAndLock: 'Aborted And Lock',
  Connection: 'Connection',
  LockingRate: 'Locking Rate',
  NetworkTraffic: 'Network Traffic',

  HostCPUUtilization: 'Host CPU Utilization',
  PodCPUUsage: 'Pod CPU Usage',
  HostMemory: 'Host Memory',
  PodMemoryUsage: 'Pod Memory Usage',
  HostDiskRead: 'Host Disk Read',
  HostDiskWrite: 'Host Disk Write',
  HostNetRecv: 'Host Net Recv',
  HostNetSent: 'Host Net Sent',
  HostDisk: 'Host Disk',
  HostDiskTotal: 'Host Disk Total',
  HostDiskUsed: 'Host Disk Used',
  HostDiskIO: 'Host Disk IO',
  HostNetErrIn: 'Host Net Err In',
  HostNetErrOut: 'Host Net Err Out',
  HostSystemLoad: 'Host System Load',

  HitRate: 'Hit Rate',
  MemFragmentationRatio: 'Mem Fragmentation Ratio',
  CommandsProcessed: 'Commands Processed',
  Connections: 'Connections',
  ConnectedSlaves: 'Connected Slaves',

  ConsumerAndLag: 'Consumer and Lag',
  TopicPartitionOffset: 'Topic Partition Offset',
  ConsumerGroupLag: 'Consumer Group Lag',
  ConsumerGroupPartitionCount: 'Consumer Group Partition Count',
  AllTopics: 'All Topics',
  AllTopicsMessagesInM1Rate: 'All Topics Messages In M1 Rate',
  AllTopicsBytesInM1Rate: 'All Topics Bytes In M1 Rate',
  AllTopicsBytesOutM1Rate: 'All Topics Bytes Out M1 Rate',
  AllTopicsProduceRequestsPerSec: 'All Topics Produce Requests Per Sec',
  AllTopicsProduceMessageConversionsPerSec:
    'All Topics Produce Message Conversions Per Sec',
  PerTopic: 'Per Topic',
  PerTopicMessagesInM1Rate: 'Per Topic Messages In M1 Rate',
  PerTopicBytesInM1Rate: 'Per Topic Bytes In M1 Rate',
  PerTopicBytesOutM1Rate: 'Per Topic Bytes Out M1 Rate',
  PerTopicTotalProduceRequestsPerSec:
    'Per Topic Total Produce Requests Per Sec',
  ReplicaManager: 'Replica Manager',
  ReplicaManagerUnderReplicatedPartitions:
    'Replica Manager Under Replicated Partitions',
  ReplicaManagerOfflineReplicaCount: 'Replica Manager Offline Replica Count',
  ReplicaManagerLeaderCount: 'Replica Manager Leader Count',
  ReplicaManagerPartitionCount: 'Replica Manager Partition Count',
  Brokers: 'Brokers',
  LeaderElectionRateAndTime: 'Leader Election Rate and Time',
  UncleanLeaderElectionsPerSec: 'Unclean Leader Elections Per Sec',
  ActiveControllerCount: 'Active Controller Count',
  GlobalTopicCount: 'Global Topic Count',
  GlobalPartitionCount: 'Global Partition Count',
  JavaCpuUseParSecond: 'Java Cpu Use Par Second',
  JavaMemoryPool: 'Java Memory Pool',
  JavaThreadCount: 'Java Thread Count',
  JavaGCCount: 'Java GC Count',
  JavaGCTime: 'Java GC Time',
  Host: 'Host',
  ConsumerGroup: 'Consumer Group',

  GlobalCounts: 'Global Counts',
  QueuedMessages: 'Queued Messages',
  Messages: 'Messages',
  Exchange: 'Exchange',
  FileDescriptors: 'File Descriptors',
  Processes: 'Processes',

  RequestsRatio: 'Requests(ratio)',
  ConnectionsRatio: 'Connections(ratio)',

  SearchQuery: 'Search Query',
  SearchCount: 'Search Count',
  SearchTime: 'Search Time',
  JVMAndGC: 'JVM And GC',
  GCCountRate: 'GC Count Rate',
  GCTimeRate: 'GC Time Rate',
  Translog: 'Translog',
  TranslogOperation: 'Translog Operation',
  TranslogOperationSizeInBytes: 'Translog Operation Size In Bytes',
  Documents: 'Documents',
  DocumentsCount: 'Documents Count',
  DocumentsIndexedRate: 'Documents Indexed Rate',
  DocumentsDeletedRate: 'Documents Deleted Rate',
  DocumentsMergedRate: 'Documents Merged Rate',
  DocumentsMergedBytes: 'Documents Merged Bytes',

  ClusterName: 'Cluster Name',
  PodName: 'Pod Name',
  NodeName: 'Node Name',
  Name: 'Name',
  CPUUsageMilliCores: 'CPU Usage (milliCores)',
  MemoryUsage: 'Memory Usage',
  NetworkingThroughputByThePod: 'Networking throughput by the pod',
  SendingThroughput: 'Sending throughput',
  ReceivingThroughput: 'Receiving throughput',

  HTTPThroughputRatio: 'HTTP Throughput Ratio',
  HTTPAPIResponseCode: 'HTTP API Response Code',
  HTTPExecutionsMilliseconds: 'HTTP Executions Milliseconds',
  JDBCThroughputRatio: 'JDBC Throughput Ratio',
  JDBCExecutionsErrorRatio: 'JDBC Executions Error Ratio',
  JDBCExecutionsMilliseconds: 'JDBC Executions Milliseconds',
  RedisClient: 'Redis Client',
  RedisThroughputRatio: 'Redis Throughput Ratio',
  RedisExecutionsMilliseconds: 'Redis Executions Milliseconds',
  KafkaProducer: 'Kafka Producer',
  KafkaProducerThroughputRatio: 'Kafka Producer Throughput Ratio',
  KafkaProducerExecutionsMilliseconds: 'Kafka Producer Executions Milliseconds',
  KafkaConsumer: 'Kafka Consumer',
  KafkaConsumerThroughputRatio: 'Kafka Consumer Throughput Ratio',
  KafkaConsumerExecutionsMilliseconds: 'Kafka Consumer Executions Milliseconds',
  RabbitMQProducer: 'RabbitMQ Producer',
  RabbitMQConsumer: 'RabbitMQ Consumer',

  TagFilters: 'Tag Filters',
  TagName: 'Tag Name',
  Operator: 'Operator',
  TagValue: 'Tag Value',
  Interval: 'Interval',
  Count: 'Count',
  Unit: 'Unit',
  GroupBy: 'Group By',
  GroupByValue: 'Group By Value',

  MoreFilters: 'More Filters',
  Error: 'Error',
  CopyTagKey: 'Copy Tag Key',
  CopyTagValue: 'Copy Tag Value',
  Attention: 'Attention',
  FailWithAlreadyBound: 'The value of this selector may fail if the element in the current page already has services and instances bound to it',
};
