const enUS = {
  'app.settings.notification.Targets.Type': 'Type',
  'app.settings.notification.Targets.Name': 'Name',
  'app.settings.notification.Targets.Config': 'Config',
  'app.settings.notification.Targets.TargetsForm': 'Targets Form',
  'app.settings.notification.Targets.Email': 'Email',
  'app.settings.notification.Targets.PhoneNumber': 'Phone Number',
  'app.settings.notification.Targets.WebhookURL': 'Webhook URL',

  'app.settings.notification.History.Target': 'Target',
  'app.settings.notification.History.Result': 'Result',
  'app.settings.notification.History.Time': 'Time',
  'app.settings.notification.History.Title': 'Title',
  'app.settings.notification.History.Content': 'Content',
  'app.settings.notification.History.Error': 'Error',

  'app._common.components.Notification.index.Notification': 'Notification',
  'app._common.components.Notification.index.MarkAllAsRead': 'Mark All As Read',

  'app.settings.notification.NotificationCenter.Title': 'Title',
  'app.settings.notification.NotificationCenter.Category': 'Category',
  'app.settings.notification.NotificationCenter.Source': 'Source',
  'app.settings.notification.NotificationCenter.Content': 'Content',
  'app.settings.notification.NotificationCenter.Resource': 'Resource',
  'app.settings.notification.NotificationCenter.MarkAllAsRead':
    'Mark all as read',
  'app.settings.notification.NotificationCenter.OnlyUnread': 'Only Unread',
  'app.settings.notification.NotificationCenter.Application': 'Application',
  'app.settings.notification.NotificationCenter.CreatedAt': 'Created At',
  'app.settings.notification.NotificationCenter.UpdatedAt': 'Updated At',
  'app.settings.notification.NotificationCenter.UpdatedBy': 'Updated By',
  'app.settings.notification.NotificationCenter.MessageDetail':
    'Message BalanceDetail',

  'app.settings.notification.NotificationCenterDetail.ViewDetail':
    'View BalanceDetail',

  'app.settings.notification.PushManagement.SMSUsagePerMonth':
    'SMS Usage/Month',
  'app.settings.notification.PushManagement.EmailUsagePerMonth':
    'Email Usage/Month',
  'app.settings.notification.PushManagement.SlackUsagePerMonth':
    'Slack Usage/Month',
  'app.settings.notification.PushManagement.DiscordUsagePerMonth':
    'Discord Usage/Month',
  'app.settings.notification.PushManagement.MessageType': 'Message Type',
  'app.settings.notification.PushManagement.PushSubscription':
    'Push Subscription',
  'app.settings.notification.PushManagement.PushDetail': 'Push BalanceDetail',
  'app.settings.notification.PushManagement.PushSetting': 'Push Setting',
  'app.settings.notification.PushManagement.SerialNumber': 'Serial Number',
  'app.settings.notification.PushManagement.Target': 'Target',
  'app.settings.notification.PushManagement.CountryCode': 'Country Code',
  'app.settings.notification.PushManagement.Addresses': 'Addresses',
  'app.settings.notification.PushManagement.Address': 'Address',
  'app.settings.notification.PushManagement.Description': 'Description',

  'app.settings.notification.PushLog.Address': 'Address',
  'app.settings.notification.PushLog.Description': 'Description',
  'app.settings.notification.PushLog.Fail': 'Whether or not the push failed?',
  'app.settings.notification.PushLog.FailMessage': 'Fail Message',
  'app.settings.notification.PushLog.JumpToMessageDetail':
    'Jump To Message BalanceDetail',

  'app.settings.alertRule.RuleTemplate.Name': 'Name',
  'app.settings.alertRule.RuleTemplate.Type': 'Type',
  'app.settings.alertRule.RuleTemplate.Level': 'Level',
  'app.settings.alertRule.RuleTemplate.EventType': 'Event Type',
  'app.settings.alertRule.RuleTemplate.CreateRuleTemplate':
    'Create Rule Template',
  'app.settings.alertRule.RuleTemplateForm.Rules': 'Rules',
  'app.settings.alertRule.RuleTemplateForm.Description': 'Description',
  'app.settings.alertRule.RuleTemplateForm.ResolvedDescription':
    'Resolved Description',

  'app.settings.doc.AppAccess.index.SelectLanguage': 'Select Language',
  'app.settings.doc.AppAccess.index.AddData': 'Add Data',
  'app.settings.doc.AppAccess.index.ApplicationName': 'Application Name',
  'app.settings.doc.AppAccess.index.UniqueName':
    'Use a unique name to find your data later: ',
  'app.settings.doc.AppAccess.index.Zone': 'Zone',
  'app.settings.doc.AppAccess.index.Name': 'Name',
  'app.settings.doc.AppAccess.index.Domain': 'Domain',
  'app.settings.doc.AppAccess.index.Service': 'Service',
  'app.settings.doc.AppAccess.index.Tracing': 'Tracing',
  'app.settings.doc.AppAccess.index.JavaConfiguration': 'Java Configuration',
  'app.settings.doc.AppAccess.index.GoConfiguration': 'Go Configuration',
  'app.settings.doc.AppAccess.index.PHPConfiguration': 'PHP Configuration',
  'app.settings.doc.AppAccess.index.DotNetConfiguration': '.NET Configuration',
  'app.settings.doc.AppAccess.index.Global': 'Global',
  'app.settings.doc.AppAccess.index.TracingEnabled': 'Tracing Enabled',
  'app.settings.doc.AppAccess.index.MetricEnabled': 'Metric Enabled',
  'app.settings.doc.AppAccess.index.Sampled': 'Sampled',
  'app.settings.doc.AppAccess.index.TracingSampled': 'Tracing Sampled',
  'app.settings.doc.AppAccess.index.Metric': 'Metric',
  'app.settings.doc.AppAccess.index.DownloadEaseAgent': 'Download EaseAgent',
  'app.settings.doc.AppAccess.index.DownloadConfig': 'Download Config',
  'app.settings.doc.AppAccess.index.AboutConfig': 'About Config',
  'app.settings.doc.AppAccess.index.JavaAgentConfigDoc':
    'Java Agent Config Doc',
  'app.settings.doc.AppAccess.index.GoSDKConfigDoc': 'Go SDK Config Doc',
  'app.settings.doc.AppAccess.index.PHPSDKConfigDoc': 'PHP SDK Config Doc',
  'app.settings.doc.AppAccess.index.DotNetSDKConfigDoc': '.NET SDK Config Doc',
  'app.settings.doc.AppAccess.index.SubmitAndDownload': 'Submit And Download',
  'app.settings.doc.AppAccess.index.RestartApplication': 'Restart Application',
  'app.settings.doc.AppAccess.index.HowToUse': 'How To Use',
  'app.settings.doc.AppAccess.index.DeployYourApplicationForJava':
    'Deploy your application to start using the Java Agent to send data to MegaEase Cloud',
  'app.settings.doc.AppAccess.index.DeployYourApplicationForGo':
    'Deploy your application to start using the Go SDK to send data to MegaEase Cloud',
  'app.settings.doc.AppAccess.index.DeployYourApplicationForPHP':
    'Deploy your application to start using the PHP SDK to send data to MegaEase Cloud',
  'app.settings.doc.AppAccess.index.DeployYourApplicationForDotNet':
    'Deploy your application to start using the .NET SDK to send data to MegaEase Cloud',
  'app.settings.doc.AppAccess.index.HowToUseEaseAgentInHost':
    'How To Use EaseAgent In Host',
  'app.settings.doc.AppAccess.index.HowToUseEaseAgentInDocker':
    'How To Use EaseAgent In Docker',
  'app.settings.doc.AppAccess.index.HowToUseGoSDK': 'How To Use Go SDK?',
  'app.settings.doc.AppAccess.index.HowToUsePHPSDK': 'How To Use PHP SDK?',
  'app.settings.doc.AppAccess.index.HowToUseDotNetSDK': 'How To Use .NET SDK?',
  'app.settings.doc.AppAccess.index.SeeData': 'See Data',
  'app.settings.doc.AppAccess.index.SeeYourData': 'See Your Data',
  'app.settings.doc.AppAccess.index.ClickTheButton':
    'Click the button and give us a few minutes or less. We will let you know when we have received your data, and you can view the data at that time.',

  'app.settings.titleid': 'Description',

  'app.settings.helpid.Sampled':
    'The value of the samplded must be between 0 and 1.',

  'app.settings.twoFactorauthentication': 'Two-factor Authentication',
  'app.settings.twoFactorauthentication.setup': 'Setup',

  'app.settings.2faEnabled': 'Two factor authentication is enabled.',
  'app.settings.2faDisabled': 'Two factor authentication is disabled.',
  'app.settings.2faEnabled.tips':
    'Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log to in.',
  'app.settings.disable2fa': 'Disable Two-factor Authentication',
  'app.settings.enable2fa': 'Enable Two-factor Authentication',
  'app.settings.Two-factormethods': 'Two-factor methods',
  'app.settings.change': 'Change',

  'app.settings.2faDisabled.warning': 'Warning',
  'app.settings.2faDisabled.warning.tips':
    'If you disable two-factor authentication, you will not be able to log in to your account using two-factor authentication.',
  'app.settings.2faDisabled.warning.tips1': 'Type 2FA code to continue',
  'app.settings.2faDisabled.warning.tips2': 'Enter 2FA code',

  'app.settings.Setupauthenticationapp': 'Setup authentication app',
  'app.settings.Setupauthenticationapp.tips':
    'Use a phone app like 1Password, Authy, LastPass Authenticator, Or Microsoft Authenticator etc. to get 2FA codes when prompted during sign-in.',
  'app.settings.Setupauthenticationapp.scan': 'Scan the QR code',
  'app.settings.Setupauthenticationapp.scan.tips':
    'Use an authenticator app from your phone to scan. If you are unable to scan,',
  'app.settings.Setupauthenticationapp.scan.tips1': 'enter this text code',
  'app.settings.Setupauthenticationapp.scan.tips2': 'instead.',
  'app.settings.Setupauthenticationapp.verify':
    'Verify the two consecutive codes from the app',
  'app.settings.Setupauthenticationapp.verify.tips1': 'Enter the first code',
  'app.settings.Setupauthenticationapp.verify.tips2': 'Enter the second code',
  'app.settings.Setupauthenticationapp.secret': 'Your two-factor secret',

  'app.settings.2f2aNotEnabled.tips':
    ' Two factor authentication is not enabled yet.',
  'app.settings.2f2aNotEnabled.tips1':
    'Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log to in.',
  'app.settings.setupApp': 'Setup using an app',
  'app.settings.setupApp.tips':
    '  Use an application on your phone to get two-factor authentication codes when prompted. We recommend using cloud-based TOTP apps such as: 1Password, Authy, lastPass Authenticator, or Microsoft Authenticator.',
  'app.settings.back': 'Back to perivious page',

  'app.settings.NewUser': 'New User',
  'app.settings.UserName': 'User Name',
  'app.settings.DisableUser': 'Disable User',
  'app.settings.EnableUser': 'Enable User',
  'app.settings.DifferVersion': 'Differ Version',
  'app.settings.Tips': 'Tips',
  'app.settings.NewPasswordIs': 'The new password is ',
  'app.settings.ResetPassword': 'Reset Password',
  'app.settings.ConfirmResetPassword': "Confirm to reset the user's password?",
  'app.settings.SureResetPassword': 'Are you sure to reset the password?',
  'app.settings.DeactivateUser': 'Deactivate User',
  'app.settings.ConfirmDeactivateUser': 'Confirm to Deactivate this User?',
  'app.settings.Status': 'Status',
  'app.settings.Activated': 'Activated',
  'app.settings.Terminated': 'Terminated',
  'app.settings.TenantName': 'Tenant Name',
  'app.settings.Email': 'Email',
  'app.settings.Password': 'Password',
  'app.settings.FieldRequired': 'This field is required',
  'app.settings.FieldMinLength': 'Minimum length is 6',
  'app.settings.FieldMaxLength': 'Maximum length is 20',
  'app.settings.FieldPattern': 'Please enter the correct email address',

  'app.settings.User': 'User',
  'app.settings.Resource': 'Resource',
  'app.settings.Method': 'Method',
  'app.settings.RequestAt': 'Request At',
  'app.settings.ElapseTime': 'Elapse Time',
  'app.settings.NeedParamId': 'Need param id',
  'app.settings.Loading': 'Loading',
  'app.settings.AuditLogDetail': 'Audit Log BalanceDetail',
  'app.settings.Payload': 'Payload',

  'app.settings.ImageName': 'Image Name',
  'app.settings.ArtifactCount': 'Artifact Count',
  'app.settings.PullCount': 'Pull Count',
  'app.settings.MegaeaseRegistry': 'Megaease Registry',
  'app.settings.StorageLimit': 'Storage Limit',
  StorageUsageLimit: 'Storage Usage/Limit',
  'app.settings.Hide': 'Hide',
  'app.settings.Show': 'Show',

  'app.settings.Address': 'Address',
  'app.settings.Username': 'Username',

  'app.settings.Member': 'Member',

  'app.settings.VendorName': 'Vendor Name',
  'app.settings.IDCLocation': 'IDC Location',
  'app.settings.SpecName': 'Spec Name',
  'app.settings.CPUNumber': 'CPU Number',
  'app.settings.Memory': 'Memory',
  'app.settings.MemoryGB': 'Memory(GB)',
  'app.settings.Disk': 'Disk',
  'app.settings.DiskGB': 'Disk(GB)',
  'app.settings.GPUType': 'GPU Type',
  'app.settings.GPUNumber': 'GPU Number',
  'app.settings.VRAM': 'Video RAM',
  'app.settings.VRAMGB': 'Video RAM(GB)',
  'app.settings.Available': 'Available',
  'app.settings.Inactive': 'Inactive',
  'app.settings.Inused': 'Inused',
  'app.settings.Price': 'Price',
  'app.settings.CreateStock': 'Create Stock',
  'app.settings.CreateSuccess': 'Create success',
  'app.settings.CreateFailure': 'Create failure',
  'app.settings.UpdateStock': 'Update Stock',
  'app.settings.UpdateSuccess': 'Update success',
  'app.settings.UpdateFailure': 'Update failure',
  'app.settings.RenewSuccess': 'Renew success',
  'app.settings.RenewFailure': 'Renew failure',
  'app.settings.ExpandSuccess': 'Expand success',
  'app.settings.ExpandFailure': 'Expand failure',
  'app.settings.DeleteSuccess': 'Delete success',
  'app.settings.DeleteFailure': 'Delete failure',

  'app.settings.Operation': 'Operation',
  'app.settings.Monitor': 'Monitor',
  'app.settings.Others': 'Others',

  CreateStorage: 'Create Storage',
  UpdateStorage: 'Update Storage',
  StoragePrice: 'Storage Price',
  StoragePriceTips: '{price}/10GB/Hour',
  StorageSize: 'Storage Size',

  Storage: 'Storage',
  UsedQuota: 'Used/Quota',
  ServerUsedQuota: 'Server Used/Quota',
  CloneUsedQuota: 'Clone Used/Quota',
  useLimit: 'use limit',

  Account: 'Account',
  ViewHistory: 'View History',

  NextPlan: 'Next Plan',
  NextPrice: 'Next Price',
  BeforePlan: 'Before Plan',
  AfterPlan: 'After Plan',
  Reason: 'Reason',
  NextQuota: 'Next Quota',
  Operator: 'Operator',
  Changes: 'Changes',
  Current: 'Current',
  WillRemove: 'Will Remove',
  Frozen: 'Frozen',
  NextPayment: 'Next Payment',
  Date: 'Date',
  PerMonth: 'Month',

  'app.settings.openInviteCode': 'Open Invite Code',
  'app.settings.closeInviteCode': 'Close Invite Code',
  'Invite User Information': 'Invite User Information',
  'Withdrawal List': 'Withdrawal List',
  username: 'Username',
  'Rebate amount': 'Rebate amount',
  'Invitation time': 'Invitation time',
  'Application Time': 'Application Time',
  'Withdrawal amount': 'Withdrawal amount',
  status: 'Status',
  amount: 'Amount',
  invitationCode: 'Invitation Code',
  copyLink: 'Copy Link',
  withdrawal: 'Withdrawal',
  withdrawMgmt: 'Withdrawal Management',
  notAvailable: 'Unavailable',
  withdrawlSuccess: 'The application for withdrawal has been submitted.',
  withdrawlTips: 'Please enter the legal withdrawal amount',

  Inviter: 'Inviter',
  Invitee: 'Invitee',
  InvitationTime: 'Invitation time',
  Amount: 'Amount',
  Rebate: 'Rebate',

  WithdrawalPerson: 'Withdrawal Person',
  WithdrawalAmount: 'Withdrawal Amount',
  WithdrawalStatus: 'Withdrawal Status',
  WithdrawalTime: 'Withdrawal Time',

  Applicant: 'Applicant',
  ApplicationTime: 'Application Time',
  paymentInfo: 'Payment Info',
  PaymentStatus: 'Payment Status',
  PaymentTime: 'Payment Time',
  orderNumber: 'Order Number',
  paymentSituation: 'Payment Situation',

  Paid: 'Paid',
  PaidSuccessfully: 'Paid Successfully',
  PleaseEnterPaymentSituation: 'Please enter payment situation',
  WithdrawalStatus0: 'Pending',
  WithdrawalStatus1: 'Paid',

  Number: 'Number',
  'user.roles': 'Roles',
  'user.roles.management': 'Roles Management',

  'stock.onTheShelf': 'Up',
  'stock.offTheShelf': 'Down',
  'stock.onTheShelf.success': 'Successfully put on the shelf',
  'stock.onTheShelf.failure': 'Failed to put on the shelf',
  'stock.offTheShelf.success': 'Successfully taken off the shelf',
  'stock.offTheShelf.failure': 'Failed to take off the shelf',
  'stock.onTheShelf.confirm':
    'Are you sure you want to put this item on the shelf?',
  'stock.offTheShelf.confirm':
    'Are you sure you want to take this item off the shelf?',
  'notification.CICD': 'CICD',
  onTheShelf: 'On the shelf',
  offTheShelf: 'Off the shelf',
  shelfStatus: 'Shelf Status',
  'app.settings.totalStock': 'Total Stock',
  'app.settings.discount': 'Discount',
  'app.settings.discountedPrice': 'Discounted Price',
  'app.settings.Spec': 'Spec',
  'app.settings.SortOrder': 'Sort Order',

  'gpu.spec.id': 'ID',
  'gpu.spec.name': 'name',
  'gpu.spec.gpuType': 'GPU Type',
  'gpu.spec.cpu': 'CPU',
  'gpu.spec.memory': 'Memory(GB)',
  'gpu.spec.gpuNum': 'GPU Quantity',
  'gpu.spec.vram': 'VRAM(GB)',
  'gpu.spec.disk': 'Disk(GB)',
  'gpu.spec.isVirtual': 'Is Virtual',
  'gpu.spec.sortOrder': 'Sort Order',
  'gpu.spec.isVirtual.yes': 'Yes',
  'gpu.spec.isVirtual.no': 'No',

  'gpu.tenant': 'Tenant',
  'gpu.resource': 'Resource',
  'gpu.resource.limit': 'Limit',

  maximumNumberOfGPUInstanceLists: 'Maximum number of GPU instance lists',
  maximumNumberOfRunningGPUInstances:
    'Maximum number of running GPU instances.',
  maximumNumberOfGPUStorageLists: 'Maximum number of GPU storage lists.',
  maximumValueForASingleGPUStorageUnitIsGB:
    'Maximum value for a single GPU storage, unit is GB.',
  maximumTotalGPUStorageValueUnitIsGB:
    'Maximum total GPU storage value, unit is GB.',
  maximumNumberOfGPUStorageTags: 'Maximum number of GPU storage tags.',
  maximumNumberOfCustomTemplates: 'Maximum number of custom templates.',
  maximumNumberOfTemplateCredentials: 'Maximum number of template credentials.',

  invitationCodeStatus: 'Status',
  invitationCodeStatus0: 'Disabled',
  invitationCodeStatus1: 'Enabled',
  rebatePermille: 'Rebate Permille',
  invitedAmount: 'Invited Amount',
  invitedRebate: 'Invited Rebate',
  calcAt: 'Calc At',
  'app.settings.DataCenter': 'Data Center',
  'Rebate information': 'Rebate information',
};

export default enUS;
