// @flow
import axios from '@/_common/utils/axios-wrapper';
import settings from '@/_common/utils/settings';

class SwaggerDocApi {
  getSwaggerResources() {
    return axios.get(
      settings.GATEWAY_API_ADDRESS + settings.SWAGGER_RESOURCES_PATH
    );
  }

  getApiDoc(path, name, code_version, branch) {
    if (!path.startsWith('/')) {
      path = '/' + path;
    }
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        path +
        '?name=' +
        name +
        '&code_version=' +
        code_version +
        '&branch=' +
        branch
    );
  }
}

export default new SwaggerDocApi();
