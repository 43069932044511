import axios from '@/_common/utils/axios-wrapper';
import settings from '@/_common/utils/settings';

const prefix = settings.EASE_MARKETING_MGMT;
const gpueyePrefix = settings.EASE_GPUEYE + '/platform/marketing';
const userPrefix = settings.EASE_GPUEYE + '/user/marketing';

class MarketingMgmtApi {
  getInviteCode() {
    return axios.get(prefix + '/invite-code', {
      autoError: false,
    });
  }
  getUserInviteCode(id) {
    return axios.get(prefix + `/user/${id}/invite-code`, {
      autoError: false,
    });
  }

  openInviteCode(id) {
    return axios.post(
      prefix + `/user/${id}/invite-code`,
      {},
      {
        autoError: false,
      }
    );
  }

  getUserInviteCodeInfo(params) {
    return axios.get(settings.EASE_BILLING + '/marketing/user-invite-code', {
      params: { ...params },
      autoError: false,
    });
  }

  // getInvitationRelatedInfo(params) {
  //   return axios.get(prefix + '/user-invite', {
  //     params: { ...params },
  //     autoError: false,
  //   });
  // }
  // getWithdrawalList(params) {
  //   return axios.get(prefix + '/cash-out-request', {
  //     params: { ...params },
  //     autoError: false,
  //   });
  // }
  // getWithdrawalDetail(id) {
  //   return axios.get(prefix + `/cash-out-request/${id}`, {
  //     autoError: false,
  //   });
  // }

  // paidWithdrawal(id, data = {}) {
  //   return axios.put(prefix + '/cash-out-request/' + id, data, {
  //     autoError: false,
  //   });
  // }

  checkUserInviteCodeList(userIdList) {
    return axios.get(gpueyePrefix + `/check-inviters`, {
      params: { userIdList },
      autoError: false,
    });
  }
  updateUserInviteCode(id, data) {
    return axios.put(gpueyePrefix + `/user/${id}/invite-code`, data, {
      autoError: false,
    });
  }
  getInvitationRelatedInfo(params) {
    return axios.get(gpueyePrefix + '/invited-users', {
      params: { ...params },
      autoError: false,
    });
  }
  getWithdrawalList(params) {
    return axios.get(gpueyePrefix + '/cash-out-request', {
      params: { ...params },
      autoError: false,
    });
  }
  getWithdrawalDetail(id) {
    return axios.get(gpueyePrefix + `/cash-out-request/${id}`, {
      autoError: false,
    });
  }
  paidWithdrawal(id, data = {}) {
    return axios.put(gpueyePrefix + '/cash-out-request/' + id, data, {
      autoError: false,
    });
  }
  getInviters(params) {
    return axios.get(gpueyePrefix + '/inviters', {
      params: { ...params },
      autoError: false,
    });
  }

  getUserInviteCodeOperations(id) {
    return axios.get(gpueyePrefix + `/user/${id}/invite-code`, {
      autoError: false,
    });
  }
  getUserInvitersMonthly(inviterId, params) {
    return axios.get(gpueyePrefix + `/inviters/${inviterId}/monthly-list`, {
      params: { ...params },
      autoError: false,
    });
  }
}

export default new MarketingMgmtApi();
