import React from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.05)',
    border: '1px solid #EAEAEA',
    bottom: '-5px',
    right: '0px',
  },
  arrow: {
    fontSize: 20,
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: theme.palette.common.white,
  },
}))(Tooltip);

export default LightTooltip;
