import workspace from './zh_md/workspace.md';
import taskqueue from './zh_md/taskqueue.md';
import reportlist from './zh_md/reportlist.md';
import env from './zh_md/env.md';
import variable from './zh_md/variable.md';
import testcase_path from './zh_md/testcase_path.md';
import testcase_header from './zh_md/testcase_header.md';
import testcase_bodycontent from './zh_md/testcase_bodycontent.md';
import testcase_checker from './zh_md/testcase_checker.md';
import testcase_extractor from './zh_md/testcase_extractor.md';
import workflow_steps from './zh_md/workflow_steps.md';
import testcase_postaction from './zh_md/testcase_postaction.md';
const zhCN = {
  'app.easeload._common.components.WorkspaceWrapper.needCreateFirst':
    '当前没有可用的Workspace，请先创建。',

  'app.easeload.data.not.save': '数据尚未保存，确定要退出吗?',

  'app.easeload._common.components.LeftSideTree.collection': '集合',
  'app.easeload._common.components.LeftSideTree.collection.create.title':
    '创建用于存放用例的集合',
  'app.easeload._common.components.LeftSideTree.CollectionCannotBeNull':
    '集合不能为空',
  'app.easeload._common.components.LeftSideTree.CollectionCannotBeRepeated':
    '集合不能重复',

  'app.easeload._common.components.LeftSideTree.case': '用例',
  'app.easeload._common.components.LeftSideTree.case.create.title':
    '创建此集合下的用例',
  'app.easeload._common.components.LeftSideTree.CaseCannotBeNull':
    '用例不能为空',
  'app.easeload._common.components.LeftSideTree.CaseCannotBeRepeated':
    '用例不能重复',

  'app.easeload._common.components.LeftSideTree.workflow': '工作流',
  'app.easeload._common.components.LeftSideTree.workflow.create.title':
    '创建工作流',
  'app.easeload._common.components.LeftSideTree.WorkflowCannotBeNull':
    '工作流不能为空',
  'app.easeload._common.components.LeftSideTree.WorkflowCannotBeRepeated':
    '工作流不能重复',

  'app.easeload._common.components.LeftSideTree.trafficmodel': '流量模型',
  'app.easeload._common.components.LeftSideTree.trafficmodel.create.title':
    '创建流量模型',
  'app.easeload._common.components.LeftSideTree.TrafficModelCannotBeNull':
    '流量模型不能为空',
  'app.easeload._common.components.LeftSideTree.TrafficModelCannotBeRepeated':
    '流量模型不能重复',

  'app.easeload._common.components.LeftSideTreeCase.VariablesManagement':
    '变量管理',
  'app.easeload._common.components.LeftSideTreeCase.VariablesManagement.title':
    '变量管理对应某个环境下具体的那些变量，变量可以直接在变量管理界面添加和删除，' +
    '也可以在所有允许下拉列表创建不存在项的场景中自动添加',

  'app.easeload._common.components.WorkspacePicker.CurrentWorkspace':
    '当前工作空间',

  'app.easeload._common.components.MenuBar.reset.title':
    '重新请求当前页面的数据，放弃修改的设置',
  'app.easeload._common.components.MenuBar.save.title':
    '保存当前页面的所有设置',

  'app.easeload.toppanel.Workspace.title': '工作空间',
  'app.easeload.toppanel.Workspace.help': workspace,

  'app.easeload.overview.OverviewPage.TaskQueue.title': '活跃的任务',
  'app.easeload.overview.OverviewPage.TaskQueue.help': taskqueue,

  'app.easeload.overview.OverviewPage.ReportList.title': '报告列表',
  'app.easeload.overview.OverviewPage.ReportList.help': reportlist,

  'app.easeload.toppanel.Env.title': '环境',
  'app.easeload.toppanel.Env.help': env,

  'app.easeload.toppanel.Variable.title': '变量',
  'app.easeload.toppanel.Variable.help': variable,

  'app.easeload.case.CaseMain.path.title': '路径',
  'app.easeload.case.CaseMain.path.help': testcase_path,

  'app.easeload.case.CaseMain.header.title': '请求头',
  'app.easeload.case.CaseMain.header.help': testcase_header,

  'app.easeload.case.CaseMain.bodycontent.title': '请求体内容',
  'app.easeload.case.CaseMain.bodycontent.help': testcase_bodycontent,

  'app.easeload.case.CaseMain.checker.title': '检查器',
  'app.easeload.case.CaseMain.checker.help': testcase_checker,

  'app.easeload.case.CaseMain.extractor.title': '变量提取器',
  'app.easeload.case.CaseMain.extractor.help': testcase_extractor,

  'app.easeload.case.CaseMain.postaction.title': '请求后动作',
  'app.easeload.case.CaseMain.postaction.help': testcase_postaction,

  'app.easeload.workflow.Main.Steps.title': '步骤集',
  'app.easeload.workflow.Main.Steps.help': workflow_steps,

  'app.easeload.myworkspaces.MyWorkspacesPage.WorkspaceList': '工作空间列表',
  'app.easeload.myworkspaces.MyWorkspacesPage.WorkspaceList.WorkspaceSlogan':
    '工作空间标语',
  'app.easeload.myworkspaces.MyWorkspacesPage.WorkspaceList.TitleCannotBeNull':
    '标题不能为空',
  'app.easeload.myworkspaces.MyWorkspacesPage.WorkspaceList.TitleCannotBeRepeated':
    '标题不能重复',
  'app.easeload.myworkspaces.MyWorkspacesPage.WorkspaceList.Rename': '重命名',

  'app.easeload.myworkspaces.MyWorkspacesPage.TaskQueue': '活跃的任务',
  'app.easeload.myworkspaces.MyWorkspacesPage.Workspace': '工作空间',
  'app.easeload.myworkspaces.MyWorkspacesPage.Slogan': '标语',
  'app.easeload.myworkspaces.MyWorkspacesPage.Type': '类型',
  'app.easeload.myworkspaces.MyWorkspacesPage.Status': '状态',
  'app.easeload.myworkspaces.MyWorkspacesPage.StartTime': '开始时间',
  'app.easeload.myworkspaces.MyWorkspacesPage.EndTime': '结束时间',
  'app.easeload.myworkspaces.MyWorkspacesPage.Elapse': '耗时',

  'app.easeload.myworkspaces.MyWorkspacesPage.ReportList': '报告列表',

  'app.easeload.overview.OverviewPage.RenameCurrentWorkspace': '重命名',
  'app.easeload.overview.OverviewPage.RenameCurrentWorkspace.title':
    '可对左边下拉列表中选中的当前工作空间进行重命名',
  'app.easeload.overview.OverviewPage.ExportCurrentWorkspace': '导出',
  'app.easeload.overview.OverviewPage.ExportCurrentWorkspace.title':
    '可将左边下拉列表中选中的当前工作空间导出为本地文件',

  'app.easeload.overview.OverviewPage.operation.video': '15秒了解工作空间',

  'app.easeload.overview.OverviewPage.ImportWorkspace': '导入',
  'app.easeload.overview.OverviewPage.ImportWorkspace.title':
    '可将本地工作空间文件导入到工作空间列表中，导入后的工作空间会在左边下拉列表中展现',
  'app.easeload.overview.OverviewPage.CreateWorkspace': '创建',
  'app.easeload.overview.OverviewPage.CreateWorkspace.title':
    '可手动创建工作空间，创建后的工作空间会在左边下拉列表中展现',

  'app.easeload.overview.OverviewPage.Slogan': '标语',
  'app.easeload.overview.OverviewPage.Title': '标题',
  'app.easeload.overview.OverviewPage.CreateMan': '创建人',
  'app.easeload.overview.OverviewPage.CreateTime': '创建时间',
  'app.easeload.overview.OverviewPage.LastModifiTime': '上次更新时间',
  'app.easeload.overview.OverviewPage.WorkspaceStatistics': '工作空间数据统计',
  'app.easeload.overview.OverviewPage.Cases': '用例集',
  'app.easeload.overview.OverviewPage.CollectionCount': '集合统计',
  'app.easeload.overview.OverviewPage.CaseCount': '用例统计',

  'app.easeload.overview.OverviewPage.Variables': '变量集',
  'app.easeload.overview.OverviewPage.EnvCount': '环境统计',
  'app.easeload.overview.OverviewPage.VariableCount': '变量统计',

  'app.easeload.overview.OverviewPage.Workflows': '工作流集',
  'app.easeload.overview.OverviewPage.WorkflowCount': '工作流集',

  'app.easeload.overview.OverviewPage.Tasks': '任务集',
  'app.easeload.overview.OverviewPage.RunnerCount': '运行任务统计',
  'app.easeload.overview.OverviewPage.CreatedCount': '创建任务统计',
  'app.easeload.overview.OverviewPage.FinishCount': '完成任务统计',

  'app.easeload.overview.OverviewPage.Reports': '报表集',
  'app.easeload.overview.OverviewPage.ReportCount': '报表统计',

  'app.easeload.case.CasePage.operation.video': '15秒了解测试用例',

  'app.easeload.case.CasePage.CreateFirst': '来创建你的第一个测试用例吧',

  'app.easeload.case.CaseMain.Method': '方法',
  'app.easeload.case.CaseMain.Endpoint': '端点',
  'app.easeload.case.CaseMain.Path': '路径',
  'app.easeload.case.CaseMain.Send': '发送',
  'app.easeload.case.CaseMain.Send.title':
    '用于测试当前设置好的用例，切换下方的选项卡可以看到不同类型的返回结果',

  'app.easeload.case.CaseMain.Request': '请求',
  'app.easeload.case.CaseMain.Request.Headers': '请求头',
  'app.easeload.case.CaseMain.Body': '请求体',
  'app.easeload.case.CaseMain.Checker': '检查器',
  'app.easeload.case.CaseMain.Extractor': '提取器',
  'app.easeload.case.CaseMain.Type': '类型',
  'app.easeload.case.CaseMain.Content': '内容',

  'app.easeload.case.CaseMain.Response': '响应',
  'app.easeload.case.CaseMain.Response.Headers': '响应头',
  'app.easeload.case.CaseMain.TestResult': '响应结果',
  'app.easeload.case.CaseMain.Checked': '检查结果',
  'app.easeload.case.CaseMain.Extracted': '提取结果',

  'app.easeload.case.CaseMain.TableRequestHeaders.Name': '名称',
  'app.easeload.case.CaseMain.TableRequestHeaders.Values': '值',
  'app.easeload.case.CaseMain.TableResponseHeaders.Name': '名称',
  'app.easeload.case.CaseMain.TableResponseHeaders.Values': '值',

  'app.easeload.case.CaseMain.TableRequestChecker.Type': '类型',
  'app.easeload.case.CaseMain.TableRequestChecker.Key': '关键词',
  'app.easeload.case.CaseMain.TableRequestChecker.Predictor': '预测器',
  'app.easeload.case.CaseMain.TableRequestChecker.Value': '值',
  'app.easeload.case.CaseMain.TableResponseChecked.Type': '类型',
  'app.easeload.case.CaseMain.TableResponseChecked.Key': '关键词',
  'app.easeload.case.CaseMain.TableResponseChecked.Predictor': '预测器',
  'app.easeload.case.CaseMain.TableResponseChecked.Value': '值',

  'app.easeload.case.CaseMain.TableRequestExtractor.Type': '类型',
  'app.easeload.case.CaseMain.TableRequestExtractor.Variable': '变量',
  'app.easeload.case.CaseMain.TableRequestExtractor.Path': '路径',
  'app.easeload.case.CaseMain.TableResponseExtracted.Type': '类型',
  'app.easeload.case.CaseMain.TableResponseExtracted.Variable': '变量',
  'app.easeload.case.CaseMain.TableResponseExtracted.Path': '路径',
  'app.easeload.case.CaseMain.TableResponseExtracted.Value': '值',

  'app.easeload.case.CaseMain.PostAction': '请求动作',
  'app.easeload.case.CaseMain.TableRequestPostAction.Action': '动作',
  'app.easeload.case.CaseMain.TableRequestPostAction.Target': '目标',

  'app.easeload.case.Variables.Env': '环境',
  'app.easeload.case.Variables.EnvManagement': '环境管理',
  'app.easeload.case.Variables.EnvManagement.title':
    '环境管理对应一般项目中的开发环境、测试环境和生产环境，' +
    '每个环境下管理多个变量，每个环境可以复制出新的环境，' +
    '不同环境之间的变量个数和名称必须相同',
  'app.easeload.case.Variables.AddVariable': '添加变量',
  'app.easeload.case.Variables.Type': '类型',
  'app.easeload.case.Variables.Name': '名称',
  'app.easeload.case.Variables.Start': '开始',
  'app.easeload.case.Variables.End': '结束',
  'app.easeload.case.Variables.Step': '步长',
  'app.easeload.case.Variables.Value': '值',

  'app.easeload.case.EnvManagement.EnvName': '环境名称',
  'app.easeload.case.EnvManagement.VariableCount': '变量统计',

  'app.easeload.workflow.Workflow.operation.video': '15秒了解工作流',

  'app.easeload.workflow.Workflow.CreateFirst': '来创建你的第一个工作流吧',

  'app.easeload.workflow.Main.Steps': '步骤集',
  'app.easeload.workflow.Main.Type': '类型',
  'app.easeload.workflow.Main.Title': '标题',
  'app.easeload.workflow.Main.Case': '用例',
  'app.easeload.workflow.Main.Step': '步骤',
  'app.easeload.workflow.Main.Step.create.title': '添加工作流步骤',
  'app.easeload.workflow.Main.Step.icon.TESTREF.title': '测试用例引用',
  'app.easeload.workflow.Main.Step.icon.WAIT.title': '等待',
  'app.easeload.workflow.Main.Step.icon.PARALLEL.title': '并行',
  'app.easeload.workflow.Main.Step.icon.LOOP.title': '循环',
  'app.easeload.workflow.Main.Step.icon.TRIGGER.title': '触发器',
  'app.easeload.workflow.Main.Step.case.detail.title':
    '与工作流相关的测试用例详情',
  'app.easeload.workflow.Main.Wait': '等待(时间单位)',
  'app.easeload.workflow.Main.Jitter': '抖动(时间单位)',
  'app.easeload.workflow.Main.Times': '次数',
  'app.easeload.workflow.Main.Workflow': '工作流',

  'app.easeload.workflow.Main.CaseOrStep': '用例或步骤',

  'app.easeload.trafficmodel.TrafficModelPage.operation.video':
    '15秒了解流量模型',

  'app.easeload.trafficmodel.TrafficModelPage.CreateFirst':
    '来创建你的第一个流量模型吧',

  'app.easeload.trafficmodel.MetricType': '指标类型',
  'app.easeload.trafficmodel.ComboBox': '组合框',
  'app.easeload.trafficmodel.MetricName': '指标名称',
  'app.easeload.trafficmodel.Value': '值',
  'app.easeload.trafficmodel.BreakControl': '断开控制',

  'app.easeload.trafficmodel.TrafficModel': '流量模型',
  'app.easeload.trafficmodel.Env': '环境',

  'app.easeload.trafficmodel.RampUp': '上升(5s)',
  'app.easeload.trafficmodel.LOAD.RampUp': '上升(1m)',
  'app.easeload.trafficmodel.SOAK.RampUp': '上升(2m)',
  'app.easeload.trafficmodel.STRESS.RampUp': '上升(2m)',
  'app.easeload.trafficmodel.CUSTOM.RampUp': '上升(10s)',

  'app.easeload.trafficmodel.StageTime': '阶段(2m)',
  'app.easeload.trafficmodel.CUSTOM.StageTime': '阶段(5m)',
  'app.easeload.trafficmodel.Attendees': '参与者(100)',

  'app.easeload.trafficmodel.CreateTask': '创建任务',
  'app.easeload.trafficmodel.Run': '运行',
  'app.easeload.trafficmodel.Run.title':
    '可运行当前的流量模型设置，并跳转到任务页面展示任务运行状态',
  'app.easeload.trafficmodel.PerformanceAnalysis': '是否进行性能分析？',

  'app.easeload.trafficmodel.Workflow': '工作流',
  'app.easeload.trafficmodel.MaxParallel': '最大并发数(1000)',
  'app.easeload.trafficmodel.HttpMaxConnections': 'HTTP最大连接数(1000)',
  'app.easeload.trafficmodel.HttpTimeout': 'HTTP超时(5s)',

  'app.easeload.trafficmodel.MaxDuration': '最大持续时间(10m)',
  'app.easeload.trafficmodel.SOAK.MaxDuration': '最大持续时间(2h)',
  'app.easeload.trafficmodel.STRESS.MaxDuration': '最大持续时间(2h)',
  'app.easeload.trafficmodel.SPIKE.MaxDuration': '最大持续时间(1h)',
  'app.easeload.trafficmodel.CUSTOM.MaxDuration': '最大持续时间(1h)',

  'app.easeload.trafficmodel.MaxAttendees': '最多参与者(100)',
  'app.easeload.trafficmodel.STRESS.MaxAttendees': '最多参与者(10000)',

  'app.easeload.trafficmodel.BaseAttendees': '基础参与者(10)',
  'app.easeload.trafficmodel.StageRampUp': '阶段上升(10s)',
  'app.easeload.trafficmodel.NormalAttendees': '一般参与者(10)',
  'app.easeload.trafficmodel.NormalRampUp': '一般上升(10s)',
  'app.easeload.trafficmodel.PeakTime': '峰值时间(1m)',
  'app.easeload.trafficmodel.PeakIntervalLow': '峰值最低间隔(5m)',
  'app.easeload.trafficmodel.PeakIntervalHigh': '峰值最高间隔(7m)',

  'app.easeload.task.TasksPage.operation.video': '15秒了解任务',

  'app.easeload.task.Summary': '概要',
  'app.easeload.task.Stage': '阶段',
  'app.easeload.task.Execution': '执行',
  'app.easeload.task.Task': '任务',
  'app.easeload.task.Title': '标题',
  'app.easeload.task.Status': '状态',
  'app.easeload.task.TrafficModels': '流量模型',
  'app.easeload.task.Workflows': '工作流',
  'app.easeload.task.Nodes': '节点',
  'app.easeload.task.Env': '环境',
  'app.easeload.task.TaskType': '任务类型',
  'app.easeload.task.CreateTime': '创建时间',
  'app.easeload.task.Start': '开始',
  'app.easeload.task.End': '结束',
  'app.easeload.task.Duration': '持续时间',
  'app.easeload.task.RequestTotal': '请求总数',
  'app.easeload.task.ErrPercent': '错误率(%)',
  'app.easeload.task.MaxActiveAttendees': '最大活动参与者',
  'app.easeload.task.AttendeeTotal': '参与者总数',
  'app.easeload.task.ActiveAttendees': '活动参与者',
  'app.easeload.task.ActiveConnections': '活动连接数',
  'app.easeload.task.IdleConnections': '空闲连接数',

  'app.easeload.task.Interface': '接口',
  'app.easeload.task.Count': '总数',
  'app.easeload.task.Errs': '错误数',
  'app.easeload.task.Qps': 'Qps(m1)',
  'app.easeload.task.P50': 'P50(毫秒)',
  'app.easeload.task.P95': 'P95(毫秒)',
  'app.easeload.task.P99': 'P99(毫秒)',
  'app.easeload.task.P999': 'P999(毫秒)',

  'app.easeload.task.Connections': '连接数',
  'app.easeload.task.ActiveAttendee': '活动参与者',
  'app.easeload.task.Estimate': '估算',
  'app.easeload.task.Elapse': '耗时',
  'app.easeload.task.cancel.title': '取消当前任务',

  'app.easeload.bottleneckanalysis.BottleneckAnalysis.operation.video':
    '15秒了解性能分析',

  'app.easeload.bottleneckanalysis.Service': '服务',
  'app.easeload.bottleneckanalysis.Api': '接口',
  'app.easeload.bottleneckanalysis.TimePoint': '分析时间点',

  'app.easeload.bottleneckanalysis.ExceptionalPoints': '异常点',
  'app.easeload.bottleneckanalysis.TreeGraphs': '树形图',

  'app.easeload.bottleneckanalysis.Weight': '权重',
  'app.easeload.bottleneckanalysis.Metric': '指标',
  'app.easeload.bottleneckanalysis.Diagnosis': '诊断',
  'app.easeload.bottleneckanalysis.Link': '链路',
  'app.easeload.bottleneckanalysis.Trace': '跟踪',
  'app.easeload.bottleneckanalysis.M1ErrorRate': 'M1错误率',
};

export default zhCN;
