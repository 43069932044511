// flow
import axios from '@/_common/utils/axios-wrapper';
import settings from '@/_common/utils/settings';

class RegistryApi {
  getRegistryTypes() {
    return axios.get(
      settings.GATEWAY_API_ADDRESS + '/v1/registry/registry-types'
    );
  }
  getServiceNamesUnderRegistry(registry) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/registry/registries/${registry}/service-names`
    );
  }
  getSummaryByRegistry(registry) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/registry/registries/${registry}/summary`,
      { autoError: false }
    );
  }
  getInstancesByConditions({ healthy, maintenance, registry, service }) {
    return axios.get(settings.GATEWAY_API_ADDRESS + `/v1/registry/instances`, {
      params: { healthy, maintenance, registry, service },
    });
  }
  updateMaintenanceMode(registry, instance, maintenance) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/registry/registries/${registry}/instances/${instance}/maintenance`,
      null,
      {
        params: { maintenance },
        autoError: false,
      }
    );
  }
  updateMetadata(
    registry,
    instance,
    updates: [{ key: string, value: string }],
    deletes: [string]
  ) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/registry/registries/${registry}/instances/${instance}/metadata`,
      { update: updates, delete: deletes },
      { autoError: false }
    );
  }
}
export default new RegistryApi();
