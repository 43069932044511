import { createBrowserHistory } from 'history';
// import { isEmpty } from 'lodash';
import settings from './settings';

const history = createBrowserHistory({ basename: settings.CONTEXT_PATH });
// const hasBasename = !isEmpty(settings.CONTEXT_PATH);
// let unListen = history.listen((tx, action) => {
//   if (hasBasename && tx.pathname.indexOf(settings.CONTEXT_PATH) > -1) {
//     const trimmed = tx.pathname.substring(settings.CONTEXT_PATH.length);
//     tx.pathname = trimmed;
//   }
// });
export { history };
