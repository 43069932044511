import toolbox from '@/_common/utils/toolbox';
import { FN_EMPTY } from './utils';

export function messageConfirm({ intl, callback = FN_EMPTY, message }) {
  toolbox.confirm({
    cancelText: intl.formatMessage({ id: 'global.cancel' }),
    okText: intl.formatMessage({ id: 'global.confirm' }),
    onOk: () => {
      if (callback) {
        callback();
      }
      return true;
    },
    title: intl.formatMessage({ id: 'global.confirm.tip' }),
    render: message,
  });
}
export function deleteConfirm(intl, callback: () => any) {
  messageConfirm({
    intl,
    callback,
    message: intl.formatMessage({ id: 'global.confirm.delete.text' }),
  });
}
export function successfulOperationTips(intl) {
  toolbox.success(intl.formatMessage({ id: 'global.op.success' }));
}

export function failOperationTips(intl) {
  toolbox.error(intl.formatMessage({ id: 'global.op.fail' }));
}
