import { action, flow, runInAction } from 'mobx';
import _ from 'lodash';
import { axiosExtra } from '../../_common/utils/axios-wrapper';
import { SLoginApi } from '@/apis/service';
import moment from 'moment';
import { history } from '../../_common/utils/routing';
import { computed, observable } from 'mobx';
import 'moment/locale/zh-cn';
import store from 'store';
import settings from '../../_common/utils/settings';
import marketingMgmtApi from '@/apis/marketing/marketing-mgmt-api';
import dayjs from 'dayjs';

const isCom = () => {
  const origin = window.location.origin;
  return origin.endsWith('megaease.com');
};
class AppModel {
  @observable accountInfo = null;
  @observable loading = false;
  @observable language = 'en-US';
  @observable languageDescription = 'English';
  @observable locale = 'enUS'; //'zhCN'
  @computed get authenticated() {
    return !_.isEmpty(this.accountInfo);
  }
  constructor() {
    const i18n = store.get('i18n');
    if (i18n !== 'en-US') {
      this.language = 'zh-CN';
      this.languageDescription = '中文';
      this.locale = 'zhCN';
      moment.locale('zh-cn');
      dayjs.locale('zh-cn');
    } else {
      this.language = 'en-US';
      this.languageDescription = 'English';
      this.locale = 'enUS';
      moment.locale('en');
      dayjs.locale('en');
    }
  }
}

class AppStore {
  model = new AppModel();

  @action
  tryAuthenticate = async () => {
    if (this.model.authenticated) {
      return;
    }
    if (_.isEmpty(axiosExtra.getAccessToken())) {
      this.model.loading = false;
      return;
    }
    this.model.loading = true;
    try {
      const response = await SLoginApi.loadMyAuth();
      // console.log('SLoginApi.loadMyAuth().response', response);
      if (response && response.data) {
        runInAction(() => {
          this.model.accountInfo = response.data;
          localStorage.setItem('authInfo', JSON.stringify(response.data));
          // console.log('get account info successful', response);
        });
      }
    } catch (error) {
      // console.log('get account info failure', error);
      runInAction(() => {
        axiosExtra.setAccessToken(null);
        this.model.accountInfo = null;
      });
    } finally {
      runInAction(() => {
        this.model.loading = false;
      });
    }
  };

  @action
  logout = async () => {
    const username = this.model.accountInfo?.username;
    this.model.loading = true;
    try {
      await SLoginApi.logout(username);
      runInAction(() => {
        this.model.accountInfo = null;
        axiosExtra.clearAll();
        localStorage.setItem('authInfo', null);
        // console.log(this.model.accountInfo, 'info');
        history.replace(settings.LOGIN_PATH);
      });
    } catch (err) {
    } finally {
      runInAction(() => {
        this.model.loading = false;
      });
    }
  };

  @action changePassword = () => {
    const username = this.model.accountInfo?.username;
    history.push({
      pathname: settings.CHANGE_PWD,
      state: username,
    });
    // this.model.loading = false;
    // this.model.accountInfo = null;
    // SLoginApi.logout(username).finally(() => {
    //   runInAction(() => {
    //     axiosExtra.clearAll();
    //     console.log(this.model.accountInfo, 'info');
    //     history.push({
    //       pathname: settings.CHANGE_PWD,
    //       state: username,
    //     });
    //   });
    // });
  };

  @action
  switchLanguage = (e) => {
    store.set('i18n', e);
    if (e === 'en-US') {
      this.model.language = 'en-US';
      this.model.languageDescription = 'English';
      this.model.locale = 'enUS';
      moment.locale('en');
      dayjs.locale('en');
    } else if (e === 'zh-CN') {
      this.model.language = 'zh-CN';
      this.model.languageDescription = '中文';
      this.model.locale = 'zhCN';
      moment.locale('zh-cn');
      dayjs.locale('zh-cn');
    }
  };
}
export default AppStore;
