import axios from '@/_common/utils/axios-wrapper';
import settings from '@/_common/utils/settings';
import fileDownload from 'js-file-download';

class OrderApi {
  createOrder(data) {
    return axios.post(settings.EASE_BILLING + '/orders/vm-quota', data, {
      autoError: false,
    });
  }

  getOrderListByOrderNo(order_no) {
    return axios.get(settings.EASE_BILLING + `/tenant-orders/${order_no}`, {
      autoError: false,
    });
  }
  getOrderListByOrderNoForOperation(order_no) {
    return axios.get(settings.EASE_BILLING_MGMT + `/orders/${order_no}`, {
      autoError: false,
    });
  }
  getPaymentData(payType, order_no) {
    return axios.get(
      settings.EASE_BILLING + `/orders/${order_no}/payment/${payType}`,
      {
        autoError: false,
      }
    );
  }
  getOrdersList(params) {
    return axios.get(settings.EASE_BILLING + '/tenant-orders', {
      params: {
        ...params,
      },
      autoError: false,
    });
  }
  refreshOrderPaymentStatus(order_no) {
    return axios.put(
      settings.EASE_BILLING + `/tenant-payment/refresh/${order_no}`,
      null,
      {
        autoError: false,
      }
    );
  }

  managerTenantOrders(params) {
    return axios.get(settings.EASE_BILLING_MGMT + '/orders', {
      params: {
        ...params,
      },
      autoError: false,
    });
  }

  managerTenantOrderDetail(order_no) {
    return axios.get(settings.EASE_BILLING_MGMT + `/orders/${order_no}`, {
      autoError: false,
    });
  }

  managerTenantOrdersExport = (params) => {
    return axios
      .get(settings.EASE_BILLING_MGMT + `/orders/_export`, {
        params,
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `orders.csv`);
      });
  };

  getInvoiceByNo(order_no) {
    return axios.get(settings.EASE_BILLING + `/tenant-costs/${order_no}`, {
      autoError: false,
    });
  }
  getInvoiceByNoForOperation(order_no) {
    return axios.get(settings.BillingOperations + `/tenant-costs/${order_no}`, {
      autoError: false,
    });
  }
  refreshOrderStatus(order_no) {
    return axios.put(
      settings.EASE_BILLING_MGMT + `/payment/refresh/${order_no}`,
      null,
      {
        autoError: false,
      }
    );
  }
}

export default new OrderApi();
