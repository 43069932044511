import { useIntl } from 'react-intl';
import React, { useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import Spacer from '@/_common/components/Spacer';
import { deepClone, getTenantId } from '@/app/_common/utils/utils';
import CustomFormItem from '@/app/_common/components/customFormItem';
import {
  handleUpdateMesh,
  handleUpdateMeshForCircuitBreakerAndRetryValidation,
  LABEL_WIDTH350,
} from '@/app/service/_common/util';
import Switch from '@material-ui/core/Switch';
import SimpleReactValidator from 'simple-react-validator';
import HelpPicker from '@/app/_common/components/helper/helpPicker2/HelpPicker';
import WarningSVG from '@/app/service/_common/assets/warning.svg';
import TextButton from '@/app/easeMiddleware/base/components/TextButton';

function Retry({
  classes,
  clusterId,
  form,
  setForm,
  handleReturnToServiceList,
  handleUpdateMesh,
  formChanged,
}) {
  const intl = useIntl();

  const retryValidator = useRef(
    new SimpleReactValidator({
      validators: {},
    })
  );
  const [, forceUpdate] = useState();

  return (
    <div className={classes.formContainer}>
      <div className={classes.tipsWrapper}>
        <img src={WarningSVG} />
        <div className={classes.tips}>
          {intl.formatMessage({
            id: 'app.service.mesh.Service.Governance.Retry.Warning',
          })}
        </div>
        <div
          className={classes.tipsLink}
          onClick={() => {
            window.open('https://resilience4j.readme.io/docs/retry', '_blank');
          }}
        >
          https://resilience4j.readme.io/docs/retry
        </div>
      </div>

      <Spacer size={8} />

      <CustomFormItem
        label={intl.formatMessage({
          id: 'app.service.mesh.Service.ServiceForm.Enabled',
        })}
        labelWidth={LABEL_WIDTH350}
        style={{
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '20px',
          color: '#3B3B3B',
        }}
      >
        <div
          style={{
            margin: '0 0 0 -12px',
          }}
        >
          <Switch
            checked={form?.resilience?.retry?.enabled ? true : false}
            onChange={(event) => {
              let formCopy = deepClone(form);
              if (!formCopy.resilience) {
                formCopy.resilience = {};
              }
              if (!formCopy.resilience.retry) {
                formCopy.resilience.retry = {};
              }
              formCopy.resilience.retry.enabled = event.target.checked;
              if (!event.target.checked) {
                formCopy.resilience.retry = {
                  enabled: false,
                };
              } else {
                formCopy.resilience.retry = {
                  enabled: true,
                  maxAttempts: 3,
                  waitDuration: '500ms',
                  backOffPolicy: 'random',
                  randomizationFactor: 0.1,
                };
              }
              setForm(formCopy);
            }}
            color="primary"
          />
        </div>
      </CustomFormItem>

      <Spacer size={8} />

      {form?.resilience?.retry?.enabled && (
        <>
          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.MaxAttempts',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={form?.resilience?.retry?.maxAttempts}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.retry.maxAttempts = event.target.value
                  ? parseInt(event.target.value)
                  : 0;
                setForm(formCopy);
              }}
              type="number"
              onBlur={() =>
                retryValidator.current.showMessageFor('maxAttempts')
              }
              error={retryValidator.current.message(
                'maxAttempts',
                form?.resilience?.retry?.maxAttempts,
                `required|min:1,num`
              )}
              helperText={retryValidator.current.message(
                'maxAttempts',
                form?.resilience?.retry?.maxAttempts,
                `required|min:1,num`
              )}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.MaxAttempts"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.WaitDuration',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={form?.resilience?.retry?.waitDuration}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.retry.waitDuration = event.target.value;
                setForm(formCopy);
              }}
              placeholder="Ex. 100ms"
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.WaitDuration"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.BackOffPolicy',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={form?.resilience?.retry?.backOffPolicy}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.retry.backOffPolicy = event.target.value;
                setForm(formCopy);
              }}
              onBlur={() =>
                retryValidator.current.showMessageFor('backOffPolicy')
              }
              error={retryValidator.current.message(
                'backOffPolicy',
                form?.resilience?.retry?.backOffPolicy,
                `required|in:random,exponential`
              )}
              helperText={retryValidator.current.message(
                'backOffPolicy',
                form?.resilience?.retry?.backOffPolicy,
                `required|in:random,exponential`
              )}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.BackOffPolicy"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>

          <Spacer size={8} />

          <CustomFormItem
            label={intl.formatMessage({
              id: 'app.service.mesh.Service.ServiceForm.RandomizationFactor',
            })}
            labelWidth={LABEL_WIDTH350}
          >
            <TextField
              value={form?.resilience?.retry?.randomizationFactor}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(event) => {
                let formCopy = deepClone(form);
                formCopy.resilience.retry.randomizationFactor = parseFloat(
                  event.target.value
                );
                setForm(formCopy);
              }}
              onBlur={() =>
                retryValidator.current.showMessageFor('randomizationFactor')
              }
              error={retryValidator.current.message(
                'randomizationFactor',
                form?.resilience?.retry?.randomizationFactor,
                'required|numeric|min:0,num|max:1,num'
              )}
              helperText={retryValidator.current.message(
                'randomizationFactor',
                form?.resilience?.retry?.randomizationFactor,
                'required|numeric|min:0,num|max:1,num'
              )}
              type="number"
              inputProps={{
                min: '0',
                max: '1',
                step: '0.1',
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <HelpPicker
                        titleid="app.service.mesh.titleid"
                        helpid="app.service.mesh.helpid.RandomizationFactor"
                      />
                    </InputAdornment>
                  </>
                ),
              }}
              className={classes.fieldValueWidth}
            />
          </CustomFormItem>
        </>
      )}

      <Spacer size={8} />

      <CustomFormItem labelWidth={LABEL_WIDTH350}>
        {formChanged ? (
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              if (
                form.resilience &&
                form.resilience.retry &&
                form.resilience.retry.enabled
              ) {
                if (retryValidator.current.allValid()) {
                  retryValidator.current.hideMessages();
                  handleUpdateMesh(form, clusterId, handleReturnToServiceList);
                } else {
                  forceUpdate(1);
                  retryValidator.current.showMessages();
                }
              } else {
                handleUpdateMesh(form, clusterId, handleReturnToServiceList);
              }
            }}
          >
            {intl.formatMessage({ id: 'global.submit' })}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{
              opacity: 0.3,
            }}
          >
            {intl.formatMessage({ id: 'global.submit' })}
          </Button>
        )}

        <Spacer size={8} />

        <Button
          variant="outlined"
          color="primary"
          disableElevation
          onClick={() => {
            handleReturnToServiceList();
          }}
        >
          {intl.formatMessage({
            id: 'base.cancel',
          })}
        </Button>
      </CustomFormItem>
    </div>
  );
}

export default Retry;
