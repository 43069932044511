import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/styles';
import rightImg from '@/app/_common/assets/right.svg';
import Box from '@material-ui/core/Box';

const TooltipMenu = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: '#3b3b3b',
    fontSize: 14,
    padding: 0,
    boxShadow: 'none',
  },
  arrow: {
    color: '#3b3b3b',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
    },
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: '9999 !important',
  },
  paper: {
    border: '1px solid #ECEDF0',
    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 20px',
    borderRadius: '4px',
    padding: '0 4px',
  },
  item: {
    color: '#3b3b3b',
    borderRadius: '4px',
    marginBottom: '4px',
    paddingRight: '4px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  itemWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: '16px',
    height: '16px',
  },
}));
const Menu = (props) => {
  const { menuList } = props;
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <MenuList
      // autoFocusItem={open}
      // id="menu-list-grow"
      // onKeyDown={handleListKeyDown}
      >
        {menuList.map((item, index) => {
          return (
            <MenuItem
              onClick={() => {
                item.handler();
              }}
              className={classes.item}
              key={item.id}
              disabled={item.disabled}
              // selected={item.selected}
            >
              <div className={classes.itemWrapper}>
                {/* {item.icon ? (
                  <img src={item.icon} alt="" className={classes.icon} />
                ) : null} */}
                {item.title}
                <Box width={'20px'} height="20px" ml="8px">
                  {item.selected ? (
                    <img src={rightImg} alt="" className={classes.icon} />
                  ) : null}
                </Box>
              </div>
            </MenuItem>
          );
        })}
      </MenuList>
    </Paper>
  );
};

const TooltipForTopbar = (props) => {
  const { placement = 'bottom', menuList = null, component } = props;

  return (
    <TooltipMenu
      title={component ? component : <Menu menuList={menuList} />}
      placement={placement}
      interactive
      open={props.open}
    >
      {props.children}
    </TooltipMenu>
  );
};

export default TooltipForTopbar;
