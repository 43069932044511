export default {
  'app.traffic.cluster.ConnectCluster': '连接集群',
  'app.traffic.cluster.needConnectFirst': '当前没有可用的集群，请先连接集群。',
  'app.traffic.cluster.label.apiAddress': '接口地址',
  'app.traffic.cluster.label.address': '地址',
  'app.traffic.cluster.label.start': '启动',
  'app.traffic.cluster.label.heartbeat': '心跳',
  'app.traffic.cluster.label.status': '状态',
  'app.traffic.cluster.label.role': '角色',
  'app.traffic.cluster.label.tps': 'TPS',
  'app.traffic.cluster.label.latency': '延迟',
  'app.traffic.cluster.dialog.editCluster': '编辑集群',
  'app.traffic.cluster.label.apiAddressFormatTips':
    "每一行的格式为'http[s]://ip:port'",

  'app.gateway.ImportServer': '导入服务器',
  'app.gateway.CreateServer': '创建服务器',
  'app.gateway.Name': '名称',
  'app.gateway.Port': '端口',
  'app.gateway.Status': '状态',
  'app.gateway.Running': '运行中',
  'app.gateway.Failed': '已失败',
  'app.gateway.Export': '导出',
  'app.gateway.Routes': '路由集',
  'app.gateway.Host': '主机',
  'app.gateway.Path': '路径',
  'app.gateway.IPFilter': 'IP过滤器',
  'app.gateway.Headers': '请求头集',
  'app.gateway.Methods': '请求方法集',
  'app.gateway.Pipeline': '流水线',
  'app.gateway.View': '查看',

  'app.gateway.BasicProperties': '基本属性',
  'app.gateway.KeepAlive': '保持存活',
  'app.gateway.KeepAliveTimeout': '保持存活超时',
  'app.gateway.Https': 'Https',
  'app.gateway.MaxConnections': '最大连接数',
  'app.gateway.SSLs': 'SSL集',
  'app.gateway.Cert': '认证',
  'app.gateway.Key': '关键字',
  'app.gateway.OptionalProperties': '可选属性',
  'app.gateway.Http3': 'Http3',
  'app.gateway.CacheSize': '缓存大小',
  'app.gateway.XForwardedFor': 'X 转发为',
  'app.gateway.AutoCert': '自动认证',
  'app.gateway.ClientMaxBodySize': '客户端最大请求体大小',
  'app.gateway.GlobalFilter': '全局过滤器',
  'app.gateway.AccessLogFormat': '访问日志格式',
  'app.gateway.RouterKind': '路由器类型',
  'app.gateway.Tracing': '追踪',
  'app.gateway.ServiceName': '服务名称',
  'app.gateway.Attributes': '属性集',
  'app.gateway.Tags': '标签',
  'app.gateway.SpanLimits': '跨度限制',
  'app.gateway.AttributeValueLengthLimit': '属性值长度限制',
  'app.gateway.AttributeCountLimit': '属性计数限制',
  'app.gateway.EventCountLimit': '事件计数限制',
  'app.gateway.LinkCountLimit': '链接数限制',
  'app.gateway.AttributePerEventCountLimit': '每个事件数的属性限制',
  'app.gateway.AttributePerLinkCountLimit': '每个链接数的属性限制',
  'app.gateway.SampleRate': '采样率',
  'app.gateway.BatchLimits': '批量限制',
  'app.gateway.MaxQueueSize': '最大队列大小',
  'app.gateway.BatchTimeout': '批量超时',
  'app.gateway.ExportTimeout': '导出超时',
  'app.gateway.MaxExportBatchSize': '最大导出批处理尺寸',
  'app.gateway.Exporter': '导出器',
  'app.gateway.Jaeger': 'Jaeger',
  'app.gateway.OTLP': 'OTLP',
  'app.gateway.HeaderFormat': '头部格式',
  'app.gateway.Value': '值',
  'app.gateway.Zipkin': 'Zipkin',
  'app.gateway.HostPort': '主机端口',
  'app.gateway.ServerURL': '服务器地址',
  'app.gateway.DisableReport': '禁用报告',
  'app.gateway.SameSpan': '相同跨度',
  'app.gateway.Id128Bit': '识别128位',
  'app.gateway.CertBase64': '认证Base64',
  'app.gateway.KeyBase64': '密钥Base64',
  'app.gateway.Certs': '证书',
  'app.gateway.Keys': '关键字集',
  'app.gateway.Rules': '规则集',
  'app.gateway.BlockByDefault': '默认阻塞',
  'app.gateway.AllowIPs': '白名单',
  'app.gateway.BlockIPs': '黑名单',
  'app.gateway.HostRegexp': '主机正则表达式',
  'app.gateway.Paths': '路径集',
  'app.gateway.PathPrefix': '路径前缀',
  'app.gateway.PathRegexp': '路径正则表达式',
  'app.gateway.RewriteTarget': '重写目标',
  'app.gateway.Regexp': '正则表达式',
  'app.gateway.Values': '值集',
  'app.gateway.Backend': '后端',
  'app.gateway.MatchAllHeader': '匹配所有请求头',
  'app.gateway.MatchAllQuery': '匹配所有查询',
  'app.gateway.CaCertBase64': '认证机构Base64',

  'app.gateway.ChooseATemplate': '选择一个模板',
  'app.gateway.Filters': '过滤器集',
  'app.gateway.PreviewYAML': '预览YAML',
  'app.gateway.YAMLDiff': 'YAML对比',
  'app.gateway.ViewYAML': '查看YAML',
  'app.gateway.Flow': '流程',
  'app.gateway.Result': '结果',
  'app.gateway.JumpIf': '跳转条件',
  'app.gateway.JumpIfForm': '跳转条件表单',
  'app.gateway.JumpIfSettings': '跳转条件设置',
  'app.gateway.JumpTo': '跳转到',
  'app.gateway.Jump': '跳转',
  'app.gateway.FilterComponents': '过滤器组件集',
  'app.gateway.Kind': '类型',
  'app.gateway.Resilience': '弹性容错',
  'app.gateway.Data': '数据',
  'app.gateway.FilterInstances': '过滤器实例集',
  'app.gateway.AddToFlow': '添加到流程',
  'app.gateway.Alias': '别名',
  'app.gateway.CopyReference': '复制引用',
  'app.gateway.FlowChat': '流程图',

  'app.gateway.CertIndex': '认证索引',
  'app.gateway.Target': '目标',
  'app.gateway.Field': '字段',
  'app.gateway.HeaderKey': '头关键字',
  'app.gateway.AllowedOrigins': '允许的来源集',
  'app.gateway.AllowedMethods': '允许的方法集',
  'app.gateway.AllowedHeaders': '允许的请求头集',
  'app.gateway.AllowCredentials': '允许凭证',
  'app.gateway.ExposedHeaders': '暴露的请求头集',
  'app.gateway.MaxAge': '最大年龄',
  'app.gateway.Template': '模板',
  'app.gateway.DataKey': '数据关键字',
  'app.gateway.LeftDelim': '左划线',
  'app.gateway.RightDelim': '右划线',
  'app.gateway.MockCode': '模拟代码',
  'app.gateway.MockHeaders': '模拟请求头集',
  'app.gateway.MockBody': '模拟请求体',
  'app.gateway.EtcdPrefix': 'Etcd前缀',
  'app.gateway.PathRegExp': '路径正则表达式',
  'app.gateway.HeaderSetters': '请求头设置器集',
  'app.gateway.EtcdKey': 'Etcd关键字',
  'app.gateway.HeaderMap': '请求头映射表',
  'app.gateway.Header': '请求头',
  'app.gateway.Default': '默认',
  'app.gateway.DynamicHeader': '动态请求头',
  'app.gateway.Policy': '策略',
  'app.gateway.HeaderHashKey': '头部哈希关键字',
  'app.gateway.StickySession': '粘性会话',
  'app.gateway.Mode': '模式',
  'app.gateway.AppCookieName': '应用Cookie名称',
  'app.gateway.LbCookieName': 'Lb Cookie名称',
  'app.gateway.LbCookieExpire': 'Lb Cookie过期时间',
  'app.gateway.HealthCheck': '健康检查',
  'app.gateway.Interval': '间隔',
  'app.gateway.Timeout': '超时',
  'app.gateway.Fails': '失败数',
  'app.gateway.Passes': '通过数',
  'app.gateway.ClientId': '客户端Id',
  'app.gateway.ClientSecret': '客户端密钥',
  'app.gateway.CookieName': 'Cookie名称',
  'app.gateway.Discovery': '发现',
  'app.gateway.AuthorizationEndpoint': '认证端点',
  'app.gateway.TokenEndpoint': '令牌端点',
  'app.gateway.UserInfoEndpoint': '用户信息端点',
  'app.gateway.RedirectURI': '重定向URI',
  'app.gateway.DefaultStatus': '默认状态',
  'app.gateway.ReadBody': '读取体',
  'app.gateway.IncludedHeaders': '包含的头部集',
  'app.gateway.Pools': '池化集',
  'app.gateway.MirrorPool': '镜像池',
  'app.gateway.Compression': '压缩',
  'app.gateway.MinLength': '最小长度',
  'app.gateway.MTLS': '双向TLS',
  'app.gateway.RootCertBase64': '根证书Base64',
  'app.gateway.MaxIdleConns': '最大空闲连接数',
  'app.gateway.MaxIdleConnsPerHost': '每台主机的最大空闲连接数',
  'app.gateway.ServerMaxBodySize': '服务器最大请求体大小',
  'app.gateway.Match': '匹配',
  'app.gateway.MatchPart': '匹配部分',
  'app.gateway.Replacement': '替代品',
  'app.gateway.StatusCode': '状态码',
  'app.gateway.URL': '网址',
  'app.gateway.Method': '方法',
  'app.gateway.Replace': '替换',
  'app.gateway.AddPrefix': '添加前缀',
  'app.gateway.TrimPrefix': '截取前缀',
  'app.gateway.RegexpReplace': '正则表达式替换',
  'app.gateway.Del': '删除',
  'app.gateway.Set': '设置',
  'app.gateway.Add': '添加',
  'app.gateway.Body': '请求体',
  'app.gateway.Compress': '压缩',
  'app.gateway.Decompress': '解压',
  'app.gateway.Sign': '签名',
  'app.gateway.ApiProvider': 'Api供应商',
  'app.gateway.Scopes': '范围集',
  'app.gateway.Protocol': '协议',
  'app.gateway.SourceNamespace': '来源命名空间',
  'app.gateway.Exact': '精确',
  'app.gateway.Regex': '正则表达式',
  'app.gateway.Empty': '空的',
  'app.gateway.Urls': '网址集',
  'app.gateway.Permil': 'Permil',
  'app.gateway.MatchAllHeaders': '匹配所有头',
  'app.gateway.Weight': '权重',
  'app.gateway.KeepHost': '保持主机',
  'app.gateway.SpanName': '跨度名称',
  'app.gateway.ServerTags': '服务器标签集',
  'app.gateway.ServiceRegistry': '服务注册中心',
  'app.gateway.LoadBalance': '负载均衡',
  'app.gateway.MemoryCache': '内存缓存',
  'app.gateway.Codes': '代码集',
  'app.gateway.Expiration': '终止日期',
  'app.gateway.MaxEntryBytes': '最大通过字节数',
  'app.gateway.Filter': '过滤器',
  'app.gateway.RetryPolicy': '重试策略',
  'app.gateway.CircuitBreakerPolicy': '熔断策略',
  'app.gateway.FailureCodes': '失败状态码集',
  'app.gateway.JWT': 'JWT跨域认证',
  'app.gateway.Algorithm': '算法',
  'app.gateway.PublicKey': '公钥',
  'app.gateway.Secret': '密钥',
  'app.gateway.Signature': '签名',
  'app.gateway.Literal': '字面量',
  'app.gateway.ScopeSuffix': '范围后缀',
  'app.gateway.AlgorithmName': '算法名称',
  'app.gateway.AlgorithmValue': '算法值',
  'app.gateway.SignedHeaders': '已签名的头集',
  'app.gateway.Date': '日期',
  'app.gateway.Expires': '过期时间',
  'app.gateway.Credential': '资格证书',
  'app.gateway.ContentSha256': '内容Sha256',
  'app.gateway.SigningKeyPrefix': '签名密钥前缀',
  'app.gateway.ExcludeBody': '排除的请求体',
  'app.gateway.TTL': '生存时间',
  'app.gateway.AccessKeys': '访问关键字集',
  'app.gateway.Id': 'Id',
  'app.gateway.AccessKeyId': '访问关键字Id',
  'app.gateway.AccessKeySecret': '访问关键字密钥',
  'app.gateway.IgnoredHeaders': '忽略的头集',
  'app.gateway.HeaderHoisting': '头部封装',
  'app.gateway.AllowedPrefix': '允许的前缀',
  'app.gateway.DisallowedPrefix': '不允许的前缀',
  'app.gateway.Disallowed': '不允许的',
  'app.gateway.Oauth2': '开放授权第2版',
  'app.gateway.TokenIntrospect': '令牌内省',
  'app.gateway.EndPoint': '端点',
  'app.gateway.BasicAuth': '基本认证',
  'app.gateway.InsecureTls': '不安全的Tls',
  'app.gateway.UserFile': '用户文件',
  'app.gateway.LDAP': 'LDAP协议',
  'app.gateway.BaseDN': '基础DN',
  'app.gateway.Uid': '唯一Id',
  'app.gateway.UseSSL': '使用SSL',
  'app.gateway.SkipTLS': '跳过TLS',
  'app.gateway.Insecure': '不安全的',
  'app.gateway.MaxConcurrency': '最大并发数',
  'app.gateway.Code': '代码',
  'app.gateway.Parameters': '参数集',
  'app.gateway.DefaultOrigin': '默认来源',

  'app.gateway.Email': '邮箱',
  'app.gateway.DirectoryURL': '目录网址',
  'app.gateway.RenewBefore': '更新前',
  'app.gateway.EnableHTTP01': '激活HTTP01',
  'app.gateway.EnableTLSALPN01': '激活TLSALPN01',
  'app.gateway.EnableDNS01': '激活DNS01',
  'app.gateway.Domains': '域名集',
  'app.gateway.DNSProvider': 'DNS供应商',
  'app.gateway.IPAddr': 'IP地址',
  'app.gateway.Address': '地址',
  'app.gateway.Scheme': '模式',
  'app.gateway.ContextPath': '上下文路径',
  'app.gateway.DataCenter': '数据中心',
  'app.gateway.Token': '令牌',
  'app.gateway.Namespace': '命名空间',
  'app.gateway.SyncInterval': '同步间隔',
  'app.gateway.ServiceTags': '服务标签集',
  'app.gateway.Driver': '驱动',
  'app.gateway.Topic': '主题',
  'app.gateway.KafkaTopic': 'Kafka主题',
  'app.gateway.Brokers': '节点代理集',
  'app.gateway.KafkaBrokers': 'Kafka节点代理集',
  'app.gateway.Endpoints': '端点集',
  'app.gateway.Prefix': '前缀',
  'app.gateway.CacheTimeout': '缓存超时',
  'app.gateway.BeforePipeline': '前置流水线',
  'app.gateway.AfterPipeline': '后置流水线',
  'app.gateway.KubeConfig': 'Kube配置',
  'app.gateway.MasterURL': '主网址',
  'app.gateway.Namespaces': '命名空间集',
  'app.gateway.IngressClass': '入口等级',
  'app.gateway.HTTPServer': 'HTTP服务器',
  'app.gateway.Servers': '服务器集',
  'app.gateway.Username': '用户名',
  'app.gateway.Password': '密码',
  'app.gateway.ZKServices': 'ZK服务集',
  'app.gateway.ConnTimeout': '连接超时',

  'app.gateway.FormSubmitCheckFail':
    '当前表单中存在未通过校验的字段，请检查后再提交',

  'app.gateway.NameDuplication': '名称不能重复，请修改后再提交',

  'app.gateway.FlowItemUpOrDownTips':
    '由于顺序的调整，与当前流程记录相关联的跳转已被删除，如有必要，请重新设置跳转关联',

  'app.gateway.MaxAttempts': '最大尝试次数',
  'app.gateway.WaitDuration': '等待时间',
  'app.gateway.BackOffPolicy': '后退政策',
  'app.gateway.RandomizationFactor': '随机化因子',

  'app.gateway.SlidingWindowType': '滑动窗口类型',
  'app.gateway.FailureRateThreshold': '失败率阈值',
  'app.gateway.SlowCallRateThreshold': '慢速调用率阈值',
  'app.gateway.SlowCallDurationThreshold': '慢速调用等待时间阈值',
  'app.gateway.SlidingWindowSize': '滑动窗口大小',
  'app.gateway.PermittedNumberOfCallsInHalfOpenState':
    '半开放状态下允许的调用次数',
  'app.gateway.MinimumNumberOfCalls': '最少调用次数',
  'app.gateway.MaxWaitDurationInHalfOpenState': '半开状态下的最大等待时间',
  'app.gateway.WaitDurationInOpenState': '开放状态下的等待时间',

  'app.traffic.tips.beforeDeploy': '确定根据当前配置进行发布？',
  'app.traffic.error.nullOrParseFail': '数据为空或解析失败',
  'app.traffic.btn.deploy': '发布',
  'app.traffic.btn.cancel': '取消',
  'app.traffic.blueGreen.label.currentSelector': '调整之前的选择器：',
  'app.traffic.blueGreen.label.selectTags': '更改选择器：',
  'app.traffic.blueGreen.label.afterSelector': '调整之后的选择器：',
  'app.traffic.blueGreen.table.name': '名称',
  'app.traffic.blueGreen.table.tags': '标签',
  'app.traffic.blueGreen.table.currentSelected': '流量(当前)',
  'app.traffic.blueGreen.table.afterSelected': '流量(发布后)',
  'app.traffic.blueGreen.table.yes': '有',
  'app.traffic.blueGreen.comm.all': '所有',

  'app.traffic.canary.title.backend': '当前流量节点(主服务池)',
  'app.traffic.canary.title.candidateBackend': '正在灰度节点(备用服务池)',
  'app.traffic.canary.title.canarySettings': '灰度设置',
  'app.traffic.canary.table.name': '名称',
  'app.traffic.canary.table.tags': '标签',
  'app.traffic.canary.table.selected': '流量',
  'app.traffic.canary.table.yes': '有',

  'app.traffic.filter.manage.table.kind': '种类',
  'app.traffic.filter.manage.table.jsonSchema': 'JSON规范',
  'app.traffic.filter.manage.table.uiSchema': 'UI规范',
  'app.traffic.filter.manage.table.results': '结果集',
  'app.traffic.filter.manage.table.helpDoc': '帮助文档',
  'app.traffic.filter.manage.table.customScript': '自定义脚本',
  'app.traffic.filter.manage.table.createDate': '创建时间',
  'app.traffic.filter.manage.table.updateDate': '更新时间',
  'app.traffic.filter.manage.table.link.delete': '删除',
  'app.traffic.filter.manage.table.link.viewOrEdit': '查看 . 编辑',
  'app.traffic.filter.manage.btn.addPlugin': '新增过滤器',
  'app.traffic.filter.manage.btn.importPlugin': '导入过滤器',
  'app.traffic.filter.manage.btn.import': '导入',
  'app.traffic.filter.manage.btn.reimport': '重新导入',

  'app.traffic.help.onlinehelp': '在线帮助',
  'app.traffic.help.nocontent': '本主题暂无帮助内容',

  'app.traffic.deploy.pullConfiguration': '拉取配置',
  'app.traffic.deploy.history': '历史',
  'app.traffic.deploy.deployBtn': '发布',
  'app.traffic.deploy.rollbackBtn': '回滚',
  'app.traffic.deploy.table.updatedAt': '更新时间',
  'app.traffic.deploy.table.name': '名称',
  'app.traffic.deploy.table.Errors': '错误',
  'app.traffic.deploy.table.view': '查看',
  'app.traffic.deploy.table.type': '操作类型',
  'app.traffic.deploy.table.kind': '组件类型',
  'app.traffic.deploy.table.time': '时间',
  'app.traffic.deploy.table.mode': '模式',
  'app.traffic.deploy.table.description': '描述',
  'app.traffic.deploy.table.operator': '操作者',

  'app.traffic.table.path': '路径',

  'app.traffic.toolBar.cluster': '集群',
  'app.traffic.toolBar.name': '名称',
  'app.traffic.toolBar.server': '服务',
  'app.traffic.toolBar.BackendProxy': '后端代理',
  'app.traffic.toolBar.member': '节点',
  'app.traffic.createServer': '创建服务',
  'app.traffic.editServer': '编辑服务',
  'app.traffic.importServer': '导入服务',
  'app.traffic.addRoute': '添加路由',
  'app.traffic.editRoute': '编辑路由',
  'app.traffic.Keyword': '关键词',
  'app.traffic.importPipeline': '导入流水线',
  'app.traffic.createPipeline': '创建流水线',
  'app.traffic.importComponents': '导入组件',
  'app.traffic.exportComponents': '导出组件',

  'app.traffic.server.label.host': '主机',
  'app.traffic.server.label.port': '端口',
  'app.traffic.server.label.status': '状态',
  'app.traffic.server.label.routes': '路由',
  'app.traffic.server.label.ipFilter': 'IP过滤',
  'app.traffic.server.label.headers': '请求头',
  'app.traffic.server.label.methods': '请求方法',
  'app.traffic.server.label.pipeline': '流水线',
  'app.traffic.server.tips.no-routes.title': '路由为空',
  'app.traffic.server.tips.no-routes.content': '发布之前，请至少添加一条路由。',
  'app.traffic.server.label.header-name': '名称',
  'app.traffic.server.label.header-value': '内容',

  'app.traffic.title-mark.edited': '被修改',
  'app.traffic.title-mark.deleted': '待删除',
  'app.traffic.title-mark.new': '新增',

  'app.traffic.pipeline.lable.filter': '过滤器',
  'app.traffic.pipeline.lable.httpServer': '关联服务',
  'app.traffic.pipeline.lable.upstreams': '上游服务器',
  'app.traffic.pipeline.lable.drafts': '草稿',

  'app.traffic.pipeline.btn.flow': '流程',

  'app.traffic.importCmp.label.text': '文本',
  'app.traffic.importCmp.label.file': '文件',

  'app.traffic.controller.description.AutoCertManager': `AutoCertManager自动管理HTTPS证书`,
  'app.traffic.controller.description.ConsulServiceRegistry':
    '使用Consul作为服务提供者进行服务发现. 可以建立多份配置以关联到不同的服务',
  'app.traffic.controller.description.EaseMonitorMetrics':
    '与EaseMonitor集成到一起， 用于将自身产生的指标数据上报到EaseMonitor系统',
  'app.traffic.controller.description.EtcdServiceRegistry':
    '使用etcd作为服务提供者进行服务发现. 可以建立多份配置以关联到不同的服务',
  'app.traffic.controller.description.EurekaServiceRegistry':
    '使用Eureka作为服务提供者进行服务发现. 可以建立多份配置以关联到不同的服务',
  'app.traffic.controller.description.GlobalFilter': `GlobalFilter是一个特殊的管道，可以在服务器中的所有管道之前或/和之后执行`,
  'app.traffic.controller.description.IngressController': `IngressController是Kubernetes ingress控制器的实现，它观察Kubernetes Ingress、Service、Endpoints和Secrets，然后将它们转换为Easegress HTTP服务器和管道`,
  'app.traffic.controller.description.NacosServiceRegistry': `NacosServiceRegistry支持以Nacos为后台的服务发现`,
  'app.traffic.controller.description.ZookeeperServiceRegistry':
    '使用ZooKeeper作为服务提供者进行服务发现. 可以建立多份配置以关联到不同的服务',

  'app.gateway.PipelinePage.components.CardItem.ThisNameAlreadyExists':
    '该名称已存在',

  'app.gateway.Pipeline.PoolsFilterError':
    'pools中有且只能有一个filter为空的pool',

  'app.gateway.titleid': '描述',

  'app.gateway.helpid.Port': '监听的HTTP端口',
  'app.gateway.helpid.IPFilter': '服务器下所有流量的IP过滤器',
  'app.gateway.helpid.Host': '准确的主机匹配，空意味着匹配所有的主机',
  'app.gateway.helpid.Path': '匹配的确切路径',
  'app.gateway.helpid.Methods': '匹配的方法，空意味着允许所有的方法',
  'app.gateway.helpid.PathHeaders':
    '匹配的头信息（匹配头信息的请求不会被放入缓存）',
  'app.gateway.helpid.Pipeline':
    '后台名称（静态配置中的流水线名称，网格中的服务名称）',
  'app.gateway.helpid.SimpleHTTPProxy': 'SimpleHTTPProxy过滤器是Proxy过滤器的一个简化版本，旨在以更直接的方式处理HTTP请求，同时为后端服务提供基本的代理功能。',

  'app.gateway.helpid.KeepAlive': '是否支持keepalive',
  'app.gateway.helpid.KeepAliveTimeout': '保持活力的超时时间',
  'app.gateway.helpid.Https': '是否使用HTTPS',
  'app.gateway.helpid.MaxConnections': '与客户端的最大连接数',
  'app.gateway.helpid.Http3': '是否支持HTTP3(QUIC)',
  'app.gateway.helpid.CacheSize': '缓存的大小，0表示没有缓存',
  'app.gateway.helpid.XForwardedFor':
    '是否通过自己的ip来设置X-Forwarded-For报头',
  'app.gateway.helpid.AutoCert': '自动做HTTP认证',
  'app.gateway.helpid.ClientMaxBodySize':
    '请求主体的最大尺寸，默认值为4MB。大于此选项的请求体将被丢弃。当此选项设置为-1时，Easegress将请求体作为一个流，请求体可以是任何大小，但在这种情况下，有些功能无法实现，更多信息请参考[Stream](https://github.com/megaease/easegress/blob/main/doc/reference/stream.md)',
  'app.gateway.helpid.GlobalFilter':
    '用于所有后端的[GlobalFilter](https://github.com/megaease/easegress/blob/main/doc/reference/controllers.md#globalfilter)的名称',
  'app.gateway.helpid.AccessLogFormat':
    '访问日志的格式，默认为\\[{{时间}}\\] \\[{{远程地址}}{{真实IP}}{{方法}}{{URI}}{{协议}}{{状态码}\\] \\[{{时间}} rx:{{请求大小}}B tx:{{响应大小}}B\\] \\[{{Tags}}\\]" ，变量以"{{"和"}}"为界，所有内置变量请参考[Access Log Variable](https://github.com/megaease/easegress/blob/main/doc/reference/controllers.md#accesslogvariable)',
  'app.gateway.helpid.RouterKind':
    '路由器的一种。见[routers](https://github.com/megaease/easegress/blob/main/doc/reference/routers.md)',
  'app.gateway.helpid.Tracing': '分布式追踪设置',
  'app.gateway.helpid.ServiceName': '顶层的服务名称',
  'app.gateway.helpid.Attributes': '每个跨度都要包括的属性',
  'app.gateway.helpid.Tags':
    '废弃的。包含在每个跨度中的标签。这个选项将被保留到下一个主要版本的增量发布。',
  'app.gateway.helpid.SpanLimits': '表示一个跨度的限制',
  'app.gateway.helpid.AttributeValueLengthLimit':
    '是允许的最大属性值长度，将其设置为负值意味着不应用限制',
  'app.gateway.helpid.AttributeCountLimit': '是允许的最大跨度属性数',
  'app.gateway.helpid.EventCountLimit': '是允许的最大跨度事件计数',
  'app.gateway.helpid.LinkCountLimit': '是允许的最大跨度链接数',
  'app.gateway.helpid.AttributePerEventCountLimit':
    '是每个跨度事件允许的最大属性数',
  'app.gateway.helpid.AttributePerLinkCountLimit':
    '是每个跨度链接允许的最大属性数',
  'app.gateway.helpid.SampleRate':
    '收集度量的采样率，范围是[0, 1]。为了向后兼容，如果导出器为空，默认使用zipkin.sampleRate',
  'app.gateway.helpid.BatchLimits': '描述了BatchSpanProcessorOptions',
  'app.gateway.helpid.MaxQueueSize': '最大的队列大小，用于缓冲延迟处理的跨度',
  'app.gateway.helpid.BatchTimeout': '构建一个批处理的最大时间',
  'app.gateway.helpid.ExportTimeout':
    '导出时间（ExportTimeout）指定导出跨度的最大持续时间',
  'app.gateway.helpid.MaxExportBatchSize': '在一个批次中处理的最大跨度数量',
  'app.gateway.helpid.Exporter': '描述了exporter。exporter和zipkin不能都是空的',
  'app.gateway.helpid.Jaeger': 'JaegerSpec描述了Jaeger',
  'app.gateway.helpid.OTLP': '描述了OpenTelemetry输出器',
  'app.gateway.helpid.HeaderFormat':
    '表示上下文传播应使用哪种格式。 选项：[trace-conext](https://www.w3.org/TR/trace-context/),b3。为了向后兼容，历史上的Zipkin配置仍采用b3格式',
  'app.gateway.helpid.Zipkin': 'ZipkinSpec描述了Zipkin',
  'app.gateway.helpid.ServerURL': 'zipkin服务器的网址',
  'app.gateway.helpid.CertBase64': '以base64编码格式的PEM编码数据的公钥',
  'app.gateway.helpid.KeyBase64': '以base64编码格式的PEM编码数据的私钥',
  'app.gateway.helpid.Certs':
    'PEM编码数据的公开密钥，密钥是逻辑对名称，必须与密钥相匹配',
  'app.gateway.helpid.Keys':
    'PEM编码数据的私钥，密钥是逻辑对名称，必须与证书相匹配',
  'app.gateway.helpid.Rules': '路由器规则',
  'app.gateway.helpid.BlockByDefault': '如果不匹配，设置块是默认动作',
  'app.gateway.helpid.AllowIPs': '允许通过的IP（支持IPv4、IPv6、CIDR）',
  'app.gateway.helpid.BlockIPs': '要封锁的IP（支持IPv4、IPv6、CIDR）',
  'app.gateway.helpid.HostRegexp': '正则表达式中要匹配的主机，空表示匹配全部',
  'app.gateway.helpid.Paths':
    '路径匹配规则，空意味着什么都不匹配。注意，多个路径是按照它们在规范中出现的顺序进行匹配的，这与Nginx不同',
  'app.gateway.helpid.PathPrefix': '要匹配的路径的前缀',
  'app.gateway.helpid.PathRegexp': '正则表达式中要匹配的路径',
  'app.gateway.helpid.RewriteTarget':
    '使用pathRegexp.[ReplaceAllString](https://pkg.go.dev/regexp#Regexp.ReplaceAllString)(path, rewriteTarget)或pathPrefix [strings.Replace](https://pkg.go.dev/strings#Replace)来重写请求路径',
  'app.gateway.helpid.Regexp': '正则表达式中要匹配的标题值',
  'app.gateway.helpid.PathRegexpReplaceRegexp':
    '用于匹配请求路径的正则表达式。正则表达式的语法是[RE2](https://github.com/google/re2/wiki/Syntax)',
  'app.gateway.helpid.Values': '要匹配的标题值',
  'app.gateway.helpid.Backend':
    '后台名称（静态配置中的管道名称，网格中的服务名称）',
  'app.gateway.helpid.MatchAllHeader':
    '匹配所有在头文件中定义的头文件，默认为false',
  'app.gateway.helpid.MatchAllQuery':
    '匹配所有在query中定义的查询，默认为false',
  'app.gateway.helpid.CaCertBase64':
    '如果TLS客户认证中的策略要求验证客户证书，则定义服务器使用的根证书机构',

  'app.gateway.helpid.Flow':
    '过滤器的执行顺序，如果为空，将使用过滤器定义的顺序。',
  'app.gateway.helpid.Filters':
    '定义过滤器，请参考[Filters](https://github.com/megaease/easegress/blob/main/doc/reference/filters.md)了解特定过滤器种类的详情',
  'app.gateway.helpid.Resilience':
    '定义弹性容错策略，请参考[Resilience Policy](https://github.com/megaease/easegress/blob/main/doc/reference/controllers.md#resiliencepolicy)以了解具体弹性容错策略的细节',
  'app.gateway.helpid.Data': '流水线的静态用户数据',
  'app.gateway.helpid.CertIndex':
    '证书在链中的索引。负数的索引是从链的末端开始的（-1是最后一个索引，-2是倒数第二个等等）',
  'app.gateway.helpid.Target':
    '[x509.Certificate](https://pkg.go.dev/crypto/x509#Certificate)的主体或签发者',
  'app.gateway.helpid.Field':
    '来自https://pkg.go.dev/crypto/x509/pkix#Name 的一个字符串或字符串片断字段',
  'app.gateway.helpid.HeaderKey': '提取的值将被添加到这个请求头的键中',
  'app.gateway.helpid.AllowedOrigins':
    '一个可以执行跨域请求的起源数组。如果列表中出现了特殊的*值，那么所有的起源都将被允许。一个原点可以包含一个通配符（）来替换0个或多个字符（例如：http://.domain.com）。使用通配符意味着一个小的性能损失。每个来源只能使用一个通配符。默认值是*',
  'app.gateway.helpid.AllowedMethods':
    '允许客户端在跨域请求中使用的方法数组。默认值是简单方法（HEAD、GET和POST）',
  'app.gateway.helpid.AllowedHeaders':
    '客户端允许在跨域请求中使用的非简单头信息数组。如果列表中存在特殊的*值，所有的头信息都将被允许。默认值是[]，但 "Origin "总是被附加到列表中',
  'app.gateway.helpid.AllowCredentials':
    '表示请求是否可以包括用户凭证，如cookies、HTTP认证或客户端SSL证书',
  'app.gateway.helpid.ExposedHeaders':
    '表示哪些头信息可以安全地暴露给CORS API规范的API',
  'app.gateway.helpid.MaxAge':
    '表示预检请求的结果可以被缓存多长时间（以秒为单位）。默认为0代表没有最大年龄',
  'app.gateway.helpid.Template':
    '创建数据的模板，请参考该[模板](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#template-of-builer-filters)的更多信息',
  'app.gateway.helpid.DataKey': '储存数据的键',
  'app.gateway.helpid.LeftDelim': '模板的左侧动作分隔符，默认为{{',
  'app.gateway.helpid.RightDelim': '模板的右侧动作分隔符，默认为}}',
  'app.gateway.helpid.MockCode': '这个代码覆盖了原始响应的状态代码',
  'app.gateway.helpid.MockHeaders': '要添加/设置到原始响应的头文件',
  'app.gateway.helpid.MockBody':
    '默认是一个空字符串，如果指定的话，覆盖原始响应的主体',
  'app.gateway.helpid.EtcdPrefix': '自定义数据的种类',
  'app.gateway.helpid.PathRegExp': '用于从请求路径获取键的正则表达式',
  'app.gateway.helpid.HeaderSetters': '为http头设置自定义数据值',
  'app.gateway.helpid.EtcdKey': '用来获取数据的键',
  'app.gateway.helpid.HeaderMap':
    'headerMap defines a map between HTTP header name and corresponding JSON field name',
  'app.gateway.helpid.Header': '包含JSON值的HTTP头',
  'app.gateway.helpid.Default': 'Kafka后端默认的主题',
  'app.gateway.helpid.DynamicHeader': '包含Kafka主题的HTTP头',

  'app.gateway.helpid.MockRules': '模拟的规则集',
  'app.gateway.helpid.MockRuleCode': '模仿响应的HTTP状态代码',
  'app.gateway.helpid.MockRuleMatch': '匹配一个请求的规则',
  'app.gateway.helpid.MockRuleMatchPath':
    '路径匹配标准，如果请求路径是这个选项的值，那么请求的响应将按照这个规则进行模拟',
  'app.gateway.helpid.MockRuleMatchPathPrefix':
    '路径前缀匹配标准，如果请求路径以该选项的值开始，那么请求的响应将按照该规则进行模拟',
  'app.gateway.helpid.MockRuleMatchMatchAllHeaders': '是否匹配所有标题',
  'app.gateway.helpid.MockRuleMatchHeaders':
    '要匹配的头信息，key是头信息名称，value是匹配头信息值的规则',
  'app.gateway.helpid.MockRuleDelay': '延迟时间，用于请求处理时间的模拟',
  'app.gateway.helpid.MockRuleHeaders': '被模拟的响应的标题',
  'app.gateway.helpid.MockRuleBody': '模拟响应的主体，默认为空字符串',

  'app.gateway.helpid.Policy':
    '负载平衡策略，有效值是roundRobin、随机、加权随机、ipHash和headerHash',
  'app.gateway.helpid.HeaderHashKey':
    '当策略为headerHash时，该选项是一个头的名称，其值用于哈希计算',
  'app.gateway.helpid.StickySession': '粘性会话规格',
  'app.gateway.helpid.Mode':
    '会话粘性模式，支持CookieConsistentHash、DurationBased、ApplicationBased',
  'app.gateway.helpid.AppCookieName':
    '应用程序cookie的名称，它的值将被用作CookieConsistentHash和ApplicationBased模式下粘性的会话标识',
  'app.gateway.helpid.LbCookieName':
    '负载均衡器生成的cookie的名称，它的值将被用作DurationBased和ApplicationBased模式下粘性的会话标识，默认为EG_SESSION',
  'app.gateway.helpid.LbCookieExpire':
    '负载平衡器生成的cookie的过期时间，它的值将被用作基于持续时间和基于应用的模式下粘性的会话过期时间，默认是2小时',
  'app.gateway.helpid.HealthCheck':
    '健康检查规格，注意，如果你使用服务注册表，就不需要健康检查',
  'app.gateway.helpid.Interval': '健康检查的间隔时间，默认为60秒',
  'app.gateway.helpid.Timeout': '健康检查的超时时间，默认为3s',
  'app.gateway.helpid.Fails': '断言失败的连续失败计数，默认为1',
  'app.gateway.helpid.Passes': '连续通过计数为断言通过，默认为1',
  'app.gateway.helpid.ClientId': 'OAuth2.0应用程序客户端的ID',
  'app.gateway.helpid.ClientSecret': 'OAuth2.0应用程序客户端的密钥',
  'app.gateway.helpid.CookieName': '用于检查是否有必要启动OpenIDConnect流程',
  'app.gateway.helpid.Discovery': '身份服务器的标准OpenID Connect发现端点URL',
  'app.gateway.helpid.AuthorizationEndpoint': 'OAuth2.0授权端点网址',
  'app.gateway.helpid.TokenEndpoint': 'OAuth2.0令牌端点网址',
  'app.gateway.helpid.UserInfoEndpoint': 'OAuth2.0用户信息端点的网址',
  'app.gateway.helpid.RedirectURI':
    '在身份服务器中注册的回调URI，例如：https://example.com/oidc/callback 或 /oidc/callback',
  'app.gateway.helpid.DefaultStatus':
    '当请求被OPA政策决定拒绝时，默认的HTTP状态代码',
  'app.gateway.helpid.ReadBody': '是否将请求主体作为OPA政策数据来读取条件',
  'app.gateway.helpid.IncludedHeaders':
    '要包含在input.request.headers中的HTTP头的名称，用逗号分隔',
  'app.gateway.helpid.Pools':
    '没有过滤器的池子被认为是主池，其他有过滤器的池子被认为是候选池，而一个Proxy必须正好包含一个主池。当Proxy得到一个请求时，它首先经过候选池，如果其中一个池的过滤器与请求相匹配，这个池的服务器就会处理这个请求，否则，请求就会被传递到主池',
  'app.gateway.helpid.MirrorPool':
    '定义一个镜像池，当请求被发送到候选池或主池时，会同时发送到该池',
  'app.gateway.helpid.Compression': '响应压缩选项',
  'app.gateway.helpid.MinLength':
    'Minimum response body size to be compressed, response with a smaller body is never compressed',
  'app.gateway.helpid.MTLS': 'mTLS配置',
  'app.gateway.helpid.RootCertBase64': 'Base64 encoded root certificate',
  'app.gateway.helpid.MaxIdleConns':
    '控制所有主机上的最大空闲（保持）连接数。默认值是10240',
  'app.gateway.helpid.MaxIdleConnsPerHost':
    '控制每个主机保持的最大空闲（保持在线）连接。默认为1024',
  'app.gateway.helpid.ServerMaxBodySize':
    '响应主体的最大尺寸，默认值是4MB。响应体大于此选项的会被丢弃。当此选项设置为-1时，Easegress会将响应体作为一个流，响应体可以是任何大小，但在这种情况下，有些功能无法实现，更多信息请参考[Stream](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/stream.md)',
  'app.gateway.helpid.Match':
    '匹配请求路径的正则表达式。正则表达式的语法是[RE2](https://github.com/google/re2/wiki/Syntax)',
  'app.gateway.helpid.MatchPart':
    '决定使用哪一部分网址进行匹配的参数，支持的值：Uri、full、path。默认值是uri',
  'app.gateway.helpid.Replacement':
    '匹配成功后进行替换。像1，2这样的占位符可以用来表示regexp中的子匹配',
  'app.gateway.helpid.StatusCode':
    '响应的状态代码。支持的值。301, 302, 303, 304, 307, 308. 默认值：301',
  'app.gateway.helpid.URL': '远程服务的地址',
  'app.gateway.helpid.Method': '结果请求的HTTP方法，默认为GET',
  'app.gateway.helpid.Replace':
    '匹配成功后进行替换。像1，2这样的占位符可以用来表示regexp中的子匹配',
  'app.gateway.helpid.AddPrefix': '当指定时，将此选项的值预置到请求路径中',
  'app.gateway.helpid.TrimPrefix':
    '如果指定请求路径以该选项开始，则修剪该选项的值',
  'app.gateway.helpid.RegexpReplace': '用正则表达式修改请求路径',
  'app.gateway.helpid.Del': '要删除的标题的名称',
  'app.gateway.helpid.Set': '要设置的标题的名称和值',
  'app.gateway.helpid.Add': '要添加的标题的名称和值',
  'app.gateway.helpid.Body': '结果请求的主体',
  'app.gateway.helpid.Compress': '压缩体，目前只支持gzip',
  'app.gateway.helpid.Decompress': '解压缩体，目前只支持gzip',
  'app.gateway.helpid.Sign':
    '如果提供了，则使用亚马逊签名V4签署程序签署请求，配置为',
  'app.gateway.helpid.ApiProvider':
    'RequestAdaptor为一些API提供者预先定义了[Literal](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#signerliteral)和[HeaderHoisting](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#signerheaderhoisting)配置，在这个字段中指定提供者名称以使用其中之一，目前只支持aws4',
  'app.gateway.helpid.Scopes': '输入请求的范围',
  'app.gateway.helpid.Protocol': '要建立的请求的协议，默认为http',
  'app.gateway.helpid.SourceNamespace': '为源命名空间的请求添加一个引用',
  'app.gateway.helpid.Exact': '该字符串必须与该字段的值相同',
  'app.gateway.helpid.Regex': '该字符串必须是该字段的值所指定的正则表达式',
  'app.gateway.helpid.Empty': '该字符串必须为空',
  'app.gateway.helpid.Urls': '要求URL匹配标准',
  'app.gateway.helpid.Permil': '请求被匹配的概率。值在0到1000之间',
  'app.gateway.helpid.MatchAllHeaders': '标题中的所有规则都应该是匹配的',
  'app.gateway.helpid.Weight':
    '当负载平衡策略为加权随机时，该值用于计算该服务器的可能性',
  'app.gateway.helpid.KeepHost':
    '如果是true，无论url的值是多少，Host都与原始请求相同。默认值为false',
  'app.gateway.helpid.SpanName':
    '用于追踪的跨度名称，如果没有指定，则使用目标服务器的网址',
  'app.gateway.helpid.ServerTags':
    '服务器选择器标签，只有标签在此数组中的服务器才包括在此池中',
  'app.gateway.helpid.ProxyServerURL':
    '服务器的地址。该地址应以http:// 或https://（当用于WebSocketProxy时，也可以以ws://和wss://开始），后面是服务器的主机名或IP地址，然后可选择跟上:{端口号}，例如：https://www.megaease.com、http://10.10.10.10:8080。当使用主机名时，发送到该服务器的请求的Host总是服务器的主机名，因此在管道中使用[RequestAdaptor](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#requestadaptor)来修改它将是不可能的；当使用IP地址时，Host与原始请求相同，那可以通过[RequestAdaptor](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#requestadaptor)来修改。参见KeepHost',
  'app.gateway.helpid.ProxyServerTags':
    '该服务器的标签，参考[proxy.PoolSpec](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#proxyPoolSpec)中的serverTags',
  'app.gateway.helpid.ProxyServerWeight':
    '当负载平衡策略为加权随机时，该值用于计算该服务器的可能性。',
  'app.gateway.helpid.ProxyServerKeepHost':
    '如果是true，无论url的值是多少，主机都和原始请求一样。默认值为false。',
  'app.gateway.helpid.ServiceRegistry': '该选项和serviceName用于动态服务器发现',
  'app.gateway.helpid.LoadBalance': '负载均衡选项',
  'app.gateway.helpid.MemoryCache': '响应缓存的选项',
  'app.gateway.helpid.Codes': '要缓存的HTTP状态代码',
  'app.gateway.helpid.Expiration': '缓存条目的失效时间',
  'app.gateway.helpid.MaxEntryBytes':
    '响应体的最大尺寸，较大的响应体不会被缓存',
  'app.gateway.helpid.Filter': '候选人池的过滤选项',
  'app.gateway.helpid.RetryPolicy': '重试策略名称',
  'app.gateway.helpid.CircuitBreakerPolicy': '熔断器策略名称',
  'app.gateway.helpid.FailureCodes':
    '当后端响应的状态代码在failessCodes中时，代理返回failessCode的结果。默认值是5xx',
  'app.gateway.helpid.Headers':
    '头部验证规则，键是头名称，值是对应头值的验证规则，一个请求需要通过所有的验证规则才能通过头验证',
  'app.gateway.helpid.JWT': '自编码访问令牌模式的配置',
  'app.gateway.helpid.ValidatorJWT':
    'JWT验证规则，验证来自授权头或cookies的JWT标记字符串',
  'app.gateway.helpid.ValidatorSignature':
    '签名验证规则，实现了[Amazon Signature V4](https://docs.aws.amazon.com/general/latest/gr/create-signed-request.html)兼容的签名验证器，可自定义字面字符串',
  'app.gateway.helpid.ValidatorOauth2':
    'OAuth/2方法支持 Token Introspection模式和Self-Encoded Access Tokens模式，每次只能配置一种模式。',
  'app.gateway.helpid.ValidatorBasicAuth':
    'BasicAuth方法支持FILE、ETCD和LDAP模式，每次只能配置一种模式。',
  'app.gateway.helpid.Algorithm':
    '支持HS256、HS384、HS512、RS256、RS384、RS512、ES256、ES384、ES512、EdDSA的验证算法',
  'app.gateway.helpid.PublicKey':
    '公钥用于RS256,RS384,RS512,ES256,ES384,ES512或EdDSA的十六进制编码验证',
  'app.gateway.helpid.Secret':
    '密钥是用于HS256,HS384,HS512的十六进制编码的验证',
  'app.gateway.helpid.Signature':
    '签名验证规则，实现了亚马逊签名V4兼容的签名验证器，可自定义字面字符串',
  'app.gateway.helpid.Literal':
    '用于自定义的字面字符串，如果省略，则使用默认值',
  'app.gateway.helpid.ScopeSuffix':
    '最后一部分是建立凭证范围，默认是request，在Amazon Signature V4中，它是aws4_request',
  'app.gateway.helpid.AlgorithmName':
    '请求中签名算法的查询名称，默认为X-Algorithm，在Amazon Signature V4中，为X-Amz-Algorithm',
  'app.gateway.helpid.AlgorithmValue':
    '请求的签名算法的头/查询值，默认为 "HMAC-SHA256"，在亚马逊签名V4中，它是AWS4-HMAC-SHA256',
  'app.gateway.helpid.SignedHeaders':
    '签名头的头/查询头，默认是X-SignedHeaders，在亚马逊签名V4中，是X-Amz-SignedHeaders',
  'app.gateway.helpid.Date':
    '请求时间的头/查询名称，默认为X-Date，在Amazon Signature V4中，它是X-Amz-Date',
  'app.gateway.helpid.Expires':
    '过期时间的查询名称，默认为X-Expires，在Amazon Signature V4中，它是X-Amz-Date',
  'app.gateway.helpid.Credential':
    '凭证的查询名称，默认为X-Credential，在Amazon Signature V4中，它是X-Amz-Credential',
  'app.gateway.helpid.ContentSha256':
    'body/payload哈希值的头名称，默认为X-Content-Sha256，在Amazon Signature V4中，它是X-Amz-Content-Sha256',
  'app.gateway.helpid.SigningKeyPrefix':
    '在推导签名密钥时，前缀会被添加到访问密钥的秘密中，默认是一个空字符串，在Amazon Signature V4中，它是AWS4',
  'app.gateway.helpid.ExcludeBody': '从签名计算中排除请求主体，默认为false',
  'app.gateway.helpid.TTL': '签名的生存时间，默认为0表示签名永不过期',
  'app.gateway.helpid.AccessKeys': '一个访问密钥ID到访问密钥密文的映射',
  'app.gateway.helpid.AccessKeyId': '用来设置凭证的ID',
  'app.gateway.helpid.AccessKeySecret': '设置凭证的美元值',
  'app.gateway.helpid.IgnoredHeaders': '将被忽略的头文件',
  'app.gateway.helpid.HeaderHoisting':
    'HeaderHoisting定义了哪些头允许从头移到预设的查询中：头的名称有一个允许的前缀，但没有任何不允许的前缀，并且不匹配任何不允许的名称，允许被吊起',
  'app.gateway.helpid.AllowedPrefix': '允许的标题前缀',
  'app.gateway.helpid.DisallowedPrefix': '不允许使用头文件的前缀',
  'app.gateway.helpid.Disallowed': '不允许的标题',
  'app.gateway.helpid.Oauth2':
    'OAuth/2方法支持 Token Introspection模式和Self-Encoded Access Tokens模式，每次只能配置一种模式',
  'app.gateway.helpid.TokenIntrospect': '令牌自省模式的配置',
  'app.gateway.helpid.EndPoint': '令牌自省服务器的端点',
  'app.gateway.helpid.BasicAuth':
    '如果没有指定clientId，并且指定了这个选项，它的值将用于与令牌反省服务器的基本授权',
  'app.gateway.helpid.InsecureTls':
    'Easegress和令牌反省服务器之间的连接是否需要安全，默认为false，意味着连接需要安全',
  'app.gateway.helpid.UserFile': '用于FILE模式的用户文件',
  'app.gateway.helpid.LDAP': '用于LDAP模式的LDAP配置',
  'app.gateway.helpid.BaseDN':
    'LDAP服务器的基本域名，例如：ou=users,dc=example,dc=org',
  'app.gateway.helpid.Uid': '用于绑定用户的用户属性，例如：cn',
  'app.gateway.helpid.UseSSL': '是否使用SSL',
  'app.gateway.helpid.SkipTLS': '是否跳过StartTLS',
  'app.gateway.helpid.Insecure': '是否跳过对LDAP服务器的验证',
  'app.gateway.helpid.MaxConcurrency':
    '过滤器可以同时处理的最大请求。默认为10，最小值为1',
  'app.gateway.helpid.Code':
    'Wasm代码，可以是base64编码的代码，也可以是包含代码的文件的路径/url',
  'app.gateway.helpid.Parameters': '用于初始化wasm代码的参数',
  'app.gateway.helpid.ProxyPools':
    '没有过滤器的池子被认为是主池，其他有过滤器的池子被认为是候选池，而一个Proxy必须正好包含一个主池。当Proxy得到一个请求时，它首先通过候选池，如果其中一个池的过滤器与请求相匹配，这个池的服务器就会处理该请求，否则，该请求就会被传递到主池。',
  'app.gateway.helpid.ProxyMirrorPool':
    '定义一个镜像池，当请求被发送到候选池或主池时，会同时发送到该池。',
  'app.gateway.helpid.WebSocketProxyPools':
    '没有过滤器的池子被认为是主池，其他有过滤器的池子被认为是候选池，一个Proxy必须正好包含一个主池。当WebSocketProxy得到一个请求时，它首先会经过候选池，如果它符合池子的过滤器之一，这个池子的服务器就会处理连接，否则，它就会被传递给主池子',
  'app.gateway.helpid.DefaultOrigin':
    'Easegress在连接到后端服务时需要设置Origin头，如果客户端请求没有这个头，就使用这个值',

  'app.gateway.helpid.Email': '一个CA账户的电子邮件地址',
  'app.gateway.helpid.DirectoryURL': 'CA目录的端点',
  'app.gateway.helpid.RenewBefore': '证书将在过期时间的这段时间内被更新',
  'app.gateway.helpid.EnableHTTP01': '',
  'app.gateway.helpid.EnableTLSALPN01':
    '启用HTTP-01挑战（Easegress需要在80端口访问时为真）',
  'app.gateway.helpid.EnableDNS01': '启用DNS-01挑战',
  'app.gateway.helpid.Domains': '要管理的领域',
  'app.gateway.helpid.DNSProvider': 'DNS供应商信息',
  'app.gateway.helpid.Address': 'Consul服务器地址',
  'app.gateway.helpid.Scheme': '通信计划',
  'app.gateway.helpid.DataCenter': '数据中心名称',
  'app.gateway.helpid.Token': '用于通信的ACL令牌',
  'app.gateway.helpid.Namespace': '使用的命名空间',
  'app.gateway.helpid.SyncInterval': '同步数据的时间间隔',
  'app.gateway.helpid.ServiceTags': '要查询的服务标签',
  'app.gateway.helpid.Driver': '使用的驱动程序',
  'app.gateway.helpid.Topic': '生产主题',
  'app.gateway.helpid.KafkaTopic': 'Kafka生产主题',
  'app.gateway.helpid.Brokers': '代理地址',
  'app.gateway.helpid.KafkaBrokers': 'Kafka代理地址',
  'app.gateway.helpid.Endpoints': 'Etcd服务器的端点',
  'app.gateway.helpid.Prefix': '服务键的前缀',
  'app.gateway.helpid.CacheTimeout': '缓存的超时',
  'app.gateway.helpid.BeforePipeline': '前流水线的规格',
  'app.gateway.helpid.AfterPipeline': '后流水线的规格',
  'app.gateway.helpid.KubeConfig': 'Kubernetes配置文件的路径',
  'app.gateway.helpid.MasterURL': 'Kubernetes API服务器的地址',
  'app.gateway.helpid.Namespaces':
    'IngressController需要监视的Kubernetes命名空间的数组，如果留空，则监视所有命名空间',
  'app.gateway.helpid.IngressClass':
    'IngressController只处理IngressClassName设置为该选项值的Ingress',
  'app.gateway.helpid.HTTPServer':
    '共享HTTP流量门的基本配置。路由规则将根据Kubernetes入口动态生成，不应该在这里指定',
  'app.gateway.helpid.Servers': 'Nacos的服务器',
  'app.gateway.helpid.Username': '客户端的用户名',
  'app.gateway.helpid.Password': '客户端的密码',
  'app.gateway.helpid.ZKServices': 'Zookeeper的服务地址',
  'app.gateway.helpid.ConnTimeout': '连接超时',

  'app.gateway.helpid.CertExtractor':
    'CertExtractor从请求的TLS证书Subject或Issuer元数据（https://pkg.go.dev/crypto/x509/pkix#Name）中提取一个值，并将该值添加到头文件中。请求可以包含零个或多个证书，所以需要证书在链中的位置（第一、第二、最后等）',
  'app.gateway.helpid.CORSAdaptor':
    'CORSAdaptor处理CORS预检，对后端服务的简单和不简单的请求',
  'app.gateway.helpid.DataBuilder':
    'DataBuilder是用来处理和存储数据的。前一个过滤器的数据可以被转换并存储在上下文中，这样数据就可以在后续的过滤器中使用',
  'app.gateway.helpid.Fallback':
    'Fallback模拟一个响应作为其他过滤器的回落动作。下面的配置示例模拟了具有指定状态代码、头信息和正文的响应',
  'app.gateway.helpid.HeaderLookup':
    'HeaderLookup检查存储在etcd中的自定义数据，并将其放入HTTP头',
  'app.gateway.helpid.HeaderToJSON':
    'HeaderToJSON将HTTP头信息转换为JSON格式，并与HTTP请求正文相结合。要使用这个过滤器，请确保你的HTTP请求体是空的或JSON模式的',
  'app.gateway.helpid.Kafka':
    'Kafka过滤器将HTTP请求转换为Kafka消息并将其发送到Kafka后端。Kafka消息的主题来自HTTP头，如果没有找到，那么将使用默认主题。Kafka消息的有效载荷来自于HTTP请求的主体',
  'app.gateway.helpid.Mock':
    'Mock过滤器根据配置的规则模拟响应，主要用于测试目的',
  'app.gateway.helpid.OIDCAdaptor':
    'OpenID Connect(OIDC)是OAuth 2.0协议之上的一个身份层。它使客户能够根据授权服务器进行的认证来验证最终用户的身份，并获得最终用户的基本资料信息',
  'app.gateway.helpid.OPAFilter':
    '开放策略代理（OPA）是一个开源的、通用的策略引擎，可以统一整个堆栈的策略执行。它提供了一种高水平的声明性语言，可以用来定义和执行Easegress API Gateway中的策略。目前，有160多个内置运算符和函数可以使用，例如net.cidr_contains和contains',
  'app.gateway.helpid.Proxy': '代理过滤器是后端服务的一个代理',
  'app.gateway.helpid.RateLimiter':
    'RateLimiter通过限制在配置的时间内向服务发送的请求数量，保护后端服务的高可用性和可靠性',
  'app.gateway.helpid.Redirector':
    '重定向器过滤器是用来做HTTP重定向的。重定向器匹配请求的URL，进行替换，并返回状态代码为3xx的响应，并在响应头中放入新的路径，键为Location',
  'app.gateway.helpid.RemoteFilter':
    'RemoteFilter是一个过滤器，使远程服务作为一个内部过滤器。它将原始请求和响应信息转发给远程服务，并根据远程服务的响应返回一个结果',
  'app.gateway.helpid.RequestAdaptor': 'RequestAdaptor根据配置修改了原始请求',
  'app.gateway.helpid.RequestBuilder':
    'RequestBuilder根据配置从现有的请求/响应中创建一个新的请求，并将新的请求保存到它所绑定的命名空间中',
  'app.gateway.helpid.ResponseAdaptor': 'ResponseAdaptor根据配置修改输入响应',
  'app.gateway.helpid.ResponseBuilder':
    '响应生成器根据配置从现有的请求/响应中创建一个新的响应，并将新的响应保存到它所绑定的命名空间中',
  'app.gateway.helpid.ResultBuilder':
    'ResultBuilder生成一个字符串，这将是过滤器的结果。这个过滤器的存在是为了与jumpIf机制一起工作，以实现条件性跳转',
  'app.gateway.helpid.Validator':
    '验证器过滤器对请求进行验证，转发有效的请求，拒绝无效的请求。到目前为止支持四种验证方法（headers, jwt, signature, oauth2 and basicAuth），这些方法可以一起使用，也可以单独使用。当两个或多个方法一起使用时，一个请求需要通过所有的方法才能被转发',
  'app.gateway.helpid.WasmHost':
    'WasmHost过滤器为用户开发的WebAssembly代码实现一个主机环境。下面是一个从文件中加载wasm代码的配置例子，更多的细节可以在这个文件中找到',
  'app.gateway.helpid.WebSocketProxy':
    'WebSocketProxy过滤器是websocket后端服务的一个代理',

  'app.gateway.helpid.MaxAttempts': '最大的尝试次数（包括初始尝试）。默认为3次',
  'app.gateway.helpid.WaitDuration': '尝试之间的基本等待时间。默认为500ms',
  'app.gateway.helpid.BackOffPolicy':
    '等待时间的后退策略，可以是EXPONENTIAL或RANDOM，默认是RANDOM。如果配置为EXPONENTIAL，在每次尝试失败后，基本等待时间会变大1.5倍',
  'app.gateway.helpid.RandomizationFactor':
    '实际等待时间的随机化系数，是区间[0，1]中的一个数字，默认为0。所使用的实际等待时间是区间[（基本等待时间）*（1-随机化系数），（基本等待时间）*（1+随机化系数）]中的一个随机数字',

  'app.gateway.helpid.SlidingWindowType':
    '滑动窗口的类型，用于记录断路器关闭时的请求结果。滑动窗口可以是COUNT_BASED或TIME_BASED。如果滑动窗口是COUNT_BASED，最后的滑动窗口大小的请求被记录和汇总。如果滑动窗口是TIME_BASED，则记录并汇总最后一个滑动窗口大小的秒数的请求。默认为COUNT_BASED',
  'app.gateway.helpid.FailureRateThreshold':
    '故障率阈值，百分比。当故障率等于或大于阈值时，断路器过渡到开放状态并开始短路请求。默认值为50',
  'app.gateway.helpid.SlowCallRateThreshold':
    '慢速率阈值，单位为百分比。当一个请求的持续时间大于慢速调用的持续时间阈值时，熔断器认为它是慢速。当慢速请求的百分比等于或大于阈值时，熔断器过渡到OPEN并开始短路请求。默认值为100',
  'app.gateway.helpid.SlowCallDurationThreshold': '慢速调用的持续时间阈值',
  'app.gateway.helpid.SlidingWindowSize':
    '滑动窗口的大小，用于记录断路器关闭时的请求结果。默认为100',
  'app.gateway.helpid.PermittedNumberOfCallsInHalfOpenState':
    '当熔断器为HALF_OPEN时，允许的请求数量。默认为10',
  'app.gateway.helpid.MinimumNumberOfCalls':
    '在熔断器计算错误率或慢速请求率之前需要的最小请求数（每个滑动窗口期）。例如，如果最小请求数是10，那么在计算故障率之前必须至少记录10个请求。如果只记录了9个请求，即使9个请求都失败了，断路器也不会过渡到开放。默认为10',
  'app.gateway.helpid.MaxWaitDurationInHalfOpenState':
    '最大的等待时间，控制断路器在切换到开放状态之前，可以在HALF_OPEN状态下停留的最长时间。值为0意味着断路器将在HALF_OPEN状态下无限等待，直到所有允许的请求都被完成。默认值为0',
  'app.gateway.helpid.WaitDurationInOpenState':
    '熔断器在从OPEN过渡到HALF_OPEN之前应该等待的时间。默认为60秒',

  PleaseEnterNewPipelineName: '请输入新的流水线名称',
  SureToDeploySelectedChanges: '你确定要部署所选的修改吗？',
};
