const zhCh = {
  'stack.template.stack.name': '部署应用名称',
  'stack.template.create.stack': '创建部署应用',
  'stack.template.update.stack': '修改部署应用',
  'stack.template.delete.stack': '删除部署应用',
  'stack.template.provision.stack': '规定一个部署应用',
  'stack.template.delete.confirm': '确认删除此部署应用吗?',
  'stack.template.update.error': '修改部署应用模板错误',
  'stack.template.create.error': '创建部署应用模板错误',
  'stack.template.component.name.duplicate': '被其他组件依赖，不能删除',
  'stack.template.data.not.save': '数据尚未保存，确定要退出吗?',
  'stack.template.component': '组件',
  'stack.template.stateful.component': '有状态组件',
  'stack.template.stateless.component': '无状态组件',
  'stack.template.enable.component': '启用组件',
  'stack.template.boot.command': '组件的启动命令(请以;分格)',
  'stack.template.liveness.command': '组件活动探测命令(请以;分格)',
  'stack.template.readiness.command': '组件就绪探测命令(请以;分格)',
  'stack.template.coloring.node': '组件的着色节点',
  'stack.template.component.create': '添加组件',
  'stack.template.component.update': '修改组件',
  'stack.template.component.common': '共同属性',
  'stack.template.component.command.resource': '命令与资源',
  'stack.template.component.access.configuration': '访问与配置',
  'stack.template.export.spec': '导出规格',
  'stack.template.component.add.env.variable': '添加环境变量',
  'stack.template.component.add.env.variable.btn': '添加环境变量',
  'stack.template.component.add.env.variable.desc':
    '要添加环境变量，请输入环境变量标签和环境变量值。',
  'stack.template.component.add.name.port': '添加命名端口',
  'stack.template.component.edit.name.port': '编辑命名端口',
  'stack.template.component.add.name.port.desc':
    '要添加命名端口, 请输入端口, 节点端口或命名端口.',
  'stack.template.component.management.name.configuration': '配置管理',
  'stack.template.component.add.name.configuration': '添加配置',
  'stack.template.component.edit.name.configuration': '编辑配置',
  'stack.template.component.add.name.configuration.btn': '添加配置',
  'stack.template.component.add.name.configuration.desc':
    '要添加配置，请输入配置值和配置键值。',

  'stack.edit.name.port.is.required': '命名端口或配置必须为非空',

  'stack.template.component.add.annotation.key': '注解关键字',
  'stack.template.component.add.annotation.value': '注解值',
  'stack.template.component.add.annotation.variable': '添加注解变量',
  'stack.template.component.add.name.annotation.btn': '添加注解',
  'stack.template.component.add.annotation.variable.desc':
    '要添加注解, 请输入注解关键字, 注解值.',

  'stack.instance.delete.confirm': '确定删除该部署应用实例?',
  'stack.instance.new.version': '选择新版本',
  'stack.instance.detail': '详情',
  'stack.instance.differ.version': '比对版本',
  'stack.instance.new.version.btn': '添加版本',

  'stack.dashboard.pod.card.restart.count': '重启次数',
  'stack.dashboard.pod.card.running.status': '就绪',
  'stack.dashboard.pod.card.pod.state': '状态',
  'stack.dashboard.service.list': '服务列表',
  'stack.dashboard.service.image': '镜像',
  'stack.dashboard.service.image.version': '镜像版本',
  'stack.dashboard.service.instance.count': '实例总数',

  'stack.rollback.history.version.title': '回滚历史版本',
  'stack.rollback.history.version.confirm': '是否确认回滚版本?',
  'stack.rollback.alert.title': '注意',
  'stack.rollback.alert.content': '回滚后一些信息将会丢失',
  'stack.rollback.alert.rollback.version': '回滚版本',
  'stack.rollback.alert.version': '版本',
  'stack.rollback.alert.cluster.name': '集群名称',
  'stack.rollback.alert.namespace': '命名空间',
  'stack.rollback.alert.comments': '注释',
  'stack.rollback.alert.your.version': '你的版本',

  'stack.manifest.base.cancel': '取消',
  'stack.manifest.base.yes': '确认',
  'stack.wizard.manifest.home.title': '部署应用',
  'stack.wizard.manifest.home.spec.yaml': '部署应用规格YAML文件',
  'stack.wizard.manifest.deploy.title': '部署',
  'stack.wizard.manifest.title.deploy.status': '部署状态',
  'stack.wizard.manifest.title.deployment.history': '部署历史',
  'stack.wizard.manifest.toolbar.edit': '编辑',
  'stack.wizard.manifest.toolbar.SaveAsManifest': '保存为部署版本',
  'stack.wizard.manifest.toolbar.pull': '拉取',
  'stack.wizard.manifest.toolbar.diff': '比较',
  'stack.wizard.manifest.toolbar.view': '查看',
  'stack.wizard.manifest.toolbar.deploy': '部署',
  'stack.wizard.manifest.toolbar.Rollback': '回滚',
  'stack.wizard.manifest.toolbar.delete.manifest': '删除清单',
  'stack.wizard.manifest.card.info.hash.tag': '哈希标记是: ',
  'stack.wizard.manifest.card.info.description': '描述是: ',
  'stack.wizard.manifest.card.info.not.deploy': '还没有部署 ',
  'stack.wizard.manifest.card.info.not.description': '没有描述 ',
  'stack.wizard.manifest.card.info.release.in': '由MegaEase团队 * 发布 ',
  'stack.wizard.manifest.card.info.by.team': '由MegaEase团队 * 还没有发布 ',
  'stack.wizard.manifest.card.info.edit.in': '  *  于发布 ',
  'stack.wizard.manifest.card.info.k8s.zone': 'Kubernates ',
  'stack.wizard.manifest.card.info.local': '本地',
  'stack.wizard.manifest.card.info.no.description': '没有描述',
  'stack.wizard.manifest.card.info.remote': '远程',
  'stack.wizard.manifest.deploy.modal.stack.name': '部署应用名称',
  'stack.wizard.manifest.deploy.modal.namespace': '命名空间',
  'stack.wizard.manifest.deploy.modal.cluster.name': '集群名称',
  'stack.wizard.manifest.deploy.modal.comments': '备注',
  'stack.wizard.manifest.stack.list.title': '部署应用列表',
  'stack.wizard.manifest.stack.new.stack': '创建部署应用',
  'stack.wizard.manifest.home.k8s.cluster': 'k8s集群',
  'stack.wizard.history.version.deploy.history': '部署应用历史',
  'stack.wizard.history.version.topology': '部署应用组件',
  'stack.wizard.history.version.deploy.at': '发布',
  'stack.wizard.history.version.hash.tag': '哈希标记: ',
  'stack.wizard.history.version.save.as.manifest.btn': '保存为清单',
  'stack.wizard.history.version.rollback.btn': '回滚',
  'stack.wizard.history.version.save.as.manifest.modal.tip':
    '保存为清单将会覆盖之前的数据，你确定要保存吗? ',
  'stack.wizard.history.version.save.as.manifest.instance.id': '实例ID',
  'stack.wizard.history.version.save.as.manifest.comments': '备注',
  'stack.wizard.history.version.topology.image.version': 'Image版本',
  'stack.wizard.history.version.topology.image.type': 'Image类型',
  'stack.wizard.history.version.topology.instance.count': '实例总数',
  'stack.wizard.event.tip.no.event': '没有事件 ...',
  'stack.wizard.event.manifest.btn': '清单文件',
  'stack.wizard.event.deployment.btn': '部署文件',
  'stack.wizard.event.choose.instance.id': '选中一个部署应用实例ID',
  'stack.wizard.event.list': '部署应用事件列表',

  'stack.wizard.event.step.begin.update': '开始更新组件',
  'stack.wizard.event.step.consistency.check': '部署应用一致性检查',
  'stack.wizard.event.step.component.health.check': '组件健康检查',
  'stack.wizard.event.step.finish': '完成',

  'stack.wizard.diff.version.title': '比对不同清单版本之间的差异',
  'stack.wizard.diff.version.manifest.version': '清单版本',
  'stack.wizard.deploy.confirm.info': '确定要部署这个应用吗?',
  'stack.wizard.deploy.confirm.title': '部署应用',
  'stack.wizard.deploy.deploy': '部署',
  'stack.wizard.deploy.version': '版本',
  'stack.wizard.deploy.comments': '备注: ',
  'stack.wizard.deploy.your.version': '你的版本',
  'stack.wizard.current.running.version': '当前运行的版本',

  'stack.wizard.edit.base.modal.stack.description': '部署应用描述',

  'stack.wizard.edit.base.modal.stack.easemesh.EditMeshConfig':
    '编辑服务网格配置',
  'stack.wizard.edit.base.modal.stack.easemesh.enable': '是否启用EaseMesh',
  'stack.wizard.edit.base.modal.stack.easemesh.service_labels': '服务标签',
  'stack.wizard.edit.base.modal.stack.easemesh.application_port': '应用端口',
  'stack.wizard.edit.base.modal.stack.easemesh.app_container_name':
    '应用容器名称',
  'stack.wizard.edit.base.modal.stack.easemesh.alive_probe_url': '探活探针地址',
  'stack.wizard.edit.base.modal.stack.easemesh.init_container_image':
    '初始容器镜像',
  'stack.wizard.edit.base.modal.stack.easemesh.sidecar_image': '边车镜像',

  'stack.wizard.edit.topology.toolbox.title': '工具盒',
  'stack.wizard.edit.yaml.confirm.title': '关闭组件的YAML配置',
  'stack.wizard.edit.yaml.confirm.content': '编辑的数据还没有保存，是否退出?',

  'stack.wizard.edit.component.name': '组件名称',
  'stack.wizard.edit.component.description': '组件描述',
  'stack.wizard.edit.component.image': '组件的镜像',
  'stack.wizard.edit.component.image.version': '组件的镜像版本',
  'stack.wizard.edit.component.replicas': '组件的副本数',
  'stack.wizard.edit.component.select.depended': '选择依赖的组件',
  'stack.wizard.edit.component.image.pull.polices': '镜像拉取策略',
  'stack.wizard.edit.component.EnableComponent': '启用组件',
  'stack.wizard.edit.component.DraftComponent': '草稿组件',
  'stack.wizard.edit.component.cpu.limit': 'CPU限制',
  'stack.wizard.edit.component.memory.require': '内存需求',
  'stack.wizard.edit.component.cpu.require': 'CPU 需求',
  'stack.wizard.edit.component.memory.limit': '内存限制',
  'stack.wizard.edit.component.pvc.mounts': 'PVC或PVC模板挂载',

  'stack.dashboard.cluster.node.count': '集群节点数',
  'stack.dashboard.cluster.pod.count': '集群Pod数',
  'stack.dashboard.cluster.waiting.pod.count': '集群等待的Pod数',
  'stack.dashboard.cluster.running.pod.count': '集群运行的Pod数',
  'stack.dashboard.cluster.terminate.pod.count': '集群结束的Pod数',

  'stack.wizard.manifest.search.stack': '查找部署应用',
  'stack.manifest.base.commit': '提交',
  'stack.manifest.base.ok': '确定',
  'stack.manifest.base.close': '关闭',
  'stack.manifest.base.import.spec': '导入规格数据',
  'stack.manifest.base.no.comments': '没有评论',
  'stack.manifest.base.topology': '拓扑图',
  'stack.manifest.base.title.cluster.info': '集群消息',
  'stack.wizard.manifest.search.service': '查找服务',
  'stack.wizard.dashboard.memory.usage': '内存使用',
  'stack.wizard.dashboard.cpu.usage': 'CPU使用',

  'stack.wizard.dashboard.node': '节点',

  'stack.wizard.edit.table.key': '键',
  'stack.wizard.edit.table.host.port': '主端口/节点端口',
  'stack.wizard.edit.value.host.port': '主端口',
  'stack.wizard.edit.value.node port': '节点端口',
  'stack.wizard.edit.table.use.as.file': '用作文件',
  'stack.wizard.edit.table.is.sub.path': '是子目录',
  'stack.wizard.edit.table.is.node.port': '是节点端口',

  'stack.wizard.manifest.diff.different': '此清单组件与部署清单组件不同',
  'stack.wizard.manifest.diff.same': '此清单组件与部署组件相同',
  'stack.wizard.manifest.diff.new': '此清单组件是新的组件',
  'stack.wizard.manifest.diff.label.different': '不同',
  'stack.wizard.manifest.diff.label.same': '相同',
  'stack.wizard.manifest.diff.label.new': '新的',

  'stack.wizard.base.components': '组件',
  'stack.wizard.base.name': '名称',
  'stack.wizard.base.rollback': '回滚',
  'stack.wizard.manifest.home.component.yaml': '组件YAML',
  'stack.wizard.manifest.home.component.yaml.diff': '组件YAML比较',
  'stack.wizard.base.namespace': '命名空间',
  'stack.wizard.base.cluster.name': '集群名称',
  'stack.wizard.base.tip.show.more': '更多信息',
  'stack.wizard.manifest.type.draft': '草稿',
  'stack.wizard.manifest.type.deployed': '已经发布',
  'stack.wizard.manifest.type.draft.deployed': '由发布版本保存的草稿',
  'stack.wizard.manifest.status.different': '不同',
  'stack.wizard.manifest.status.same': '相同',
  'stack.wizard.manifest.status.behind': '落后',
  'stack.wizard.manifest.status.undeploy': '未部署',
  'stack.wizard.manifest.deploy.error': '部署错误',

  'stack.wizard.event.read.more': '查看更多',
};
export default zhCh;
