import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #ECEDF0',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
  },
}));
export default function PaperComponent(props) {
  const classes = useStyles();
  return <Paper {...props} className={classes.root} />;
}
