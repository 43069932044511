import React from 'react';
import { makeStyles } from '@material-ui/styles';
import validateCorrectImg from '@/app/_common/assets/validate-correct.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 14,
    fontSize: 12,
  },
  title: {
    color: '#a6a6a6',
  },
  item: {
    color: '#000000',
    marginTop: 12,
    display: 'flex',
    alignItems: 'center',
  },
  pass: {
    color: '#52c41a',
  },
  img: {
    width: 20,
  },
}));
const PasswordTooltipTitle = ({ passwordField }) => {
  const cs = useStyles();
  return (
    <div className={cs.wrapper}>
      <div className={cs.title}>REQUIREMENTS</div>

      {passwordField.map((item, index) => {
        return (
          <div key={index} className={cs.item}>
            <div className={cs.img}>
              {!Object.values(item)[0] ? (
                <img src={validateCorrectImg} alt="validateCorrect" />
              ) : null}
            </div>
            <div className={!Object.values(item)[0] ? cs.pass : null}>
              {Object.keys(item)[0]}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default PasswordTooltipTitle;
