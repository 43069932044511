const enUS = {
  Order: 'Order',
  Refund: 'Refund',
  GPU: 'GPU',

  Time: 'Time',
  PrepaidAmount: 'Prepaid Amount',
  RefundAmount: 'Refund Amount',
  ActualIncome: 'Actual Income',
  IncomeSource: 'Income Source',
  AllSource: 'All',
  GPUSource: 'GPU',
  PlanSource: 'Plan',
  detailsByDay: 'Details (by day)',
  detailsByTenant: 'Details (by tenant)',
  Tenant: 'Tenant',
  UnrealizedRevenue: 'Unrealized Revenue',
  ActualRevenue: 'Actual Revenue',
  TotalPrepaid: 'Total Prepaid',
  TotalRefund: 'Total Refund',

  TotalTenants: 'Total Tenants',
  NewTenantsPerMonth: 'New Tenants/Month',
  NewTenantsPerWeek: 'New Tenants/Week',
  NewTenant: 'New Tenant',
  PayingTenantPlan: 'Paying Tenant - Plan',
  PayingTenantGPU: 'Paying Tenant - GPU',
  NewTenantsPerDay: 'New Tenants/Day',
  NewPayingTenantsPerDay: 'Plan - New Paying Tenants/Day',
  NewPayingTenantsPerWeek: 'Plan - New Paying Tenants/Week',
  NewPayingTenantsPerMonth: 'Plan - New Paying Tenants/Month',
  NewPayingTenantsPerDayGPU: 'GPU - New Paying Tenants/Day',
  NewPayingTenantsPerWeekGPU: 'GPU - New Paying Tenants/Week',
  NewPayingTenantsPerMonthGPU: 'GPU - New Paying Tenants/Month',
  Date: 'Date',

  HostRanking: 'Host Ranking',
  InstanceRanking: 'Instance Ranking',
  'table.tenant': 'Tenant',
  'table.total': 'Total',
  'table.running': 'Running',

  'total.income': 'Total Income',
  'total.month.income': 'Monthly Income',
  'total.day.income': 'Daily Income',
  'actual.income': 'Actual Income',

  'gpu.ranking.cost': 'GPU paid ranking',
  'gpu.ranking.pod': 'GPU Pod ranking',
  'gpu.ranking.storage': 'GPU storage ranking',
  'gpu.ranking.template': 'GPU template ranking',
  'gpu.TotalPayment': 'Total Payment',
  'gpu.number': 'Number',
  'gpu.capacity': 'Capacity',
  'gpu.income': 'GPU Income',
  'store.income': 'Storage Income',

  'gpu.total': 'Total',
  'gpu.used': 'Used',
  'gpu.available': 'Available',
  'gpu.inactive': 'Inactive',
  'gpu.usage.total': 'Total {name}',
  'gpu.usage.history': 'History',
  'gpu.usage.allStockSnapShot': 'All',
  'gpu.usage.modelStockSnapShot': 'Model',
  'gpu.usage.specStockSnapShot': 'Spec',
  'gpu.previous': 'Previous',
  'gpu.model.used': 'Used',
  'gpu.model.available': 'Available',
  'gpu.model.inactive': 'Inactive',
  'gpu.startup.success': 'Startup Success',
  'gpu.startup.failure': 'Startup Failure',
  'gpu.startup.count': 'Startup Count',
  'gpu.startup.time': 'Startup Time',
  'gpu.startup.topSlow': 'Top Slow',
  'gpu.startup.topSlow.time': 'Duration',
  'gpu.startup.topSlow.tenant': 'Tenant',
  'gpu.startup.topSlow.spec': 'Specification',
  'gpu.startup.topSlow.template': 'Template',
  'gpu.startup.topSlow.gpuPodId': 'GPU Pod ID',
  'gpu.startup.topSlow.instanceId': 'Instance ID',
  'gpu.startup.topSlow.vendor': 'Vendor',
  'gpu.startup.topSlow.dataCenter': 'Data Center',
  'gpu.startup.topSlow.result': 'Result',
  'gpu.startup.topSlow.startTime': 'Start Time',
  'gpu.startup.topSlow.size': 'Size',
  'gpu.revenue.download': 'Download',
  'gpu.rebate.download': 'Rebate Download',
  'gpu.rebate': '推广返利',
};

export default enUS;
