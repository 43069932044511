import React, { useState } from 'react';
import { cloneDeep } from 'lodash';

const usePasswordValidator = () => {
  const [passwordField, setPasswordField] = useState([
    {
      'Password must be at least 8 characters': true,
    },
    { 'Password must include lowercase letter': true },
    { 'Password must include uppercase letter': true },
    { 'Password must include digit': true },
    { 'Password must include special character': true },
  ]);

  const validatorPassword = (password) => {
    //false pass
    // console.log(password);
    const newPasswordField = cloneDeep(passwordField);
    if (password) {
    }
    if (password.length < 8) {
      newPasswordField[0] = {
        'Password must be at least 8 characters': true,
      };
    } else {
      newPasswordField[0] = {
        'Password must be at least 8 characters': false,
      };
    }
    if (!/^(?=.*[a-z])/.test(password)) {
      newPasswordField[1] = {
        'Password must include lowercase letter': true,
      };
    } else {
      newPasswordField[1] = {
        'Password must include lowercase letter': false,
      };
    }
    if (!/^(?=.*[A-Z])/.test(password)) {
      newPasswordField[2] = {
        'Password must include uppercase letter': true,
      };
    } else {
      newPasswordField[2] = {
        'Password must include uppercase letter': false,
      };
    }
    if (!/^(?=.*[0-9])/.test(password)) {
      newPasswordField[3] = {
        'Password must include digit': true,
      };
    } else {
      newPasswordField[3] = {
        'Password must include digit': false,
      };
    }
    if (!/^(?=.*[^a-zA-Z0-9])/.test(password)) {
      newPasswordField[4] = {
        'Password must include special character': true,
      };
    } else {
      newPasswordField[4] = {
        'Password must include special character': false,
      };
    }

    setPasswordField(newPasswordField);
    // console.log(newPasswordField, 'newPasswordField');
  };
  return {
    passwordField,
    validatorPassword,
  };
};

export default usePasswordValidator;
