export default {
  'app.traffic.cluster.ConnectCluster': 'Connect Cluster',
  'app.traffic.cluster.needConnectFirst':
    'There is currently no cluster available, please connect one first.',
  'app.traffic.cluster.label.apiAddress': 'API Address',
  'app.traffic.cluster.label.address': 'Address',
  'app.traffic.cluster.label.start': 'Start',
  'app.traffic.cluster.label.heartbeat': 'Heartbeat',
  'app.traffic.cluster.label.status': 'Status',
  'app.traffic.cluster.label.role': 'Role',
  'app.traffic.cluster.label.tps': 'TPS',
  'app.traffic.cluster.label.latency': 'Latency',
  'app.traffic.cluster.dialog.editCluster': 'Edit Cluster',
  'app.traffic.cluster.label.apiAddressFormatTips':
    "Use format 'http[s]://ip:port' in each line",

  'app.gateway.ImportServer': 'Import Server',
  'app.gateway.CreateServer': 'Create Server',
  'app.gateway.Name': 'Name',
  'app.gateway.Port': 'Port',
  'app.gateway.Status': 'Status',
  'app.gateway.Running': 'Running',
  'app.gateway.Failed': 'Failed',
  'app.gateway.Export': 'Export',
  'app.gateway.Routes': 'Routes',
  'app.gateway.Host': 'Host',
  'app.gateway.Path': 'Path',
  'app.gateway.IPFilter': 'IP Filter',
  'app.gateway.Headers': 'Headers',
  'app.gateway.Methods': 'Methods',
  'app.gateway.Pipeline': 'Pipeline',
  'app.gateway.View': 'View',

  'app.gateway.BasicProperties': 'Basic Properties',
  'app.gateway.KeepAlive': 'Keep Alive',
  'app.gateway.KeepAliveTimeout': 'Keep Alive Timeout',
  'app.gateway.Https': 'Https',
  'app.gateway.MaxConnections': 'Max Connections',
  'app.gateway.SSLs': 'SSLs',
  'app.gateway.Cert': 'Cert',
  'app.gateway.Key': 'Key',
  'app.gateway.OptionalProperties': 'Optional Properties',
  'app.gateway.Http3': 'Http3',
  'app.gateway.CacheSize': 'Cache Size',
  'app.gateway.XForwardedFor': 'X Forwarded For',
  'app.gateway.AutoCert': 'Auto Cert',
  'app.gateway.ClientMaxBodySize': 'Client Max Body Size',
  'app.gateway.GlobalFilter': 'Global Filter',
  'app.gateway.AccessLogFormat': 'Access Log Format',
  'app.gateway.RouterKind': 'Router Kind',
  'app.gateway.Tracing': 'Tracing',
  'app.gateway.ServiceName': 'Service Name',
  'app.gateway.Attributes': 'Attributes',
  'app.gateway.Tags': 'Tags',
  'app.gateway.SpanLimits': 'Span Limits',
  'app.gateway.AttributeValueLengthLimit': 'Attribute Value Length Limit',
  'app.gateway.AttributeCountLimit': 'Attribute Count Limit',
  'app.gateway.EventCountLimit': 'Event Count Limit',
  'app.gateway.LinkCountLimit': 'Link Count Limit',
  'app.gateway.AttributePerEventCountLimit': 'Attribute Per Event Count Limit',
  'app.gateway.AttributePerLinkCountLimit': 'Attribute Per Link Count Limit',
  'app.gateway.SampleRate': 'Sample Rate',
  'app.gateway.BatchLimits': 'Batch Limits',
  'app.gateway.MaxQueueSize': 'Max Queue Size',
  'app.gateway.BatchTimeout': 'Batch Timeout',
  'app.gateway.ExportTimeout': 'Export Timeout',
  'app.gateway.MaxExportBatchSize': 'Max Export Batch Size',
  'app.gateway.Exporter': 'Exporter',
  'app.gateway.Jaeger': 'Jaeger',
  'app.gateway.OTLP': 'OTLP',
  'app.gateway.HeaderFormat': 'Header Format',
  'app.gateway.Value': 'Value',
  'app.gateway.Zipkin': 'Zipkin',
  'app.gateway.HostPort': 'Host Port',
  'app.gateway.ServerURL': 'Server URL',
  'app.gateway.DisableReport': 'Disable Report',
  'app.gateway.SameSpan': 'Same Span',
  'app.gateway.Id128Bit': 'Id 128 Bit',
  'app.gateway.CertBase64': 'Cert Base64',
  'app.gateway.KeyBase64': 'Key Base64',
  'app.gateway.Certs': 'Certs',
  'app.gateway.Keys': 'Keys',
  'app.gateway.Rules': 'Rules',
  'app.gateway.BlockByDefault': 'Block By Default',
  'app.gateway.AllowIPs': 'Allow IPs',
  'app.gateway.BlockIPs': 'Block IPs',
  'app.gateway.HostRegexp': 'Host Regexp',
  'app.gateway.Paths': 'Paths',
  'app.gateway.PathPrefix': 'Path Prefix',
  'app.gateway.PathRegexp': 'Path Regexp',
  'app.gateway.RewriteTarget': 'Rewrite Target',
  'app.gateway.Regexp': 'Regexp',
  'app.gateway.Values': 'Values',
  'app.gateway.Backend': 'Backend',
  'app.gateway.MatchAllHeader': 'Match All Header',
  'app.gateway.MatchAllQuery': 'Match All Query',
  'app.gateway.CaCertBase64': 'Ca Cert Base64',

  'app.gateway.ChooseATemplate': 'Choose a template',
  'app.gateway.Filters': 'Filters',
  'app.gateway.PreviewYAML': 'Preview YAML',
  'app.gateway.YAMLDiff': 'YAML Diff',
  'app.gateway.ViewYAML': 'View YAML',
  'app.gateway.Flow': 'Flow',
  'app.gateway.Result': 'Result',
  'app.gateway.JumpIf': 'Jump If',
  'app.gateway.JumpIfForm': 'Jump If Form',
  'app.gateway.JumpIfSettings': 'Jump If Settings',
  'app.gateway.JumpTo': 'Jump To',
  'app.gateway.Jump': 'Jump',
  'app.gateway.FilterComponents': 'Filter Components',
  'app.gateway.Kind': 'Kind',
  'app.gateway.Resilience': 'Resilience',
  'app.gateway.Data': 'Data',
  'app.gateway.FilterInstances': 'Filter Instances',
  'app.gateway.AddToFlow': 'Add to Flow',
  'app.gateway.Alias': 'Alias',
  'app.gateway.CopyReference': 'Copy Reference',
  'app.gateway.FlowChat': 'Flow Chat',

  'app.gateway.CertIndex': 'Cert Index',
  'app.gateway.Target': 'Target',
  'app.gateway.Field': 'Field',
  'app.gateway.HeaderKey': 'Header Key',
  'app.gateway.AllowedOrigins': 'Allowed Origins',
  'app.gateway.AllowedMethods': 'Allowed Methods',
  'app.gateway.AllowedHeaders': 'Allowed Headers',
  'app.gateway.AllowCredentials': 'Allow Credentials',
  'app.gateway.ExposedHeaders': 'Exposed Headers',
  'app.gateway.MaxAge': 'Max Age',
  'app.gateway.Template': 'Template',
  'app.gateway.DataKey': 'Data Key',
  'app.gateway.LeftDelim': 'Left Delim',
  'app.gateway.RightDelim': 'Right Delim',
  'app.gateway.MockCode': 'Mock Code',
  'app.gateway.MockHeaders': 'Mock Headers',
  'app.gateway.MockBody': 'Mock Body',
  'app.gateway.EtcdPrefix': 'Etcd Prefix',
  'app.gateway.PathRegExp': 'Path RegExp',
  'app.gateway.HeaderSetters': 'Header Setters',
  'app.gateway.EtcdKey': 'Etcd Key',
  'app.gateway.HeaderMap': 'Header Map',
  'app.gateway.Header': 'Header',
  'app.gateway.Default': 'Default',
  'app.gateway.DynamicHeader': 'Dynamic Header',
  'app.gateway.Policy': 'Policy',
  'app.gateway.HeaderHashKey': 'Header Hash Key',
  'app.gateway.StickySession': 'Sticky Session',
  'app.gateway.Mode': 'Mode',
  'app.gateway.AppCookieName': 'App Cookie Name',
  'app.gateway.LbCookieName': 'Lb Cookie Name',
  'app.gateway.LbCookieExpire': 'Lb Cookie Expire',
  'app.gateway.HealthCheck': 'Health Check',
  'app.gateway.Interval': 'Interval',
  'app.gateway.Timeout': 'Timeout',
  'app.gateway.Fails': 'Fails',
  'app.gateway.Passes': 'Passes',
  'app.gateway.ClientId': 'Client Id',
  'app.gateway.ClientSecret': 'Client Secret',
  'app.gateway.CookieName': 'Cookie Name',
  'app.gateway.Discovery': 'Discovery',
  'app.gateway.AuthorizationEndpoint': 'Authorization Endpoint',
  'app.gateway.TokenEndpoint': 'Token Endpoint',
  'app.gateway.UserInfoEndpoint': 'User Info Endpoint',
  'app.gateway.RedirectURI': 'Redirect URI',
  'app.gateway.DefaultStatus': 'Default Status',
  'app.gateway.ReadBody': 'Read Body',
  'app.gateway.IncludedHeaders': 'Included Headers',
  'app.gateway.Pools': 'Pools',
  'app.gateway.MirrorPool': 'Mirror Pool',
  'app.gateway.Compression': 'Compression',
  'app.gateway.MinLength': 'Min Length',
  'app.gateway.MTLS': 'MTLS',
  'app.gateway.RootCertBase64': 'Root Cert Base64',
  'app.gateway.MaxIdleConns': 'Max Idle Conns',
  'app.gateway.MaxIdleConnsPerHost': 'Max Idle Conns Per Host',
  'app.gateway.ServerMaxBodySize': 'Server Max Body Size',
  'app.gateway.Match': 'Match',
  'app.gateway.MatchPart': 'Match Part',
  'app.gateway.Replacement': 'Replacement',
  'app.gateway.StatusCode': 'Status Code',
  'app.gateway.URL': 'URL',
  'app.gateway.Method': 'Method',
  'app.gateway.Replace': 'Replace',
  'app.gateway.AddPrefix': 'Add Prefix',
  'app.gateway.TrimPrefix': 'Trim Prefix',
  'app.gateway.RegexpReplace': 'Regexp Replace',
  'app.gateway.Del': 'Del',
  'app.gateway.Set': 'Set',
  'app.gateway.Add': 'Add',
  'app.gateway.Body': 'Body',
  'app.gateway.Compress': 'Compress',
  'app.gateway.Decompress': 'Decompress',
  'app.gateway.Sign': 'Sign',
  'app.gateway.ApiProvider': 'Api Provider',
  'app.gateway.Scopes': 'Scopes',
  'app.gateway.Protocol': 'Protocol',
  'app.gateway.SourceNamespace': 'Source Namespace',
  'app.gateway.Exact': 'Exact',
  'app.gateway.Regex': 'Regex',
  'app.gateway.Empty': 'Empty',
  'app.gateway.Urls': 'Urls',
  'app.gateway.Permil': 'Permil',
  'app.gateway.MatchAllHeaders': 'Match All Headers',
  'app.gateway.Weight': 'Weight',
  'app.gateway.KeepHost': 'Keep Host',
  'app.gateway.SpanName': 'Span Name',
  'app.gateway.ServerTags': 'Server Tags',
  'app.gateway.ServiceRegistry': 'Server Registry',
  'app.gateway.LoadBalance': 'Load Balance',
  'app.gateway.MemoryCache': 'Memory Cache',
  'app.gateway.Codes': 'Codes',
  'app.gateway.Expiration': 'Expiration',
  'app.gateway.MaxEntryBytes': 'Max Entry Bytes',
  'app.gateway.Filter': 'Filter',
  'app.gateway.RetryPolicy': 'Retry Policy',
  'app.gateway.CircuitBreakerPolicy': 'Circuit Breaker Policy',
  'app.gateway.FailureCodes': 'Failure Codes',
  'app.gateway.JWT': 'JWT',
  'app.gateway.Algorithm': 'Algorithm',
  'app.gateway.PublicKey': 'Public Key',
  'app.gateway.Secret': 'Secret',
  'app.gateway.Signature': 'Signature',
  'app.gateway.Literal': 'Literal',
  'app.gateway.ScopeSuffix': 'Scope Suffix',
  'app.gateway.AlgorithmName': 'Algorithm Name',
  'app.gateway.AlgorithmValue': 'Algorithm Value',
  'app.gateway.SignedHeaders': 'Signed Headers',
  'app.gateway.Date': 'Date',
  'app.gateway.Expires': 'Expires',
  'app.gateway.Credential': 'Credential',
  'app.gateway.ContentSha256': 'Content Sha256',
  'app.gateway.SigningKeyPrefix': 'Signing Key Prefix',
  'app.gateway.ExcludeBody': 'Exclude Body',
  'app.gateway.TTL': 'TTL',
  'app.gateway.AccessKeys': 'Access Keys',
  'app.gateway.Id': 'Id',
  'app.gateway.AccessKeyId': 'Access Key Id',
  'app.gateway.AccessKeySecret': 'Access Key Secret',
  'app.gateway.IgnoredHeaders': 'Ignored Headers',
  'app.gateway.HeaderHoisting': 'Header Hoisting',
  'app.gateway.AllowedPrefix': 'Allowed Prefix',
  'app.gateway.DisallowedPrefix': 'Disallowed Prefix',
  'app.gateway.Disallowed': 'Disallowed',
  'app.gateway.Oauth2': 'Oauth2',
  'app.gateway.TokenIntrospect': 'Token Introspect',
  'app.gateway.EndPoint': 'End Point',
  'app.gateway.BasicAuth': 'Basic Auth',
  'app.gateway.InsecureTls': 'Insecure Tls',
  'app.gateway.UserFile': 'User File',
  'app.gateway.LDAP': 'LDAP',
  'app.gateway.BaseDN': 'Base DN',
  'app.gateway.Uid': 'Uid',
  'app.gateway.UseSSL': 'Use SSL',
  'app.gateway.SkipTLS': 'Skip TLS',
  'app.gateway.Insecure': 'Insecure',
  'app.gateway.MaxConcurrency': 'Max Concurrency',
  'app.gateway.Code': 'Code',
  'app.gateway.Parameters': 'Parameters',
  'app.gateway.DefaultOrigin': 'Default Origin',

  'app.gateway.Email': 'Email',
  'app.gateway.DirectoryURL': 'Directory URL',
  'app.gateway.RenewBefore': 'Renew Before',
  'app.gateway.EnableHTTP01': 'Enable HTTP01',
  'app.gateway.EnableTLSALPN01': 'Enable TLSALPN01',
  'app.gateway.EnableDNS01': 'Enable DNS01',
  'app.gateway.Domains': 'Domains',
  'app.gateway.DNSProvider': 'DNS Provider',
  'app.gateway.IPAddr': 'IP Addr',
  'app.gateway.Address': 'Address',
  'app.gateway.Scheme': 'Scheme',
  'app.gateway.ContextPath': 'Context Path',
  'app.gateway.DataCenter': 'Data Center',
  'app.gateway.Token': 'Token',
  'app.gateway.Namespace': 'Namespace',
  'app.gateway.SyncInterval': 'Sync Interval',
  'app.gateway.ServiceTags': 'Service Tags',
  'app.gateway.Driver': 'Driver',
  'app.gateway.Topic': 'Topic',
  'app.gateway.KafkaTopic': 'Kafka Topic',
  'app.gateway.Brokers': 'Brokers',
  'app.gateway.KafkaBrokers': 'Kafka Brokers',
  'app.gateway.Endpoints': 'Endpoints',
  'app.gateway.Prefix': 'Prefix',
  'app.gateway.CacheTimeout': 'Cache Timeout',
  'app.gateway.BeforePipeline': 'Before Pipeline',
  'app.gateway.AfterPipeline': 'After Pipeline',
  'app.gateway.KubeConfig': 'Kube Config',
  'app.gateway.MasterURL': 'Master URL',
  'app.gateway.Namespaces': 'Namespaces',
  'app.gateway.IngressClass': 'Ingress Class',
  'app.gateway.HTTPServer': 'HTTP Server',
  'app.gateway.Servers': 'Servers',
  'app.gateway.Username': 'Username',
  'app.gateway.Password': 'Password',
  'app.gateway.ZKServices': 'ZK Services',
  'app.gateway.ConnTimeout': 'Conn Timeout',

  'app.gateway.FormSubmitCheckFail':
    'There are fields in the current form that have not passed the checksum, please check them before submitting',

  'app.gateway.NameDuplication':
    'Name cannot be duplicated, please change it and submit again',

  'app.gateway.FlowItemUpOrDownTips':
    'Due to the reordering, the jumps associated with the current process record have been removed, so if necessary, please reset the jump association',

  'app.gateway.MaxAttempts': 'Max Attempts',
  'app.gateway.WaitDuration': 'Wait Duration',
  'app.gateway.BackOffPolicy': 'Back Off Policy',
  'app.gateway.RandomizationFactor': 'Randomization Factor',

  'app.gateway.SlidingWindowType': 'Sliding Window Type',
  'app.gateway.FailureRateThreshold': 'Failure Rate Threshold',
  'app.gateway.SlowCallRateThreshold': 'SlowCall Rate Threshold',
  'app.gateway.SlowCallDurationThreshold': 'Slow Call Duration Threshold',
  'app.gateway.SlidingWindowSize': 'Sliding Window Size',
  'app.gateway.PermittedNumberOfCallsInHalfOpenState':
    'Permitted Number Of Calls In Half Open State',
  'app.gateway.MinimumNumberOfCalls': 'Minimum Number Of Calls',
  'app.gateway.MaxWaitDurationInHalfOpenState':
    'Max Wait Duration In Half Open State',
  'app.gateway.WaitDurationInOpenState': 'Wait Duration In Open State',

  'app.traffic.tips.beforeDeploy': 'Do deploy based on current configuration?',
  'app.traffic.error.nullOrParseFail': 'No data or data format incorrect.',
  'app.traffic.btn.deploy': 'Deploy',
  'app.traffic.btn.cancel': 'Cancel',
  'app.traffic.blueGreen.label.currentSelector': 'Selector before adjustment: ',
  'app.traffic.blueGreen.label.selectTags': 'Adjustment selector: ',
  'app.traffic.blueGreen.label.afterSelector': 'Selector after adjustment: ',
  'app.traffic.blueGreen.table.name': 'name',
  'app.traffic.blueGreen.table.tags': 'tags',
  'app.traffic.blueGreen.table.currentSelected': 'Effective before adjustment',
  'app.traffic.blueGreen.table.afterSelected': 'Effective after adjustment',
  'app.traffic.blueGreen.table.yes': 'yes',
  'app.traffic.blueGreen.comm.all': 'ALL',

  'app.traffic.canary.title.backend': 'MainPool',
  'app.traffic.canary.title.candidateBackend': 'CandidatePool',
  'app.traffic.canary.title.canarySettings': 'Canary Settings',
  'app.traffic.canary.table.name': 'url',
  'app.traffic.canary.table.tags': 'tags',
  'app.traffic.canary.table.selected': 'selected',
  'app.traffic.canary.table.yes': 'yes',

  'app.traffic.filter.manage.table.kind': 'Kind',
  'app.traffic.filter.manage.table.jsonSchema': 'JSON Schema',
  'app.traffic.filter.manage.table.uiSchema': 'UI Schema',
  'app.traffic.filter.manage.table.results': 'Results',
  'app.traffic.filter.manage.table.helpDoc': 'Help Documentation',
  'app.traffic.filter.manage.table.customScript': 'Custom Script',
  'app.traffic.filter.manage.table.createDate': 'Create Date',
  'app.traffic.filter.manage.table.updateDate': 'Update Date',
  'app.traffic.filter.manage.table.link.delete': 'Delete',
  'app.traffic.filter.manage.table.link.viewOrEdit': 'View / Edit',
  'app.traffic.filter.manage.btn.addPlugin': 'Add Filter',
  'app.traffic.filter.manage.btn.importPlugin': 'Import Filter',
  'app.traffic.filter.manage.btn.import': 'Import',
  'app.traffic.filter.manage.btn.reimport': 'Reimport',

  'app.traffic.help.onlinehelp': 'Online Help',
  'app.traffic.help.nocontent': 'No help for this topic.',

  'app.traffic.deploy.pullConfiguration': 'Pull Configuration',
  'app.traffic.deploy.history': 'History',
  'app.traffic.deploy.deployBtn': 'Deploy',
  'app.traffic.deploy.rollbackBtn': 'Rollback',

  'app.traffic.deploy.table.updatedAt': 'Updated At',
  'app.traffic.deploy.table.name': 'Name',
  'app.traffic.deploy.table.Errors': 'Errors',
  'app.traffic.deploy.table.view': 'View',
  'app.traffic.deploy.table.type': 'Type',
  'app.traffic.deploy.table.kind': 'Kind',
  'app.traffic.deploy.table.time': 'Time',
  'app.traffic.deploy.table.mode': 'Mode',
  'app.traffic.deploy.table.description': 'Description',
  'app.traffic.deploy.table.operator': 'Operator',

  'app.traffic.table.path': 'Path',

  'app.traffic.toolBar.cluster': 'Cluster',
  'app.traffic.toolBar.name': 'Name',
  'app.traffic.toolBar.server': 'Server',
  'app.traffic.toolBar.BackendProxy': 'Backend Proxy',
  'app.traffic.toolBar.member': 'Member',

  'app.traffic.createServer': 'Create Server',
  'app.traffic.editServer': 'Edit Server',
  'app.traffic.importServer': 'Import Server',
  'app.traffic.addRoute': 'Add Route',
  'app.traffic.editRoute': 'Edit Route',

  'app.traffic.Keyword': 'Keyword',
  'app.traffic.importPipeline': 'Import Pipeline',
  'app.traffic.createPipeline': 'Create Pipeline',

  'app.traffic.importComponents': 'Import Components',
  'app.traffic.exportComponents': 'Export Components',

  'app.traffic.server.label.host': 'Host',
  'app.traffic.server.label.port': 'Port',
  'app.traffic.server.label.status': 'Status',
  'app.traffic.server.label.routes': 'Routes',
  'app.traffic.server.label.ipFilter': 'IP Filter',
  'app.traffic.server.label.headers': 'Headers',
  'app.traffic.server.label.methods': 'Methods',
  'app.traffic.server.label.pipeline': 'Pipeline',
  'app.traffic.server.tips.no-routes.title': 'No Routes',
  'app.traffic.server.tips.no-routes.content':
    'Please add at least one Route before deploying.',
  'app.traffic.server.label.header-name': 'Name',
  'app.traffic.server.label.header-value': 'Value',

  'app.traffic.title-mark.edited': 'Edited',
  'app.traffic.title-mark.deleted': 'Deleted',
  'app.traffic.title-mark.new': 'New',

  'app.traffic.pipeline.lable.filter': 'Filter',
  'app.traffic.pipeline.lable.httpServer': 'HTTP Server',
  'app.traffic.pipeline.lable.upstreams': 'Upstreams',
  'app.traffic.pipeline.lable.drafts': 'Drafts',

  'app.traffic.pipeline.btn.flow': 'Flow',

  'app.traffic.importCmp.label.text': 'Text',
  'app.traffic.importCmp.label.file': 'File',
  'app.traffic.controller.description.AutoCertManager': `AutoCertManager automatically manage HTTPS certificates.`,
  'app.traffic.controller.description.ConsulServiceRegistry': `Use Consul as a service provider for service discovery. You can configure multiple configurations for different requests.`,
  'app.traffic.controller.description.EaseMonitorMetrics': `EaseMonitor integration, used to report the metrics data generated by itself to the EaseMonitor System.`,
  'app.traffic.controller.description.EtcdServiceRegistry': `Use etcd as a service provider for service discovery. You can configure multiple configurations for different requests.`,
  'app.traffic.controller.description.EurekaServiceRegistry': `Use Eureka as a service provider for service discovery. You can configure multiple configurations for different requests.`,
  'app.traffic.controller.description.GlobalFilter': `GlobalFilter is a special pipeline that can be executed before or/and after all pipelines in a server.`,
  'app.traffic.controller.description.IngressController': `The IngressController is an implementation of Kubernetes ingress controller, it watches Kubernetes Ingress, Service, Endpoints, and Secrets then translates them to Easegress HTTP server and pipelines.`,
  'app.traffic.controller.description.NacosServiceRegistry': `NacosServiceRegistry supports service discovery for Nacos as backend.`,
  'app.traffic.controller.description.ZookeeperServiceRegistry': `Use ZooKeeper as a service provider for service discovery. You can configure multiple configurations for different requests.`,

  'app.gateway.PipelinePage.components.CardItem.ThisNameAlreadyExists':
    'This Name Already Exists',

  'app.gateway.Pipeline.PoolsFilterError':
    'Pools have and can have only one pool with an empty filter',

  'app.gateway.titleid': 'Description',

  'app.gateway.helpid.Port': 'The HTTP port listening on',
  'app.gateway.helpid.IPFilter': 'IP Filter for all traffic under the server',
  'app.gateway.helpid.Host': 'Exact host to match, empty means to match all',
  'app.gateway.helpid.Path': 'Exact path to match',
  'app.gateway.helpid.Methods':
    'Methods to match, empty means to allow all methods',
  'app.gateway.helpid.PathHeaders':
    "Headers to match (the requests matching headers won't be put into cache)",
  'app.gateway.helpid.Pipeline':
    'backend name (pipeline name in static config, service name in mesh)',
  'app.gateway.helpid.SimpleHTTPProxy': 'The SimpleHTTPProxy filter is a simplified version of the Proxy filter, designed to handle HTTP requests in a more straightforward manner while providing basic proxy functionality for backend services.',

  'app.gateway.helpid.KeepAlive': 'Whether to support keepalive',
  'app.gateway.helpid.KeepAliveTimeout': 'The timeout of keepalive',
  'app.gateway.helpid.Https': 'Whether to use HTTPS',
  'app.gateway.helpid.MaxConnections': 'The max connections with clients',
  'app.gateway.helpid.Http3': 'Whether to support HTTP3(QUIC)',
  'app.gateway.helpid.CacheSize': 'The size of cache, 0 means no cache',
  'app.gateway.helpid.XForwardedFor':
    'Whether to set X-Forwarded-For header by own ip',
  'app.gateway.helpid.AutoCert': 'Do HTTP certification automatically',
  'app.gateway.helpid.ClientMaxBodySize':
    'Max size of request body. the default value is 4MB. Requests with a body larger than this option are discarded. When this option is set to -1, Easegress takes the request body as a stream and the body can be any size, but some features are not possible in this case, please refer [Stream](https://github.com/megaease/easegress/blob/main/doc/reference/stream.md) for more information.',
  'app.gateway.helpid.GlobalFilter':
    'Name of [GlobalFilter](https://github.com/megaease/easegress/blob/main/doc/reference/controllers.md#globalfilter) for all backends',
  'app.gateway.helpid.AccessLogFormat':
    'Format of access log, default is \\[{{Time}}\\] \\[{{RemoteAddr}} {{RealIP}} {{Method}} {{URI}} {{Proto}} {{StatusCode}}\\] \\[{{Duration}} rx:{{ReqSize}}B tx:{{RespSize}}B\\] \\[{{Tags}}\\], variable is delimited by "{{" and "}}", please refer [Access Log Variable](https://github.com/megaease/easegress/blob/main/doc/reference/controllers.md#accesslogvariable) for all built-in variables',
  'app.gateway.helpid.RouterKind':
    'Kind of router. see [routers](https://github.com/megaease/easegress/blob/main/doc/reference/routers.md)',
  'app.gateway.helpid.Tracing': 'Distributed tracing settings',
  'app.gateway.helpid.ServiceName': 'The service name of top level',
  'app.gateway.helpid.Attributes': 'Attributes to include to every span.',
  'app.gateway.helpid.Tags':
    'Deprecated. Tags to include to every span. This option will be kept until the next major version incremented release.',
  'app.gateway.helpid.SpanLimits':
    'SpanLimitsSpec represents the limits of a span.',
  'app.gateway.helpid.AttributeValueLengthLimit':
    'AttributeValueLengthLimit is the maximum allowed attribute value length, Setting this to a negative value means no limit is applied',
  'app.gateway.helpid.AttributeCountLimit':
    'AttributeCountLimit is the maximum allowed span attribute count',
  'app.gateway.helpid.EventCountLimit':
    'EventCountLimit is the maximum allowed span event count',
  'app.gateway.helpid.LinkCountLimit':
    'LinkCountLimit is the maximum allowed span link count',
  'app.gateway.helpid.AttributePerEventCountLimit':
    'AttributePerEventCountLimit is the maximum number of attributes allowed per span event',
  'app.gateway.helpid.AttributePerLinkCountLimit':
    'AttributePerLinkCountLimit is the maximum number of attributes allowed per span link',
  'app.gateway.helpid.SampleRate':
    'The sample rate for collecting metrics, the range is [0, 1]. For backward compatibility, if the exporter is empty, the default is to use zipkin.sampleRate',
  'app.gateway.helpid.BatchLimits':
    'BatchLimitsSpec describes BatchSpanProcessorOptions',
  'app.gateway.helpid.MaxQueueSize':
    'MaxQueueSize is the maximum queue size to buffer spans for delayed processing',
  'app.gateway.helpid.BatchTimeout':
    'BatchTimeout is the maximum duration for constructing a batch',
  'app.gateway.helpid.ExportTimeout':
    'ExportTimeout specifies the maximum duration for exporting spans',
  'app.gateway.helpid.MaxExportBatchSize':
    'MaxExportBatchSize is the maximum number of spans to process in a single batch',
  'app.gateway.helpid.Exporter':
    'ExporterSpec describes exporter. exporter and zipkin cannot both be empty',
  'app.gateway.helpid.Jaeger': 'JaegerSpec describes Jaeger',
  'app.gateway.helpid.OTLP': 'OTLPSpec describes OpenTelemetry exporter',
  'app.gateway.helpid.HeaderFormat':
    'HeaderFormat represents which format should be used for context propagation. options: [trace-conext](https://www.w3.org/TR/trace-context/),b3. For backward compatibility, the historical Zipkin configuration remains in b3 format.',
  'app.gateway.helpid.Zipkin': 'ZipkinSpec describes Zipkin',
  'app.gateway.helpid.ServerURL': 'The zipkin server URL',
  'app.gateway.helpid.CertBase64':
    'Public key of PEM encoded data in base64 encoded format',
  'app.gateway.helpid.KeyBase64':
    'Private key of PEM encoded data in base64 encoded format',
  'app.gateway.helpid.Certs':
    'Public keys of PEM encoded data, the key is the logic pair name, which must match keys',
  'app.gateway.helpid.Keys':
    'Private keys of PEM encoded data, the key is the logic pair name, which must match certs',
  'app.gateway.helpid.Rules': 'Router rules',
  'app.gateway.helpid.BlockByDefault':
    'Set block is the default action if not matching',
  'app.gateway.helpid.AllowIPs':
    'IPs to be allowed to pass (support IPv4, IPv6, CIDR)',
  'app.gateway.helpid.BlockIPs':
    'IPs to be blocked to pass (support IPv4, IPv6, CIDR)',
  'app.gateway.helpid.HostRegexp':
    'Host in regular expression to match, empty means to match all',
  'app.gateway.helpid.Paths':
    'Path matching rules, empty means to match nothing. Note that multiple paths are matched in the order of their appearance in the spec, this is different from Nginx.',
  'app.gateway.helpid.PathPrefix': 'Prefix of the path to match',
  'app.gateway.helpid.PathRegexp': 'Path in regular expression to match',
  'app.gateway.helpid.RewriteTarget':
    'Use pathRegexp.[ReplaceAllString](https://pkg.go.dev/regexp#Regexp.ReplaceAllString)(path, rewriteTarget) or pathPrefix [strings.Replace](https://pkg.go.dev/strings#Replace) to rewrite request path',
  'app.gateway.helpid.Regexp': 'Header value in regular expression to match',
  'app.gateway.helpid.PathRegexpReplaceRegexp':
    'Regular expression to match request path. The syntax of the regular expression is [RE2](https://github.com/google/re2/wiki/Syntax)',
  'app.gateway.helpid.Values': 'Header values to match',
  'app.gateway.helpid.Backend':
    'backend name (pipeline name in static config, service name in mesh)',
  'app.gateway.helpid.MatchAllHeader':
    'Match all headers that are defined in headers, default is false.',
  'app.gateway.helpid.MatchAllQuery':
    'Match all queries that are defined in queries, default is false.',
  'app.gateway.helpid.CaCertBase64':
    'Define the root certificate authorities that servers use if required to verify a client certificate by the policy in TLS Client Authentication.',

  'app.gateway.helpid.Flow':
    'The execution order of filters, if empty, will use the order of the filter definitions',
  'app.gateway.helpid.Filters':
    'Defines filters, please refer [Filters](https://github.com/megaease/easegress/blob/main/doc/reference/filters.md) for details of a specific filter kind',
  'app.gateway.helpid.Resilience':
    'Defines resilience policies, please refer [Resilience Policy](https://github.com/megaease/easegress/blob/main/doc/reference/controllers.md#resiliencepolicy) for details of a specific resilience policy',
  'app.gateway.helpid.Data': 'Static user data of the pipeline',
  'app.gateway.helpid.CertIndex':
    'The index of the certificate in the chain. Negative indexes from the end of the chain (-1 is the last index, -2 second last etc.)',
  'app.gateway.helpid.Target':
    'Either subject or issuer of the [x509.Certificate](https://pkg.go.dev/crypto/x509#Certificate)',
  'app.gateway.helpid.Field':
    'One of the string or string slice fields from https://pkg.go.dev/crypto/x509/pkix#Name',
  'app.gateway.helpid.HeaderKey':
    'Extracted value is added to this request header key.',
  'app.gateway.helpid.AllowedOrigins':
    'An array of origins a cross-domain request can be executed from. If the special * value is present in the list, all origins will be allowed. An origin may contain a wildcard () to replace 0 or more characters (i.e.: http://.domain.com). Usage of wildcards implies a small performance penalty. Only one wildcard can be used per origin. Default value is *',
  'app.gateway.helpid.AllowedMethods':
    'An array of methods the client is allowed to use with cross-domain requests. The default value is simple methods (HEAD, GET, and POST)',
  'app.gateway.helpid.AllowedHeaders':
    'An array of non-simple headers the client is allowed to use with cross-domain requests. If the special * value is present in the list, all headers will be allowed. The default value is [] but "Origin" is always appended to the list',
  'app.gateway.helpid.AllowCredentials':
    'Indicates whether the request can include user credentials like cookies, HTTP authentication, or client-side SSL certificates',
  'app.gateway.helpid.ExposedHeaders':
    'Indicates which headers are safe to expose to the API of a CORS API specification',
  'app.gateway.helpid.MaxAge':
    'Indicates how long (in seconds) the results of a preflight request can be cached. The default is 0 stands for no max age',
  'app.gateway.helpid.Template':
    'template to create data, please refer the [template](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#template-of-builer-filters) for more information',
  'app.gateway.helpid.DataKey': 'key to store data',
  'app.gateway.helpid.LeftDelim':
    'left action delimiter of the template, default is {{',
  'app.gateway.helpid.RightDelim':
    'right action delimiter of the template, default is }}',
  'app.gateway.helpid.MockCode':
    'This code overwrites the status code of the original response',
  'app.gateway.helpid.MockHeaders':
    'Headers to be added/set to the original response',
  'app.gateway.helpid.MockBody':
    'Default is an empty string, overwrite the body of the original response if specified',
  'app.gateway.helpid.EtcdPrefix': 'Kind of custom data',
  'app.gateway.helpid.PathRegExp': 'Reg used to get key from request path',
  'app.gateway.helpid.HeaderSetters': 'Set custom data value to http header',
  'app.gateway.helpid.EtcdKey': 'Key used to get data',
  'app.gateway.helpid.HeaderMap':
    'headerMap defines a map between HTTP header name and corresponding JSON field name',
  'app.gateway.helpid.Header': 'The HTTP header that contains JSON value',
  'app.gateway.helpid.Default': 'Default topic for Kafka backend',
  'app.gateway.helpid.DynamicHeader':
    'The HTTP header that contains Kafka topic',

  'app.gateway.helpid.MockRules': 'Mocking rules',
  'app.gateway.helpid.MockRuleCode': 'HTTP status code of the mocked response',
  'app.gateway.helpid.MockRuleMatch': 'Rule to match a request\t',
  'app.gateway.helpid.MockRuleMatchPath':
    'Path match criteria, if request path is the value of this option, then the response of the request is mocked according to this rule\t',
  'app.gateway.helpid.MockRuleMatchPathPrefix':
    'Path prefix match criteria, if request path begins with the value of this option, then the response of the request is mocked according to this rule\t',
  'app.gateway.helpid.MockRuleMatchMatchAllHeaders':
    'Whether to match all headers\t',
  'app.gateway.helpid.MockRuleMatchHeaders':
    'Headers to match, key is a header name, value is the rule to match the header value\t',
  'app.gateway.helpid.MockRuleDelay':
    'Delay duration, for the request processing time mocking',
  'app.gateway.helpid.MockRuleHeaders': 'Headers of the mocked response\t',
  'app.gateway.helpid.MockRuleBody':
    'Body of the mocked response, default is an empty string\t',

  'app.gateway.helpid.Policy':
    'Load balance policy, valid values are roundRobin, random, weightedRandom, ipHash ,and headerHash',
  'app.gateway.helpid.HeaderHashKey':
    'When policy is headerHash, this option is the name of a header whose value is used for hash calculation',
  'app.gateway.helpid.StickySession': 'Sticky session spec',
  'app.gateway.helpid.Mode':
    'Mode of session stickiness, support CookieConsistentHash,DurationBased,ApplicationBased',
  'app.gateway.helpid.AppCookieName':
    'Name of the application cookie, its value will be used as the session identifier for stickiness in CookieConsistentHash and ApplicationBased mode',
  'app.gateway.helpid.LbCookieName':
    'Name of the cookie generated by load balancer, its value will be used as the session identifier for stickiness in DurationBased and ApplicationBased mode, default is EG_SESSION',
  'app.gateway.helpid.LbCookieExpire':
    'Expire duration of the cookie generated by load balancer, its value will be used as the session expire time for stickiness in DurationBased and ApplicationBased mode, default is 2 hours',
  'app.gateway.helpid.HealthCheck':
    'Health check spec, note that healthCheck is not needed if you are using service registry',
  'app.gateway.helpid.Interval':
    'Interval duration for health check, default is 60s',
  'app.gateway.helpid.Timeout':
    'Timeout duration for health check, default is 3s',
  'app.gateway.helpid.Fails':
    'Consecutive fails count for assert fail, default is 1',
  'app.gateway.helpid.Passes':
    'Consecutive passes count for assert pass , default is 1',
  'app.gateway.helpid.ClientId': 'The OAuth2.0 app client id',
  'app.gateway.helpid.ClientSecret': 'The OAuth2.0 app client secret',
  'app.gateway.helpid.CookieName':
    'Used to check if necessary to launch OpenIDConnect flow',
  'app.gateway.helpid.Discovery':
    'Standard OpenID Connect discovery endpoint URL of the identity server',
  'app.gateway.helpid.AuthorizationEndpoint':
    'OAuth2.0 authorization endpoint URL',
  'app.gateway.helpid.TokenEndpoint': 'OAuth2.0 token endpoint URL',
  'app.gateway.helpid.UserInfoEndpoint': 'OAuth2.0 user info endpoint URL',
  'app.gateway.helpid.RedirectURI':
    'The callback uri registered in identity server, for example: https://example.com/oidc/callback or /oidc/callback',
  'app.gateway.helpid.DefaultStatus':
    'The default HTTP status code when request is denied by the OPA policy decision',
  'app.gateway.helpid.ReadBody':
    'Whether to read request body as OPA policy data on condition',
  'app.gateway.helpid.IncludedHeaders':
    'Names of the HTTP headers to be included in input.request.headers, comma-separated',
  'app.gateway.helpid.Pools':
    "The pool without filter is considered the main pool, other pools with filter are considered candidate pools, and a Proxy must contain exactly one main pool. When Proxy gets a request, it first goes through the candidate pools, and if one of the pool's filter matches the request, servers of this pool handle the request, otherwise, the request is passed to the main pool.",
  'app.gateway.helpid.MirrorPool':
    'Define a mirror pool, requests are sent to this pool simultaneously when they are sent to candidate pools or main pool',
  'app.gateway.helpid.Compression': 'Response compression options',
  'app.gateway.helpid.MinLength':
    'Minimum response body size to be compressed, response with a smaller body is never compressed',
  'app.gateway.helpid.MTLS': 'mTLS configuration',
  'app.gateway.helpid.RootCertBase64': 'Base64 encoded root certificate',
  'app.gateway.helpid.MaxIdleConns':
    'Controls the maximum number of idle (keep-alive) connections across all hosts. Default is 10240',
  'app.gateway.helpid.MaxIdleConnsPerHost':
    'Controls the maximum idle (keep-alive) connections to keep per-host. Default is 1024',
  'app.gateway.helpid.ServerMaxBodySize':
    'Max size of response body. the default value is 4MB. Responses with a body larger than this option are discarded. When this option is set to -1, Easegress takes the response body as a stream and the body can be any size, but some features are not possible in this case, please refer [Stream](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/stream.md) for more information.',
  'app.gateway.helpid.Match':
    'Regular expression to match request path. The syntax of the regular expression is [RE2](https://github.com/google/re2/wiki/Syntax)',
  'app.gateway.helpid.MatchPart':
    'Parameter to decide which part of url used to do match, supported values: uri, full, path. Default value is uri.',
  'app.gateway.helpid.Replacement':
    'Replacement when the match succeeds. Placeholders like $1, $2 can be used to represent the sub-matches in regexp',
  'app.gateway.helpid.StatusCode':
    'Status code of response. Supported values: 301, 302, 303, 304, 307, 308. Default: 301.',
  'app.gateway.helpid.URL': 'Address of remote service',
  'app.gateway.helpid.Method':
    'HTTP Method of the result request, default is GET.',
  'app.gateway.helpid.Replace':
    'Replacement when the match succeeds. Placeholders like $1, $2 can be used to represent the sub-matches in regexp',
  'app.gateway.helpid.AddPrefix':
    'Prepend the value of this option to request path when specified',
  'app.gateway.helpid.TrimPrefix':
    'Trims the value of this option if request path start with it when specified\t',
  'app.gateway.helpid.RegexpReplace':
    'Revise request path with regular expression\t',
  'app.gateway.helpid.Del': 'Name of the headers to be removed',
  'app.gateway.helpid.Set': 'Name & value of headers to be set',
  'app.gateway.helpid.Add': 'Name & value of headers to be added',
  'app.gateway.helpid.Body': 'Body of the result request.',
  'app.gateway.helpid.Compress': 'compress body, currently only support gzip',
  'app.gateway.helpid.Decompress':
    'decompress body, currently only support gzip',
  'app.gateway.helpid.Sign':
    'If provided, sign the request using the Amazon Signature V4 signing process with the configuration',
  'app.gateway.helpid.ApiProvider':
    'The RequestAdaptor pre-defines the [Literal](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#signerliteral) and [HeaderHoisting](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#signerheaderhoisting) configuration for some API providers, specify the provider name in this field to use one of them, only aws4 is supported at present.',
  'app.gateway.helpid.Scopes': 'Scopes of the input request',
  'app.gateway.helpid.Protocol':
    'protocol of the request to build, default is http.',
  'app.gateway.helpid.SourceNamespace':
    'add a reference to the request of the source namespace',
  'app.gateway.helpid.Exact':
    'The string must be identical to the value of this field.',
  'app.gateway.helpid.Regex':
    'The string must the regular expression specified by the value of this field',
  'app.gateway.helpid.Empty': 'The string must be empty',
  'app.gateway.helpid.Urls': 'Request URL match criteria',
  'app.gateway.helpid.Permil':
    'the probability of requests been matched. Value between 0 to 1000',
  'app.gateway.helpid.MatchAllHeaders': 'All rules in headers should be match',
  'app.gateway.helpid.Weight':
    'When load balance policy is weightedRandom, this value is used to calculate the possibility of this server',
  'app.gateway.helpid.KeepHost':
    'If true, the Host is the same as the original request, no matter what is the value of url. Default value is false.',
  'app.gateway.helpid.SpanName':
    'Span name for tracing, if not specified, the url of the target server is used',
  'app.gateway.helpid.ServerTags':
    'Server selector tags, only servers have tags in this array are included in this pool',
  'app.gateway.helpid.ProxyServerURL':
    'Address of the server. The address should start with http:// or https:// (when used in the WebSocketProxy, it can also start with ws:// and wss://), followed by the hostname or IP address of the server, and then optionally followed by :{port number}, for example: https://www.megaease.com, http://10.10.10.10:8080. When host name is used, the Host of a request sent to this server is always the hostname of the server, and therefore using a [RequestAdaptor](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#requestadaptor) in the pipeline to modify it will not be possible; when IP address is used, the Host is the same as the original request, that can be modified by a [RequestAdaptor](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#requestadaptor). See also KeepHost',
  'app.gateway.helpid.ProxyServerTags':
    'Tags of this server, refer serverTags in [proxy.PoolSpec](https://github.com/megaease/easegress/blob/d5edf281b2e253577c27b9656f5b1c2924019430/doc/reference/filters.md#proxyPoolSpec)',
  'app.gateway.helpid.ProxyServerWeight':
    'When load balance policy is weightedRandom, this value is used to calculate the possibility of this server',
  'app.gateway.helpid.ProxyServerKeepHost':
    'If true, the Host is the same as the original request, no matter what is the value of url. Default value is false',
  'app.gateway.helpid.ServiceRegistry':
    'This option and serviceName are for dynamic server discovery',
  'app.gateway.helpid.LoadBalance': 'Load balance options',
  'app.gateway.helpid.MemoryCache': 'Options for response caching',
  'app.gateway.helpid.Codes': 'HTTP status codes to be cached',
  'app.gateway.helpid.Expiration': 'Expiration duration of cache entries',
  'app.gateway.helpid.MaxEntryBytes':
    'Maximum size of the response body, response with a larger body is never cached',
  'app.gateway.helpid.Filter': 'Filter options for candidate pools',
  'app.gateway.helpid.RetryPolicy': 'Retry policy name',
  'app.gateway.helpid.CircuitBreakerPolicy': 'CircuitBreaker policy name',
  'app.gateway.helpid.FailureCodes':
    "Proxy return result of failureCode when backend resposne's status code in failureCodes. The default value is 5xx",
  'app.gateway.helpid.Headers':
    'Header validation rules, the key is the header name and the value is validation rule for corresponding header value, a request needs to pass all of the validation rules to pass the headers validation',
  'app.gateway.helpid.JWT': 'Configuration for Self-Encoded Access Tokens mode',
  'app.gateway.helpid.ValidatorJWT':
    'JWT validation rule, validates JWT token string from the Authorization header or cookies',
  'app.gateway.helpid.ValidatorSignature':
    'Signature validation rule, implements an [Amazon Signature V4](https://docs.aws.amazon.com/general/latest/gr/create-signed-request.html) compatible signature validation validator, with customizable literal strings',
  'app.gateway.helpid.ValidatorOauth2':
    'The OAuth/2 method support Token Introspection mode and Self-Encoded Access Tokens mode, only one mode can be configured at a time',
  'app.gateway.helpid.ValidatorBasicAuth':
    'The BasicAuth method support FILE, ETCD and LDAP mode, only one mode can be configured at a time',
  'app.gateway.helpid.Algorithm':
    'The algorithm for validation:HS256,HS384,HS512,RS256,RS384,RS512,ES256,ES384,ES512,EdDSA are supported',
  'app.gateway.helpid.PublicKey':
    'The public key is used for RS256,RS384,RS512,ES256,ES384,ES512 or EdDSA validation in hex encoding',
  'app.gateway.helpid.Secret':
    'The secret is for HS256,HS384,HS512 validation in hex encoding',
  'app.gateway.helpid.Signature':
    'Signature validation rule, implements an Amazon Signature V4 compatible signature validation validator, with customizable literal strings',
  'app.gateway.helpid.Literal':
    'Literal strings for customization, default value is used if omitted',
  'app.gateway.helpid.ScopeSuffix':
    'The last part to build credential scope, default is request, in Amazon Signature V4, it is aws4_request',
  'app.gateway.helpid.AlgorithmName':
    'The query name of the signature algorithm in the request, default is X-Algorithm, in Amazon Signature V4, it is X-Amz-Algorithm',
  'app.gateway.helpid.AlgorithmValue':
    'The header/query value of the signature algorithm for the request, default is "HMAC-SHA256", in Amazon Signature V4, it is AWS4-HMAC-SHA256',
  'app.gateway.helpid.SignedHeaders':
    'The header/query headers of the signed headers, default is X-SignedHeaders, in Amazon Signature V4, it is X-Amz-SignedHeaders',
  'app.gateway.helpid.Date':
    'The header/query name of the request time, default is X-Date, in Amazon Signature V4, it is X-Amz-Date',
  'app.gateway.helpid.Expires':
    'The query name of expire duration, default is X-Expires, in Amazon Signature V4, it is X-Amz-Date',
  'app.gateway.helpid.Credential':
    'The query name of credential, default is X-Credential, in Amazon Signature V4, it is X-Amz-Credential',
  'app.gateway.helpid.ContentSha256':
    'The header name of body/payload hash, default is X-Content-Sha256, in Amazon Signature V4, it is X-Amz-Content-Sha256',
  'app.gateway.helpid.SigningKeyPrefix':
    'The prefix is prepended to access key secret when deriving the signing key, default is an empty string, in Amazon Signature V4, it is AWS4',
  'app.gateway.helpid.ExcludeBody':
    'Exclude request body from the signature calculation, default is false',
  'app.gateway.helpid.TTL':
    'Time to live of a signature, default is 0 means a signature never expires',
  'app.gateway.helpid.AccessKeys':
    'A map of access key id to access key secret',
  'app.gateway.helpid.AccessKeyId': 'ID used to set credential',
  'app.gateway.helpid.AccessKeySecret': 'Value usd to set credential',
  'app.gateway.helpid.IgnoredHeaders': 'Headers to be ignored',
  'app.gateway.helpid.HeaderHoisting':
    "HeaderHoisting defines which headers are allowed to be moved from header to query in presign: header with name has one of the allowed prefixes, but hasn't any disallowed prefixes and doesn't match any of disallowed names are allowed to be hoisted",
  'app.gateway.helpid.AllowedPrefix': 'Allowed prefix for headers',
  'app.gateway.helpid.DisallowedPrefix': 'Disallowed prefix for headers',
  'app.gateway.helpid.Disallowed': 'Disallowed headers',
  'app.gateway.helpid.Oauth2':
    'The OAuth/2 method support Token Introspection mode and Self-Encoded Access Tokens mode, only one mode can be configured at a time',
  'app.gateway.helpid.TokenIntrospect':
    'Configuration for Token Introspection mode',
  'app.gateway.helpid.EndPoint':
    'The endpoint of the token introspection server',
  'app.gateway.helpid.BasicAuth':
    'If clientId not specified and this option is specified, its value is used for basic authorization with the token introspection server',
  'app.gateway.helpid.InsecureTls':
    'Whether the connection between Easegress and the token introspection server need to be secure or not, default is false means the connection need to be a secure one',
  'app.gateway.helpid.UserFile': 'The user file used for FILE mode',
  'app.gateway.helpid.LDAP': 'The LDAP configuration used for LDAP mode',
  'app.gateway.helpid.BaseDN':
    'The base dn of the LDAP server, e.g. ou=users,dc=example,dc=org',
  'app.gateway.helpid.Uid': 'The user attribute used to bind user, e.g. cn',
  'app.gateway.helpid.UseSSL': 'Whether to use SSL',
  'app.gateway.helpid.SkipTLS': 'Whether to skip StartTLS',
  'app.gateway.helpid.Insecure': "Whether to skip verifying LDAP server's",
  'app.gateway.helpid.MaxConcurrency':
    'The maximum requests the filter can process concurrently. Default is 10 and minimum value is 1.',
  'app.gateway.helpid.Code':
    'The wasm code, can be the base64 encoded code, or path/url of the file which contains the code.',
  'app.gateway.helpid.Parameters': 'Parameters to initialize the wasm code.',
  'app.gateway.helpid.ProxyPools':
    "The pool without filter is considered the main pool, other pools with filter are considered candidate pools, and a Proxy must contain exactly one main pool. When Proxy gets a request, it first goes through the candidate pools, and if one of the pool's filter matches the request, servers of this pool handle the request, otherwise, the request is passed to the main pool",
  'app.gateway.helpid.ProxyMirrorPool':
    'Define a mirror pool, requests are sent to this pool simultaneously when they are sent to candidate pools or main pool',
  'app.gateway.helpid.WebSocketProxyPools':
    "The pool without filter is considered the main pool, other pools with filter are considered candidate pools, and a Proxy must contain exactly one main pool. When WebSocketProxy gets a request, it first goes through the candidate pools, and if it matches one of the pool's filter, servers of this pool handle the connection, otherwise, it is passed to the main pool",
  'app.gateway.helpid.DefaultOrigin':
    'Easegress need to set the Origin header when connecting to the backend service, if the client request does not have this header, this value is used',

  'app.gateway.helpid.Email': 'An email address for CA account',
  'app.gateway.helpid.DirectoryURL': 'The endpoint of the CA directory',
  'app.gateway.helpid.RenewBefore':
    'A certificate will be renewed before this duration of its expire time',
  'app.gateway.helpid.EnableHTTP01':
    'Enable HTTP-01 challenge (Easegress need to be accessable at port 80 when true)',
  'app.gateway.helpid.EnableTLSALPN01':
    'Enable TLS-ALPN-01 challenge (Easegress need to be accessable at port 443 when true)',
  'app.gateway.helpid.EnableDNS01': 'Enable DNS-01 challenge',
  'app.gateway.helpid.Domains': 'Domains to be managed',
  'app.gateway.helpid.DNSProvider': 'DNS provider information',
  'app.gateway.helpid.Address': 'Consul server address',
  'app.gateway.helpid.Scheme': 'Communication scheme',
  'app.gateway.helpid.DataCenter': 'Datacenter name',
  'app.gateway.helpid.Token': 'ACL token for communication',
  'app.gateway.helpid.Namespace': 'Namespace to use',
  'app.gateway.helpid.SyncInterval': 'Interval to synchronize data',
  'app.gateway.helpid.ServiceTags': 'Service tags to query',
  'app.gateway.helpid.Driver': 'Driver to use',
  'app.gateway.helpid.Topic': 'Produce topic',
  'app.gateway.helpid.KafkaTopic': 'Kafka Produce topic',
  'app.gateway.helpid.Brokers': 'Broker addresses',
  'app.gateway.helpid.KafkaBrokers': 'Kafka Broker addresses',
  'app.gateway.helpid.Endpoints': 'Endpoints of Etcd servers',
  'app.gateway.helpid.Prefix': 'Prefix of the keys of services',
  'app.gateway.helpid.CacheTimeout': 'Timeout of cache',
  'app.gateway.helpid.BeforePipeline': 'Spec for before pipeline',
  'app.gateway.helpid.AfterPipeline': 'Spec for after pipeline',
  'app.gateway.helpid.KubeConfig': 'Path of the Kubernetes configuration file.',
  'app.gateway.helpid.MasterURL': 'The address of the Kubernetes API server.',
  'app.gateway.helpid.Namespaces':
    'An array of Kubernetes namespaces which the IngressController needs to watch, all namespaces are watched if left empty.',
  'app.gateway.helpid.IngressClass':
    'The IngressController only handles Ingresses with ingressClassName set to the value of this option.',
  'app.gateway.helpid.HTTPServer':
    'Basic configuration for the shared HTTP traffic gate. The routing rules will be generated dynamically according to Kubernetes ingresses and should not be specified here.',
  'app.gateway.helpid.Servers': 'Servers of Nacos',
  'app.gateway.helpid.Username': 'The username of client',
  'app.gateway.helpid.Password': 'The password of client',
  'app.gateway.helpid.ZKServices': 'Zookeeper service addresses',
  'app.gateway.helpid.ConnTimeout': 'Timeout of connection',

  'app.gateway.helpid.CertExtractor':
    'CertExtractor extracts a value from requests TLS certificates Subject or Issuer metadata (https://pkg.go.dev/crypto/x509/pkix#Name) and adds the value to headers. Request can contain zero or multiple certificates so the position (first, second, last, etc) of the certificate in the chain is required',
  'app.gateway.helpid.CORSAdaptor':
    'The CORSAdaptor handles the CORS preflight, simple and not so simple request for the backend service',
  'app.gateway.helpid.DataBuilder':
    'DataBuilder is used to manipulate and store data. The data from the previous filter can be transformed and stored in the context so that the data can be used in subsequent filters',
  'app.gateway.helpid.Fallback':
    'The Fallback filter mocks a response as the fallback action of other filters. The below example configuration mocks the response with a specified status code, headers, and body',
  'app.gateway.helpid.HeaderLookup':
    'HeaderLookup checks custom data stored in etcd and put them into HTTP header',
  'app.gateway.helpid.HeaderToJSON':
    'The HeaderToJSON converts HTTP headers to JSON and combines it with the HTTP request body. To use this filter, make sure your HTTP Request body is empty or JSON schema',
  'app.gateway.helpid.Kafka':
    'The Kafka filter converts HTTP Requests to Kafka messages and sends them to the Kafka backend. The topic of the Kafka message comes from the HTTP header, if not found, then the default topic will be used. The payload of the Kafka message comes from the body of the HTTP Request',
  'app.gateway.helpid.Mock':
    'The Mock filter mocks responses according to configured rules, mainly for testing purposes',
  'app.gateway.helpid.OIDCAdaptor':
    'OpenID Connect(OIDC) is an identity layer on top of the OAuth 2.0 protocol. It enables Clients to verify the identity of the End-User based on the authentication performed by an Authorization Server, as well as to obtain basic profile information about the End-User',
  'app.gateway.helpid.OPAFilter':
    'The Open Policy Agent (OPA) is an open source, general-purpose policy engine that unifies policy enforcement across the stack. It provides a high-level declarative language, which can be used to define and enforce policies in Easegress API Gateway. Currently, there are 160+ built-in operators and functions we can use, for examples net.cidr_contains and contains',
  'app.gateway.helpid.Proxy':
    'The Proxy filter is a proxy of the backend service',
  'app.gateway.helpid.RateLimiter':
    'RateLimiter protects backend service for high availability and reliability by limiting the number of requests sent to the service in a configured duration',
  'app.gateway.helpid.Redirector':
    'The Redirector filter is used to do HTTP redirect. Redirector matches request url, do replacement, and return response with status code of 3xx and put new path in response header with key of Location',
  'app.gateway.helpid.RemoteFilter':
    'The RemoteFilter is a filter making remote service act as an internal filter. It forwards original request & response information to the remote service and returns a result according to the response of the remote service',
  'app.gateway.helpid.RequestAdaptor':
    'The RequestAdaptor modifies the original request according to configuration',
  'app.gateway.helpid.RequestBuilder':
    'The RequestBuilder creates a new request from existing requests/responses according to the configuration, and saves the new request into the namespace it is bound',
  'app.gateway.helpid.ResponseAdaptor':
    'The ResponseAdaptor modifies the input response according to the configuration',
  'app.gateway.helpid.ResponseBuilder':
    'The ResponseBuilder creates a new response from existing requests/responses according to the configuration, and saves the new response into the namespace it is bound',
  'app.gateway.helpid.ResultBuilder':
    'ResultBuilder generates a string, which will be the result of the filter. This filter exists to work with the jumpIf mechanism for conditional jumping',
  'app.gateway.helpid.Validator':
    'The Validator filter validates requests, forwards valid ones, and rejects invalid ones. Four validation methods (headers, jwt, signature, oauth2 and basicAuth) are supported up to now, and these methods can either be used together or alone. When two or more methods are used together, a request needs to pass all of them to be forwarded',
  'app.gateway.helpid.WasmHost':
    'The WasmHost filter implements a host environment for user-developed WebAssembly code. Below is an example configuration that loads wasm code from a file, and more details could be found in this document',
  'app.gateway.helpid.WebSocketProxy':
    'The WebSocketProxy filter is a proxy of the websocket backend service',

  'app.gateway.helpid.MaxAttempts':
    'The maximum number of attempts (including the initial one). Default is 3',
  'app.gateway.helpid.WaitDuration':
    'The base wait duration between attempts. Default is 500ms',
  'app.gateway.helpid.BackOffPolicy':
    'The back-off policy for wait duration, could be EXPONENTIAL or RANDOM and the default is RANDOM. If configured as EXPONENTIAL, the base wait duration becomes 1.5 times larger after each failed attempt',
  'app.gateway.helpid.RandomizationFactor':
    'Randomization factor for actual wait duration, a number in interval [0, 1], default is 0. The actual wait duration used is a random number in interval [(base wait duration) * (1 - randomizationFactor),  (base wait duration) * (1 + randomizationFactor)]',

  'app.gateway.helpid.SlidingWindowType':
    'Type of the sliding window which is used to record the outcome of requests when the CircuitBreaker is CLOSED. Sliding window can either be COUNT_BASED or TIME_BASED. If the sliding window is COUNT_BASED, the last slidingWindowSize requests are recorded and aggregated. If the sliding window is TIME_BASED, the requests of the last slidingWindowSize seconds are recorded and aggregated. Default is COUNT_BASED',
  'app.gateway.helpid.FailureRateThreshold':
    'Failure rate threshold in percentage. When the failure rate is equal to or greater than the threshold the CircuitBreaker transitions to OPEN and starts short-circuiting requests. Default is 50',
  'app.gateway.helpid.SlowCallRateThreshold':
    'Slow rate threshold in percentage. The CircuitBreaker considers a request as slow when its duration is greater than slowCallDurationThreshold. When the percentage of slow requests is equal to or greater than the threshold, the CircuitBreaker transitions to OPEN and starts short-circuiting requests. Default is 100',
  'app.gateway.helpid.SlowCallDurationThreshold':
    'Duration threshold for slow call',
  'app.gateway.helpid.SlidingWindowSize':
    'The size of the sliding window which is used to record the outcome of requests when the CircuitBreaker is CLOSED. Default is 100',
  'app.gateway.helpid.PermittedNumberOfCallsInHalfOpenState':
    'The number of permitted requests when the CircuitBreaker is HALF_OPEN. Default is 10',
  'app.gateway.helpid.MinimumNumberOfCalls':
    'The minimum number of requests which are required (per sliding window period) before the CircuitBreaker can calculate the error rate or slow requests rate. For example, if minimumNumberOfCalls is 10, then at least 10 requests must be recorded before the failure rate can be calculated. If only 9 requests have been recorded the CircuitBreaker will not transition to OPEN even if all 9 requests have failed. Default is 10',
  'app.gateway.helpid.MaxWaitDurationInHalfOpenState':
    'The maximum wait duration which controls the longest amount of time a CircuitBreaker could stay in HALF_OPEN state before it switches to OPEN. Value 0 means CircuitBreaker would wait infinitely in HALF_OPEN State until all permitted requests have been completed. Default is 0',
  'app.gateway.helpid.WaitDurationInOpenState':
    'The time that the CircuitBreaker should wait before transitioning from OPEN to HALF_OPEN. Default is 60s',

  PleaseEnterNewPipelineName: 'Please enter the new pipeline name',
  SureToDeploySelectedChanges: 'Are you sure to deploy the selected changes?',
};
