import axios from '@/_common/utils/axios-wrapper';
import settings from '@/_common/utils/settings';

class ServiceAPI {
  getBasicServices(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/control/tenants/${params.tenantId}/basic-services`,
      {
        params,
      }
    );
  }

  getServices(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/control/tenants/${params.tenantId}/services`,
      {
        params,
      }
    );
  }
  createService(params) {
    return axios.post(
      settings.GATEWAY_API_ADDRESS +
        `/v1/control/tenants/${params.tenantId}/services`,
      params,
      { autoError: false }
    );
  }
  getServiceById(params) {
    return axios.get(
      settings.GATEWAY_API_ADDRESS +
        `/v1/control/tenants/${params.tenantId}/services/${params.id}`
    );
  }
  updateService(params) {
    return axios.put(
      settings.GATEWAY_API_ADDRESS +
        `/v1/control/tenants/${params.tenantId}/services/${params.id}`,
      params,
      { autoError: false }
    );
  }
  deleteService(params) {
    return axios.delete(
      settings.GATEWAY_API_ADDRESS +
        `/v1/control/tenants/${params.tenantId}/services/${params.id}`,
      { autoError: false }
    );
  }
}

export default new ServiceAPI();
