import Axios from 'axios';
import getMeta from './meta';
import store from 'store';
const settings = {
  API_ADDRESS: process.env.REACT_APP_API_ADDRESS,
  EASE_WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_ADDRESS,
  CONTEXT_PATH: process.env.PUBLIC_URL,
  IS_PRO: process.env.NODE_ENV === 'production',
  SHOWCASE: `${process.env.REACT_APP_SHOWCASE}` === 'true',
  LOGIN_PATH: '/login',
  REGISTER_PATH: '/register',
  INVITE: '/verify/invite',
  INVITE_REGISTER_PATH: '/invite/register',
  CHANGE_PWD: '/change-password',
  GATEWAY_API_ADDRESS: process.env.REACT_APP_API_ADDRESS,
  SERVICE_API_ADDRESS: process.env.REACT_APP_API_ADDRESS,
  MONITOR_API_ADDRESS: process.env.REACT_APP_API_ADDRESS,
  STACK_API_ADDRESS: process.env.REACT_APP_API_ADDRESS,
  SWAGGER_RESOURCES_PATH: '/v1/apimgmt/swagger-resources',
  REGISTER_SERVICES_LIST_PATH: '/v1/control/directories/users/current/list',
  SERVICE_INSTANCES_PATH: '/v1/monitor/service-instances',
  ACCESS_LOGS_PATH: '/v1/monitor/access-logs',
  APPLICATION_LOGS_PATH: '/v1/monitor/application-logs',
  MIDDLEWARE_LOGS_PATH: '/v1/monitor/middleware-logs',
  TOPN_PATH: '/v1/monitor/topn',
  MONITOR_METRICS_PATH: '/v1/monitor/metrics',
  EASE_MONITOR: '/v1/monitor',
  GOVERNANCE_PARAMETERS_PATH: '/v1/governance/parameters',
  EASE_MONITOR_WITH_TENANT: '/v1/monitor/tenants',
  EASE_STACK_MGMT: '/v1/stack/management/',
  EASE_STACK_TEMPLATE: '/v1/stack/management/template',
  EASE_STACK_INSTANCE: '/v1/stack/management/instance',
  EASE_STACK_MANIFEST: '/v1/stack/management/manifest',
  EASE_STACK_HISTORY_VERSION: '/v1/stack/management/history-version',
  EASE_STACK_CLUSTER: '/v1/stack/management/cluster',
  EASE_STACK_EVENT: '/v1/stack/management/event',
  EASE_STACK_DEPLOYMENT: '/v1/stack/management/deployment',
  EASE_STACK_CONFIGURATION: '/v1/stack/management/configuration',

  EASE_USER_CENTER: '/v1/control',

  EASE_FAAS: '/v1/faas/funcs',

  EASE_MIDDLEWARE_MGMT: '/v1/middleware/management',
  EASE_MIDDLEWARE_MGMT_STATE_MACHINE: '/v1/middleware/management/state-machine',
  EASE_MIDDLEWARE_MGMT_v2: '/v2/middleware/management',
  EASE_TAGS_MGMT: '/v1/control',
  EASE_DEPLOY_PATH: '/v1/deployment',
  EASE_TENANT_MGMT: '/v1/control',
  EASE_MFA_MGMT: '/v1/control/mfa',
  EASE_MFA_MGMT_VERIFY: '/v1/control/sessions',
  EASE_BILLING: '/v1/billing',
  EASE_BILLING_MGMT: '/v1/billing/management',
  EASE_MARKETING_MGMT: '/v1/billing/management/marketing',

  ControlOperations: '/v1/control/operations',
  BillingOperations: '/v1/billing/operations',
  EASE_CICD_TENANT: '/v1/cicd/tenant',
  EASE_CICD_PLATFORM: '/v1/cicd/platform',
  EASE_GPUEYE: '/v1/gpueye',

  page: {
    homeTraffic: {
      'traffic-throughput-metric': {
        name: 'eg-http-request-m1-sum-metric',
        filters: [],
      },
      'traffic-lantency-metric': {
        name: 'eg-http-request-p95-avg-metric',
        filters: [],
      },
      'access-unique-visitor-metric': {
        name: 'access-log-client-ip-cardinality-metric',
        filters: [],
      },
      'access-log-status-code-terms-metric': {
        name: 'access-log-status-code-terms-metric',
        filters: [],
      },
    },
  },
  IS_BETA: false,
  menuMetaData: [
    'menu_overview',
    'menu_traffic',
    'menu_service',
    'menu_monitor',
    'menu_stack',
    'menu_ease_middleware',
    'menu_ease_load',
    'menu_tags',
  ],
  REACT_APP_QUOTA_CONTROL_ENABLED: true,
  CALLBACK_PATH: '/oauth2/callback',
  MAINTENANCE_PATH: '/maintenance',
  PROVIDER: '', // 'datastone' or 'megaease' or ''
  MAINTENANCE_STATUS: '', // 'maintenance',
};

export function initConfig() {
  settings.META = getMeta(settings.IS_BETA);
  if (settings.IS_PRO) {
    return Axios.get(`${settings.CONTEXT_PATH}/cfg.json`).then((res) => {
      const { data } = res;
      if (data && data.apiAddress) {
        settings.API_ADDRESS = data.apiAddress;
        settings.GATEWAY_API_ADDRESS = data.apiAddress;
        settings.SERVICE_API_ADDRESS = data.apiAddress;
        settings.MONITOR_API_ADDRESS = data.apiAddress;
        settings.STACK_API_ADDRESS = data.apiAddress;
      } else {
        //process.env.REACT_APP_API_ADDRESS is unused
        const origin = window.location.origin;
        settings.API_ADDRESS = origin;
        settings.GATEWAY_API_ADDRESS = origin;
        settings.SERVICE_API_ADDRESS = origin;
        settings.MONITOR_API_ADDRESS = origin;
        settings.STACK_API_ADDRESS = origin;
      }
      if (data && data.websocketAddress) {
        settings.EASE_WEBSOCKET_URL = data.websocketAddress;
      }
      if (data && data.page && data.page.homeTraffic) {
        settings.page.homeTraffic = data.page.homeTraffic;
      }
      if (data?.menuMetaData) {
        settings.menuMetaData = data.menuMetaData;
      }
      if (data?.IS_BETA) {
        settings.IS_BETA = data.IS_BETA;
      }
      settings.META = getMeta(settings.IS_BETA);
      if (data?.PROVIDER) {
        settings.PROVIDER = data.PROVIDER;
      }
      if (data?.MAINTENANCE_STATUS) {
        settings.MAINTENANCE_STATUS = data.MAINTENANCE_STATUS;
      }
    });
  } else {
    return Promise.resolve();
  }
}

export default settings;
