//flow

import { SnackbarProvider, withSnackbar } from 'notistack';
import React from 'react';

const holder = {
  enqueueSnackbar: () => alert('Please use MessageStub!'),
  closeSnackbar: () => alert('Please use MessageStub!'),
};

function Tmp({ enqueueSnackbar, closeSnackbar }) {
  holder.enqueueSnackbar = enqueueSnackbar;
  holder.closeSnackbar = closeSnackbar;
  return null;
}
const Wrappered = withSnackbar(Tmp);
export function MessageStub(props) {
  return (
    <SnackbarProvider {...props}>
      {props.children}
      <Wrappered />
    </SnackbarProvider>
  );
}
const defMsgCfg = {
  autoHideDuration: 2000,
  variant: 'default',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};
class Message {
  info = (msg) => {
    holder.enqueueSnackbar(msg, { ...defMsgCfg, variant: 'info' });
  };
  warning = (msg) => {
    holder.enqueueSnackbar(msg, { ...defMsgCfg, variant: 'warning' });
  };
  success = (msg) => {
    holder.enqueueSnackbar(msg, { ...defMsgCfg, variant: 'success' });
  };
  error = (msg) => {
    holder.enqueueSnackbar(msg, { ...defMsgCfg, variant: 'error' });
  };
}
export default new Message();
