const zhCN = {
  'base.LoginLeftContainer.CloudNativeManagementPlatform':
    'Cloud Native 云原生管理平台',
  'base.LoginLeftContainer.OpenSourceFreedom': '开源、自由',
  'base.LoginLeftContainer.LowCostHighAvailability': '低成本、高可用',
  'base.LoginLeftContainer.CloudNativePlatform': 'Cloud Native 平台',

  'base.LoginPage.Welcome': '欢迎',
  'base.LoginPage.UsernameOrEmail': '用户名或邮箱',
  'base.LoginPage.Password': '密码',
  'base.LoginPage.ForgotPassword': '忘记密码？',
  'base.LoginPage.Login': '登录',
  "base.LoginPage.Don'tHaveAnAccountYet": '没有账户？',
  'base.LoginPage.SignUp': '注册',
  'base.LoginPage.PleaseSelectATenant': '请选择一个租户',
  'base.LoginPage.Select': '选择',
  'base.LoginPage.Back': '返回',

  'base.RegisterPage.CreateAccount': '创建账户',
  'base.RegisterPage.Username': '用户名',
  'base.RegisterPage.Email': '邮箱',
  'base.RegisterPage.Password': '密码',
  'base.RegisterPage.ConfirmPassword': '确认密码',
  'base.RegisterPage.Register': '注册',
  'base.RegisterPage.AlreadyAMember': '已经注册？',
  'base.RegisterPage.LogIn': '登录',

  'base.ResetPassword.ResetPassword': '重设密码',
  'base.ResetPassword.Username': '用户名',
  'base.ResetPassword.Email': '邮箱',
  'base.ResetPassword.Password': '新密码',
  'base.ResetPassword.ConfirmPassword': '确认密码',
  'base.ResetPassword.ChangePassword': '修改密码',
  'base.ResetPassword.LogIn': '登录',

  'base.VerifyCode.Code': '输入邮箱验证码',
  'base.VerifyCode.GetCode': '获取验证码',
  'base.email.send.success': '验证码已发送到您的邮箱，请注意查收',
  'base.VerifyCode': '验证码',
  'base.VerifyCode.tips': '请在邮箱中查看您的验证码',
  'global.switchTenant': '切换租户',
  'global.currentTenant': '当前租户',

  'base.LoginPage.twofactorAuthentication': '二次验证',
  'base.LoginPage.AuthenticationCode': '身份验证码',
  'base.LoginPage.Entertheauthenticationcode': '输入身份验证码',
  'base.LoginPage.Verify': '验证',
  'base.LoginPage.Verify.tips':
    '打开移动设备上的双重身份验证器（TOTP）应用程序，以查看您的身份验证码。',
  LoginFailure: '登录失败',
  VerifyFailure: '验证失败',
  PleaseTryAgain: '请重试',

  TotalIncome: '全部收入',
  PlanIncome: '套餐收入',
  GPUIncome: 'GPU收入',
  GPUVendor: 'GPU供应商',
  VendorBinding: '供应商绑定',
  VendorIncome: '收入',
  GPUUsage: 'GPU使用情况',
  GPUUsageHistory: '历史',
  GPUStartUpStatus: 'GPU启动情况',
  GPUUtilization: 'GPU使用率',
  SuspendedStorage: '已挂起存储',
};

export default zhCN;
