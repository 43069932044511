import { SLoginApi as LoginApi } from '@/apis/service';
import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import toolbox from '../../_common/utils/toolbox';
import { parseResponseError } from '../_common/utils/utils';
import './ResetPassword.less';
import { useFormik, Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import * as yup from 'yup';
import settings from '@/_common/utils/settings';
import userApi from '@/apis/userCenter/user-api';
import VerifyCode from './VerifyCode';
import LoginLeftContainer from '@/app/base/LoginLeftContainer';
import { makeStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import userImg from '@/app/_common/assets/user.svg';
import emailImg from '@/app/_common/assets/email.svg';
import passwordImg from '@/app/_common/assets/password.svg';
import validateCorrectImg from '@/app/_common/assets/validate-correct.svg';
import LightTooltip from '@/app/_common/components/LightTooltip';
import usePasswordValidator from './usePasswordValidator';
import PasswordTooltipTitle from './PasswordTooltipTitle';
import { rootHub } from '@/app/_common/utils/mhub.js';
import AppStore from '@/app/base/AppStore';
import { useIntl } from 'react-intl';
import LanguageSwitch from '@/app/_common/components/languageSwitch/LanguageSwitch';
import Manual from '@/app/_common/components/Manual';

const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiInputBase-root': {
      height: '46px',
      fontSize: 14,
    },
  },
}));

yup.addMethod(yup.string, 'strongPassword', strongPasswordMethod);

function strongPasswordMethod() {
  return this.test('strongPasswordTest', null, function (value) {
    const { path, createError } = this;
    switch (Boolean(value)) {
      case !/^(?=.*[a-z])/.test(value):
        return createError({
          path,
          message: 'Password must include lowercase letter',
        });
      case !/^(?=.*[A-Z])/.test(value):
        return createError({
          path,
          message: 'Password must include uppercase letter',
        });
      case !/^(?=.*[0-9])/.test(value):
        return createError({ path, message: 'Password must include digit' });
      case !/^(?=.*[^a-zA-Z0-9])/.test(value):
        return createError({
          path,
          message: 'Password must include special character',
        });
      default:
        return true;
    }
  });
}

const validationSchema = yup.object({
  email: yup.string('Enter your email').email().required('Email is required'),
  password: yup
    .string('Enter your password')
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(64, 'Password contains a maximum of 64 characters')
    // eslint-disable-next-line no-control-regex
    .matches(/^[\x00-\x7F]+$/, 'Password must contain only ASCII characters')
    .strongPassword(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  code: yup
    .string('Enter your verificationCode')
    .required('Verification Code is required')
    .length(6, 'Verification Code must be 6 characters'),
});

export default function ResetPassword({ history, location }) {
  const classes = useStyles();

  const intl = useIntl();

  const handleResetPassword = async (values) => {
    const { password, code, email } = values;
    try {
      await userApi.changePasswordWithoutUsername({
        code,
        newPass: password,
        email,
      });
      toolbox.success('密码修改成功，请重新登录');
      const hub = rootHub();
      const [appStore] = hub.useSome(AppStore);

      appStore.logout();
    } catch (err) {
      toolbox.error(parseResponseError(err));
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      code: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleResetPassword,
  });

  const { passwordField, validatorPassword } = usePasswordValidator();

  return (
    <div className="change-password-container">
      <LoginLeftContainer page="reset-password" />
      <div className="change-password-wrapper">
        <div className="change-password-header-language-switch">
          <div className="change-password-header-manual">
            <Manual size={20} />
          </div>
          <LanguageSwitch useTitle={true} />
        </div>
        <div className="change-password-form">
          <div className="change-password-title">
            {intl.formatMessage({
              id: 'base.ResetPassword.ResetPassword',
            })}
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="custom-change-password-form"
          >
            <div className="custom-change-password-item">
              <TextField
                fullWidth
                id="email"
                name="email"
                className={classes.textField}
                placeholder={intl.formatMessage({
                  id: 'base.ResetPassword.Email',
                })}
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ width: '58px', justifyContent: 'center' }}
                    >
                      <img src={emailImg} alt="email" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="custom-change-password-item">
              <LightTooltip
                disableHoverListener
                disableTouchListener
                title={<PasswordTooltipTitle passwordField={passwordField} />}
                placement="top-end"
                arrow
              >
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  className={classes.textField}
                  placeholder={intl.formatMessage({
                    id: 'base.ResetPassword.Password',
                  })}
                  type="password"
                  value={formik.values.password}
                  onChange={(e) => {
                    formik.handleChange(e);
                    validatorPassword(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ width: '58px', justifyContent: 'center' }}
                      >
                        <img src={passwordImg} alt="password" />
                      </InputAdornment>
                    ),
                  }}
                />
              </LightTooltip>
            </div>

            <div className="custom-change-password-item">
              <TextField
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                className={classes.textField}
                placeholder={intl.formatMessage({
                  id: 'base.ResetPassword.ConfirmPassword',
                })}
                type="password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ width: '58px', justifyContent: 'center' }}
                    >
                      <img src={passwordImg} alt="password" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="custom-change-password-item">
              <div className="custom-change-password-input-wrapper">
                <VerifyCode
                  onClick={async (
                    callback,
                    time,
                    setLoading,
                    errorCallback
                  ) => {
                    const err = await formik.setFieldTouched(
                      'email',
                      true,
                      true
                    );
                    if (!err.hasOwnProperty('email')) {
                      if (time === 0) {
                        setLoading(true);
                        const { email } = formik.values;
                        try {
                          const res = await LoginApi.sendCode({
                            email,
                          });
                          if (res) {
                            toolbox.success(
                              '验证码已发送到您的邮箱，请注意查收'
                            );
                            callback && callback();
                          }
                        } catch (err) {
                          const message = parseResponseError(err);
                          toolbox.warn(`Send failure: ${message}`);
                          errorCallback && errorCallback();
                        } finally {
                          setLoading(false);
                        }
                      }
                    }
                  }}
                  formik={formik}
                  passwordField={passwordField}
                />
              </div>
            </div>

            <Button
              type="submit"
              bsStyle="primary"
              className="change-password-button"
              size="normal"
            >
              {intl.formatMessage({
                id: 'base.ResetPassword.ChangePassword',
              })}
            </Button>
          </form>

          <div className="other-option">
            <Button
              bsStyle="link"
              className="login-button"
              onClick={() => history.push(settings.LOGIN_PATH)}
            >
              {intl.formatMessage({
                id: 'base.ResetPassword.LogIn',
              })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
